import React from "react";
import { Modal, Button } from "react-bootstrap";
import { commonUtils } from "../CommonUtils";
const CustomModal = (props) => {
  const { show, handleClose, title, body, performSubmit, post, btnText } =
    props;

  return (
    <Modal show={show} onHide={handleClose} backdrop={post ? "static" : true}>
      <Modal.Header closeButton className="p-3">
        <Modal.Title
          className="mb-1"
          style={{ fontSize: "15px", fontWeight: 600 }}
        >
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3 bordr">{body}</Modal.Body>
      {props.post == true ? (
        <></>
      ) : (
        <Modal.Footer className="bordr pad_13">
          <commonUtils.CustomButton
            btnText={btnText}
            btnWidth="fit-content"
            variant="primary"
            perform={() => performSubmit()}
            isDisabled={false}
          />
          <commonUtils.CustomButton
            btnText="Cancel"
            btnWidth="fit-content"
            variant="danger"
            perform={() => handleClose()}
            isDisabled={false}
          />
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CustomModal;
