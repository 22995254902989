import React, { useRef, useState, useEffect, forwardRef } from "react";
import profile from "../../assets/images/profile.jpg";
import StarRating from "../Dashboard/DashboardTabs/StarRating";
import { HiLocationMarker } from "react-icons/hi";
import RatingBar from "../plugins/RatingBar";
import { Card, Col, Row } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import usrImg from "../../assets/images/user_Img.jpg";

const ViewShare = forwardRef((props, canvasRef) => {
  const [rating, setRating] = useState("");
  const [location, setLocation] = useState("");

  const rmvMenFormat = (value) => {
    const mentionRegex = /\@\[([^)]+)\]\((\d+)\)/g;
    const transformedComment = value.replace(mentionRegex, "@$1");
    return transformedComment;
  };

  useEffect(() => {
    const userLocation = props.userData.city + ", " + props.userData.state;
    setLocation(userLocation);

    const reviewRating =
      props.shareData.rating ?? props.userData.rating;
    console.log("Average Review Rating:", reviewRating);
    setRating(reviewRating);
  }, [props.userData, props.shareData]);

  //

  const renderStars = () => {
    const stars = [];
    const maxStars = 5;

    for (let i = 1; i <= maxStars; i++) {
      let starColor = "";
      let fillPercentage = 0;

      // Calculate fill percentage for each star
      if (i <= Math.floor(rating)) {
        fillPercentage = 100; // Full stars
      } else if (i === Math.ceil(rating) && i > Math.floor(rating)) {
        fillPercentage = (rating % 1) * 100; // Partial star
      }

      // Determine color based on rating value
      if (rating <= 0) {
        starColor = "gray"; // All stars gray when rating is 0
      } else if (rating < 2) {
        starColor = "red"; // Stars red if rating is below 2
      } else if (rating >= 4 && rating <= 5) {
        starColor = "green"; // Stars green if rating is 4 or above
      } else {
        starColor = "#FFA500"; // Stars yellow if rating is between 2 and 4
      }

      stars.push(
        <span
          key={i}
          className={`star ${props.disabled ? "disabled" : ""} ${
            props.isFollow ? "fs_20" : ""
          } ${props.isReview ? "isreview" : ""} ${props.customClass || ""} ${
            props.lineH || ""
          }`}
          style={{
            position: "relative",
            display: "inline-block",
            fontSize: "20px",

            color: "gray", // Default star color
          }}
        >
          &#9733;
          {/* Partial fill overlay */}
          {fillPercentage > 0 && (
            <span
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: `${fillPercentage}%`,
                overflow: "hidden",
                color: starColor,
                display: "inline-block",
              }}
            >
              &#9733;
            </span>
          )}
        </span>
      );
    }
    return stars;
  };
  return (
    <>
      <Card ref={canvasRef} className="shadow" style={{ maxWidth: "500px" }}>
        <Card.Body className="p-0 cd_bd">
          <div className="d-flex w-100">
            <Col sm={12} md={12} lg={12} xl={12} className="p-0">
              <div className="d-flex w-100">
                <Col
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  style={{
                    flex: "19 0 33.33333%",
                    maxWidth: "40.33333%",
                  }}
                  className="pad_shr text-center"
                >
                  <div>
                    <img
                      className="shareLogo"
                      src={
                        props.shareData.userImagePath
                          ? props.shareData.userImagePath
                          : profile
                      }
                    />
                  </div>
                  {/* <img className='share_img' src={usrImg}/> */}
                  <div className="fz-15 font-weight-500 mb-1">
                    {props.shareData.userName}
                  </div>

                  {props.shareData.reviewerLocation && (
                    <div
                      className="d-flex justify-content-center mt-1"
                      title={location}
                    >
                      <div className="fz_14 d-flex align-items-center">
                        <HiLocationMarker />
                        &nbsp;
                      </div>
                      <div className="fz_13">
                        {location.length > 15
                          ? location.substring(0, 14) + "..."
                          : location}
                      </div>
                    </div>
                  )}
                </Col>

                <Col sm={12} md={8} lg={8} xl={8} className="bdr_lt pad_shr">
                  <div className="d-flex">
                    <div
                      className="fol_name text-start text_sty pb-1"
                      style={{ minWidth: "100px" }}
                    >
                      Reviewed by:
                    </div>
                    <span className="mar_stty text_sty">
                      {props.shareData.reviewerName}
                    </span>
                  </div>

                  <div className="d-flex">
                    <div
                      className="fol_name text-start text_sty pb-1"
                      style={{ minWidth: "100px" }}
                    >
                      Role:
                    </div>
                    <span className="mar_stty text_sty">
                      {props.shareData.userRole}
                    </span>
                  </div>

                  {props.shareData.comment && (
                    <div className="d-flex">
                      <div
                        className="fol_name text-start text_sty"
                        style={{ minWidth: "100px" }}
                      >
                        Comment:
                      </div>
                      <span
                        className="mar_stty text_sty"
                        title={props.shareData.comment}
                      >
                        {props.shareData.comment
                          ? props.shareData.comment.length > 20
                            ? rmvMenFormat(props.shareData.comment).substring(
                                0,
                                20
                              ) + "..."
                            : rmvMenFormat(props.shareData.comment)
                          : ""}
                      </span>
                    </div>
                  )}
                  <div className="d-flex align-items-center">
                    <div
                      className="fol_name text-start text_sty"
                      style={{ minWidth: "100px" }}
                    >
                      Rating:
                    </div>
                    <span
                      className="fz-12"
                      style={{ paddingLeft: "6px", fontWeight: "bold" }}
                    >
                      {rating ? rating.toFixed(1) : "0.0"}
                    </span>
                    <div
                      className="d-flex align-items-center"
                      style={{ marginLeft: "5px" }}
                    >
                      {renderStars()}
                    </div>
                  </div>
                </Col>
              </div>
            </Col>
          </div>
        </Card.Body>
      </Card>
    </>
  );
});

export default ViewShare;
