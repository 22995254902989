import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { BiX } from "react-icons/bi";
import HeaderMenu from "../HeaderMenu";
import SideBar from "../AdminSideBar";
import BreadCrumb from "../../plugins/Breadcrumb";
import { useNavigate, useLocation } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { commonUtils } from "../../CommonUtils";
const BusinessDetailsPage = () => {
  const location = useLocation();
  const id = location.state?.id;
  const [business, setBusiness] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isDocumentViewerOpen, setIsDocumentViewerOpen] = useState(false);
  const [isBusinessDetailsOpen, setIsBusinessDetailsOpen] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    fetchBusinessDetails();
  }, []);

  const fetchBusinessDetails = async () => {
    try {
      const response = await window.apiService.viewBusinessDetails(id);
      setBusiness(response.data.data);
    } catch (error) {
      console.error("Error fetching business details:", error);
    }
  };

  const handleViewDocument = (filePath) => {
    setSelectedDocument(filePath);
    setIsDocumentViewerOpen(true);
    setIsBusinessDetailsOpen(false); // Close business details when document viewer is opened
  };

  const handleCloseDocumentViewer = () => {
    setIsDocumentViewerOpen(false);
    setIsBusinessDetailsOpen(true); // Reopen business details when document viewer is closed
  };

  // const toggleBusinessDetails = () => {
  //   setIsBusinessDetailsOpen(!isBusinessDetailsOpen);
  // };

  return (
    <>
      <HeaderMenu />
      <SideBar />
      <div className="main-content ml_200">
        {/* <BreadCrumb /> */}
        <div
          className="business-details-container"
          style={{ marginLeft: "27px", position: "relative" }}
        >
          <div
            className="toggle-details justify-content-between align-items-center mb-4"
            style={{ display: "flex", alignItems: "center" }}
          >
            <h4>Business Details</h4>
            <commonUtils.CustomButton
              btnText={
                <div className="d-flex align-items-center">
                  <IoArrowBack /> <span>Back</span>
                </div>
              }
              btnWidth="fit-content"
              variant="dark"
              perform={() => navigate("/dashboard/business")}
              isDisabled={false}
            />
          </div>
          {/* {isBusinessDetailsOpen && ( */}
          <Row>
            <Col xs={12} sm={4} className="mb-4">
              <strong>Company Name:</strong> {business?.companyName}
            </Col>
            <Col xs={12} sm={4} className="mb-4">
              <strong>AIN / TIN Number:</strong> {business?.itiNo || "-"}
            </Col>
            <Col xs={12} sm={4} className="mb-4">
              <strong>Phone:</strong> {business?.phone}
            </Col>
            <Col xs={12} sm={4} className="mb-4">
              <strong>Email:</strong> {business?.email}
            </Col>
            <Col xs={12} sm={4} className="mb-4">
              <strong>Description:</strong>{" "}
              {business?.description || "Not provided"}
            </Col>
            <Col xs={12} sm={4} className="mb-4">
              <strong>City:</strong> {business?.city}
            </Col>
            <Col xs={12} sm={4} className="mb-4">
              <strong>State:</strong> {business?.state}
            </Col>
            <Col xs={12} sm={4} className="mb-4">
              <strong>Country:</strong> {business?.country || "-"}
            </Col>
            <Col xs={12} sm={4} className="mb-4">
              <strong>Zipcode:</strong> {business?.zipcode || "-"}
            </Col>
            <Col xs={12} sm={4} className="mb-4">
              <strong>Website:</strong> {business?.website || "-"}
            </Col>
            <Col xs={12} sm={4} className="mb-4">
              <strong>Facebook URL:</strong> {business?.facebookUrl || "-"}
            </Col>
            <Col xs={12} sm={4} className="mb-4">
              <strong>Instagram URL:</strong> {business?.instagramUrl || "-"}
            </Col>
            <Col xs={12} sm={4} className="mb-4">
              <strong>LinkedIn URL:</strong> {business?.linkedinUrl || "-"}
            </Col>
            <Col xs={12} sm={4} className="mb-4">
              <strong>Twitter URL:</strong> {business?.twitterUrl || "-"}
            </Col>
            <Col xs={12} sm={4} className="mb-4">
              <strong>Since:</strong> {business?.yearStarted || "-"}
            </Col>
            <Col xs={12} sm={4} className="mb-4">
              <strong>Documents Uploaded:</strong>
              {business?.businessFiles && business?.businessFiles.length > 0 ? (
                <ul>
                  {business.businessFiles.map((file, index) => (
                    <li key={index}>
                      <Button
                        variant="link"
                        onClick={() => handleViewDocument(file.filePath)}
                      >
                        {file.fileName}
                      </Button>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No documents uploaded</p>
              )}
            </Col>
          </Row>
          {/* )} */}
        </div>
        {isDocumentViewerOpen && (
          <Row>
            <Col xs={12}>
              <div
                className="document-viewer"
                style={{ width: "96%", marginLeft: "27px" }}
              >
                <h4 className="mb-4" style={{ marginLeft: "27px" }}>
                  Document Viewer
                </h4>
                <iframe
                  src={selectedDocument}
                  width="100%"
                  height="600px"
                  title="Document Viewer"
                ></iframe>
                <div
                  style={{ position: "absolute", top: "27px", right: "27px" }}
                >
                  <BiX
                    size={32}
                    color="red"
                    onClick={handleCloseDocumentViewer}
                    style={{ cursor: "pointer", marginTop: "-45px" }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default BusinessDetailsPage;
