import React, { useState, useEffect } from "react";
import { useToast } from "../../Toast";
import HeaderMenu from "../headerMenu";
import SideBar from "../SideBar";
import { Card, Container, Row, Col } from "reactstrap";
import BreadCrumb from "../../plugins/Breadcrumb";
import { connect } from "react-redux";

const TiiptokPreference = (props) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [approvedTiipstrOption, setApprovedTiipstrOption] = useState(false);
    const toast = useToast();

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleApprovedTiipstrChange = (e) => {
        setApprovedTiipstrOption(e.target.checked);
    };

    useEffect(() => {
        listTiiptokPref();
    }, [props]);

    const updateTiiptokPref = async (e) => {
        e.preventDefault();

        const promises = [];

        if (approvedTiipstrOption) {
            const approvedParams = {
                name: "view_post_comment",
                value: 1,
                userId: props.userId,
                userType: props.userType,
                typeId: props.profileId ? props.profileId : null,
                type: "approvedtiipstrs"
            };
            promises.push(window.apiService.updateTiiptokPref(approvedParams));
        }

        if (!approvedTiipstrOption) {
            const approvedParams = {
                name: "view_post_comment",
                value: 1,
                userId: props.userId,
                userType: props.userType,
                typeId: props.profileId ? props.profileId : null,
                type: "approvedtiipstrs",
                value: false
            };
            promises.push(window.apiService.updateTiiptokPref(approvedParams));
        }

        if (selectedOption) {
            const selectedParams = {
                name: selectedOption,
                value: 1,
                userId: props.userId,
                userType: props.userType,
                typeId: props.profileId ? props.profileId : null,
                type: "kyctiipstrs"
            };
            promises.push(window.apiService.updateTiiptokPref(selectedParams));
        }

        try {
            const responses = await Promise.all(promises);

            const allSuccess = responses.every(response => response.data.status === 'OK');

            if (allSuccess) {
                toast.success('Updated successfully');
            } else {
                toast.error("Error");
            }
            listTiiptokPref();
        } catch (error) {
            toast.error("Error occurred while updating preferences.");
        }
    };



    const listTiiptokPref = async () => {
        const params = {
            userId: props.userId,
            userType: props.userType,
            typeId: props.profileId ? props.profileId : null,
        };
        try {
            if (params.userId) {
                const response = await window.apiService.listTiiptokPref(params);
                if (response.data.data) {
                    setSelectedOption(response.data.data[0].type == "kyctiipstrs" ? response.data.data[0].name : response.data.data[1].type == "kyctiipstrs" ? response.data.data[1].name : "");
                    setApprovedTiipstrOption(response.data.data[0].type == "approvedtiipstrs" && response.data.data[0].value == true ? true : response.data.data[1].type == "approvedtiipstrs" && response.data.data[1].value == true ? true : false);
                }
            }
        } catch (error) {
            console.log("Error Occurred");
        }
    };

    return (
        <>
            <HeaderMenu />
            <SideBar />
            <div className="main-content ml_200">
                {/* <BreadCrumb /> */}
                <Card className="shade h-500">
                    <Container fluid>
                        <div className="header-body pt-3">
                            <Row>
                                <Col lg="12" xl="12">
                                    <div className="d-flex mt-2 toggle_gap">
                                        <label className="font-weight-500 fz_14">
                                            Tiiptok Preference
                                        </label>
                                    </div>

                                    <div style={{ marginLeft: "20px" }}>
                                        <div className="font-weight-500 fz_14 m_b8">KYC Tiipstrs</div>
                                        <form onSubmit={updateTiiptokPref}>
                                            <div className="d-flex m_b8">
                                                <input
                                                    className="c_pointer"
                                                    type="radio"
                                                    value="view_and_comment"
                                                    checked={selectedOption === 'view_and_comment'}
                                                    onChange={handleOptionChange}
                                                />
                                                <label className="marL mb-0">
                                                    All KYC tiipstrs can view and comment
                                                </label>
                                            </div>
                                            <div className="d-flex">
                                                <input
                                                    className="c_pointer"
                                                    type="radio"
                                                    value="view_only"
                                                    checked={selectedOption === 'view_only'}
                                                    onChange={handleOptionChange}
                                                />
                                                <label className="marL mb-0">
                                                    All KYC tiipstrs can view but not comment
                                                </label>
                                            </div>
                                            <div className="row col-12 col-sm-6 col-md-6 col-lg-6 mt_20"><hr /></div>
                                            <div className="font-weight-500 fz_14 m_b8">Approved Tiipstrs</div>
                                            <div className="d-flex m_b18">
                                                <input
                                                    className="c_pointer"
                                                    type="checkbox"
                                                    checked={approvedTiipstrOption}
                                                    onChange={handleApprovedTiipstrChange}
                                                />
                                                <label className="marL mb-0">
                                                    All Approved tiipstrs can view, post, and comment
                                                </label>
                                            </div>
                                            <button
                                                type="submit"
                                                className="btn btn-primary rev_btn"
                                            >
                                                Update
                                            </button>
                                        </form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Card>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        userId: state.user.userId,
        userType: state.user.userType,
        profileId: state.user.profileId,
    };
};

export default connect(mapStateToProps)(TiiptokPreference);
