import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  ToggleButton,
  ButtonGroup,
  Modal,
  Form,
  Table,
} from "react-bootstrap";
import { TiTick } from "react-icons/ti";
import { FaCircle } from "react-icons/fa";
import { LuRocket } from "react-icons/lu";
import { FaArrowRight } from "react-icons/fa6";
import { connect } from "react-redux";
import { useToast } from "../Toast";
import { format } from "date-fns";
import { useSubscription } from "../SubscriptionContext";
import { isBefore } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { AiOutlineClose } from "react-icons/ai";
import loader from "../../assets/images/loader.gif";
import { FaArrowLeft } from "react-icons/fa";

import { FaRegCreditCard } from "react-icons/fa6";
import pendingimg from "../../assets/images/pendingstage.png";

import visaCard from "../../assets/images/Visa_Inc._logo.svg.png";
import mastercard from "../../assets/images/Mastercard-logo.svg.png";
import americancard from "../../assets/images/american express.png";
import discovercard from "../../assets/images/Discover_Card_logo.svg.png";
import jcbcard from "../../assets/images/JCB_logo.svg.png";
import dinerscard from "../../assets/images/Diners_Club_Logo3.svg";
import { commonUtils } from "../CommonUtils";
import SockJS from "sockjs-client";
import { Client } from "@stomp/stompjs";
import { useDispatch } from "react-redux";
import { userAction } from "../../Redux/Actions/userAction";

const SOCKET_URL = process.env.REACT_APP_BASEURL + "/ws";

const PlanDetails = ({ back, ...props }) => {
  const dispatch = useDispatch();
  const { login } = useAuth();
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [planList, setPlanList] = useState([]);
  const [activePlanId, setActivePlanId] = useState(null);
  const [validity, setValidity] = useState(null);
  const [trialPlan, setTrialPlan] = useState("");
  const [trialStatus, setTrialStatus] = useState("");
  const [trialStartDate, setTrialStartDate] = useState(null);
  const [trialEndDate, setTrialEndDate] = useState("");
  const [planStatus, setPlanStatus] = useState("");
  const [paymentProfileId, setPaymentProfileId] = useState("");
  const [PrevPlanId, setPrevPlanId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const [pendingParams, setPendingParams] = useState(null);
  const [actions, setActions] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [trialUsed, setTrialUsed] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();
  const { refreshSubscriptions } = useSubscription();

  const [paymentData, setPaymentData] = useState([]);

  let stompClient = null;

  const [plan, SetPlan] = useState("");
  const [errors, setErrors] = useState({});

  const [eventType, setEventType] = useState("");

  const [planDetials, setPlanDetials] = useState();

  //authorize.net

  const [paymentPopup, setPaymentPopup] = useState(false);
  const [pendingPopup, setPendingPopup] = useState(false);
  const [addFeaturePaymentPopup, setAddFeaturePaymentPopup] = useState(false);
  const [upgradePopup, setUpgradePopup] = useState(false);
  const [downgradePopup, setDowngradePopup] = useState(false);
  const [upgradeAmount, setUpgradeAmount] = useState("");
  const [currentPlanEndDate, setCurrentPlanEndDate] = useState("");
  const [currentPlanAction, setCurrentPlanAction] = useState("");
  const [activePlan, setActivePlan] = useState("");
  const [updatingPlan, setUpdatingPlan] = useState("");
  const [activePlanPrice, setActivePlanPrice] = useState();
  const [updatePlanPrice, setUpdatePlanPrice] = useState();
  const [planInformation, setPlansInformation] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState({
    creditCardNumber: "",
    expirationDate: "",
    cvvCode: "",
    amount: "",
    holdername: "",
    recurring: true,
  });

  const Refresh = async () => {
    if (props.authToken) {
      const switchAccount = localStorage.getItem("switchAccount");
      const params = {
        profileId: props.profileId,
        authToken: props.authToken,
        userType: props.userType ? props.userType : "Personal",
        switchAccount: switchAccount ? switchAccount : false,
      };
      try {
        const data = await window.apiService.refresh(params);
        let result = data.data;
        if (data) {
          dispatch(userAction(result));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    loadPlans();
    getPlansById();
  }, []);

  const goBack = () => {
    // back(false);
    navigate("/dashboard/user_settings/plans_billings");
  };

  const loadPlans = async () => {
    setLoading(true);
    try {
      const response = await window.apiService.loadPlans();
      const allPlans = response.data.recordInfo;
      setPlanList(allPlans);
      if (response?.data?.recordInfo) {
        // Transform the response into a key-value object
        const formattedPlans = response.data.recordInfo.reduce((acc, plan) => {
          acc[plan.id] = {
            servicePlan: plan.servicePlan,
            price: `$${plan.price.toFixed(2)}`, // Format price to 2 decimal places
          };
          return acc;
        }, {});

        // Update state with the formatted plan list
        setPlansInformation(formattedPlans);
      } else {
        console.error("Unexpected response format:", response);
      }
      const trialPlan = allPlans.find((plan) => plan.isTrial === 1);
      setTrialPlan(trialPlan.servicePlan);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("Updated Plan Information:", planInformation[1]);
  }, [planInformation]);

  const getPlansById = async () => {
    if (props) {
      try {
        const response = await window.apiService.getPlanById(props.userId);
        if (response.data.status === "OK") {
          setActivePlanId(response.data.recordInfo.planId);
          setValidity(response.data.recordInfo.endDate);
          setTrialStatus(response.data.recordInfo.trialStatus);
          setTrialStartDate(response.data.recordInfo.trialStartDate);
          setTrialUsed(response.data.recordInfo.trialUsed);
          setTrialEndDate(response.data.recordInfo.trialEndDate);
          setPlanStatus(response.data.recordInfo.status);
          setPaymentProfileId(response.data.recordInfo.paymentProfileId);
          setPrevPlanId(response.data.recordInfo.planId);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  console.log("paymentProfileId", paymentProfileId);

  const getPlansfrompaymentById = async () => {
    if (props) {
      try {
        const response = await window.apiService.getPlanById(props.userId);
        if (response.data.recordInfo.status == "active") {
          toast.success("Plan Activated");
          getPlansById();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const filteredPlans = planList.filter(
    (plan) => plan.billingCycle === billingCycle
  );
  const colors = ["#3498db", "#28a745", "#6f42c1", "#ffa000", "#9b59b6"];

  const addUserPlans = async (plan, status) => {
    setLoading(true);
    console.log("Plan selected:", plan);

    const startDate = new Date();
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + plan.planPeriod);

    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate = format(endDate, "yyyy-MM-dd");

    const currentPlan = planList.find((p) => p.id === activePlanId);
    const currentPrice = currentPlan ? currentPlan.price : 0;

    const action = plan.price > currentPrice ? "upgraded" : "downgraded";
    setActions(action);
    setPlanId(plan.id);
    const params = {
      userId: props.userId,
      planId: plan.id,
      prevPlanId: status == "trial" ? activePlanId : plan.id,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      status: status == "trial" ? "none" : "active",
      action: status == "trial" ? status : action,
      actionScheduledDate:
        (status == "trial" || action == "downgraded") &&
          new Date(validity) >= new Date()
          ? formattedStartDate
          : undefined,
      userPlanAction:
        (status == "trial" || action == "downgraded") &&
          new Date(validity) >= new Date()
          ? status == "trial"
            ? status
            : action
          : undefined,
      actionPlanId:
        (status == "trial" || action == "downgraded") &&
          new Date(validity) >= new Date()
          ? plan.id
          : undefined,
      trialUsed: 0,
    };

    if (plan.trialPeriod && status) {
      const trialStart = new Date();
      const trialEnd = new Date(trialStart);
      trialEnd.setDate(trialStart.getDate() + plan.trialPeriod);

      params.trialStartDate = format(trialStart, "yyyy-MM-dd");
      params.trialEndDate = format(trialEnd, "yyyy-MM-dd");
      params.trialStatus = "active";
      params.trialUsed = 1;
    }

    try {
      const response = await window.apiService.addUserPlan(params);
      if (response.status === "OK") {
        setLoading(false);
        toast.success(response.message);
        getPlansById();
        loadPlans();
        login(props.userId);
        refreshSubscriptions();
        Refresh();
      }
    } catch (error) {
      setLoading(false);
      console.log("Error adding user plan:", error);
    }
    // if (action === "downgraded" && new Date(validity) > new Date() && trialPlan != currentPlan.servicePlan) {
    //     setPendingParams(params);
    //     setShowModal(true);
    //     return;
    // }

    // await executeApiCall(params);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setPendingParams(null);
  };

  const executeApiCall = async (params) => {
    try {
      const response = await window.apiService.addUserPlan(params);
      if (response.status === "OK") {
        toast.success(response.message);
        getPlansById();
        loadPlans();
        login(props.userId);
        refreshSubscriptions();
      }
    } catch (error) {
      console.log("Error adding user plan:", error);
    }
  };

  const handleModalAction = (action) => {
    if (pendingParams) {
      if (action === "after") {
        pendingParams.actionScheduledDate = format(
          new Date(validity),
          "yyyy-MM-dd"
        );
        pendingParams.userPlanAction = actions;
        pendingParams.actionPlanId = planId;
      } else if (action === "now") {
        pendingParams.action = "downgraded";
      }
      executeApiCall(pendingParams);
    }
    setShowModal(false);
  };

  const hasPlanExpired = () => {
    const validityDate = new Date(validity);
    const today = new Date();
    validityDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    return validityDate < today;
  };

  const getDiscountedPrice = (plan) => {
    const today = new Date();

    const applicableDiscount = plan.discount.find((discount) => {
      const startDate = new Date(discount.startDate);
      const endDate = new Date(discount.endDate);
      return today >= startDate && today <= endDate;
    });
    if (applicableDiscount) {
      const { discountType, discountValue } = applicableDiscount;

      if (discountType === "percentage") {
        return plan.price * (1 - discountValue / 100);
      } else if (discountType === "fixed_amount") {
        return plan.price - discountValue;
      }
    }

    return plan.price;
  };

  useEffect(() => {
    connectToWebSocket();
    return () => {
      disconnectWebSocket();
    };
  }, []);

  useEffect(() => {
    if (paymentPopup) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.text =
        'var ANS_customer_id = "1f7e5523-edef-4435-8667-8cf72eb8a819";';
      document.body.appendChild(script);

      const sealScript = document.createElement("script");
      sealScript.type = "text/javascript";
      sealScript.src = "https://verify.authorize.net/anetseal/seal.js";
      document.body.appendChild(sealScript);

      return () => {
        document.body.removeChild(script);
        document.body.removeChild(sealScript);
      };
    }
  }, [paymentPopup]);

  //authorize.net---------------------------------------------------------->

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPaymentDetails({ ...paymentDetails, [name]: value });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handlePayment = (status) => {
    console.log("aaa", PrevPlanId);

    const newErrors = {};
    // Check for empty fields
    if (!paymentDetails.creditCardNumber) {
      newErrors.creditCardNumber = "Card Number required.";
    }
    if (!paymentDetails.expirationDate) {
      newErrors.expirationDate = "Expire Date required.";
    }
    if (!paymentDetails.cvvCode) {
      newErrors.cvvCode = "CVV required.";
    }

    setErrors(newErrors);

    const startDate = new Date();
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + planDetials.planPeriod);

    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate = format(endDate, "yyyy-MM-dd");

    const currentPlan = planList.find((p) => p.id === activePlanId);
    const currentPrice = currentPlan ? currentPlan.price : 0;

    const action = planDetials.price > currentPrice ? "upgraded" : "downgraded";
    setCurrentPlanAction(action);

    const [expMonth, expYear] = paymentDetails.expirationDate?.split("/") || [];

    if (!expMonth || !expYear) {
      toast.error("Please provide a valid expiration date in MM/YY format.");
      return;
    }

    const secureData = {
      authData: {
        clientKey: process.env.REACT_APP_AUTHORIZE_CLIENTKEY,
        apiLoginID: process.env.REACT_APP_AUTHORIZE_LOGINKEY,
      },
      cardData: {
        cardNumber: paymentDetails.creditCardNumber,
        month: expMonth.trim(),
        year: expYear.trim(),
        cardCode: paymentDetails.cvvCode,
      },
    };

    if (window.Accept) {
      window.Accept.dispatchData(secureData, async (response) => {
        if (response.messages.resultCode === "Error") {
          console.error("Error:", response.messages.message);
          const errorMessages = response.messages.message
            .map((msg) => msg.text)
            .join(", ");
          toast.error(errorMessages);
          setLoading(false);
        } else {
          const { dataDescriptor, dataValue } = response.opaqueData;

          // Handling another subscription tokenization
          window.Accept.dispatchData(
            secureData,
            async (subscriptionResponse) => {
              if (subscriptionResponse.messages.resultCode === "Error") {
                console.error(
                  "Subscription Error:",
                  subscriptionResponse.messages.message
                );
                const subErrorMessages = subscriptionResponse.messages.message
                  .map((msg) => msg.text)
                  .join(", ");
                toast.error(subErrorMessages);
                setLoading(false);
              } else {
                const {
                  dataDescriptor: reniewdataDescriptor,
                  dataValue: reniewdataValueSub,
                } = subscriptionResponse.opaqueData;

                console.log("Payment Tokenized Data:", response.opaqueData);
                console.log(
                  "Subscription Tokenized Data:",
                  subscriptionResponse.opaqueData
                );

                const params = {
                  userId: props.userId,
                  planId: planDetials.id,
                  startDate: formattedStartDate,
                  endDate: status == "trial" ? null : formattedEndDate,
                  status: status == "trial" ? "none" : "active",
                  action: status == "trial" ? status : action,
                  actionScheduledDate:
                    (status == "trial" || action == "downgraded") &&
                      new Date(validity) >= new Date()
                      ? formattedStartDate
                      : undefined,
                  userPlanAction:
                    (status == "trial" || action == "downgraded") &&
                      new Date(validity) >= new Date()
                      ? status == "trial"
                        ? status
                        : action
                      : undefined,
                  actionPlanId:
                    (status == "trial" || action == "downgraded") &&
                      new Date(validity) >= new Date()
                      ? planDetials.id
                      : undefined,
                  autoRenew: paymentDetails.recurring,
                };

                const requestData = {
                  dataDescriptor,
                  dataValue,
                  reniewdataDescriptor,
                  reniewdataValueSub,
                  amount: planDetials.price,
                  userId: props.userId,
                  planId: planDetials.id,
                  holdername: paymentDetails.holdername,
                  paymentGateWay: "authorize.net",
                  jsonData: JSON.stringify(params),
                  planPeriod: planDetials.planPeriod,
                  prevPlanId: PrevPlanId,
                  trialUsed: 0,
                  trialStartDate: null,
                  trialEndDate: null,
                  trialStatus: null,
                };

                if (planDetials.trialPeriod && status) {
                  const trialStart = new Date();
                  const trialEnd = new Date(trialStart);
                  trialEnd.setDate(trialStart.getDate() + plan.trialPeriod);

                  requestData.trialStartDate = format(trialStart, "yyyy-MM-dd");
                  requestData.trialEndDate = format(trialEnd, "yyyy-MM-dd");
                  requestData.trialStatus = "active";
                  requestData.trialUsed = 1;
                }

                try {
                  const paymentProcess = await window.apiService.sendPayment(
                    requestData
                  );
                  setPaymentPopup(false);
                  setPendingPopup(true);
                  const result = paymentProcess.data;
                  setPaymentDetails({
                    creditCardNumber: "",
                    expirationDate: "",
                    cvvCode: "",
                    amount: "",
                  });
                  console.log("Payment Processed:", result);
                } catch (error) {
                  setLoading(false);
                  console.error("Backend Error:", error);
                  if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                  ) {
                    toast.error(`${error.response.data.message}`);
                  } else {
                    toast.error(
                      "An unexpected error occurred. Please try again."
                    );
                  }
                } finally {
                  setPaymentPopup(false);
                }
              }
            }
          );
        }
      });
    } else {
      console.error("Accept.js not loaded.");
    }
  };

  const hnadlePaymentPopup = () => {
    setPaymentPopup(false); // Open the payment modal

    setPaymentDetails({
      creditCardNumber: "",
      expirationDate: "",
      cvvCode: "",
      amount: "",
    });
    setErrors("");
  };

  const connectToWebSocket = () => {
    const socket = new SockJS(SOCKET_URL);
    stompClient = new Client({
      webSocketFactory: () => socket,
      reconnectDelay: 5000,
      debug: (str) => {
        console.log(str);
      },
      onConnect: () => {
        if (stompClient.connected) {
          stompClient.unsubscribe(`/topic/webhook-events`); // Unsubscribe before subscribing
        }
        stompClient.subscribe(`/topic/webhook-events`, (messageOutput) => {
          const id = JSON.parse(messageOutput.body); // Assuming the payload is a plain JSON object
          if (id == props.userId) {
            setTimeout(() => getPaymentStatus(), 1000);
            setTimeout(() => getPlansfrompaymentById(), 3000);
            console.log("Received ID:", id);
          }
          // console.log("Received message:", messageOutput);
        });
      },
      onStompError: (error) => {
        console.error("STOMP error:", error);
      },
    });
    stompClient.activate();
  };

  const disconnectWebSocket = () => {
    if (stompClient !== null) {
      stompClient.deactivate();
    }
  };

  const getPaymentStatus = async () => {
    try {
      const response = await window.apiService.paymentStatus(props.userId); // Fetch payment data for userId 72
      console.log(response);
      const data = response.data;
      setPaymentData(data);
      const [firstItem] = data;
      const { paymentStatus } = firstItem;
      console.log("Payment Status:", paymentStatus);
      if (paymentStatus === "authorizedPendingCapture") {
        toast.info("Payment authorized, pending capture.");
      } else if (paymentStatus === "capturedPendingSettlement") {
        toast.success("Payment successfully.");
      } else if (paymentStatus === "settledSuccessfully") {
        toast.success("Payment settled successfully.");
      } else if (paymentStatus === "declined") {
        toast.error("Payment declined.");
      } else if (paymentStatus === "voided") {
        toast.info("Transaction voided.");
      } else if (paymentStatus === "expired") {
        toast.warning("Authorization expired.");
      } else if (paymentStatus === "underReview") {
        toast.warning("Transaction under review.");
      } else if (paymentStatus === "heldForReview") {
        toast.warning("Transaction held for review.");
      } else if (paymentStatus === "failedReview") {
        toast.error("Transaction failed review.");
      } else if (paymentStatus === "refundPendingSettlement") {
        toast.info("Refund pending settlement.");
      } else if (paymentStatus === "refundSettledSuccessfully") {
        toast.success("Refund settled successfully.");
      } else if (paymentStatus === "chargeback") {
        toast.warning("Chargeback initiated.");
      } else if (paymentStatus === "chargebackReversal") {
        toast.success("Chargeback reversed.");
      } else if (paymentStatus === "generalError") {
        toast.error("A general error occurred during the transaction.");
      } else if (paymentStatus === "communicationError") {
        toast.error("Communication error with payment processor.");
      } else {
        toast.info("Unhandled payment status: " + paymentStatus);
      }

      setLoading(false);
      console.log(data);
    } catch (error) {
      console.log("Error fetching payment status:", error);
    }
  };

  useEffect(() => {
    connectToWebSocket();
    return () => {
      disconnectWebSocket();
    };
  }, []);

  useEffect(() => {
    if (paymentPopup) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.text =
        'var ANS_customer_id = "1f7e5523-edef-4435-8667-8cf72eb8a819";';
      document.body.appendChild(script);

      const sealScript = document.createElement("script");
      sealScript.type = "text/javascript";
      sealScript.src = "https://verify.authorize.net/anetseal/seal.js";
      document.body.appendChild(sealScript);

      return () => {
        document.body.removeChild(script);
        document.body.removeChild(sealScript);
      };
    }
  }, [paymentPopup]);

  const handleAddFeaturePopup = () => {
    setAddFeaturePaymentPopup(false); // Open the payment modal
    setPaymentDetails({
      creditCardNumber: "",
      expirationDate: "",
      cvvCode: "",
      amount: "",
    });
    setErrors("");
  };

  const addFeaturePayments = async (status) => {
    console.log("aaa", PrevPlanId);

    const newErrors = {};
    // Check for empty fields
    if (!paymentDetails.creditCardNumber) {
      newErrors.creditCardNumber = "Card Number required.";
    }
    if (!paymentDetails.expirationDate) {
      newErrors.expirationDate = "Expire Date required.";
    }
    if (!paymentDetails.cvvCode) {
      newErrors.cvvCode = "CVV required.";
    }

    setErrors(newErrors);

    const startDate = new Date();
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + planDetials.planPeriod);

    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate = format(endDate, "yyyy-MM-dd");

    const currentPlan = planList.find((p) => p.id === activePlanId);
    const currentPrice = currentPlan ? currentPlan.price : 0;

    const action = planDetials.price > currentPrice ? "upgraded" : "downgraded";
    // const [expMonth, expYear] = paymentDetails.expirationDate.split("/");
    const [expMonth, expYear] =
      (paymentDetails.expirationDate &&
        paymentDetails.expirationDate.split("/")) ||
      [];

    if (!expMonth || !expYear) {
      toast.error("Please provide a valid expiration date in MM/YY format.");
      return;
    }

    const secureData = {
      authData: {
        clientKey: process.env.REACT_APP_AUTHORIZE_CLIENTKEY,
        apiLoginID: process.env.REACT_APP_AUTHORIZE_LOGINKEY,
      },
      cardData: {
        cardNumber: paymentDetails.creditCardNumber,
        month: expMonth.trim(),
        year: expYear.trim(),
        cardCode: paymentDetails.cvvCode,
      },
    };

    if (window.Accept) {
      // setLoading(true);
      window.Accept.dispatchData(secureData, async (response) => {
        if (response.messages.resultCode === "Error") {
          console.error("Error:", response.messages.message);
          const errorMessages = response.messages.message
            .map((msg) => msg.text)
            .join(", ");
          toast.error(errorMessages);
          setLoading(false);
        } else {
          const { dataDescriptor, dataValue } = response.opaqueData;
          window.Accept.dispatchData(
            secureData,
            async (subscriptionResponse) => {
              if (subscriptionResponse.messages.resultCode === "Error") {
                console.error(
                  "Subscription Error:",
                  subscriptionResponse.messages.message
                );
                const subErrorMessages = subscriptionResponse.messages.message
                  .map((msg) => msg.text)
                  .join(", ");
                toast.error(subErrorMessages);
                setLoading(false);
              } else {
                const {
                  dataDescriptor: reniewdataDescriptor,
                  dataValue: reniewdataValueSub,
                } = subscriptionResponse.opaqueData;

                console.log("Payment Tokenized Data:", response.opaqueData);
                console.log(
                  "Subscription Tokenized Data:",
                  subscriptionResponse.opaqueData
                );
                const params = {
                  userId: props.userId,
                  planId: planDetials.id,
                  startDate: formattedStartDate,
                  endDate: status == "trial" ? null : formattedEndDate,
                  status: status == "trial" ? "none" : "active",
                  action: status == "trial" ? status : action,
                  actionScheduledDate:
                    (status == "trial" || action == "downgraded") &&
                      new Date(validity) >= new Date()
                      ? formattedStartDate
                      : undefined,
                  userPlanAction:
                    (status == "trial" || action == "downgraded") &&
                      new Date(validity) >= new Date()
                      ? status == "trial"
                        ? status
                        : action
                      : undefined,
                  actionPlanId:
                    (status == "trial" || action == "downgraded") &&
                      new Date(validity) >= new Date()
                      ? planDetials.id
                      : undefined,
                  autoRenew: paymentDetails.recurring,
                };

                const requestData = {
                  dataDescriptor,
                  dataValue,
                  amount: planDetials.price,
                  userId: props.userId,
                  planId: activePlanId,
                  holdername: paymentDetails.holdername,
                  paymentGateWay: "authorize.net",
                  jsonData: JSON.stringify(params),
                  planPeriod: planDetials.planPeriod,
                  reniewdataDescriptor,
                  reniewdataValueSub,
                  prevPlanId: PrevPlanId,
                  trialUsed: 0,
                  trialStartDate: null,
                  trialEndDate: null,
                  trialStatus: null,
                };

                if (planDetials.trialPeriod && status) {
                  const trialStart = new Date();
                  const trialEnd = new Date(trialStart);
                  trialEnd.setDate(trialStart.getDate() + plan.trialPeriod);

                  requestData.trialStartDate = format(trialStart, "yyyy-MM-dd");
                  requestData.trialEndDate = format(trialEnd, "yyyy-MM-dd");
                  requestData.trialStatus = "active";
                  requestData.trialUsed = 1;
                }

                try {
                  const paymentProcess =
                    await window.apiService.addFeaturesInplan(requestData);
                  setPaymentPopup(false);
                  setPendingPopup(true);
                  setAddFeaturePaymentPopup(false);
                  const result = paymentProcess.data;
                  setPaymentDetails({
                    creditCardNumber: "",
                    expirationDate: "",
                    cvvCode: "",
                    amount: "",
                  });
                  console.log(result);
                  // addUserPlans(plan)
                  console.log("Payment Processed:", result);
                } catch (error) {
                  setLoading(false);
                  console.error("Backend Error:", error);
                  if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                  ) {
                    toast.error(`${error.response.data.message}`);
                  } else {
                    toast.error(
                      "An unexpected error occurred. Please try again."
                    );
                  }
                } finally {
                  // setLoading(false);
                  setAddFeaturePaymentPopup(false);
                  setPaymentPopup(false); // Close modal after submission
                }
              }
            }
          );
        }
      });
    } else {
      console.error("Accept.js not loaded.");
    }
  };

  const handleUpgradePopup = () => {
    setUpgradePopup(false);
  };

  const handleDowngradePopup = () => {
    setDowngradePopup(false);
  };

  const updatePlans = async (plan) => {
    const currentPlan = planList.find((p) => p.id === activePlanId);
    const currentPrice = currentPlan ? currentPlan.price : 0;
    const action = plan.price > currentPrice ? "upgraded" : "downgraded";
    const startDate = new Date();
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + plan.planPeriod);
    setCurrentPlanAction(action);
    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate = format(endDate, "yyyy-MM-dd");

    setCurrentPlanEndDate(formattedEndDate);
    // alert(action);

    setPaymentDetails({
      recurring: true,
    });

    if (planInformation[activePlanId]) {
      setActivePlan(planInformation[activePlanId].servicePlan);
      setActivePlanPrice(planInformation[activePlanId].price);
    }

    if (planInformation[plan.id]) {
      setUpdatingPlan(planInformation[plan.id].servicePlan);
      setUpdatePlanPrice(planInformation[plan.id].price);
    }

    if (action == "downgraded") {
      setDowngradePopup(true);
    } else if (action == "upgraded") {
      const data = {
        userId: props.userId,
        newPlanId: plan.id,
        newPlanEndDate: formattedEndDate,
      };
      try {
        const result = await window.apiService.getUpgradeAmount(data);
        const response = result.data;
        console.log(response.data);
        setUpgradeAmount(response.data);
        setUpgradePopup(true);
        // setDowngradePopup(true)
      } catch (error) {
        console.error("Error fetching upgrade amount:", error);
      }
    }
  };

  const handleToggleSwitch = () => {
    setPaymentDetails({
      ...paymentDetails,
      recurring: !paymentDetails.recurring,
    });
  };

  const CreatePaymentProfile = async () => {
    const data = {
      email: props.emailId,
      userId: props.userId,
    };
    try {
      const result = await window.apiService.createPaymentProfile(data);
      const response = result.data;
      console.log("profile created", response);
    } catch (err) {
      console.log(err);
    }
  };

  const addPaymentCard = async () => {
    const newErrors = {};
    // Check for empty fields
    if (!paymentDetails.creditCardNumber) {
      newErrors.creditCardNumber = "Card Number required.";
    }
    if (!paymentDetails.expirationDate) {
      newErrors.expirationDate = "Expire Date required.";
    }
    if (!paymentDetails.cvvCode) {
      newErrors.cvvCode = "CVV required.";
    }

    setErrors(newErrors);

    const [expMonth, expYear] =
      (paymentDetails.expirationDate &&
        paymentDetails.expirationDate.split("/")) ||
      [];

    if (!expMonth || !expYear) {
      toast.error("Please provide a valid expiration date in MM/YY format.");
      return;
    }

    const secureData = {
      authData: {
        clientKey: process.env.REACT_APP_AUTHORIZE_CLIENTKEY,
        apiLoginID: process.env.REACT_APP_AUTHORIZE_LOGINKEY,
      },
      cardData: {
        cardNumber: paymentDetails.creditCardNumber,
        month: expMonth.trim(),
        year: expYear.trim(),
        cardCode: paymentDetails.cvvCode,
      },
    };

    if (window.Accept) {
      window.Accept.dispatchData(secureData, async (response) => {
        if (response.messages.resultCode === "Error") {
          console.error("Error:", response.messages.message);
          const errorMessages = response.messages.message
            .map((msg) => msg.text)
            .join(", ");
          toast.error(errorMessages);
          setLoading(false);
        } else {
          const { dataDescriptor, dataValue } = response.opaqueData;
          const data = {
            customerProfileId: paymentProfileId,
            dataDescriptor: dataDescriptor,
            dataValue: dataValue,
            userId: props.userId,
          };
          try {
            const result = await window.apiService.addPaymentCard(data);
            const response = result.data;
            console.log(response);
          } catch (err) {
            console.log(err);
          }
        }
      });
    }
  };
  return (
    <div className="profile_div pln">
      <Container className="p-0" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary profile_card pad_10">
              <Row className="mb-2">
                <Col lg="10">
                  <h4>Choose your plan</h4>
                  <p>
                    Get the right plan for your business. Plans can be upgraded
                    in the future.
                  </p>
                  {trialPlan && (
                    <span className="text-muted">
                      <LuRocket /> Free {trialPlan} Plan
                    </span>
                  )}
                </Col>
                <Col lg="2" className="text-end c_pointer">
                  <commonUtils.CustomButton
                    btnText={
                      <div className="d-flex align-items-center">
                        <FaArrowLeft /> <span>Back</span>
                      </div>
                    }
                    btnWidth="fit-content"
                    variant="dark"
                    perform={() => goBack()}
                    isDisabled={false}
                  />
                </Col>
              </Row>

              <Row className="justify-content-end">
                <ButtonGroup className="mb-2" style={{ width: "20%" }}>
                  <ToggleButton
                    type="radio"
                    name="billingCycle"
                    value="monthly"
                    checked={billingCycle === "monthly"}
                    onClick={() => setBillingCycle("monthly")}
                    className={
                      billingCycle === "monthly"
                        ? "btn_brown_checked"
                        : "btn_brown_unchecked"
                    }
                  >
                    Monthly
                  </ToggleButton>
                  <ToggleButton
                    type="radio"
                    name="billingCycle"
                    value="yearly"
                    checked={billingCycle === "yearly"}
                    onClick={() => setBillingCycle("yearly")}
                    className={
                      billingCycle === "yearly"
                        ? "btn_brown_checked"
                        : "btn_brown_unchecked"
                    }
                  >
                    Yearly
                  </ToggleButton>
                </ButtonGroup>
              </Row>

              <Row className="justify-content-center">
                {filteredPlans && filteredPlans.length > 0 && !loading ? (
                  (() => {
                    const sortedPlans = [...filteredPlans].sort(
                      (a, b) => a.price - b.price
                    );

                    const displayedFeatures = new Set();
                    let previousPlan = null;

                    return sortedPlans.map((plan, idx) => {
                      const includesPreviousPlan = previousPlan
                        ? previousPlan.servicePlan
                        : null;

                      const renderedCard = (
                        <Col key={idx} md="3" className="mb-4">
                          <Card className="plan-card">
                            {plan.id === activePlanId &&
                              (trialStatus != "active" ? (
                                <div className="ribbon">
                                  {hasPlanExpired() && plan.isTrial == 0
                                    ? "Expired"
                                    : "Active"}
                                </div>
                              ) : trialStatus == "active" ? (
                                <div className="ribbon"> Active</div>
                              ) : (
                                ""
                              ))}
                            <Card.Header
                              className="bg-light"
                              style={{ color: colors[idx % colors.length] }}
                            >
                              <span>
                                <FaCircle className="icn_blur" />
                              </span>{" "}
                              {plan.servicePlan}
                              {plan.isTrial == 1 && (
                                <span className="freePln">Free</span>
                              )}
                            </Card.Header>
                            <Card.Body
                              className={
                                (plan.id === activePlanId &&
                                  plan.isTrial == 0) ||
                                  (plan.trialPeriod && trialUsed == 0)
                                  ? "pad_tp"
                                  : ""
                              }
                            >
                              <div className="d-flex justify-content-end">
                                {plan.trialPeriod &&
                                  (plan.id != activePlanId ||
                                    planStatus == "none") && (
                                    <div
                                      className={
                                        trialUsed == 0 ? "freePln trl" : ""
                                      }
                                    >
                                      {trialStatus === "none" &&
                                        trialUsed == 0 ? (
                                        `${plan.trialPeriod} day${plan.trialPeriod > 1 ? "s" : ""
                                        } trial`
                                      ) : trialEndDate &&
                                        plan.id === activePlanId ? (
                                        <div className="freePln">
                                          {new Date(
                                            trialEndDate
                                          ).toDateString() ===
                                            new Date().toDateString()
                                            ? "Trial expires today"
                                            : `Trial expires on ${format(
                                              new Date(trialEndDate),
                                              "dd MMM yyyy"
                                            )}`}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}

                                {plan.id === activePlanId &&
                                  plan.isTrial == 0 &&
                                  planStatus == "active" ? (
                                  <div className="text-end fs_12 text-muted">
                                    {new Date(validity).toDateString() ===
                                      new Date().toDateString() ? (
                                      <div className="freePln">
                                        Plan expires today
                                      </div>
                                    ) : hasPlanExpired() ? (
                                      "Plan Expired"
                                    ) : (
                                      `Valid till: ${format(
                                        new Date(validity),
                                        "dd MMM yyyy"
                                      )}`
                                    )}
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                              <h4>
                                {plan.isTrial === 1 ? (
                                  <s>${plan.price}</s>
                                ) : (
                                  <>
                                    {getDiscountedPrice(plan) < plan.price && (
                                      <>
                                        <s className="">${plan.price}</s>{" "}
                                        <span className="text-success">
                                          ${getDiscountedPrice(plan).toFixed(2)}
                                        </span>
                                      </>
                                    )}
                                    {getDiscountedPrice(plan) ===
                                      plan.price && <span>${plan.price}</span>}
                                  </>
                                )}
                                <small>
                                  {" "}
                                  /{" "}
                                  {billingCycle === "monthly"
                                    ? "month"
                                    : "year"}
                                </small>
                              </h4>

                              <div className="text-muted fs_12">
                                {/* {includesPreviousPlan && (
                                                                    <div>
                                                                        <strong>(Includes {includesPreviousPlan} plan)</strong>
                                                                    </div>
                                                                )} */}
                                <strong>Subscription Duration:</strong>{" "}
                                {plan.isTrial == 0
                                  ? `${plan.planPeriod} days`
                                  : "Unlimited"}
                              </div>
                              <div>{plan.description}</div>

                              <ul className="list-unstyled mt-3 mb-4 feat">
                                {includesPreviousPlan && (
                                  <li className="fs14">
                                    <TiTick className="tck" />
                                    <span className="uppercase fontBold fs_14px">
                                      Everything in {includesPreviousPlan}
                                    </span>
                                  </li>
                                )}

                                {plan.features
                                  .filter((feature) => {
                                    const isDisplayed = displayedFeatures.has(
                                      feature.featureName
                                    );
                                    if (!isDisplayed) {
                                      displayedFeatures.add(
                                        feature.featureName
                                      );
                                      return true;
                                    }
                                    return false;
                                  })
                                  .map((feature, featureIdx) => (
                                    <li key={featureIdx} className="fs14">
                                      <TiTick className="tck" />{" "}
                                      {feature.featureName}{" "}
                                      {/* <span className="text-muted">
                                                                                ({feature.description})
                                                                            </span> */}
                                    </li>
                                  ))}
                              </ul>
                              {plan.isTrial == 0 &&
                                trialStatus != "active" &&
                                (!hasPlanExpired() ||
                                  plan.id !== activePlanId) ? (
                                <>
                                  <div className="mt-auto">
                                    {plan.trialPeriod &&
                                      (plan.id != activePlanId ||
                                        planStatus == "none") &&
                                      trialUsed == 0 && (
                                        <div className="mar_bt_8">
                                          <Button
                                            onClick={() =>
                                              addUserPlans(plan, "trial")
                                            }
                                            disabled={trialStatus !== "none"}
                                            className="w_100"
                                            variant={
                                              trialStatus == "active"
                                                ? "primary"
                                                : "outline-primary"
                                            }
                                          >
                                            {trialStatus == "active" &&
                                              plan.id === activePlanId &&
                                              trialStartDate &&
                                              trialEndDate ? (
                                              <>Trial Activated</>
                                            ) : (
                                              "Get Trial"
                                            )}
                                          </Button>
                                        </div>
                                      )}
                                    <Button
                                      onClick={() => {
                                        // if (activePlanId && plan.id !== activePlanId) {
                                        //     alert(activePlanId)
                                        //     addFeaturePlans()
                                        //     setPlanDetials(plan);
                                        // } else if(plan.id == activePlanId){
                                        //     setPaymentPopup(true); // Open the modal
                                        //     setPlanDetials(plan);
                                        // }
                                        // setPaymentPopup(true); // Open the modal
                                        // setPlanDetials(plan);

                                        if (activePlanId == 1) {
                                          setPaymentPopup(true); // Open the modal
                                          setPlanDetials(plan);
                                          setPaymentDetails({
                                            recurring: true,
                                          });
                                          if (paymentProfileId == null) {
                                            CreatePaymentProfile();
                                          }
                                        } else {
                                          setPlanDetials(plan);
                                          updatePlans(plan);
                                          if (paymentProfileId == null) {
                                            CreatePaymentProfile();
                                          }
                                          // addFeaturePlans()
                                        }
                                      }}
                                      disabled={plan.id === activePlanId}
                                      className="w_100"
                                      variant={
                                        plan.id === activePlanId
                                          ? "primary"
                                          : "outline-primary"
                                      }
                                    >
                                      {plan.id === activePlanId
                                        ? "Active Plan"
                                        : "Get Plan"}
                                    </Button>
                                  </div>
                                </>
                              ) : plan.isTrial == 0 && trialStatus == "none" ? (
                                <Button
                                  onClick={() =>
                                    navigate(
                                      "/dashboard/user_settings/plans_billings"
                                    )
                                  }
                                  className="w_100"
                                  variant={
                                    plan.id === activePlanId
                                      ? "primary"
                                      : "outline-primary"
                                  }
                                >
                                  Renew plan
                                </Button>
                              ) : trialStatus == "active" ? (
                                <Button
                                  onClick={() => {
                                    // addUserPlans(plan); // Call your function
                                    setPaymentPopup(true); // Open the modal
                                    setPlanDetials(plan);

                                    // handlePlanClick(plan)
                                  }}
                                  className="w_100"
                                  variant="outline-primary"
                                >
                                  Get Plan
                                </Button>
                              ) : (
                                ""
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                      );

                      previousPlan = plan;
                      return renderedCard;
                    });
                  })()
                ) : loading ? (
                  <div className="loader-center">
                    <img
                      className="loader-image"
                      src={loader}
                      alt="Loading..."
                    />
                  </div>
                ) : (
                  ""
                  // <div className="row d-align mx-0 pb-4">
                  //     <div className="no-data font-weight-600 w-100 text-center fz_14">
                  //         Sorry, there is no data to be displayed
                  //     </div>
                  // </div>
                )}

                <Col md="3" className="mb-4">
                  {!loading && (
                    <Card className="plan-card">
                      <Card.Header className="bg-light">
                        <span>
                          <FaCircle className="icn_blur" />
                        </span>{" "}
                        Enterprise
                      </Card.Header>
                      <Card.Body>
                        <h6 className="text-muted">Enterprise Plan</h6>
                        <div>
                          <span>
                            <ul className="list-unstyled mt-3 mb-4 feat">
                              <li className="uppercase fontBold fs_14px">
                                <TiTick className="tck" /> Everything in
                                Business Plus
                              </li>
                              <li className="fs14">
                                <TiTick className="tck" /> Additional features
                              </li>
                            </ul>
                          </span>
                        </div>
                        <div>
                          <div>
                            <span className="font-weight-bold">contact</span>{" "}
                            support@tiipstr.com
                          </div>
                          <div>
                            <span className="font-weight-bold">phone</span> 180
                            xxx xxx
                          </div>
                          <div>
                            contact supporting team for plan and pricing details
                          </div>
                        </div>
                        {/* <Button className="btn_plan w_100">Get Plan</Button> */}
                      </Card.Body>
                    </Card>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* <Button onClick={handlePlanClick}>Payment Testing</Button> */}

      {/* ----------- confirm degrade plan before expiry ---------- */}
      <Modal size="sm" show={showModal}>
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            Confirm Plan Downgrade
          </Modal.Title>
          <span onClick={handleModalClose} className="c_pointer" title="Close">
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="bordr">
          <p>
            Would you like the new plan to start after the current one expires,
            or would you prefer it to start right away?
          </p>
        </Modal.Body>

        <Modal.Footer className="bordr pinBtm">
          <commonUtils.CustomButton
            btnText="After Current Plan"
            btnWidth="fit-content"
            variant="primary"
            perform={() => handleModalAction("after")}
            isDisabled={false}
          />
          <commonUtils.CustomButton
            btnText="Start Now"
            btnWidth="fit-content"
            variant="danger"
            perform={() => handleModalAction("now")}
            isDisabled={false}
          />
        </Modal.Footer>
      </Modal>

      {/* //authorize.net */}

      <Modal size="md" show={paymentPopup} onHide={hnadlePaymentPopup}>
        {/* <Modal.Header className="d-flex  align-items-center position-relative">
          <Modal.Title>
            <div
              style={{ fontSize: "20px", fontWeight: 600, color: "#4A4A4A" }}
            >
              Payment Details
            </div>

            <div
              style={{ fontSize: "13px", fontWeight: 400, color: "#8D8A8A" }}
              className="mt-3"
            >
              All transactions are secure and encrypted
            </div>
          </Modal.Title>
          <span
            onClick={hnadlePaymentPopup}
            title="Close"
            className="c_pointer position-absolute"
            style={{ right: "50px" }}
          >
            <AiOutlineClose />
          </span>
        </Modal.Header> */}
        <Modal.Header className="d-flex align-items-center justify-content-between position-relative" style={{ padding: "10px 1.5rem" }}>
          <Modal.Title>
            <div
              style={{ fontSize: "20px", fontWeight: 600, color: "#4A4A4A" }}
            >
              Payment Details
            </div>

            <div
              style={{ fontSize: "13px", fontWeight: 400, color: "#8D8A8A" }}
              className="mt-2"
            >
              All transactions are secure and encrypted
            </div>
          </Modal.Title>

          <span
            onClick={hnadlePaymentPopup}
            title="Close"
            className="c_pointer position-absolute"
            style={{ right: "50px", top: '15px' }}
          >
            <AiOutlineClose />
          </span>
        </Modal.Header>

        <Modal.Body
          style={{
            background: "#F0F0F0",
            margin: "0px 20px 0px 20px",
            borderRadius: "30px",
          }}
        >
          <div className="card-logos d-flex justify-content-between align-items-center flex-wrap mb-1">
            <div style={{ fontSize: "18px", fontWeight: "600" }}>
              Card Details
            </div>
            <img
              src={visaCard}
              alt="Visa"
              title="Visa"
              className="card-logo img-fluid"
              style={{ height: "10px", width: "auto" }}
            />
            <img
              src={mastercard}
              alt="MasterCard"
              title="MasterCard"
              className="card-logo img-fluid"
              style={{ height: "15px", width: "auto" }}
            />
            <img
              src={americancard}
              alt="American Express"
              title="American Express"
              className="card-logo img-fluid"
              style={{ height: "18px", width: "auto" }}
            />
            <img
              src={discovercard}
              alt="Discover"
              title="Discover"
              className="card-logo img-fluid"
              style={{ height: "10px", width: "auto" }}
            />
            <img
              src={dinerscard}
              alt="Diners Club"
              title="Diners Club"
              className="card-logo img-fluid"
              style={{ height: "20px", width: "auto" }}
            />
            <img
              src={jcbcard}
              alt="JCB"
              title="JCB"
              className="card-logo img-fluid"
              style={{ height: "18px", width: "auto" }}
            />
          </div>

          <div
            className="mt-3"
            style={{ fontSize: "14px", fontWeight: "bold", color: "#28a745" }}
          >
            Amount will be charged:{" "}
            {/* <span style={{ fontSize: "18px", color: "#dc3545" }}>
              $
              {currentPlanAction === "upgraded"
                ? upgradeAmount
                : planDetials?.price || ""}
            </span> */}
            <span style={{ fontSize: "18px", color: "#dc3545" }}>
              ${planDetials?.price || "0.00"}
            </span>
          </div>

          <Form>
            <div className="row ">
              {/* Card Holder Name */}
              <div className="col-md-6">
                <Form.Group controlId="creditCardNumber">
                  <Form.Label className="mt-3">Card Number</Form.Label>
                  <div className="input-group">
                    <Form.Control
                      type="text"
                      name="creditCardNumber"
                      value={paymentDetails.creditCardNumber}
                      onChange={handleInputChange}
                      placeholder="4111 1111 1111 1111"
                    />
                    <span className="input-group-text">
                      <FaRegCreditCard />
                    </span>
                  </div>
                  {errors.creditCardNumber && (
                    <div className="text-danger mt-1">
                      {errors.creditCardNumber}
                    </div>
                  )}
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group controlId="holdername">
                  <Form.Label className="mt-3">Card Holder Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="holdername"
                    value={paymentDetails.holdername}
                    onChange={handleInputChange}
                    placeholder="Holder Name"
                  />
                  {errors.holdername && (
                    <div className="text-danger mt-1">{errors.holdername}</div>
                  )}
                </Form.Group>
              </div>
            </div>
            {/* Grid layout for holder name, expiration date, and CVV */}
            <div className="row mt-3">
              {/* Card Holder Name */}

              {/* Expiration Date */}
              <div className="col-md-6">
                <Form.Group controlId="expirationDate">
                  <Form.Label>Expire date</Form.Label>
                  <Form.Control
                    type="text"
                    name="expirationDate"
                    value={paymentDetails.expirationDate}
                    onChange={handleInputChange}
                    placeholder="MM/YY"
                  />
                  {errors.expirationDate && (
                    <div className="text-danger mt-1">
                      {errors.expirationDate}
                    </div>
                  )}
                </Form.Group>
              </div>

              {/* CVV Code */}
              <div className="col-md-6">
                <Form.Group controlId="cvvCode">
                  <Form.Label>CVV Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="cvvCode"
                    value={paymentDetails.cvvCode}
                    onChange={handleInputChange}
                    placeholder="123"
                  />
                  {errors.cvvCode && (
                    <div className="text-danger mt-1">{errors.cvvCode}</div>
                  )}
                </Form.Group>
              </div>
            </div>
          </Form>

          <div className="w-100 px-3">
            <Form.Group className="d-flex align-items-center ">
              <Form.Label
                className="mb-0 mt-4 mr-4 "
                style={{ fontSize: "14px", fontWeight: "600" }}
              >
                {" "}
                Auto Renewal
              </Form.Label>
              <Form.Check
                type="switch"
                id="recurringPaymentToggle"
                checked={paymentDetails.recurring}
                onChange={() => {
                  setPaymentDetails({
                    ...paymentDetails,
                    recurring: !paymentDetails.recurring,
                  });
                }}
              />
            </Form.Group>

            {/* Show message when recurring payment is active */}
            {paymentDetails.recurring && (
              <div>
                <div
                  className="text-success mt-2"
                  style={{ fontWeight: "600", fontSize: "12px" }}
                >
                  Your Auto Renewal is active.
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            className="d-flex justify-content-center w-100"
            style={{ padding: "0 30px 10px 30px" }}
          >
            <Button
              variant="primary"
              onClick={() => {
                handlePayment();
                addPaymentCard();
              }}
              className="me-2 px-4 w-50"
            >
              Pay Now
            </Button>
            <Button
              variant="danger"
              onClick={hnadlePaymentPopup}
              className="px-4 w-50"
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        show={addFeaturePaymentPopup}
        onHide={handleAddFeaturePopup}
      >
        {/* <Modal.Header className="d-flex  align-items-center position-relative">
          <Modal.Title>
            <div
              style={{ fontSize: "20px", fontWeight: 600, color: "#4A4A4A" }}
            >
              Payment Details
            </div>

            <div
              style={{ fontSize: "13px", fontWeight: 400, color: "#8D8A8A" }}
              className="mt-3"
            >
              All transactions are secure and encrypted
            </div>
          </Modal.Title>
          <span
            onClick={handleAddFeaturePopup}
            title="Close"
            className="c_pointer position-absolute"
            style={{ right: "50px" }}
          >
            <AiOutlineClose />
          </span>
        </Modal.Header> */}

        <Modal.Header className="d-flex align-items-center justify-content-between position-relative" style={{ padding: "10px 1.5rem" }}>
          <Modal.Title>
            <div
              style={{ fontSize: "20px", fontWeight: 600, color: "#4A4A4A" }}
            >
              Payment Details
            </div>

            <div
              style={{ fontSize: "13px", fontWeight: 400, color: "#8D8A8A" }}
              className="mt-2"
            >
              All transactions are secure and encrypted
            </div>
          </Modal.Title>

          <span
            onClick={handleAddFeaturePopup}
            title="Close"
            className="c_pointer position-absolute"
            style={{ right: "50px", top: '15px' }}
          >
            <AiOutlineClose />
          </span>
        </Modal.Header>

        <Modal.Body
          style={{
            background: "#F0F0F0",
            margin: "0px 20px 0px 20px",
            borderRadius: "30px",
          }}
        >
          <div className="card-logos d-flex justify-content-between align-items-center flex-wrap mb-1">
            <div style={{ fontSize: "18px", fontWeight: "600" }}>
              Card Details
            </div>
            <img
              src={visaCard}
              alt="Visa"
              title="Visa"
              className="card-logo img-fluid"
              style={{ height: "10px", width: "auto" }}
            />
            <img
              src={mastercard}
              alt="MasterCard"
              title="MasterCard"
              className="card-logo img-fluid"
              style={{ height: "15px", width: "auto" }}
            />
            <img
              src={americancard}
              alt="American Express"
              title="American Express"
              className="card-logo img-fluid"
              style={{ height: "18px", width: "auto" }}
            />
            <img
              src={discovercard}
              alt="Discover"
              title="Discover"
              className="card-logo img-fluid"
              style={{ height: "10px", width: "auto" }}
            />
            <img
              src={dinerscard}
              alt="Diners Club"
              title="Diners Club"
              className="card-logo img-fluid"
              style={{ height: "20px", width: "auto" }}
            />
            <img
              src={jcbcard}
              alt="JCB"
              title="JCB"
              className="card-logo img-fluid"
              style={{ height: "18px", width: "auto" }}
            />
          </div>

          <div
            className="mt-3"
            style={{ fontSize: "14px", fontWeight: "bold", color: "#28a745" }}
          >
            Amount will be charged:{" "}
            <span style={{ fontSize: "18px", color: "#dc3545" }}>
              $
              {currentPlanAction === "upgraded"
                ? upgradeAmount
                : planDetials?.price || ""}
            </span>
          </div>

          <Form>
            <div className="row mt-3">
              {/* Card Holder Name */}
              <div className="col-md-6">
                <Form.Group controlId="creditCardNumber">
                  <Form.Label className="mt-3">Card Number</Form.Label>
                  <div className="input-group">
                    <Form.Control
                      type="text"
                      name="creditCardNumber"
                      value={paymentDetails.creditCardNumber}
                      onChange={handleInputChange}
                      placeholder="4111 1111 1111 1111"
                    />
                    <span className="input-group-text">
                      <FaRegCreditCard />
                    </span>
                  </div>
                  {errors.creditCardNumber && (
                    <div className="text-danger mt-1">
                      {errors.creditCardNumber}
                    </div>
                  )}
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="holdername">
                  <Form.Label className="mt-3">Card Holder Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="holdername"
                    value={paymentDetails.holdername}
                    onChange={handleInputChange}
                    placeholder="Holder Name"
                  />
                  {errors.holdername && (
                    <div className="text-danger mt-1">{errors.holdername}</div>
                  )}
                </Form.Group>
              </div>
            </div>
            {/* Grid layout for holder name, expiration date, and CVV */}
            <div className="row mt-3">
              {/* Card Holder Name */}

              {/* Expiration Date */}
              <div className="col-md-6">
                <Form.Group controlId="expirationDate">
                  <Form.Label>Expire date</Form.Label>
                  <Form.Control
                    type="text"
                    name="expirationDate"
                    value={paymentDetails.expirationDate}
                    onChange={handleInputChange}
                    placeholder="MM/YY"
                  />
                  {errors.expirationDate && (
                    <div className="text-danger mt-1">
                      {errors.expirationDate}
                    </div>
                  )}
                </Form.Group>
              </div>

              {/* CVV Code */}
              <div className="col-md-6">
                <Form.Group controlId="cvvCode">
                  <Form.Label>CVV Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="cvvCode"
                    value={paymentDetails.cvvCode}
                    onChange={handleInputChange}
                    placeholder="123"
                  />
                  {errors.cvvCode && (
                    <div className="text-danger mt-1">{errors.cvvCode}</div>
                  )}
                </Form.Group>
              </div>
            </div>
          </Form>

          <div className="w-100 px-3">
            <Form.Group className="d-flex align-items-center ">
              <Form.Label
                className="mb-0 mt-4 mr-4 "
                style={{ fontSize: "14px", fontWeight: "600" }}
              >
                Auto Renewal
              </Form.Label>
              <Form.Check
                type="switch"
                id="recurringPaymentToggle"
                checked={paymentDetails.recurring}
                onChange={handleToggleSwitch}
              />
            </Form.Group>

            {/* Show message when recurring payment is active */}
            {paymentDetails.recurring && (
              <div>
                <div
                  className="text-success mt-2"
                  style={{ fontWeight: "600", fontSize: "11px" }}
                >
                  Your Auto Renewal is active.
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            className="d-flex justify-content-center w-100"
            style={{ padding: "0 30px 10px 30px" }}
          >
            <Button
              variant="primary"
              onClick={() => {
                addFeaturePayments();
                addPaymentCard();
              }}
              className="me-2 px-4 w-50"
            >
              Pay Now
            </Button>
            <Button
              variant="danger"
              onClick={handleAddFeaturePopup}
              className="px-4 w-50"
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        size="sm"
        show={pendingPopup}
        onHide={() => setPendingPopup(false)}
      >
        <Modal.Header className="d-flex  align-items-center position-relative">
          <Modal.Title>
            <div
              style={{ fontSize: "25px", fontWeight: 600, color: "#4A4A4A" }}
            >
              Payment Status
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-column align-items-center text-center">
            <div>
              <img alt="pendimg-img" src={pendingimg} width={90} height={90} />
            </div>
            <div
              className="p-3 rounded fw-bold mt-3"
              style={{
                color: "#DC9D10",
                width: "fit-content",
                minWidth: "100px",
                border: "1px solid rgb(210 206 206)",
              }}
            >
              "Payment Pending"
            </div>
            <p
              className="mt-3 "
              style={{ color: "#666464", fontWeight: "400" }}
            >
              Your payment is still being processed. You will be notified once
              it's complete.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => setPendingPopup(false)}
            className="w-100"
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>

      {/* //upgrade plan  */}

      <Modal show={upgradePopup} onHide={handleUpgradePopup} centered>
        <Modal.Header
          className="justify-content-center"
          style={{
            padding: "10px 1.2rem",
            fontSize: "18px",
            position: "relative",
            height: "auto",
          }}
        >
          <button
            type="button"
            className="close"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              fontSize: "18px",
            }}
            onClick={handleUpgradePopup}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <Modal.Title className="fs-6 text-center w-100">
            Upgrade Your Plan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "5px 1.2rem" }}>
          {/* New Plan Details */}
          <h5 className="text-danger fs-7 ">🔽 Switching Plan</h5>
          <div className="mt-2 p-2 bg-light rounded">
            <h6 className="text-primary fs-7">
              🔹 Switching to: {updatingPlan}
            </h6>
            <ul className="fs-7">
              <li>
                <strong>New Plan Price:</strong> {updatePlanPrice}/month
              </li>
              <li>
                <strong>Upgrade Cost Today:</strong> ${upgradeAmount}{" "}
                <span className="text-muted">(One-time adjustment fee)</span>
              </li>
              <li>
                <strong>Next Billing Cycle:</strong> {updatePlanPrice}/month
                starting next month
              </li>
              <p className="fs-7 text-center mt-2 ">
                You are moving from <strong>{activePlan}</strong> to{" "}
                <strong>{updatingPlan}</strong>.Enjoy a more cost-effective plan
                while retaining essential features!
              </p>
            </ul>
          </div>

          {/* Current Plan Details */}
          <h5 className="text-success fs-7 ">✅ Current Plan</h5>
          <div className="p-2 bg-light rounded">
            <h6 className="text-primary fs-7">🔹 Current Plan Details</h6>
            <ul className="fs-7">
              <li>
                <strong>Plan:</strong> {activePlan}
              </li>
              <li>
                <strong>Status:</strong>{" "}
                <span className="text-success">Active</span>
              </li>
              <li>
                <strong>Current Price:</strong> {activePlanPrice}/month
              </li>
              <li>
                <strong>Current Plan will be Expiry on:</strong>{" "}
                {new Date(validity).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })}
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ padding: "5px 1.2rem" }}>
          <Button
            variant="secondary"
            onClick={handleUpgradePopup}
            className="fs-7"
          >
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={() => {
              setAddFeaturePaymentPopup(true);
              handleUpgradePopup();
            }}
            className="fs-7"
          >
            Confirm  Upgrade
          </Button>
        </Modal.Footer>
      </Modal>

      {/* downgrade plan */}

      <Modal show={downgradePopup} onHide={handleDowngradePopup} centered>
        <Modal.Header
          className="justify-content-center"
          style={{
            padding: "10px 1.2rem",
            fontSize: "18px",
            position: "relative",
            height: "auto",
          }}
        >
          <button
            type="button"
            className="close"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              fontSize: "18px",
            }}
            onClick={handleDowngradePopup}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <Modal.Title className="fs-6 text-center w-100">
            Downgrade Your Plan
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: "5px 1.2rem" }}>
          <h5 className="text-danger fs-7 ">🔽 Switching Plan</h5>
          <p className="fs-7 text-center">
            You are moving from <strong>{activePlan}</strong> to{" "}
            <strong>{updatingPlan}</strong>.
          </p>

          {/* Downgraded Plan Details */}
          <div className="p-2 bg-light rounded">
            <ul className="fs-7">
              <li>
                <strong>New Plan Activation:</strong> Once your current plan
                expires
              </li>
              <li>
                <strong>New Plan Price:</strong> {updatePlanPrice}/month
              </li>
              <li>
                <strong>Your New Plan Will Be Activated On:</strong>{" "}
                {new Date(validity).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })}
              </li>
            </ul>
            <p className="text-black fs-7 text-center">
              Your downgraded plan will automatically start after your current
              plan ends.
            </p>
          </div>

          {/* Current Plan Details */}
          <h5 className="text-success fs-7  mt-2">✅ Current Plan</h5>
          <div className="p-2 bg-light rounded">
            <ul className="fs-7">
              <li>
                <strong>Plan:</strong> {activePlan}
              </li>
              <li>
                <strong>Status:</strong>{" "}
                <span className="text-success">Active</span>
              </li>
              <li>
                <strong>Current Price:</strong> {activePlanPrice}/month
              </li>
              <li>
                <strong>Plan Expiry Date:</strong>{" "}
                {/* {new Date(currentPlanEndDate).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })} */}
                {new Date(validity).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })}
              </li>
            </ul>
            <p className="text-black fs-7 text-center">
              Your current plan benefits will remain available until the expiry
              date.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ padding: "5px 1.2rem" }}>
          <Button
            variant="secondary"
            onClick={handleDowngradePopup}
            className="fs-7"
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setAddFeaturePaymentPopup(true);
              handleDowngradePopup();
            }}
            className="fs-7"
          >
            Confirm Downgrade
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    authToken: state.user.token,
    userType: state.user.userType,
    profileId: state.user.profileId,
    emailId: state.user.email,
  };
};
export default connect(mapStateToProps)(PlanDetails);
