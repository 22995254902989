export const getExpiryStatus = (endDate, gracePeriodDays = 3) => {
  if (!endDate) {
    return null;
  }

  const planEndDate = new Date(endDate);
  const currentDate = new Date();

  planEndDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  const diffInTime = planEndDate.getTime() - currentDate.getTime();
  const diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24));

  if (diffInDays === 0) {
    return { days: 0, message: "Your plan is expiring today. Please renew soon." };
  } else if (diffInDays > 0 && diffInDays <= 5) {
    return { days: diffInDays, message: `Your plan is expiring in ${diffInDays} ${diffInDays === 1 ? "day" : "days"}. Please renew soon.` };
  } else if (diffInDays < 0) {
    const daysSinceExpiry = Math.abs(diffInDays); 
    const remainingGraceDays = gracePeriodDays - daysSinceExpiry; 

    if (remainingGraceDays > 0) {
      return { days: diffInDays, message: `Your plan has expired. You have ${remainingGraceDays} additional ${remainingGraceDays === 1 ? "day" : "days"} to renew it before access is restricted.` };
    } else {
      return { days: diffInDays, message: "Your plan has expired. Please renew to continue." };
    }
  }

  return null;
};