import React, { useState, useEffect } from "react";
import StarRating from "./StarRating";
import { Card, Button, Row, Col, Container } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import HeaderMenu from "../headerMenu";
import SideBar from "../SideBar";
import BreadCrumb from "../../plugins/Breadcrumb";
import { useNavigate } from "react-router-dom";
import profile from "../../../assets/images/profile.jpg";
import { MentionsInput, Mention } from "react-mentions";
import loader from "../../../assets/images/loader.gif";
import mentionInputStyle from "../../../assets/css/MentionStyle";
import { IoArrowBack } from "react-icons/io5";
import { connect } from "react-redux";
import FollowCard from "../DashboardTabs/FollowCard";
import { commonUtils } from "../../CommonUtils";
import { TbInfoCircle } from "react-icons/tb";
const Review = (props) => {
  const navigate = useNavigate();
  const [config, setConfig] = useState([]);
  const [rating, setRating] = useState(0);
  const [knownSinceDate, setKnownSinceDate] = useState(null);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [comment, setComment] = useState("");
  const [mentionuserIds, setmentionuserIds] = useState([]);
  const [roleList, setRoleList] = useState("");
  const [knownSinceError, setKnownSinceError] = useState("");
  const [selectedRoleError, setSelectedRoleError] = useState("");
  const [commentError, setCommentError] = useState("");
  const [reviewQuestions, setReviewQuestions] = useState([]);
  const [ratingError, setRatingError] = useState("");
  const [roleRating, setRoleRating] = useState(0);
  const [quesRating, setQuesRating] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userCardList, setUserCardList] = useState();
  const [businessUserId, setBusinessUserId] = useState(null);
  const reviewedById = useSelector((state) => state.user.userId);
  const location = useLocation();
  const {
    userId,
    followedTypeId,
    followingTypeId,
    followedUserType,
    followingUserType,
    professionalId,
    businessId,
    businessName,
    viewUserType,
    professionType,
    professionTypeId,
    userTypeInfo,
    loadQuestion,
  } = location.state;
  const filteredData = location.state.filteredData;
  const filterBusData = location.state.filterBusData;
  const companyName = location.state.filterBusData?.companyName;
  const [submit_dis, setSubmit_dis] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState("");
  const [selectedRole, setSelectedRole] = useState(
    followedTypeId || followingTypeId || professionTypeId
  );
  const [loadQues, setLoadQues] = useState(loadQuestion);
  const [selectedUserType, setSelectedUserType] = useState(
    viewUserType || followingUserType || followedUserType
  );
  const [selectedTypeId, setSelectedTypeId] = useState(
    businessId ||
      professionTypeId ||
      (filteredData && filteredData.professionTypeId) ||
      (filterBusData && filterBusData.id) ||
      null
  );
  const [selectedType, setSelectedType] = useState(
    professionType ||
      (filteredData && filteredData.professionType) ||
      businessName ||
      (filterBusData && filterBusData.companyName) ||
      ""
  );
  const [selectedId, setSelectedId] = useState(
    businessId || professionalId || followedTypeId || followingTypeId
  );
  useEffect(() => {
    loadRoleList();
    loadUserType();
    loaduserLists();
    reviewUserInfo(selectedUserType, selectedTypeId);
    fetch("/config.json")
      .then((res) => res.json())
      .then((res) => {
        setConfig(res);
      });
  }, [location.state]);
  useEffect(() => {
    setLoadQues(false);
    console.log("selectedRole", selectedRole);
    if (selectedRole) loadQuestions(selectedRole);
    else setReviewQuestions([]);
    loadRoleList();
  }, [selectedUserType || selectedRole || loadQuestion]);
  const loaduserLists = async () => {
    try {
      const response = await window.apiService.lookupAllUsersList();
      const usersArr = response.data.recordInfo.filter((row) => {
        return (
          row.userId != reviewedById &&
          row.userId.split("-")[0] != reviewedById &&
          row.userId.split("&")[0] != reviewedById
        );
      });
      const users = usersArr.map(function (row) {
        return { display: row.name, id: row.userId };
      });
      setUserList(users);
    } catch (error) {
      console.log(error);
    }
  };
  const OnRevClick = (profileId, type, professionType, professionTypeId) => {
    if (props.userType == "Business") {
      setLoadQues(true);
    } else {
      setLoadQues(false);
    }
    setSelectedUserType(type);
    setSelectedTypeId(professionTypeId);
    if (type == "Professional") setSelectedRole(professionTypeId);
    else setSelectedRole("");
    setSelectedType(professionType);
    setSelectedId(profileId);
    reviewUserInfo(type, professionTypeId);
  };
  const reviewUserInfo = async (type, profileId) => {
    const params = {
      userType: type,
      profileId: profileId,
      userId: userId,
    };
    try {
      const response = await window.apiService.reviewUserInfo(params);
      setUserCardList(response.data.data);
      if (type === "Business")
        setBusinessUserId(
          response.data.data.businessList.filter(
            (user) => user.id === profileId
          )?.[0]?.businessUser.id
        );
    } catch (error) {
      console.log(error);
    }
  };
  const handleReviewSubmit = async () => {
    console.log("role", selectedRole, selectedTypeId, selectedId);
    setKnownSinceError("");
    setSelectedRoleError("");
    setRatingError("");
    setCommentError("");

    let isValid = true;
    if (!knownSinceDate) {
      setKnownSinceError("Known since is required.");
      isValid = false;
    }
    if (!selectedRole) {
      setSelectedRoleError("Role is required.");
      isValid = false;
    }
    if (!comment.trim()) {
      setCommentError("Comment is required.");
      isValid = false;
    }
    if (roleRating == 0 && rating == 0) {
      setRatingError("Rating is required.");
      isValid = false;
    }

    if (!isValid) {
      return;
    }
    const params = {
      userId: userId,
      professionalId:
        selectedUserType == "Professional" && selectedId
          ? selectedId
          : undefined,
      businessId:
        selectedUserType == "Business" && selectedId ? selectedId : undefined,
      knownSince: knownSinceDate,
      userType: selectedUserType ? selectedUserType : "",
      // roleId:
      //   filteredData !== undefined && filteredData !== null
      //     ? filteredData.professionTypeId
      //     : filterBusDataId !== undefined && filterBusDataId !== ""
      //     ? filterBusDataId
      //     : selectedTypeId !== undefined
      //     ? selectedTypeId
      //     : selectedRole,
      roleId:
        selectedUserType === "Professional" && props.userType !== "Business"
          ? selectedTypeId
          : selectedRole,
      rating: roleRating ? roleRating : rating,
      businessUserId: businessUserId,
      comment: comment,
      fileName: "",
      fileType: "",
      filePath: "",
      imageName: "",
      imagePath: "",
      reviewedBy: reviewedById,
      url: "",
      isDeleted: 0,
      reviewQuestions: quesRating,
      mentionUserIds: mentionuserIds,
      reviewedByProfessional:
        props.userType == "Professional" ? props.profileId : undefined,
      reviewedByBusiness:
        props.userType == "Business" ? props.profileId : undefined,
    };
    setLoading(true);
    console.log("params", params);
    if (params) {
      try {
        const response = await window.apiService.addReview(params);
        if (response.data.status == "OK") {
          uploadImage(response.data.id);
          uploadFile(response.data.id);
          toast.success(response.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              setTimeout(() => {
                navigate("/dashboard/reviews");
              }, 0);
            },
          });
          setRating(0);
          setSelectedRole("");
          setKnownSinceDate(null);
          setFiles(null);
          setImages(null);
          setComment("");
          setReviewQuestions([]);
          setRoleRating(0);
          setQuesRating([]);
          setmentionuserIds([]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error("Error occurred", {
          theme: "colored",
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const handleReviewClose = () => {
    setRating(0);
    setSelectedRole("");
    setKnownSinceDate(null);
    setFiles(null);
    setImages(null);
    setComment("");
    setReviewQuestions([]);
    setRoleRating(0);
    navigate(-1);
  };
  const changeCmtText = (value) => {
    commonUtils.changeSimpleCmtText(
      value,
      setCommentError,
      setmentionuserIds,
      setComment
    );
  };
  function filteredcontent(content) {
    return content.find((value) => {
      return value.startsWith("@");
    });
  }
  const handleStarRatingChange = (selectedRating) => {
    setRating(selectedRating);
    if (selectedRating) {
      setRatingError("");
    }
  };
  function calculateAverageRating(data) {
    let sumOfRatings = 0;
    let countOfRatings = 0;

    for (const item of data) {
      if (item.hasOwnProperty("rating")) {
        sumOfRatings += item.rating;
        countOfRatings++;
      }
    }

    if (countOfRatings === 0) {
      return 0;
    }

    return sumOfRatings / countOfRatings;
  }
  const handleRoleStarRatingChange = (selectedRating, roleId, id, idx) => {
    const updatedReviewQuestions = reviewQuestions.map((obj, index) => {
      if (index === idx) {
        return { ...obj, rating: selectedRating };
      }
      return obj;
    });

    setReviewQuestions(updatedReviewQuestions);

    const result = updatedReviewQuestions.map((obj) => ({
      roleId: roleId,
      questionId: obj.id,
      rating: obj.rating || 0,
    }));
    setQuesRating(result);
    const averageRating = calculateAverageRating(result);
    setRoleRating(averageRating);
    if (averageRating) {
      setRatingError("");
    }
  };
  const uploadFile = async (id) => {
    if (files.length > 0) {
      let formData = new FormData();
      for (const row of Object.keys(files)) {
        formData.append("files", files[row]);
      }
      formData.append("id", id);
      formData.append("type", "file");
      try {
        const response = await window.apiService.revUploadFile(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data.status == "OK") {
          setLoading(false);
        } else if (response.data.status == "EXPECTATION_FAILED") {
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleFileChange = (event) => {
    let allowedfiletypes = ["pdf", "doc", "docx", "mp4"];
    let fileResult = document.getElementById("file-result");
    if (event.target.files.length > 0) {
      const fileSize = event.target.files[0].size;
      const fileMb = fileSize / 1024;
      if (fileMb >= 5120) {
        fileResult.innerHTML = "Please select a file less than 5MB.";
        setSubmit_dis(true);
      } else {
        fileResult.innerHTML = "";
        setSubmit_dis(false);
        let fileprev = [];
        let file = [];
        let ext = event.target.files[0].name.substring(
          event.target.files[0].name.lastIndexOf(".") + 1
        );
        const isFound = allowedfiletypes.some((element) => {
          if (element === ext) {
            return true;
          }
          return false;
        });
        if (isFound) {
          fileprev.push(...filePreviews, { name: event.target.files[0].name });
          file.push(...files, event.target.files[0]);
          setFiles(file);
          setFilePreviews(fileprev);
        } else {
          toast.error(config.FileFormatErr, {
            theme: "colored",
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          fileprev = [];
          file = [];
        }
      }
    }
  };
  // const handleImageChange = (event) => {
  //   let allowedfiletypes = ["jpg", "svg", "jpeg", "png", "bmp", "gif", "jfif"];

  //   if (event.target.files.length > 0) {
  //     let imgprev = [];
  //     let img = [];
  //     let ext = event.target.files[0].name.substring(
  //       event.target.files[0].name.lastIndexOf(".") + 1
  //     );
  //     const isnotFound = allowedfiletypes.some((element) => {
  //       if (element === ext) {
  //         return true;
  //       }
  //       return false;
  //     });
  //     if (isnotFound) {
  //       imgprev.push(...imagePreviews, {
  //         name: event.target.files[0].name,
  //         url: URL.createObjectURL(event.target.files[0]),
  //       });
  //       img.push(...images, event.target.files[0]);
  //       setImages(img);
  //       setImagePreviews(imgprev);
  //     } else {
  //       toast.error(config.FileFormatErr, {
  //         theme: "colored",
  //         position: "top-right",
  //         autoClose: 1500,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //       });
  //       imgprev = [];
  //       img = [];
  //     }
  //   }
  // };
  const handleImageChange = (event) => {
    const allowedFileTypes = [
      "jpg",
      "svg",
      "jpeg",
      "png",
      "bmp",
      "gif",
      "jfif",
    ];
    const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

    if (event.target.files.length > 0) {
      let imgPrev = [];
      let img = [];
      const file = event.target.files[0];
      const ext = file.name
        .substring(file.name.lastIndexOf(".") + 1)
        .toLowerCase();
      const isFileTypeAllowed = allowedFileTypes.includes(ext);

      if (!isFileTypeAllowed) {
        toast.error(
          "File format not supported. Allowed formats: " +
            allowedFileTypes.join(", "),
          {
            theme: "colored",
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          }
        );
        return;
      }

      if (file.size > maxFileSize) {
        toast.error("File size exceeds 5MB limit.", {
          theme: "colored",
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        return;
      }

      imgPrev.push(...imagePreviews, {
        name: file.name,
        url: URL.createObjectURL(file),
      });
      img.push(...images, file);
      setImages(img);
      setImagePreviews(imgPrev);
    }
  };
  const rmvImage = (index) => {
    const removIdx = imagePreviews.filter(function (imgPrev, idx) {
      if (idx != index) {
        return imgPrev;
      }
    });
    setImagePreviews(removIdx);
    let rmvImage = [];
    for (let i = 0; i < images.length; i++) {
      if (i != index) {
        rmvImage.push(images[i]);
      }
    }
    setImages(rmvImage);
  };
  const rmvFile = (index) => {
    const removIdx = filePreviews.filter(function (filePrev, idx) {
      if (idx != index) {
        return filePrev;
      }
    });
    setFilePreviews(removIdx);
    let rmvFile = [];
    for (let i = 0; i < files.length; i++) {
      if (i != index) {
        rmvFile.push(files[i]);
      }
    }
    setFiles(rmvFile);
  };
  const uploadImage = async (id) => {
    if (images.length > 0) {
      let formData = new FormData();

      for (const row of Object.keys(images)) {
        formData.append("files", images[row]);
      }
      formData.append("id", id);
      formData.append("type", "image");
      try {
        const response = await window.apiService.revUploadFile(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data.status == "OK") {
          setLoading(false);
        } else if (response.data.status == "EXPECTATION_FAILED") {
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    setLoadQues(false);
    handleRoleChange(selectedRole);
  }, [selectedRole]);
  const handleRoleChange = (value) => {
    setRoleRating(0);
    setRating(0);
    setSelectedRole(value);
    loadQuestions(value);
    if (value) {
      setSelectedRoleError("");
    }
  };
  const handleKnownSinceChange = (data) => {
    setKnownSinceDate(data);
    if (data) {
      setKnownSinceError("");
    }
  };
  const loadQuestions = async (id) => {
    if (id) {
      const params = {
        roleId: id,
        userType: selectedUserType,
      };
      try {
        const response = await window.apiService.revQnsById(params);
        const reviewQues = response.data.recordInfo.map((object) => {
          return { ...object, rating: "" };
        });
        setReviewQuestions(reviewQues);
      } catch (error) {
        console.log(error);
        toast.error("Error Occured");
      }
    } else {
      setReviewQuestions([]);
    }
  };
  const GoBack = () => {
    navigate(-1);
  };
  const loadUserType = async () => {
    try {
      const response = await window.apiService.loadUserTypes();
      setUserType(response.data.recordInfo);
    } catch (error) {
      console.log(error);
    }
  };
  const loadRoleList = async () => {
    try {
      const response = await window.apiService.professionalList({
        userTypeId:
          selectedUserType === "Business" ||
          (props.userType === "Business" && selectedUserType === "Personal")
            ? 3
            : props.userType === "Business" &&
              selectedUserType == "Professional"
            ? 2
            : 1,
      });
      setRoleList(response.data.recordInfo);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <HeaderMenu />
      <div className="">
        <SideBar />
        <div className="main-content ml_200">
          {/* <BreadCrumb /> */}
          {loading ? (
            <div className="pro-loader-center">
              <img className="loader-image" src={loader} alt="Loading..." />
            </div>
          ) : (
            ""
          )}
          <Container className="mb-4 pg_scrll" fluid>
            <Row>
              <Col>
                <Card className="bg-secondary profile_card">
                  <Card.Body>
                    <Row>
                       <Col>
                     <h5 className="text-black">Add Review</h5>
                       </Col>
                      <div className="d-flex justify-content-end"
                       style={{marginTop:'-28px'}}>
                        <commonUtils.CustomButton
                          btnText={
                            <div className="d-flex align-items-center">
                              
                              <IoArrowBack /> <span >Back</span>
                            </div>
                          }
                          btnWidth="fit-content"
                          variant="dark"
                          perform={() => GoBack()}
                          isDisabled={false}
                        />
                      </div>
                      <Col
                        lg="5"
                        md="12"
                        sm="12"
                        className="overflow-y-auto rev_scrl "
                      >
                        {userCardList && (
                          <div className="d-flex mt-2 justify-content-center border-bottom pb-3">
                            {selectedUserType === "Personal" &&
                            userCardList.personal != null ? (
                              <Col
                                sm={12}
                                md={8}
                                lg={8}
                                xl={6}
                                // className="m-auto"
                              >
                                <FollowCard
                                  active={true}
                                  list={userCardList.personal}
                                  userId={userId}
                                  firstName={userCardList.personal.firstName}
                                  lastName={userCardList.personal.lastName}
                                  onReview={() =>
                                    OnRevClick(
                                      null,
                                      userCardList.personal.userType,
                                      "",
                                      undefined
                                    )
                                  }
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            {userCardList.professionalList &&
                            userCardList.professionalList.length > 0
                              ? userCardList.professionalList.map(
                                  (profession) =>
                                    selectedUserType === "Professional" &&
                                    profession.professionTypeId ===
                                      selectedTypeId && (
                                      <Col
                                        sm={12}
                                        md={8}
                                        lg={8}
                                        xl={6}
                                        // className="m-auto"
                                      >
                                        <FollowCard
                                          active={true}
                                          userId={userId}
                                          firstName={
                                            userCardList.personal.firstName
                                          }
                                          lastName={
                                            userCardList.personal.lastName
                                          }
                                          list={profession}
                                          onReview={() =>
                                            OnRevClick(
                                              profession.id,
                                              profession.userType,
                                              profession.professionType,
                                              profession.professionTypeId
                                            )
                                          }
                                        />
                                      </Col>
                                    )
                                )
                              : ""}
                            {userCardList.businessList &&
                            userCardList.businessList.length > 0
                              ? userCardList.businessList.map(
                                  (business) =>
                                    selectedUserType === "Business" &&
                                    business.id === selectedTypeId && (
                                      <Col
                                        sm={12}
                                        md={8}
                                        lg={8}
                                        xl={6}
                                        // className="m-auto"
                                      >
                                        <FollowCard
                                          active={true}
                                          userId={userId}
                                          firstName={
                                            userCardList.personal.firstName
                                          }
                                          lastName={
                                            userCardList.personal.lastName
                                          }
                                          list={business}
                                          onReview={() =>
                                            OnRevClick(
                                              business.id,
                                              business.userType,
                                              business.companyName,
                                              business.businessId
                                            )
                                          }
                                        />
                                      </Col>
                                    )
                                )
                              : ""}
                          </div>
                        )}
                        {/* <Row className="justify-content-center">
                          <div className="card-profile-image">
                            <img
                              className="imagePreview w_h_120"
                              src={
                                selectedUser.imagePath
                                  ? selectedUser.imagePath
                                  : profile
                              }
                            />
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <div className="text-center fz-22 alert-link">
                            {selectedUser.firstName
                              ? selectedUser.firstName
                              : ""}{" "}
                            {selectedUser.lastName ? selectedUser.lastName : ""}
                          </div>
                          {selectedType && (
                            <div className="text-center alert-link">
                              {"(" + selectedType + ")"}
                            </div>
                          )}
                          {companyName && (
                            <div className="text-center alert-link">
                              {"(" + companyName + ")"}
                            </div>
                          )}
                        </Row> */}
                        {userCardList && (
                          <div className="row mt-2 pt-3 justify-content-center">
                            {userCardList.personal != null &&
                            selectedUserType !== "Personal" ? (
                              <Col
                                sm={12}
                                md={8}
                                lg={8}
                                xl={6}
                                // className="m-auto"
                              >
                                <FollowCard
                                  active={false}
                                  list={userCardList.personal}
                                  userId={userId}
                                  firstName={userCardList.personal.firstName}
                                  lastName={userCardList.personal.lastName}
                                  onReview={() =>
                                    OnRevClick(
                                      null,
                                      userCardList.personal.userType,
                                      "",
                                      undefined
                                    )
                                  }
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            {userCardList.professionalList &&
                            userCardList.professionalList.length > 0
                              ? userCardList.professionalList.map(
                                  (profession) =>
                                    profession.professionTypeId !==
                                      selectedTypeId && (
                                      <Col
                                        sm={12}
                                        md={8}
                                        lg={8}
                                        xl={6}
                                        // className="m-auto"
                                      >
                                        <FollowCard
                                          active={false}
                                          userId={userId}
                                          firstName={
                                            userCardList.personal.firstName
                                          }
                                          lastName={
                                            userCardList.personal.lastName
                                          }
                                          list={profession}
                                          onReview={() =>
                                            OnRevClick(
                                              profession.id,
                                              profession.userType,
                                              profession.professionType,
                                              profession.professionTypeId
                                            )
                                          }
                                        />
                                      </Col>
                                    )
                                )
                              : ""}
                            {userCardList.businessList &&
                            userCardList.businessList.length > 0
                              ? userCardList.businessList.map(
                                  (business) =>
                                    business.id !== selectedTypeId && (
                                      <Col
                                        sm={12}
                                        md={8}
                                        lg={8}
                                        xl={6}
                                        // className="m-auto"
                                      >
                                        <FollowCard
                                          active={false}
                                          userId={userId}
                                          firstName={
                                            userCardList.personal.firstName
                                          }
                                          lastName={
                                            userCardList.personal.lastName
                                          }
                                          list={business}
                                          onReview={() =>
                                            OnRevClick(
                                              business.id,
                                              business.userType,
                                              business.companyName,
                                              business.id
                                            )
                                          }
                                        />
                                      </Col>
                                    )
                                )
                              : ""}
                          </div>
                        )}
                      </Col>
                      <div className="rec_div"></div>
                      <Col lg="6" md="12" sm="12">
                        <div className="container review-container">
                          <div className="row d-flex">
                            <div className="col-sm-12 col-md-4 col-lg-4 reviewPage">
                              Known since
                            </div>
                            <div className="col-sm-12 col-md-8 col-lg-8 reviewPage">
                              <DatePicker
                                selected={knownSinceDate}
                                onChange={handleKnownSinceChange}
                                className="form-control fz-12"
                                placeholderText="Known since"
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                maxDate={new Date()}
                              />
                              {knownSinceError && (
                                <div className="text-danger fz-12">
                                  {knownSinceError}
                                </div>
                              )}
                            </div>
                          </div>
                          {selectedUserType === "Business" && (
                            <div className="row d-flex">
                              <div className="col-sm-12 col-md-4 col-lg-4 reviewPage">
                                Company Name
                              </div>
                              <div className="col-sm-12 col-md-8 col-lg-8 reviewPage">
                                {selectedType || businessName || companyName}
                              </div>
                            </div>
                          )}
                          <div className="row d-flex">
                            <div className="col-sm-12 col-md-4 col-lg-4 reviewPage">
                              Reviewed as
                            </div>
                            <div className="col-sm-12 col-md-8 col-lg-8 reviewPage">
                              <select
                          
                                className="form-control  fz-12"
                                value={
                                  selectedUserType == "Professional" &&
                                  props.userType !== "Business"
                                    ? selectedTypeId
                                    : selectedRole
                                }
                                onChange={(e) =>
                                  handleRoleChange(e.target.value)
                                }
                                disabled={
                                  selectedUserType == "Professional"
                                    ? true
                                    : false
                                }
                              >
                               
                                <option value="">Select a role</option>
                                {(selectedUserType == "Personal" ||
                                  selectedUserType == "Business" ||
                                  selectedUserType == "Professional") &&
                                  roleList &&
                                  roleList.map((role) => (
                                    <option value={role.id} 
                                   >
                                      {role.name}</option>
                                  ))}
                                
                                {/* {(followedUserType == "Professional" ||
                                    followingUserType == "Professional") &&
                                    professionalList && (
                                      <option
                                        value={
                                          professionalList.professionTypeId
                                        }
                                      >
                                        {professionalList.professionType}
                                      </option>
                                    )} */}
                                {/* {(followedUserType == "Business" ||
                                    followingUserType == "Business" ||
                                    viewUserType == "Business") &&
                                    roleList &&
                                    roleList.map((role) => (
                                      <option value={role.id}>
                                        {role.name}
                                      </option>
                                    ))} */}
                                {(selectedUserType == "Professional" ||
                                  selectedUserType == "professional") &&
                                  props.userType != "Business" && (
                                    <option value={selectedTypeId}>
                                      {selectedType}
                                    </option>
                                  )}
                                {/* {(selectedUserType == "Business" ||
                                    selectedUserType == "Business") && (
                                    <option value={selectedId}>
                                      {selectedType}
                                    </option>
                                  )} */}
                                {/* {businessList && selectedTypeId == "3" && (
                                    <option
                                      value={businessList.bussinessTypeId}
                                    >
                                      {businessList.bussinessType}
                                    </option>
                                  )} */}
                              </select>
                              {selectedRoleError && (
                                <div className="text-danger fz-12">
                                  {selectedRoleError}
                                </div>
                              )}
                            </div>
                            {/* ) : (
                              <div className="col-7 col-md-6 col-lg-7 reviewPage">
                                <input
                                  className="form-control roleClr fz-12"
                                  value={selectedType}
                                  disabled
                                ></input>
                              </div>
                            )} */}
                          </div>
                          <div className="row d-flex">
                            <div className="col-sm-12 col-md-4 col-lg-4 reviewPage"></div>
                            <div className="col-sm-12 col-md-8 col-lg-8 reviewPage">
                              {reviewQuestions &&
                                reviewQuestions.length > 0 &&
                                reviewQuestions.map((data, idx) => (
                                  <div className="row" key={idx}>
                                    <div className="col-6 col-md-6 col-lg-6 ques">
                                      <p>{data.question}</p>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-6">
                                      <StarRating
                                        rating={data.rating}
                                        id={data.id}
                                        idx={idx}
                                        roleId={data.userRoleId}
                                        isReview={true}
                                        onRatingChange={
                                          handleRoleStarRatingChange
                                        }
                                      />
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                          <div className="row d-flex">
                            <div className="col-sm-12 col-md-4 col-lg-4 reviewPage">
                              Comment
                            </div>
                            <div className="col-sm-12 col-md-8 col-lg-8 reviewPage">
                              <MentionsInput
                                style={mentionInputStyle}
                                className="Comment_sty form-control"
                                maxLength={288}
                                onChange={(e) => {
                                  changeCmtText(e.target.value);
                                }}
                                value={comment}
                                placeholder="Please add information supporting your review…"
                              >
                                <Mention
                                  style={{ backgroundColor: "#efebe3" }}
                                  trigger="@"
                                  data={userList}
                                />
                              </MentionsInput>
                              <span className="limt_cmt">
                                Limit : Max. 288 characters
                              </span>
                              {commentError && (
                                <div className="text-danger fz-12">
                                  {commentError}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row d-flex">
                            <div className="col-sm-12 col-md-4 col-lg-4 reviewPage">
                              Review
                            </div>
                            <div className="col-sm-12 col-md-8 col-lg-8 reviewPage d-flex align-items-center">
                              <StarRating
                                rating={roleRating != 0 ? roleRating : rating}
                                onRatingChange={handleStarRatingChange}
                                disabled={reviewQuestions.length != 0}
                              />
                              <span className="marLf">
                                (
                                {(roleRating !== 0 ? roleRating : rating) %
                                  1 !==
                                0
                                  ? (roleRating !== 0
                                      ? roleRating
                                      : rating
                                    ).toFixed(1)
                                  : roleRating !== 0
                                  ? roleRating
                                  : rating}
                                )
                              </span>
                              {ratingError && (
                                <div className="text-danger fz-12">
                                  {ratingError}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row d-flex">
                            <div className="col-sm-12 col-md-4 col-lg-4 reviewPage"></div>
                            <div className="col-sm-12 col-md-8 col-lg-8 reviewPage">
                              <div className="row upload-container">
                                <div className="col-12 col-md-6 col-lg-6">
                                  <div className="d-flex">
                                    <button className="upload_click">
                                      Click to upload video/doc
                                    </button>
                                    <span
                                      className="c_pointer clr_darkblue"
                                      title="Accepted formats: RTF, DOC, DOCX, PDF, MP4, M4V, and other video files."
                                    >
                                      <TbInfoCircle />
                                    </span>
                                  </div>
                                  <input
                                    type="file"
                                    accept=".rtf,.doc,.docx,.pdf,.DOCX,video/mp4,video/x-m4v,video/*"
                                    className="file_click_up"
                                    onChange={handleFileChange}
                                  />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                  <div className="d-flex">
                                    <button className="upload_click marLef">
                                      Click to upload image
                                    </button>
                                    <span
                                      className="c_pointer clr_darkblue"
                                      title="Accepted formats: Images only."
                                    >
                                      <TbInfoCircle />
                                    </span>
                                  </div>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    className="file_click_up"
                                    onChange={handleImageChange}
                                  />
                                </div>
                                <div className="row">
                                  <p id="file-result"></p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row d-flex">
                            <div className="col-sm-12 col-md-4 col-lg-4 reviewPage"></div>
                            <div className="col-sm-12 col-md-8 col-lg-8 reviewPage">
                              <div className="row upload-container">
                                <div className="col-12 col-md-6 col-lg-6">
                                  {filePreviews.length > 0 ? (
                                    <Card className="file_pad">
                                      {filePreviews.map((file, i) => {
                                        return (
                                          <div className="row m-1">
                                            <div className="col-10 preview_ttle">
                                              {file.name && (
                                                <a
                                                  href={file.url}
                                                  target="_blank"
                                                  title={file.name}
                                                  className="fz-10"
                                                >
                                                  {file.name}
                                                </a>
                                              )}
                                            </div>
                                            <div className="col-2 p-1">
                                              <span className="pl-1 c_pointer">
                                                <AiOutlineClose
                                                  className="drop_header"
                                                  onClick={() => {
                                                    rmvFile(i);
                                                  }}
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </Card>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                  {imagePreviews.length > 0 ? (
                                    <Card>
                                      {imagePreviews.map((img, i) => {
                                        return (
                                          <Row className="m-1">
                                            <div className="col-3 p-0">
                                              <img
                                                className="rev_preview"
                                                src={img.url}
                                                alt={"image-" + i}
                                                key={i}
                                              />
                                            </div>
                                            <div className="col-9 p-0">
                                              <Row>
                                                <div className="col-9 preview_ttle">
                                                  {img.name && (
                                                    <a
                                                      href={img.url}
                                                      target="_blank"
                                                      title={img.name}
                                                      className="fz-10"
                                                    >
                                                      {img.name}
                                                    </a>
                                                  )}
                                                </div>
                                                <div className="col-2 p-1">
                                                  <span className="pl-1 c_pointer">
                                                    <AiOutlineClose
                                                      className="drop_header"
                                                      onClick={() => {
                                                        rmvImage(i);
                                                      }}
                                                    />
                                                  </span>
                                                </div>
                                              </Row>
                                            </div>
                                          </Row>
                                        );
                                      })}
                                    </Card>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row d-flex">
                            <div className="col-sm-12 col-md-4 col-lg-4 reviewPage"></div>
                            <div className="col-sm-12 col-md-8 col-lg-8 reviewPage">
                              <div className="row review_center mt-2">
                                <div className='col-md-6 col-lg-6  col-lg-12" reviewPage'>
                                  <commonUtils.CustomButton
                                    btnText="Cancel"
                                    btnWidth="100%"
                                    variant="danger"
                                    perform={() => handleReviewClose()}
                                    isDisabled={false}
                                  />
                                </div>
                                <div className='col-md-6 col-lg-6  col-lg-12" reviewPage'>
                                  <commonUtils.CustomButton
                                    btnText="Submit"
                                    btnWidth="100%"
                                    variant="primary"
                                    perform={() => handleReviewSubmit()}
                                    isDisabled={submit_dis}
                                  />
                                </div>
                                <div className="fs_14px">
                                  By clicking on Submit, you represent you know
                                  this person in this Role.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userType: state.user.userType,
    profileId: state.user.profileId,
  };
};
export default connect(mapStateToProps)(Review);
