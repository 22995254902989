import React, { useState, useEffect } from "react";
import { Card, Col, Button } from "react-bootstrap";
import { PiPhoneFill } from "react-icons/pi";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { useToast } from "../../../Toast";
import { commonUtils } from "../../../CommonUtils";
import loader from "../../../../assets/images/loader.gif";
const Hubspot = ({ userId }) => {
  const [loading, setLoading] = useState(false);
  const [hubspotData, setHubspotData] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextCursor, setNextCursor] = useState(null);
  const [prevCursors, setPrevCursors] = useState([]);
  const limit = 10;
  const toast = useToast();
  useEffect(() => {
    getHubspotData();
  }, []);
  const getHubspotData = async (afterCursor = null, isNext = true) => {
    try {
      setLoading(true);
      const params = new URLSearchParams({ limit });
      if (afterCursor) {
        params.append("after", afterCursor);
      }
      const response = await window.apiService.getHubspotData(params);
      if (response.data.results.length > 0) {
        setHubspotData(response.data.results);
        if (isNext) {
          if (hubspotData.length > 0) {
            const prevFirstRecordId = hubspotData[0].id;
            setPrevCursors((prev) => [...prev, prevFirstRecordId]);
          }
        }
        setNextCursor(response.data.paging?.next?.after || null);
      } else {
        setNextCursor(null);
      }
    } catch (error) {
      console.error("Error Fetching Data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    if (nextCursor) {
      setPrevCursors((prev) => [...prev, nextCursor]);
      setCurrentPage((prev) => prev + 1);
      getHubspotData(nextCursor, true);
    }
  };
  const handlePreviousPage = () => {
    if (prevCursors.length > 0) {
      const prevCursor = prevCursors[prevCursors.length - 1];
      setPrevCursors((prev) => prev.slice(0, -1));
      setCurrentPage((prev) => prev - 1);
      getHubspotData(prevCursor, false);
    }
  };
  const handleSubmit = async () => {
    const formattedContacts = selectedContacts.map((user) => ({
      email: user.properties.email,
      firstName: user.properties.firstname,
      lastName: user.properties.lastname,
      phone: user.properties.phone,
      inviterId: userId,
    }));
    try {
      setLoading(true);
      const response = await window.apiService.addBulkUser(formattedContacts);
      if (response.status == 200) {
        toast.success(response.data.message);
        selectedContacts([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  return (
    <>
      <Card className="shade">
        <div className="d-flex">
          {hubspotData.length > 0 ? (
            <Col lg={10} className="bg-content overflow-auto">
              <div className="d-flex justify-content-end mb-2 mt-2">
                <commonUtils.CustomButton
                  btnText="Push to tiipstr DB"
                  btnWidth="fit-content"
                  variant="primary"
                  perform={() => handleSubmit()}
                  isDisabled={selectedContacts.length > 0 ? false : true}
                />
              </div>

              {hubspotData.map((user) => (
                <Card className="box_shadow" key={user.id}>
                  <div className="d-flex align-items-center">
                    <Col lg={10} className="pe-2">
                      <div className="text-left d-flex justify-content-between align-items-center">
                        <div>
                          <p className="mb-1 font-weight-500 card_txt">
                            {user.properties?.firstname +
                              " " +
                              (user.properties?.lastname || "")}
                          </p>
                          <p className="text-lowercase of-ellipsis card_txt">
                            {user.properties?.email}
                          </p>
                        </div>
                        {user.properties?.phone && (
                          <div className="d-flex align-items-center">
                            <PiPhoneFill className="mt_4" />
                            &nbsp;
                            <p className="text-lowercase card_txt mb-0">
                              {user.properties?.phone}
                            </p>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={2} className="pe-2">
                      <input
                        type="checkbox"
                        checked={selectedContacts.some((c) => c.id === user.id)}
                        onChange={() =>
                          setSelectedContacts((prev) =>
                            prev.some((c) => c.id === user.id)
                              ? prev.filter((c) => c.id !== user.id)
                              : [...prev, user]
                          )
                        }
                      />
                    </Col>
                  </div>
                </Card>
              ))}

              <div className="mt-3 d-flex align-items-center justify-content-center">
                <Button
                  className="mr-0 d-flex align-items-center"
                  onClick={handlePreviousPage}
                  disabled={currentPage <= 1 || prevCursors.length === 0}
                >
                  <FaArrowLeft />
                </Button>

                <span className="ml-2 mr-2">{currentPage}</span>
                <Button
                  className="d-flex align-items-center"
                  onClick={handleNextPage}
                  disabled={!nextCursor || loading}
                >
                  <FaArrowRight />
                </Button>
              </div>
            </Col>
          ) : (
            !loading && (
              <div className="row col-md-12 d-align mx-0 p-0 w-100">
                <div className="no-data font-weight-600 w-100 text-center fz_14">
                  Sorry, there is no data to be displayed
                </div>
              </div>
            )
          )}
        </div>
        {loading ? (
          <div className="pro-loader-center">
            <img className="loader-image" src={loader} alt="Loading..." />
          </div>
        ) : (
          ""
        )}
      </Card>
    </>
  );
};

export default Hubspot;
