import AttachmentModal from "../../plugins/AttachmentModal";
import fileImg from "../../../assets/images/file.jpg";
import StarRating from "./StarRating";
import profile from "../../../assets/images/profile.jpg";
import { Card, Col, Row, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { BsShareFill } from "react-icons/bs";
import moment from "moment";
import logoImg from "../../../assets/images/tiipstrlogo.jpg";
import chatImg from "../../../assets/images/dash_chat.png";
import { Helmet } from "react-helmet";
import React, { useState, useEffect, useRef } from "react";
import { useToast } from "../../Toast";
import { useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import SideBar from "../SideBar";
import HeaderMenu from "../headerMenu";
import BreadCrumb from "../../plugins/Breadcrumb";
import loader from "../../../assets/images/loader.gif";
import Horizontalbar from "../../plugins/Horizontalbar";
import RatingBar from "../../plugins/RatingBar";
import { FaInstagramSquare, FaPhoneAlt } from "react-icons/fa";
import { commonUtils } from "../../CommonUtils";
import { BsGridFill } from "react-icons/bs";
import { BiArrowBack } from "react-icons/bi";
import PostCard from "./PostCard";
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { followId } from "../../../Redux/Actions/userAction";
import { listType } from "../../../Redux/Actions/userAction";
import { Overlay, Popover, OverlayTrigger } from "react-bootstrap";
import { BiSolidCommentDetail } from "react-icons/bi";
import { HiLocationMarker } from "react-icons/hi";
import { MdSwitchAccount, MdEmail } from "react-icons/md";
import { FiUsers, FiUser } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import { BsQuestionDiamond } from "react-icons/bs";
import mentionInputStyle from "../../../assets/css/MentionStyle";
import { MentionsInput, Mention } from "react-mentions";
import { useLocation } from "react-router-dom";
import { FaSquareXTwitter, FaLinkedin } from "react-icons/fa6";
import { TbSocial } from "react-icons/tb";
import InstagramIcon from "../../plugins/InstagramIcon";
import { AiFillFacebook } from "react-icons/ai";
import {
  IoChatbubbleEllipsesSharp,
  IoArrowBackCircleOutline,
} from "react-icons/io5";
import { IoIosChatboxes } from "react-icons/io";
import setldImg from "../../../assets/images/setld.jpg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { IoPersonCircleSharp } from "react-icons/io5";
import { FaUserTie } from "react-icons/fa";
import { MdBusinessCenter } from "react-icons/md";
import { userAction } from "../../../Redux/Actions/userAction";
import LineChart from "../../plugins/LineChart";
import { MdReviews } from "react-icons/md";
import BusinessScores from "./BusinessScores";
import { TiTick } from "react-icons/ti";
import { FaArrowLeft } from "react-icons/fa6";
import { PiLinkBold } from "react-icons/pi";
import filter from "../../../assets/images/filter.jpg";
import { HiBadgeCheck, HiCheckCircle } from "react-icons/hi";
import CustomModal from "../../plugins/CustomModal";
import { LuActivitySquare } from "react-icons/lu";
import { GrSend } from "react-icons/gr";
import AddPost from "./AddPost";
import ViewShare from "../../Home/ViewShare";
import { createRoot } from "react-dom/client";
import html2canvas from "html2canvas";
import ReviewsTab from "./ReviewsTab";
import { MdOutlineAttachment } from "react-icons/md";
import { useSubscription } from "../../SubscriptionContext";
import UpgradePopup from "../../plugins/UpgradePopup";
import { BsFillPinAngleFill } from "react-icons/bs";
import UserProfileDropdown from "../../plugins/UserProfileDropdown";
import { IoArrowBack } from "react-icons/io5";
const Viewuser = (props) => {
  const [attachment, setAttachment] = useState([]);
  const userIdNew = useSelector((state) => state.user.followid);
  const userTypeNew = useSelector((state) => state.user.followUserType);
  const userProfileId = useSelector((state) => state.user.followProfileId);
  const location = useLocation();
  const userId = location.state?.userId;
  const userType = location.state?.userType;
  const profileId = location.state?.profileId;
  const isTiiptok = location.state?.tiiptok;
  const [currentIndex, setcurrentIndex] = useState(null);
  const [globalRating, setGlobalRating] = useState([]);
  const [revGlobalRtng, setRevGlobalRtng] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [reviewInfo, setReviewInfo] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [reviewList, setReviewList] = useState([]);
  const [userreviewList, setUserReviewList] = useState([]);
  const [professionalData, setProfessionalData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [userData, setUserData] = useState({});
  const [showAdditionalReviews, setShowAdditionalReviews] = useState(false);
  const [selectedReviewId, setSelectedReviewId] = useState(null);
  const [isShare, setIsShare] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [reviewId, setreviewId] = useState("");
  const [reportMsg, setReportMsg] = useState("");
  const [showPopover, setShowPopover] = useState(false);
  const [showPop, setShowPop] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [shareData, setShareData] = useState({});
  const [comment, setComment] = useState("");
  const [revCmnt, setRevCmnt] = useState([]);
  const [avgCount, setAvgCount] = useState("");
  const [childComment, setchildComment] = useState("");
  const [childcurrentIndex, setchildcurrentIndex] = useState(null);
  const [childrepplycurrentIndex, setchildreplycurrentIndex] = useState([]);
  const [showChildReply, setShowChildReply] = useState(false);
  const [replies, setRevReplies] = useState([]);
  const [reviewSts, setReviewSts] = useState("");
  const [expandedCommentIndex, setExpandedCommentIndex] = useState(-1);
  const [cmtError, setCmtError] = useState("");
  const [cmtErrId, setCmtErrId] = useState(null);
  const [userList, setUserList] = useState("");
  const targetRef = useRef(null);
  const drpRef = useRef(null);
  const [users, setUsers] = useState([]);
  const [mentionuserIds, setmentionuserIds] = useState([]);
  const [eventKey, setEventKey] = useState("Reviews");
  const [showData, setShowData] = useState(false);
  const [labels, setLabels] = useState("");
  const [graphRating, setgraphRating] = useState("");
  const [reviewCount, setReviewCount] = useState("");
  const [tiiptok, setTiiptok] = useState(false);
  const [postList, setPostList] = useState([]);
  const [aggregationType, setAggregationType] = useState("monthly");
  const [scoreType, setScoreType] = useState("default");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [roleList, setRoleList] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [showBusnScore, setShowBusnScore] = useState(false);
  const [avgRatng, setAvgRatng] = useState(0);
  const [ratngCount, setRatngCount] = useState(0);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1200);
  const [postBtnDisable, setPostBtnDisable] = useState(false);
  const [urlLink, setUrlLink] = useState("");
  const [formData, setFormData] = useState(null);
  const [showAttachment, setShowAttachment] = useState(false);
  const { allFeatures, isFeatureEnabled } = useSubscription();
  const likeFeatureEnable = isFeatureEnabled(
    allFeatures["Like-Dislike%"]?.key,
    allFeatures["Like-Dislike%"]?.isDeleted
  );
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    if (location.state) {
      userDetails();
    }
  }, [location.state]);
  const renderRatingBar = (data, msgreply) => (
    <RatingBar
      likecount={data.likes}
      dislikecount={data.dislikes}
      msgreply={msgreply}
      liked={data.liked}
      handlelike={() => renderUpgrade()}
      handledislike={() => renderUpgrade()}
      handleRmvlike={() => renderUpgrade()}
      labelVAlue={data.score}
    />
  );
  const renderUpgrade = () => {
    setShowPopup(true);
  };
  const hideUpgrade = () => {
    setShowPopup(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toggleFilterSection = () => {
    setShowFilterSection(!showFilterSection);
  };
  const handleTabChange = (key) => {
    setEventKey(key);
    clear();
  };

  const readComment = (index) => {
    setExpandedCommentIndex(index === expandedCommentIndex ? -1 : index);
  };
  const handleButtonClick = () => {
    setShowPopover(!showPopover);
  };
  const handleMouseEnter = (users) => {
    if (users.length > 0) setCurrentUsers(users);
    else setCurrentUsers([]);
  };
  const handleArrowClick = (rating, avgCount) => {
    setAvgCount(avgCount);
    const result = rating.map((starData) => ({
      label: starData.star,
      value: starData.totalReviewCount,
      userRatings: starData.userRatings,
    }));
    result.sort((a, b) => {
      const numA = parseInt(a.label.split(" ")[0]);
      const numB = parseInt(b.label.split(" ")[0]);
      return numB - numA;
    });
    setRevGlobalRtng(result);
    setShowPop(!showPop);
  };
  const handleOverlayClose = () => {
    setShowPopover(false);
    setShowPop(false);
  };
  const getRevQnsRating = (value) => {
    const num =
      value.reduce((total, next) => total + next.rating, 0) / value.length;
    return num.toFixed(1);
  };
  useEffect(() => {
    userDetails();
    userLists();
    loadPosts();
  }, [userId || userIdNew]);
  useEffect(() => {
    userDetails();
  }, [props]);
  useEffect(() => {
    loadReviewList();
  }, [eventKey == "Posted Reviews"]);
  useEffect(() => {
    profileChartInfo();
  }, [props, aggregationType, scoreType]);
  useEffect(() => {
    setTiiptok(isTiiptok ? isTiiptok : false);
  }, [location]);

  useEffect(() => {
    loadRoleList();
  }, [userreviewList]);

  useEffect(() => {
    loadRoleList();
  }, [reviewInfo]);

  const loadPosts = async () => {
    const params = {
      userType: userType ? userType : userTypeNew,
      profileId:
        userType != "Personal"
          ? userProfileId
            ? userProfileId
            : profileId
          : null,
    };
    try {
      const response = await window.apiService.postList(params);
      setPostList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const reloadPostListHandler = () => {
      loadPosts();
    };
    window.addEventListener("reloadPostList", reloadPostListHandler);
    return () => {
      window.removeEventListener("reloadPostList", reloadPostListHandler);
    };
  }, []);
  const CloseShare = () => {
    setIsShare(false);
    setUrlLink("");
    setShareData({});
  };
  useEffect(() => {
    if (shareData?.userId && shareData?.id) {
      const shareUrl = `https://devapi.tiipstr.app/api/v1/fbshare/view/${shareData.userId}/${shareData.id}`;
      setUrlLink(shareUrl);
    }
  }, [shareData]);

  const checkExt = (file) => {
    let allowedfiletypes = ["jpg", "svg", "jpeg", "png", "bmp", "gif", "jfif"];
    if (
      allowedfiletypes.some(
        (ext) =>
          ext == file.fileName.substring(file.fileName.lastIndexOf(".") + 1)
      )
    ) {
      return true;
    }
    return false;
  };

  const profileChartInfo = async () => {
    const params = {
      userId: userId ? userId : userIdNew,
      userType: userType ? userType : userTypeNew,
      profileId:
        userType != "Personal"
          ? userProfileId
            ? userProfileId
            : profileId
          : null,
      aggregationType: aggregationType,
      scoreType: scoreType,
    };
    try {
      const response = await window.apiService.profileChartInfo(params);
      const { labels, ratings, reviewCounts } =
        commonUtils.convertDataToChartData(
          response.data.data.ratingChart,
          aggregationType,
          scoreType
        );
      setLabels(labels);
      setgraphRating(ratings);
      setReviewCount(reviewCounts);
    } catch {}
  };
  const userDetails = async () => {
    if ((userId || userIdNew) && props) {
      setLoading(true);
      const params = {
        id: userId ? userId : userIdNew,
        userType: userType ? userType : userTypeNew,
        profileId:
          userType != "Personal"
            ? userProfileId
              ? userProfileId
              : profileId
            : null,
        loggedId: props.userId,
        loggedUserType: props.userType,
        loggedProfileId: props.profileId ? props.profileId : null,
      };
      try {
        const response = await window.apiService.userProfileView(params);
        setUserData(response.data.data);
        if (response.data.data?.business) {
          if (response.data.data.business?.showBusinessScore)
            setScoreType("BS");
          else if (response.data.data.business?.showPeopleScore)
            setScoreType("PS");
          else setScoreType("CS");
        } else setScoreType("default");
        if (
          response.data.data.professional == undefined &&
          response.data.data.userType == "Professional"
        )
          setShowData(true);
        else setShowData(false);
        setUserReviewList(response.data.data.reviewList);
        const starResponse =
          response.data.data.userType == "Professional"
            ? response.data.data.professional.starRating
            : response.data.data.userType == "Business"
            ? response.data.data.business.starRating
            : response.data.data.starRating;
        const result = starResponse.map((starData) => ({
          label: starData.star,
          value: starData.totalReviewCount,
          userRatings: starData.userRatings,
        }));
        result.sort((a, b) => {
          const numA = parseInt(a.label.split(" ")[0]);
          const numB = parseInt(b.label.split(" ")[0]);
          return numB - numA;
        });
        setGlobalRating(result);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        toast.error("Error Occured");
      }
    }
  };
  const handleShare = (data) => {
    setIsShare(true);
    setShareData(data);
  };

  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mimeMatch = arr[0].match(/:(.*?);/);

    if (!mimeMatch || mimeMatch.length < 2) {
      console.error("Invalid dataURL format");
      return null;
    }

    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };

  const viewAttachment = (data) => {
    setShowAttachment(true);
    setAttachment(data);
  };

  const togglechildcomment = (id) => {
    setchildComment("");
    if (childrepplycurrentIndex == id) {
      setchildreplycurrentIndex(null);
    } else {
      setchildreplycurrentIndex(id);
    }
  };
  const loadRevComments = async (revId) => {
    try {
      const response = await window.apiService.reviewCmtList({ id: revId });
      setRevCmnt(response.data.recordInfo.reviewComments);
      setRevReplies(response.data.recordInfo.reviewReplies);
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const viewRevComments = (data, replies, sts) => {
    setShowReply(!showReply);
    setReviewSts(sts);
    setShowChildReply(false);
    loadRevComments(data[0].reviewId);
    setcurrentIndex(null);
    setchildreplycurrentIndex(null);
  };
  const viewChildComments = (data, id, revId) => {
    setcurrentIndex(null);
    setShowChildReply(!showChildReply);
    if (childcurrentIndex == id) {
      setchildcurrentIndex(null);
    } else {
      setchildcurrentIndex(id);
    }
    loadRevComments(revId);
  };
  const handlerplylikechange = (id, revId) => {
    console.log("revId", revId);
    updaterplyLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: props.userId,
      liked: 1,
    });
  };
  const handlerplydislikechange = (id, revId) => {
    updaterplyLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: props.userId,
      liked: 0,
    });
  };
  const rplylikecntDecrement = (id, revId) => {
    updaterplyLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: props.userId,
      liked: -1,
    });
  };
  const updaterplyLikeStatus = async (likeParams) => {
    try {
      const response = await window.apiService.addCommentLike(likeParams);
      if (response.data.status == "OK") {
        loadRevComments(likeParams.reviewId);
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const handlelikechange = (id, userId) => {
    updateLikeStatus({ reviewId: id, userId: userId, liked: 1 });
  };
  const handledislikechange = (id, userId) => {
    updateLikeStatus({ reviewId: id, userId: userId, liked: 0 });
  };
  const updateLikeStatus = async (likeParams) => {
    try {
      const response = await window.apiService.addLike(likeParams);
      if (response.data.status == "OK") {
        userDetails();
        setShowAdditionalReviews(false);
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const likecntDecrement = (id, userId) => {
    updateLikeStatus({ reviewId: id, userId: userId, liked: -1 });
  };
  const togglecomment = (id) => {
    setComment("");
    setchildcurrentIndex(null);
    setShowChildReply(false);
    if (currentIndex == id) {
      setcurrentIndex(null);
    } else {
      setcurrentIndex(id);
    }
  };
  const postComment = async (id, revId, type) => {
    if (comment.trim().length !== 0 || childComment.trim().length !== 0) {
      let params = "";
      if (type == "child") {
        params = {
          userId: props.userId,
          commentText: childComment,
          reviewId: revId,
          parentCommentId: id,
          mentionUserIds: mentionuserIds,
          professionalId:
            props.userType == "Professional" ? props.profileId : null,
          businessId: props.userType == "Business" ? props.profileId : null,
        };
      } else {
        params = {
          userId: props.userId,
          commentText: comment,
          reviewId: id,
          parentCommentId: null,
          mentionUserIds: mentionuserIds,
          professionalId:
            props.userType == "Professional" ? props.profileId : null,
          businessId: props.userType == "Business" ? props.profileId : null,
        };
      }
      setPostBtnDisable(true);
      try {
        const response = await window.apiService.addComment(params);
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          setComment("");
          setchildComment("");
          loadRevComments(params.reviewId);
          userDetails();
          togglecomment(response.data.id);
          setmentionuserIds([]);
          setPostBtnDisable(false);
        }
      } catch (error) {
        console.log(error);
        toast.error("Error Occured");
        setPostBtnDisable(false);
      }
    } else {
      setCmtError("Comment text is required");
      setCmtErrId(id);
    }
  };
  const handleReviewClick = (
    userId,
    professionalList,
    professionalId,
    businessId,
    businessName,
    viewUserType,
    professionType,
    professionTypeId
  ) => {
    navigate("/dashboard/viewuser/postreview", {
      state: {
        userId,
        professionalList,
        professionalId,
        businessId,
        businessName,
        viewUserType,
        professionType,
        professionTypeId,
      },
    });
  };
  const handleNameClick = (
    userId,
    reviewerUserType,
    reviewedByProfessional,
    reviewedByBusiness,
    self
  ) => {
    clear();
    const profileId = reviewedByProfessional || reviewedByBusiness;
    dispatch(followId(userId, reviewerUserType, profileId));
    navigate("/dashboard/viewuser", {
      state: {
        userId,
        profileId,
        userType: reviewerUserType,
      },
    });
    if (self == "1") userDetails();
  };
  const handleShowIcons = () => {
    setShowIcons(true);
  };
  const userLists = async () => {
    try {
      const response = await window.apiService.lookupAllUsersList();
      const usersArr = response.data.recordInfo.filter((row) => {
        return (
          row.userId != props.userId &&
          row.userId.split("-")[0] != props.userId &&
          row.userId.split("&")[0] != props.userId
        );
      });
      const users = usersArr.map(function (row) {
        return { display: row.name, id: row.userId };
      });
      setUsers(users);
    } catch (error) {
      console.log(error);
    }
  };
  const changeCmtText = (value, type) => {
    commonUtils.changeCmtText(
      value,
      type,
      setmentionuserIds,
      setComment,
      setchildComment
    );
  };
  const showAddPost = userData?.preferenceList?.some((item) => {
    if (item.preferenceName === "view_post_comment" && item.value === true) {
      return true;
    } else {
      return false;
    }
  });
  const handleMention = (mentionedUser) => {
    const match = mentionedUser.match(/@\[([^\]]+)\](?:\(([^)]+)\))?/);
    if (match) {
      const [, name, userIdPart] = match;
      let userId = userIdPart;
      let busnId = null;
      let profId = null;
      if (userIdPart && userIdPart.includes("&")) {
        [userId, busnId] = userIdPart.split("&");
      } else if (userIdPart && userIdPart.includes("-")) {
        [userId, profId] = userIdPart.split("-");
      }
      if (name && userId) {
        if (userId !== props.userId) {
          dispatch(followId(userId));
          navigate("/dashboard/viewuser", {
            state: {
              userId,
              userType:
                profId || busnId
                  ? profId
                    ? "Professional"
                    : "Business"
                  : "Personal",
              profileId: profId || busnId ? (profId ? profId : busnId) : null,
            },
          });
        }
      }
    }
  };
  const rmvMenFormat = (value) => {
    const mentionRegex = /@\[([^\]]+?)\](?:\(\d+([&-])\d+\))|@([^\s]+)/g;
    const match = mentionRegex.exec(value);
    if (match) {
      if (match[3]) {
        const nestedMatch = match[3].match(/\[([^\]]+)\]\(\d+\)/);
        return nestedMatch ? nestedMatch[1] : "";
      }
      return match[1] || "";
    }
    return null;
  };
  function textWithLinks(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const mentionRegex = /@\[([^\]]+?)\](\((\d+([&-])\d+)?\))|@([^\s]+)/g;
    return text.split(/\s(?![^\[]*])/).map((word, index) => {
      if (urlRegex.test(word)) {
        return (
          <a key={index} href={word} target="_blank" rel="noopener noreferrer">
            {word}
          </a>
        );
      } else if (mentionRegex.test(word)) {
        const mentions = word.match(mentionRegex);
        if (mentions) {
          return mentions.map((mention, mentionIndex) => {
            const match = mention.match(/@\[([^\]]+?)\](\((\d+([&-])\d+)?\))/g);
            const id = match ? match[2] : null;
            return (
              <span
                key={index + mentionIndex}
                className={id == userId ? "" : "cmt_icon c_pointer"}
                onClick={() => handleMention(mention)}
              >
                {rmvMenFormat(mention)}
              </span>
            );
          });
        }
      }

      return word;
    });
  }
  function rederPostCards(posts) {
    return (
      <>
        <div className="row col-md-12 fixed-div justify-content-center">
          <div className="w-100 d-flex mt-2">
            <commonUtils.CustomButton
              btnText={
                <div className="d-flex align-items-center">
                  <IoArrowBack /> <span>Back</span>
                </div>
              }
              btnWidth="fit-content"
              variant="dark"
              perform={() => setTiiptok(false)}
              isDisabled={false}
            />
          </div>
        </div>
        {userData && showAddPost && userData.frdReqStatus == "accepted" && (
          <div
            className="d-flex justify-content-center"
            style={{ marginBottom: "4px" }}
          >
            <AddPost userData={userData} props={props} />
          </div>
        )}
        <div className="row col-md-12 p-0 d-align mx-0 flx_column">
          {posts &&
          posts.filter((post) => post.postedTo == userId).length > 0 ? (
            posts
              .filter((post) => post.postedTo == userId)
              .map((post, idx) => (
                <PostCard
                  postInfo={post}
                  key={idx}
                  userId={props.userId}
                  loadPostList={loadPosts}
                />
              ))
          ) : (
            <div className="row d-align mx-0 pb-4">
              <div className="no-data font-weight-600 w-100 text-center fz_14">
                Sorry, there is no data to be displayed
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
  function renderReviewCards(reviewData) {
    return reviewData.length > 0 && !loading ? (
      (selectedRoleId ? filteredList : reviewData)
        .sort((a, b) => {
          const isAPinned =
            a.pinnedBy === userId && new Date(a.pinExpiration) > new Date();
          const isBPinned =
            b.pinnedBy === userId && new Date(b.pinExpiration) > new Date();

          if (isAPinned && !isBPinned) return -1;
          if (!isAPinned && isBPinned) return 1;

          if (isAPinned && isBPinned) {
            return new Date(b.updatedAt) - new Date(a.updatedAt);
          }
          return 0;
        })
        .map((data, i) => (
          <Card className="meCard me_ht" key={data.id}>
            {data.pinnedBy == data.userId &&
            new Date(data.pinExpiration) > new Date() ? (
              <div className="pinRev" title="Pinned">
                <BsFillPinAngleFill />
              </div>
            ) : (
              ""
            )}
            <Card.Body
              className={`${data.status === "new" ? "rev_padg" : "rev_padg"} ${
                showAdditionalReviews && selectedReviewId === data.reviewedBy
                  ? "card_drp"
                  : ""
              } ${
                data.status === "reported" || data.status == "blocked"
                  ? "bg_gray rev_padg"
                  : ""
              }`}
            >
              <div className="text-end dot_3">
                {data.status == "reported" && (
                  <span className="uder_Rev">Under Review</span>
                )}
                {data.status == "blocked" && (
                  <span className="uder_Rev">Blocked</span>
                )}
              </div>
              <Row>
                <Col
                  sm={12}
                  md={6}
                  lg={3}
                  xl={3}
                  className="text-center custom-xl-3"
                >
                  <div className="border_btm">
                    <img
                      className="meImg_review"
                      src={
                        data.reviewerImagePath
                          ? data.reviewerImagePath
                          : profile
                      }
                    />
                  </div>
                  {data.status != "blocked" &&
                  data.status != "reported" &&
                  data.reviewedBy != props.userId &&
                  userData.id != data.reviewedBy ? (
                    <Card.Text
                      title={data.businessTypeName}
                      className={`mb-0 card_txt name_blue h_22 ${
                        data.reviewerUserType == "Personal" ? "" : ""
                      } c_pointer `}
                      onClick={() =>
                        handleNameClick(
                          data.reviewedBy,
                          data.reviewerUserType,
                          data.reviewedByProfessional,
                          data.reviewedByBusiness,
                          "0"
                        )
                      }
                      style={{
                        minWidth: "120px", // Ensures it doesn’t shrink too much
                        maxWidth: "200px", // Adjust for truncation
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        fontSize:'12px',
                        display: "inline-block",
                        verticalAlign: "middle",
                        flexGrow: 1 // Allows it to expand when space is available
                      }}

                    >
                      {data.reviewerUserType === "Business"
                        ? data.busnShortName
                          ? data.busnShortName
                          : data.businessTypeName
                        : data.reviewerName}
                    </Card.Text>
                  ) : (
                    <Card.Text className="mb2 card_txt">
                      {data.reviewerUserType === "Business"
                        ? data.busnShortName
                          ? data.busnShortName
                          : data.businessTypeName
                        : data.reviewerName}
                    </Card.Text>
                  )}
                  {data.profTypeName && (
                    <span className="fz_14">
                      {"(" + data.profTypeName + ")"}
                    </span>
                  )}
                  <div
                    className=" d-flex justify-content-center align-items-center fs_12"
                    style={{ height: "20px" }}
                  >
                    <div className="d-flex align-items-center">
                      {data.averageReviewScore > 0.0 ? (
                        <span className="font-weight-bold pr-2">
                          {data.averageReviewScore.toFixed(1)}
                        </span>
                      ) : (
                        ""
                      )}
                      <StarRating
                        rating={data.averageReviewCount}
                        disabled={true}
                        customClass="ques-star"
                      />
                      <span className="pl-2">({data.totalReviewCount})</span>
                      <OverlayTrigger
                        trigger="click"
                        key={data.id}
                        placement="bottom"
                        overlay={
                          <Popover
                            id={`popover-${data.id}`}
                            className="pop_rating"
                          >
                            <div className="drp_rating">
                              <StarRating
                                customClass="user-star"
                                rating={avgCount}
                                disabled={true}
                              />
                              <span
                                style={{
                                  marginLeft: "-45px",
                                  marginTop: "3px",
                                }}
                              >
                                {avgCount} out of 5
                              </span>{" "}
                            </div>
                            {revGlobalRtng.map((item, index) => (
                              <Row key={index}>
                                <Col
                                  sm={12}
                                  md={3}
                                  lg={3}
                                  xl={2}
                                  className="p-0"
                                >
                                  <span className="prog_span">
                                    {item.label}
                                  </span>{" "}
                                </Col>
                                <Col
                                  sm={12}
                                  md={8}
                                  lg={8}
                                  xl={7}
                                  className="p-0"
                                >
                                  <Horizontalbar dataSet={item.value} />
                                </Col>
                                <Col
                                  sm={12}
                                  md={3}
                                  lg={3}
                                  xl={2}
                                  className="p-0 prog_span"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <span className="tooltip">
                                    <span
                                      className="c_pointer"
                                      onMouseEnter={() =>
                                        handleMouseEnter(item.userRatings)
                                      }
                                    >
                                      {item.value}
                                      {item.value > 1 ? (
                                        <FiUsers
                                          style={{ marginLeft: "6px" }}
                                        />
                                      ) : (
                                        <FiUser style={{ marginLeft: "6px" }} />
                                      )}
                                    </span>
                                    {commonUtils.ratingTooltip(currentUsers)}
                                  </span>
                                </Col>
                              </Row>
                            ))}
                          </Popover>
                        }
                        rootClose
                      >
                        {data.status != "blocked" ? (
                          <span
                            className="d-flex align-items-center"
                            onClick={() =>
                              handleArrowClick(
                                data.starRating,
                                data.averageReviewCount
                              )
                            }
                          >
                            <IoIosArrowDown className="clr_gray c_pointer" />
                          </span>
                        ) : (
                          <span className="d-flex align-items-center"></span>
                        )}
                      </OverlayTrigger>
                      {data.status == "blocked" && (
                        <IoIosArrowDown className="clr_gray" />
                      )}
                    </div>
                  </div>
                </Col>
                <Col className="card_bdr_left m-0" sm={12} md={6} lg={3} xl={3}>
                  <Card.Text className="mb-0" title={data.comment}>
                    <div
                      className={` ${
                        expandedCommentIndex === i ? "show-full" : ""
                      }`}
                      title={data.comment}
                    >
                      {data.comment.length >= 40 && expandedCommentIndex !== i
                        ? textWithLinks(data.comment.substring(0, 40))
                            .map((word, index) => (
                              <span key={index}>{word} </span>
                            ))
                            .concat("...")
                        : textWithLinks(data.comment).map((word, index) => (
                            <span key={index}>{word} </span>
                          ))}
                    </div>

                    {data.comment.length >= 40 && (
                      <button
                        disabled={
                          data.status == "blocked" || data.status == "reported"
                            ? true
                            : false
                        }
                        onClick={() => readComment(i)}
                        className={
                          data.status == "blocked" || data.status == "reported"
                            ? "read-more-dis"
                            : "read-more-button"
                        }
                      >
                        {expandedCommentIndex === i ? "Read less" : "Read more"}
                      </button>
                    )}
                  </Card.Text>
                  {data.reviewComments.length != 0 && (
                    <span
                      className="rply c_pointer"
                      title="view review comment"
                      onClick={() =>
                        viewRevComments(
                          data.reviewComments,
                          data.reviewReplies,
                          data.status
                        )
                      }
                    >
                      {data.reviewComments.length === 1
                        ? `${data.reviewComments.length} Reply `
                        : `${data.reviewComments.length} Replies `}
                    </span>
                  )}
                  <span>
                    {data.status == "reported" ||
                    data.status == "blocked" ||
                    data.reviewedBy === props.userId ? (
                      <BiSolidCommentDetail
                        className="cmt_icn clr_gray"
                        title="Add comment"
                      />
                    ) : (
                      <BiSolidCommentDetail
                        className="cmt_icn c_pointer"
                        title="Add comment"
                        onClick={() => togglecomment(data.id)}
                      />
                    )}
                    {currentIndex == data.id ? (
                      <Row>
                        <div className="m-2 position-relative">
                          {cmtError && cmtErrId == data.id ? (
                            <div className="text-danger fz-12">{cmtError}</div>
                          ) : (
                            ""
                          )}
                          <MentionsInput
                            style={mentionInputStyle}
                            className="form-control me_cmt_box"
                            maxLength={288}
                            onChange={(e) => {
                              changeCmtText(e.target.value, "parent");
                            }}
                            value={comment}
                            placeholder="type someting..."
                          >
                            <Mention
                              style={{ backgroundColor: "#efebe3" }}
                              trigger="@"
                              data={users}
                            />
                          </MentionsInput>
                          <button
                            className="btn btn-primary cmt_btnn"
                            onClick={() => {
                              if (
                                isFeatureEnabled(
                                  allFeatures["Add-Comment%"]?.key,
                                  allFeatures["Add-Comment%"]?.isDeleted
                                )
                              ) {
                                postComment(data.id, 0, "parent");
                              } else {
                                setShowPopup(true);
                              }
                            }}
                          >
                            Post
                          </button>
                        </div>
                      </Row>
                    ) : (
                      ""
                    )}
                  </span>
                </Col>
                <Col sm={12} md={6} lg={3} xl={3}>
                  <div className="d-flex">
                    <div className="w-90">
                      <Card.Text className="mb-0">
                        <span className="txt_rev">Reviewed as:</span>{" "}
                        <span className="fs_13 fw_500">{data.userRole}</span>
                      </Card.Text>
                      <Card.Text className="mb-0">
                        <span className="txt_rev">Date of Review:</span>
                        <span className="fs_13 fw_500">
                          {" "}
                          {moment(data.createdAt).format("DD-MMM-YYYY")}
                        </span>
                      </Card.Text>
                      <Card.Text className="mb2">
                        <div className="d-flex align-items-center">
                          {data.rating != 0 ? (
                            <span className="font-weight-bold fs_13 pr-2">
                              {data.rating.toFixed(1)}
                            </span>
                          ) : (
                            ""
                          )}
                          <StarRating
                            rating={data.rating}
                            disabled={true}
                            customClass="ques-star"
                          />
                          {data.reviewQuestion.length != 0 && (
                            <OverlayTrigger
                              trigger="click"
                              key={data.id}
                              placement="bottom"
                              overlay={
                                <Popover
                                  id={`popover-${data.id}`}
                                  className="pop_rating"
                                >
                                  <div className="drp_rating"></div>
                                  {data.reviewQuestion.length === 0 ? (
                                    <div>No questions found</div>
                                  ) : (
                                    data.reviewQuestion.map((item, index) => (
                                      <Row className="d-flex" key={index}>
                                        <Col
                                          sm={1}
                                          md={1}
                                          lg={1}
                                          xl={1}
                                          className="cus-col p-0 prog_span d-flex align-items-center"
                                        >
                                          {index + 1}.
                                        </Col>
                                        <Col
                                          sm={5}
                                          md={4}
                                          lg={4}
                                          xl={4}
                                          className="cus-col-ques p-0 d-flex align-items-center"
                                        >
                                          <span className="prog_span">
                                            {item.question}
                                          </span>
                                        </Col>
                                        <Col
                                          sm={6}
                                          md={7}
                                          lg={7}
                                          xl={7}
                                          className="cus-col-ratng p-0"
                                        >
                                          <StarRating
                                            rating={item.rating}
                                            disabled={true}
                                            customClass="ques-star"
                                          />
                                        </Col>
                                      </Row>
                                    ))
                                  )}
                                </Popover>
                              }
                              rootClose
                            >
                              <span
                                style={{ marginLeft: "5px" }}
                                className="fs_13 d-flex align-items-center c_pointer"
                                onClick={() =>
                                  handleArrowClick(
                                    data.starRating,
                                    data.averageReviewCount
                                  )
                                }
                              >
                                <BsQuestionDiamond className="clr_gray" />
                              </span>
                            </OverlayTrigger>
                          )}
                          {data.reviewAttachment.length != 0 && (
                            <span
                              title="Attachment"
                              className="attachmnt"
                              onClick={() =>
                                viewAttachment(data.reviewAttachment)
                              }
                            >
                              <MdOutlineAttachment />
                            </span>
                          )}
                        </div>
                      </Card.Text>
                    </div>
                    <div className="d-flex mt-1">
                      {isFeatureEnabled(
                        allFeatures["SM-Share-Feature%"]?.key,
                        allFeatures["SM-Share-Feature%"]?.isDeleted
                      ) ? (
                        <React.Fragment>
                          <span className="shre_icn">
                            {data.status === "reported" ||
                            data.status == "blocked" ? (
                              <BsShareFill
                                className="dis_share_icon"
                                title="Share"
                              />
                            ) : (
                              <BsShareFill
                                className="share_icon"
                                title="Share"
                                onClick={() => handleShare(data)}
                              />
                            )}
                          </span>
                        </React.Fragment>
                      ) : (
                        <BsShareFill
                          className="share_icon"
                          title="Share"
                          onClick={() => setShowPopup(true)}
                        />
                      )}
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={6} lg={3} xl={3}>
                  {likeFeatureEnable ? (
                    data.status === "reported" ||
                    data.status == "blocked" ||
                    data.reviewedBy === props.userId ? (
                      <RatingBar
                        disabled={true}
                        likecount={data.likes}
                        dislikecount={data.dislikes}
                        liked={data.liked}
                        handlelike={() =>
                          handlelikechange(data.id, props.userId)
                        }
                        handledislike={() =>
                          handledislikechange(data.id, props.userId)
                        }
                        handleRmvlike={() =>
                          likecntDecrement(data.id, props.userId)
                        }
                        labelVAlue={data.score}
                      />
                    ) : (
                      <RatingBar
                        disabled={
                          data.status == "reported" || data.status == "blocked"
                            ? true
                            : false
                        }
                        likecount={data.likes}
                        dislikecount={data.dislikes}
                        liked={data.liked}
                        handlelike={() =>
                          handlelikechange(data.id, props.userId)
                        }
                        handledislike={() =>
                          handledislikechange(data.id, props.userId)
                        }
                        handleRmvlike={() =>
                          likecntDecrement(data.id, props.userId)
                        }
                        labelVAlue={data.score}
                      />
                    )
                  ) : (
                    <>{renderRatingBar(data, false)}</>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))
    ) : loading ? (
      <div className="loader-center">
        <img className="loader-image" src={loader} alt="Loading..." />
      </div>
    ) : (
      <div className="row col-md-12 p-0 d-align mx-0">
        <div className="no-data w-100 font-weight-600 text-center fz_14">
          Sorry, there is no data to be displayed
        </div>
      </div>
    );
  }
  const loadReviewList = async () => {
    if (userData) {
      setLoading(true);
      const params = {
        userId: userData.id,
        userType: userData.userType,
        profileId:
          userData.userType !== "Personal" ? userData.profileId : undefined,
      };
      try {
        if (params.userId) {
          const response = await window.apiService.latestReview(params);
          setLoading(false);
          if (response.data.status == "OK") {
            let result = response.data.recordInfo;
            setReviewInfo(result);
          }
        }
      } catch (error) {
        setLoading(false);
        toast.error("Error Occured");
      }
    }
  };

  const loadRoleList = async () => {
    try {
      const response = await window.apiService.loadUserRoles();
      const userReviewRoleIds = new Set(
        (eventKey == "Posted Reviews" && reviewInfo
          ? reviewInfo
          : userreviewList
        ).map((review) => review.roleId)
      );
      const filteredRoleList = response.data.recordInfo.filter((role) =>
        userReviewRoleIds.has(role.id)
      );
      setRoleList(filteredRoleList);
    } catch (error) {
      console.log(error);
    }
  };
  const clear = () => {
    setSelectedRoleId("");
    setAvgRatng(0);
  };
  const handleUserRole = (event) => {
    const roleId = event.target.value;
    setSelectedRoleId(roleId);
    if (roleId) {
      const filtered = (
        eventKey == "Posted Reviews" ? reviewInfo : userreviewList
      ).filter((review) => review.roleId === parseInt(roleId, 10));
      setFilteredList(filtered);
      if (filtered.length > 0) {
        const totalRating = filtered.reduce(
          (acc, review) => acc + (review.rating || 0),
          0
        );
        const averageRating = totalRating / filtered.length;
        setAvgRatng(averageRating);
        setRatngCount(filtered.length);
      } else {
        console.log("No reviews available for the selected role.");
      }
    } else {
      setFilteredList(userreviewList);
    }
  };

  const sendRequest = async () => {
    if (userData) {
      const params = {
        senderId: props.userId,
        senderUserType: props.userType,
        senderTypeId: props.profileId ? props.profileId : null,
        receiverId: userData.id,
        receiverUserType: userData.userType,
        receiverTypeId: userData.business?.id
          ? userData.business?.id
          : userData.professional?.id
          ? userData.professional?.id
          : null,
        status: "pending",
        isRead: false,
      };
      try {
        const response = await window.apiService.sendRequest(params);
        if (response.data.status === "OK") {
          toast.success(response.data.message);
          userDetails();
        }
      } catch (error) {
        toast.error("Error Occurred");
      }
    }
  };

  return (
    <>
      <HeaderMenu />
      <div className="">
        <SideBar />
        <div className="dash_hgt ml_200">
          {!showData ? (
            <div className="row h-100">
              <div className="col-12 col-sm-4 col-md-3 pd_rt">
                <Card className="shadow h-100">
                  <Card.Body
                    className={`me_revList_nw ${loading ? "me_sty" : ""}`}
                    style={{ scrollbarWidth: "none", padding: "0px 7px" }}
                  >
                    <Col sm={12} md={12} lg={12} xl={12}>
                      <Row className="pr_cd">
                        <Col
                          className={`pl-0 ${
                            userData.userType === "Business" &&
                            (isFeatureEnabled(
                              allFeatures["BS,-PS%"]?.key,
                              allFeatures["BS,-PS%"]?.isDeleted
                            ) ||
                              isFeatureEnabled(
                                allFeatures["Culture-Score%"]?.key,
                                allFeatures["Culture-Score%"]?.isDeleted
                              )) &&
                            (userData.business?.showBusinessScore ||
                              userData.business?.showPeopleScore ||
                              userData.business?.showCultureScore)
                              ? "mx_wdt"
                              : ""
                          }`}
                          sm={12}
                          md={12}
                          lg={
                            userData.userType === "Business" &&
                            (isFeatureEnabled(
                              allFeatures["BS,-PS%"]?.key,
                              allFeatures["BS,-PS%"]?.isDeleted
                            ) ||
                              isFeatureEnabled(
                                allFeatures["Culture-Score%"]?.key,
                                allFeatures["Culture-Score%"]?.isDeleted
                              )) &&
                            (userData.business?.showBusinessScore ||
                              userData.business?.showPeopleScore ||
                              userData.business?.showCultureScore) &&
                            isWideScreen
                              ? 12
                              : 12
                          }
                          xl={
                            userData.userType === "Business" &&
                            (isFeatureEnabled(
                              allFeatures["BS,-PS%"]?.key,
                              allFeatures["BS,-PS%"]?.isDeleted
                            ) ||
                              isFeatureEnabled(
                                allFeatures["Culture-Score%"]?.key,
                                allFeatures["Culture-Score%"]?.isDeleted
                              )) &&
                            (userData.business?.showBusinessScore ||
                              userData.business?.showPeopleScore ||
                              userData.business?.showCultureScore) &&
                            isWideScreen
                              ? 12
                              : 12
                          }
                        >
                          <div className="mecrd_bdr_bm me_cd">
                            <div className="row d-flex justify-content-center w_100">
                              <div
                                className="col-10 d-flex align-items-center"
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                  padding: " 0px 0px 0px 45px",
                                }}
                              >
                                <img
                                  className="meImg_prof"
                                  src={
                                    userData.userType == "Professional"
                                      ? userData.professional?.imagePath
                                        ? userData.professional.imagePath
                                        : profile
                                      : userData.userType == "Business"
                                      ? userData.business?.logo
                                        ? userData.business.logo
                                        : profile
                                      : userData.imagePath
                                      ? userData.imagePath
                                      : profile
                                  }
                                />
                              </div>
                              {userData.id != props.userId && (
                                <div className="col-2 mt-2 ">
                                  <MdReviews
                                    title="Add Review"
                                    className="rev_clr fz_30 mr-4 c_pointer"
                                    onClick={() => {
                                      if (
                                        isFeatureEnabled(
                                          allFeatures["Add-Review%"]?.key,
                                          allFeatures["Add-Review%"]?.isDeleted
                                        )
                                      ) {
                                        handleReviewClick(
                                          userData?.id,
                                          userData?.professionalList,
                                          userData?.professional?.id,
                                          userData?.business?.id,
                                          userData?.business?.companyName,
                                          userData?.userType,
                                          userData?.professional
                                            ?.professionType,
                                          userData?.professional
                                            ?.professionTypeId
                                        );
                                      } else {
                                        setShowPopup(true);
                                      }
                                    }}
                                  />
                                  <a
                                    className="c_pointer"
                                    onClick={() => {
                                      if (
                                        isFeatureEnabled(
                                          allFeatures["Chat%"]?.key,
                                          allFeatures["Chat%"]?.isDeleted
                                        )
                                      ) {
                                        navigate("/inbox/3", {
                                          state: {
                                            userId: userId,
                                            showDirect: true,
                                          },
                                        });
                                      } else {
                                        setShowPopup(true);
                                      }
                                    }}
                                  >
                                    <div className="d-flex mr-2">
                                      <img
                                        title="Chat"
                                        src={chatImg}
                                        alt="chat"
                                        height={35}
                                        width={35}
                                      />
                                    </div>
                                  </a>
                                  <div>
                                    <BsGridFill
                                      title="Tiiptok"
                                      className="fz-22 rev_clr mr-3 c_pointer"
                                      onClick={() => {
                                        if (
                                          isFeatureEnabled(
                                            allFeatures["Tiptok%"]?.key,
                                            allFeatures["Tiptok%"]?.isDeleted
                                          )
                                        ) {
                                          setTiiptok(true);
                                        } else {
                                          setShowPopup(true);
                                        }
                                      }}
                                    />
                                  </div>
                                  {userData.userType != "Professional" &&
                                  userData.isKycVerified &&
                                  props.isKycVerified &&
                                  userData.frdReqStatus != "pending" &&
                                  userData.frdReqStatus != "accepted" ? (
                                    <div>
                                      <GrSend
                                        title="Send Friend Request"
                                        className="fz-22 mr-3 c_pointer req"
                                        onClick={() => {
                                          if (
                                            isFeatureEnabled(
                                              allFeatures["Tiptok%"]?.key,
                                              allFeatures["Tiptok%"]?.isDeleted
                                            )
                                          ) {
                                            sendRequest();
                                          } else {
                                            setShowPopup(true);
                                          }
                                        }}
                                      />
                                    </div>
                                  ) : userData.userType != "Professional" ? (
                                    <div>
                                      <GrSend
                                        title={
                                          userData.frdReqStatus == "pending"
                                            ? "Requested"
                                            : userData.frdReqStatus ==
                                              "accepted"
                                            ? "Request Accepted"
                                            : !userData.isKycVerified
                                            ? "KYC not verified"
                                            : ""
                                        }
                                        className="fz-22 mr-3 gray_clr"
                                        style={{ marginTop: "10px" }}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                            </div>
                            {userData.userType !== "Business" && (
                              <div
                                className=" d-flex justify-content-center align-items-center"
                                style={{ height: "20px", marginLeft: "20px" }}
                              >
                                <div className="d-flex align-items-center">
                                  {userData.userType == "Professional" ? (
                                    userData.professional?.averageReviewScore >
                                    0.0 ? (
                                      <span className="font-weight-bold fs_13">
                                        {userData.professional?.averageReviewScore.toFixed(
                                          1
                                        )}
                                      </span>
                                    ) : (
                                      ""
                                    )
                                  ) : userData.userType == "Business" ? (
                                    userData.business?.averageReviewScore >
                                    0.0 ? (
                                      <span className="font-weight-bold fs_13">
                                        {userData.business?.averageReviewScore.toFixed(
                                          1
                                        )}
                                      </span>
                                    ) : (
                                      ""
                                    )
                                  ) : userData.averageReviewScore > 0.0 ? (
                                    <span className="font-weight-bold fs_13 pr-2">
                                      {userData.averageReviewScore.toFixed(1)}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  <StarRating
                                    customClass="ques-star"
                                    rating={
                                      userData.userType == "Professional" ||
                                      userData.userType == "professional"
                                        ? userData.professional
                                            ?.averageReviewScore
                                        : userData.userType == "Business"
                                        ? userData.business?.averageReviewScore
                                        : userData.averageReviewScore
                                    }
                                    disabled={true}
                                  />
                                </div>
                                <span className="fs_13 pl-2">
                                  (
                                  {userData.userType == "Professional"
                                    ? userData.professional?.totalReviewCount
                                    : userData.userType == "Business"
                                    ? userData.business?.totalReviewCount
                                    : userData.totalReviewCount}
                                  )
                                </span>
                                <span
                                  className="c_pointer fs_13 pl-2"
                                  onClick={handleButtonClick}
                                  ref={targetRef}
                                >
                                  <IoIosArrowDown />
                                </span>
                              </div>
                            )}
                            <div className=" d-flex justify-content-center align-items-center mt-2">
                              <div className="disply_foll">
                                <div className="circle-background  text-center  ">
                                  {userData.userType == "Professional"
                                    ? userData.professional
                                        ?.professionalFollowerCount
                                    : userData.userType == "Business"
                                    ? userData.business?.businessFollowerCount
                                    : userData.followersCount}
                                </div>
                                <div className="me_txt">Followers</div>
                              </div>
                              <div className="disply_foll border-right-0">
                                <div className="circle-background  text-center">
                                  {userData.userType == "Professional"
                                    ? userData.professional
                                        ?.professionalFollowingCount
                                    : userData.userType == "Business"
                                    ? userData.business?.businessFollowingCount
                                    : userData.followingCount}
                                </div>
                                <div className="me_txt">Following</div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <div className="brdr_lne"></div>
                        {userData.userType === "Business" &&
                          (isFeatureEnabled(
                            allFeatures["BS,-PS%"]?.key,
                            allFeatures["BS,-PS%"]?.isDeleted
                          ) ||
                            isFeatureEnabled(
                              allFeatures["Culture-Score%"]?.key,
                              allFeatures["Culture-Score%"]?.isDeleted
                            )) &&
                          (userData.business?.showBusinessScore ||
                            userData.business?.showPeopleScore ||
                            userData.business?.showCultureScore) && (
                            <>
                              <div className="bs_icon">
                                <div className="d-flex justify-content-end">
                                  <LuActivitySquare
                                    className="fz_22"
                                    onClick={() => setShowBusnScore(true)}
                                  />
                                </div>
                              </div>
                              <div className="pad_15">
                                {isWideScreen ? (
                                  <BusinessScores
                                    businessScore={
                                      userData.business?.showBusinessScore
                                    }
                                    cultureScore={
                                      userData.business?.showCultureScore
                                    }
                                    peopleScore={
                                      userData.business?.showPeopleScore
                                    }
                                    userData={userData}
                                    loadChart={() => profileChartInfo()}
                                    setScoreType={setScoreType}
                                    scoreType={scoreType}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </>
                          )}
                        {userData.userType === "Business" && (
                          <div className="brdr_lne"></div>
                        )}
                        <Col
                          sm={12}
                          md={12}
                          lg={
                            userData.userType === "Business" &&
                            (isFeatureEnabled(
                              allFeatures["BS,-PS%"]?.key,
                              allFeatures["BS,-PS%"]?.isDeleted
                            ) ||
                              isFeatureEnabled(
                                allFeatures["Culture-Score%"]?.key,
                                allFeatures["Culture-Score%"]?.isDeleted
                              )) &&
                            (userData.business?.showBusinessScore ||
                              userData.business?.showPeopleScore ||
                              userData.business?.showCultureScore)
                              ? 12
                              : 12
                          }
                          xl={12}
                          className="pad_15 pd_btm"
                        >
                          <div className="me_center_div me_cn_dv">
                            <Card.Text className="mb-0 alert-link">
                              {userData.userType == "Business"
                                ? userData.business.companyName
                                : userData.name}
                              {isFeatureEnabled(
                                allFeatures["Kyc%"]?.key,
                                allFeatures["Kyc%"]?.isDeleted
                              ) &&
                              userData.isKycVerified &&
                              userData.userType == "Personal" ? (
                                <HiBadgeCheck
                                  style={{
                                    fontSize: "22px",
                                    color: "green",
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                  }}
                                  title="KYC Verified"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                />
                              ) : (
                                <></>
                              )}
                            </Card.Text>
                            <div className="contact-item">
                              <span className="me_txt">
                                {userData.userType
                                  ? userData.userType == "Professional" ||
                                    userData.userType == "professional"
                                    ? userData.userType +
                                      " (" +
                                      userData.professional?.professionType +
                                      ")"
                                    : userData.userType
                                  : ""}
                                &nbsp;
                                <UserProfileDropdown
                                  userId={userData.id}
                                  userType={userData.userType}
                                  profileId={userProfileId || profileId}
                                />
                              </span>
                            </div>
                            <div className="contact-item">
                              {userData.userType == "Professional" ? (
                                <div className="">
                                  {userData.professional.location ? (
                                    <>
                                      <HiLocationMarker />
                                      &nbsp;
                                      <span className="me_txt">
                                        {userData.professional.location}
                                      </span>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : userData.userType == "Business" ? (
                                <div className="">
                                  {userData.business.city ||
                                  userData.business.state ? (
                                    <>
                                      <HiLocationMarker />
                                      &nbsp;
                                      <span className="me_txt">
                                        {userData.business.city
                                          ? userData.business.city
                                          : ""}
                                        {userData.business.city &&
                                        userData.business.state
                                          ? ","
                                          : ""}
                                        {userData.business.state
                                          ? userData.business.state
                                          : ""}
                                      </span>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div className="">
                                  {userData.city || userData.state ? (
                                    <>
                                      <HiLocationMarker />
                                      &nbsp;
                                      <span className="me_txt">
                                        {userData.city ? userData.city : ""}
                                        {userData.city && userData.state
                                          ? ","
                                          : ""}{" "}
                                        {userData.state ? userData.state : ""}
                                      </span>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                            </div>

                            {/* <div className=""> */}
                            <div className="contact-item">
                              {userData && userData.phone && (
                                <>
                                  {userData.phone && <FaPhoneAlt />} &nbsp;
                                  <span className="me_txt">
                                    {userData.phone
                                      ? userData.isPublic
                                        ? userData.phone
                                        : "x".repeat(
                                            Math.max(
                                              0,
                                              userData.phone.length - 4
                                            )
                                          ) + userData.phone.slice(-4)
                                      : "-"}
                                  </span>
                                </>
                              )}
                            </div>
                            {userData.business?.website ? (
                              <div className="contact-item">
                                <PiLinkBold /> &nbsp;
                                <a
                                  href={userData.business?.website}
                                  target="_blank"
                                  className="a_social"
                                >
                                  <span
                                    className="me_txt name_blue h_22"
                                    title={userData.business?.website}
                                  >
                                    {" "}
                                    {userData.business?.website.length >= 30
                                      ? userData.business?.website
                                          .substring(0, 30)
                                          .concat("...")
                                      : userData.business?.website}
                                  </span>
                                </a>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="contact-item">
                              {userData && userData.email && (
                                <>
                                  <MdEmail /> &nbsp;
                                  <span className="me_txt">
                                    {userData.isPublic
                                      ? `${userData.email}`
                                      : `${userData.email.slice(
                                          0,
                                          4
                                        )}${"x".repeat(
                                          Math.max(0, userData.email.length - 4)
                                        )}`}
                                  </span>
                                </>
                              )}
                            </div>
                            <div className="me_txt">
                              {userData.userType == "Professional"
                                ? userData.professional?.bio
                                : userData.userType == "Business"
                                ? userData.business?.bio
                                : userData.bio}
                            </div>
                            {userData.isPublic && (
                              <div className="mt-2">
                                {userData.facebookUrl && (
                                  <a
                                    href={userData.facebookUrl}
                                    target="_blank"
                                    className="a_social"
                                  >
                                    <AiFillFacebook
                                      className="c_pointer social_icon_f mr-2"
                                      size={28}
                                    />
                                  </a>
                                )}
                                {userData.linkedinUrl && (
                                  <a
                                    href={userData.linkedinUrl}
                                    target="_blank"
                                    className="a_social"
                                  >
                                    <FaLinkedin
                                      size={25}
                                      className="social_icon_li mr-2"
                                    />
                                  </a>
                                )}
                                {userData.instagramUrl && (
                                  <a
                                    href={userData.instagramUrl}
                                    target="_blank"
                                    className="a_social mr-2"
                                  >
                                    <InstagramIcon />
                                  </a>
                                )}
                                {userData.twitterUrl && (
                                  <a
                                    href={userData.twitterUrl}
                                    target="_blank"
                                    className="a_social"
                                  >
                                    <FaSquareXTwitter
                                      size={25}
                                      className="rounded-0 social_icon mr-2"
                                    />
                                  </a>
                                )}
                                {userData.facebookUrl ||
                                userData.linkedinUrl ||
                                userData.instagramUrl ||
                                userData.twitterUrl ? (
                                  <a
                                    href="https://setld.app/"
                                    target="_blank"
                                    className="a_social mr-2"
                                  >
                                    <img
                                      src={setldImg}
                                      height={22}
                                      width={22}
                                    />
                                  </a>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </div>
                          <Overlay
                            show={showPopover}
                            target={targetRef.current}
                            placement="bottom"
                            container={document.body}
                            onHide={handleOverlayClose}
                            rootClose
                          >
                            <Popover id="popover" className="pop_rating">
                              <div className="drp_rating">
                                <StarRating
                                  customClass="user-star"
                                  rating={
                                    userData.userType == "Professional"
                                      ? userData.professional
                                          ?.averageReviewCount
                                      : userData.userType == "Business"
                                      ? userData.business?.averageReviewCount
                                      : userData.averageReviewCount
                                  }
                                  disabled={true}
                                />
                                <span
                                  style={{
                                    marginLeft: "-45px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {userData.userType == "Professional"
                                    ? userData.professional?.averageReviewCount
                                    : userData.userType == "Business"
                                    ? userData.business?.averageReviewCount
                                    : userData.averageReviewCount}{" "}
                                  out of 5
                                </span>{" "}
                              </div>
                              {globalRating.map((item, index) => (
                                <Row>
                                  <Col
                                    sm={12}
                                    md={3}
                                    lg={3}
                                    xl={2}
                                    className="p-0"
                                  >
                                    <span className="prog_span">
                                      {item.label}
                                    </span>{" "}
                                  </Col>
                                  <Col
                                    sm={12}
                                    md={8}
                                    lg={8}
                                    xl={7}
                                    className="p-0"
                                  >
                                    <Horizontalbar dataSet={item.value} />
                                  </Col>
                                  <Col
                                    sm={12}
                                    md={3}
                                    lg={3}
                                    xl={2}
                                    className="p-0 prog_span"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <span className="tooltip">
                                      <span
                                        className="c_pointer"
                                        onMouseEnter={() =>
                                          handleMouseEnter(item.userRatings)
                                        }
                                      >
                                        {item.value}
                                        {item.value > 1 ? (
                                          <FiUsers
                                            style={{ marginLeft: "6px" }}
                                          />
                                        ) : (
                                          <FiUser
                                            style={{ marginLeft: "6px" }}
                                          />
                                        )}
                                      </span>
                                      {commonUtils.ratingTooltip(currentUsers)}
                                    </span>
                                  </Col>
                                </Row>
                              ))}
                            </Popover>
                          </Overlay>
                        </Col>
                        <div className="brdr_lne"></div>
                        <Col
                          className="pad_15 pd_btmm"
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                        >
                          <LineChart
                            data={graphRating}
                            labels={labels}
                            additionalData={reviewCount}
                          />
                          <div className="d-flex justify-content-end">
                            <Col sm={12} md={12} lg={12} xl={12}>
                              <select
                                className="rle_place form-control input_select fz-12"
                                value={aggregationType}
                                onChange={(e) =>
                                  setAggregationType(e.target.value)
                                }
                              >
                                <option value="yearly">Yearly</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                              </select>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                      {/* </Row> */}
                    </Col>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-12 col-sm-8 col-md-9 pd_sz">
                <Card className="shadow h-100">
                  <div className="pro_tab_div">
                    <div style={{ position: "relative" }}>
                      {!tiiptok && (
                        <Tabs
                          activeKey={eventKey}
                          defaultActiveKey="Personal"
                          id="profile_tab"
                          className="mb-2 dash-tab"
                          onSelect={(key) => {
                            handleTabChange(key);
                          }}
                        >
                          <Tab
                            eventKey="Reviews"
                            title={
                              <span className="pro_tab_span">
                                {/* <TiGroup
                          className={`dashboardTabIcon ${eventKey === "Groups" ? "clr_darkblue" : ""
                            }`}
                        /> */}
                                <span style={{ marginLeft: "8px" }}>
                                  Reviews Received
                                </span>
                              </span>
                            }
                          ></Tab>
                          <Tab
                            eventKey="Posted Reviews"
                            title={
                              <span className="pro_tab_span">
                                {/* <TiGroup
                          className={`dashboardTabIcon ${eventKey === "Groups" ? "clr_darkblue" : ""
                            }`}
                        /> */}
                                <span style={{ marginLeft: "8px" }}>
                                  Reviews Posted
                                </span>
                              </span>
                            }
                          >
                            <ReviewsTab
                              userData={userData}
                              selectedRoleId={selectedRoleId}
                            />
                          </Tab>
                          {avgRatng != 0 ? (
                            <Tab
                              className="str"
                              title={
                                <span
                                  className="d-flex align-items-center"
                                  style={{ fontSize: "12px", height: "24px" }}
                                >
                                  <span className="mar_ratng">
                                    {Number(avgRatng).toFixed(1)}
                                  </span>
                                  <StarRating
                                    className="avgRat"
                                    rating={avgRatng}
                                    disabled={true}
                                    customClass="special-star"
                                  />
                                  <span className="mar_cnt">
                                    ({ratngCount})
                                  </span>
                                </span>
                              }
                            ></Tab>
                          ) : (
                            <Tab
                              eventKey="rating_none"
                              style={{ border: "none" }}
                              title={<span></span>}
                            ></Tab>
                          )}
                          <Tab
                            title={
                              <span
                                className="pro_tab_span"
                                style={{ paddingRight: "0 px" }}
                              >
                                <span
                                  className="filImg"
                                  title="filter"
                                  onClick={toggleFilterSection}
                                >
                                  <img
                                    src={filter}
                                    height={18}
                                    className="c_pointer"
                                  />
                                </span>
                                {showFilterSection && (
                                  <>
                                    <select
                                      className="border border-0 fs_12 clr_flter"
                                      value={selectedRoleId}
                                      onChange={handleUserRole}
                                    >
                                      <option value="">
                                        Select Reviewed as
                                      </option>
                                      {roleList &&
                                        roleList.map((role) => (
                                          <option key={role.id} value={role.id}>
                                            {role.name}
                                          </option>
                                        ))}
                                    </select>
                                    {selectedRoleId && (
                                      <span
                                        className="clr_icn c_pointer"
                                        onClick={clear}
                                      >
                                        <IoMdClose />
                                      </span>
                                    )}
                                  </>
                                )}
                              </span>
                            }
                          ></Tab>
                        </Tabs>
                      )}
                    </div>
                  </div>
                  <div className="me_revList_nw">
                    {!tiiptok
                      ? renderReviewCards(userreviewList)
                      : rederPostCards(postList)}
                  </div>
                </Card>
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center justify-content-center shade">
              <Card className="shadow">
                <Card.Body style={{ padding: "20px" }} className="me_card">
                  The user's professional account is currently unavailable.
                  <br />
                  Would you like to see the user's personal account?&nbsp;
                  <a
                    className="mb2 card_txt name_blue h_22 c_pointer"
                    onClick={() => {
                      handleNameClick(userData.id, "Personal", null, null, "1");
                    }}
                  >
                    {userData.name}
                  </a>
                </Card.Body>
              </Card>
            </div>
          )}
        </div>
        <Modal size="sm" show={isShare} onHide={CloseShare}>
          <Modal.Header style={{ padding: "15px 0px!important" }}>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "15px", fontWeight: 600 }}
            >
              Share
            </Modal.Title>
            <span
              onClick={CloseShare}
              title="Close"
              style={{ cursor: "pointer" }}
            >
              <AiOutlineClose />
            </span>
          </Modal.Header>
          <Modal.Body className="text-center p-2">
            {shareData && (
              <ViewShare shareData={shareData} userData={userData} />
            )}
            {urlLink && (
              <FacebookShareButton
                url={urlLink}
                title="tiipstr"
                className="m-2"
              >
                <FacebookIcon className="c_pointer" size={32} />
              </FacebookShareButton>
            )}
            {urlLink && (
              <TwitterShareButton
                url={urlLink}
                title="SEE WHAT THEY'RE SAYING . . ."
                className="m-2"
              >
                <FaSquareXTwitter size={36} />
              </TwitterShareButton>
            )}
            {urlLink && (
              <LinkedinShareButton
                url={urlLink}
                title="tiipstr"
                className="m-2"
              >
                <LinkedinIcon size={31} />
              </LinkedinShareButton>
            )}
          </Modal.Body>
        </Modal>
        <Modal
          size="md"
          className="report_modal"
          show={showReply}
          onHide={() => setShowReply(false)}
        >
          <Modal.Header
            className="border_rst__btm"
            style={{ padding: "15px 0px!important" }}
          >
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "15px", fontWeight: 600 }}
            >
              <h6 className="font-weight-bold">Review Comments</h6>
            </Modal.Title>
            <span
              onClick={() => {
                setShowReply(false);
              }}
              title="Close"
              style={{ cursor: "pointer" }}
            >
              <AiOutlineClose />
            </span>
          </Modal.Header>
          <Modal.Body style={{ fontSize: "14px" }}>
            {revCmnt &&
              revCmnt.map((data, i) => (
                <Row>
                  <Col
                    className={`revCmtPop pb-0 ${
                      showChildReply && replies.length != 0 ? "revcmt_bg" : ""
                    }`}
                  >
                    <div className="d-flex">
                      <div>
                        <img
                          className="brd_rad h_w_25"
                          src={
                            data.userImagePath ? data.userImagePath : profile
                          }
                        />
                      </div>
                      <div
                        className="d-flex"
                        style={{ marginLeft: "8px", alignItems: "center" }}
                      >
                        {data.userName}
                        <span className="dat_icn">
                          <GoDotFill />
                        </span>{" "}
                        <div style={{ fontSize: "11px", color: "#959595" }}>
                          {" "}
                          {moment
                            .utc(data.createdAt)
                            .local()
                            .startOf("seconds")
                            .fromNow()}
                        </div>
                      </div>
                    </div>
                    <div className="ctTxt">
                      {textWithLinks(data.commentText).map((word, index) => (
                        <span key={index}>{word} </span>
                      ))}
                    </div>
                    <div class="send d-flex gap_20 mb-2 mt-2">
                      {likeFeatureEnable ? (
                        <RatingBar
                          disabled={
                            reviewSts == "reported" ||
                            reviewSts == "blocked" ||
                            data.userId === props.userId
                              ? true
                              : false
                          }
                          msgreply={true}
                          liked={data.liked}
                          likecount={data.likes}
                          dislikecount={data.dislikes}
                          handlelike={() =>
                            handlerplylikechange(data.id, data.reviewId)
                          }
                          handledislike={() =>
                            handlerplydislikechange(data.id, data.reviewId)
                          }
                          handleRmvlike={() =>
                            rplylikecntDecrement(data.id, data.reviewId)
                          }
                          labelVAlue={data.score}
                        />
                      ) : (
                        <>{renderRatingBar(data, true)}</>
                      )}
                      {reviewSts == "reported" ||
                      reviewSts == "blocked" ||
                      data.userId === props.userId ? (
                        <BiSolidCommentDetail className="cmt_icn clr_gray" />
                      ) : (
                        <BiSolidCommentDetail
                          className="cmt_icon c_pointer"
                          title="Add comment"
                          onClick={() => togglecomment(data.id)}
                        />
                      )}
                      <Row>
                        {replies.filter(
                          (obj) =>
                            obj.id == data.id || obj.parentCommentId == data.id
                        ).length != 0 && (
                          <span
                            className="rply c_pointer"
                            onClick={() =>
                              viewChildComments(replies, data.id, data.reviewId)
                            }
                          >
                            {replies.filter(
                              (obj) =>
                                obj.id == data.id ||
                                obj.parentCommentId == data.id
                            ).length === 1
                              ? `${
                                  replies.filter(
                                    (obj) =>
                                      obj.id == data.id ||
                                      obj.parentCommentId == data.id
                                  ).length
                                } Reply `
                              : `${
                                  replies.filter(
                                    (obj) =>
                                      obj.id == data.id ||
                                      obj.parentCommentId == data.id
                                  ).length
                                } Replies `}
                            <span>
                              {childcurrentIndex == data.id ? (
                                <IoMdArrowDropup />
                              ) : (
                                <IoMdArrowDropdown />
                              )}
                            </span>
                          </span>
                        )}
                      </Row>
                    </div>
                    {currentIndex == data.id ? (
                      <Row>
                        <div className="position-relative p-0">
                          {cmtError && cmtErrId == data.id ? (
                            <div className="text-danger fz-12 ml-2">
                              {cmtError}
                            </div>
                          ) : (
                            ""
                          )}
                          <MentionsInput
                            style={mentionInputStyle}
                            className="form-control Cmt_box"
                            maxLength={288}
                            onChange={(e) => {
                              changeCmtText(e.target.value, "child");
                            }}
                            value={childComment}
                            placeholder="type someting..."
                          >
                            <Mention
                              style={{ backgroundColor: "#efebe3" }}
                              trigger="@"
                              data={users}
                            />
                          </MentionsInput>
                          {/* <textarea className="form-control Cmt_box" placeholder="type someting..." onChange={(e) => setchildComment(e.target.value)} value={childComment}/> */}
                          <button
                            disabled={postBtnDisable}
                            className="btn btn-primary cmt_btn"
                            onClick={() => {
                              if (
                                isFeatureEnabled(
                                  allFeatures["Add-Comment%"]?.key,
                                  allFeatures["Add-Comment%"]?.isDeleted
                                )
                              ) {
                                postComment(data.id, data.reviewId, "child");
                              } else {
                                setShowPopup(true);
                              }
                            }}
                          >
                            Post
                          </button>
                        </div>
                      </Row>
                    ) : (
                      ""
                    )}
                    {childcurrentIndex == data.id && replies.length != 0
                      ? replies.map((item, i) =>
                          item.id == data.id ||
                          item.parentCommentId == data.id ? (
                            <Row>
                              <Col className="revchdcmt pb-0">
                                <div className="d-flex">
                                  <div>
                                    <img
                                      className="brd_rad h_w_25"
                                      src={
                                        item.replyUserImagePath
                                          ? item.replyUserImagePath
                                          : profile
                                      }
                                    />
                                  </div>
                                  <div
                                    className="d-flex"
                                    style={{
                                      marginLeft: "8px",
                                      alignItems: "center",
                                    }}
                                  >
                                    {item.replyUserName}
                                    <span className="dat_icn">
                                      <GoDotFill />
                                    </span>{" "}
                                    <div
                                      style={{
                                        fontSize: "11px",
                                        color: "#959595",
                                      }}
                                    >
                                      {" "}
                                      {moment
                                        .utc(item.createdAt)
                                        .local()
                                        .startOf("seconds")
                                        .fromNow()}
                                    </div>
                                  </div>
                                </div>
                                <div className="ctTxt">
                                  <span className="rep_men">
                                    {"@" + item.userName + " "}
                                  </span>
                                  {textWithLinks(item.replyCommentText).map(
                                    (word, index) => (
                                      <span key={index}>{word} </span>
                                    )
                                  )}
                                </div>
                                <div class="send d-flex gap_20 mb-2 mt-2">
                                  {likeFeatureEnable ? (
                                    <RatingBar
                                      disabled={
                                        reviewSts == "reported" ||
                                        item.replyUserId === props.userId
                                          ? true
                                          : false
                                      }
                                      msgreply={true}
                                      liked={item.liked}
                                      likecount={item.likes}
                                      dislikecount={item.dislikes}
                                      handlelike={() =>
                                        handlerplylikechange(
                                          item.replyCommentId,
                                          data.reviewId
                                        )
                                      }
                                      handledislike={() =>
                                        handlerplydislikechange(
                                          item.replyCommentId,
                                          data.reviewId
                                        )
                                      }
                                      handleRmvlike={() =>
                                        rplylikecntDecrement(
                                          item.replyCommentId,
                                          data.reviewId
                                        )
                                      }
                                      labelVAlue={item.score}
                                    />
                                  ) : (
                                    <>{renderRatingBar(item, true)}</>
                                  )}
                                  {reviewSts == "reported" ||
                                  reviewSts == "blocked" ||
                                  item.replyUserId === props.userId ? (
                                    <BiSolidCommentDetail className="cmt_icn clr_gray" />
                                  ) : (
                                    <BiSolidCommentDetail
                                      className="cmt_icon c_pointer"
                                      title="Add comment"
                                      onClick={() =>
                                        togglechildcomment(item.replyCommentId)
                                      }
                                    />
                                  )}
                                </div>
                                {childrepplycurrentIndex ==
                                item.replyCommentId ? (
                                  <Row>
                                    <div className="position-relative p-0">
                                      {cmtError &&
                                      cmtErrId == item.replyCommentId ? (
                                        <div className="text-danger ml-2 fz-12">
                                          {cmtError}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <MentionsInput
                                        style={mentionInputStyle}
                                        className="form-control Cmt_box"
                                        maxLength={288}
                                        onChange={(e) => {
                                          changeCmtText(
                                            e.target.value,
                                            "child"
                                          );
                                        }}
                                        value={childComment}
                                        placeholder="type someting..."
                                      >
                                        <Mention
                                          style={{ backgroundColor: "#efebe3" }}
                                          trigger="@"
                                          data={users}
                                        />
                                      </MentionsInput>
                                      {/* <textarea className="form-control Cmt_box" placeholder="type someting..." onChange={(e) => setchildComment(e.target.value)} value={childComment}/> */}
                                      <button
                                        disabled={postBtnDisable}
                                        className="btn btn-primary cmt_btn"
                                        onClick={() => {
                                          if (
                                            isFeatureEnabled(
                                              allFeatures["Add-Comment%"]?.key,
                                              allFeatures["Add-Comment%"]
                                                ?.isDeleted
                                            )
                                          ) {
                                            postComment(
                                              item.replyCommentId,
                                              data.reviewId,
                                              "child"
                                            );
                                          } else {
                                            setShowPopup(true);
                                          }
                                        }}
                                      >
                                        Post
                                      </button>
                                    </div>
                                  </Row>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )
                        )
                      : ""}
                  </Col>
                </Row>
              ))}
          </Modal.Body>
        </Modal>
        <CustomModal
          show={showBusnScore}
          handleClose={() => setShowBusnScore(false)}
          post={true}
          title="Business scores"
          body={
            <>
              <BusinessScores
                businessScore={userData.business?.showBusinessScore}
                cultureScore={userData.business?.showCultureScore}
                peopleScore={userData.business?.showPeopleScore}
                userData={userData}
                loadChart={() => profileChartInfo()}
                setScoreType={setScoreType}
                scoreType={scoreType}
              />
            </>
          }
        />
        {/* Attachment popup */}
        <AttachmentModal
          show={showAttachment}
          onClose={() => setShowAttachment(false)}
          attachment={attachment}
          checkExt={checkExt}
          fileImg={fileImg}
        />
        <UpgradePopup
          show={showPopup}
          handleClose={() => setShowPopup(false)}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    userType: state.user.userType,
    profileId: state.user.profileId,
    isKycVerified: state.user.isKycVerified,
    imagePath: state.user.imagePath,
  };
};
export default connect(mapStateToProps)(Viewuser);
