import { Button, Card, Col, Row } from "react-bootstrap";
import profile from "../../../assets/images/profile.jpg";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import StarRating from "./StarRating";
import { IoIosArrowDown } from "react-icons/io";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FiUsers, FiUser } from "react-icons/fi";
import { HiLocationMarker } from "react-icons/hi";
import { PiPhoneFill } from "react-icons/pi";
import { GrMail } from "react-icons/gr";
import Horizontalbar from "../../plugins/Horizontalbar";
import { useDispatch } from "react-redux";
import { followId } from "../../../Redux/Actions/userAction";
import { commonUtils } from "../../CommonUtils";

const FollowCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [revGlobalRtng, setRevGlobalRtng] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [globalRating, setGlobalRating] = useState([]);
  const [avgCount, setAvgCount] = useState("");

  const handleArrowClick = (rating, avgCount) => {
    setAvgCount(avgCount);
    const starResponse = rating;
    const result = starResponse.map((starData) => ({
      label: starData.star,
      value: starData.totalReviewCount,
      userRatings: starData.userRatings,
    }));
    result.sort((a, b) => {
      const numA = parseInt(a.label.split(" ")[0]);
      const numB = parseInt(b.label.split(" ")[0]);
      return numB - numA;
    });
    setRevGlobalRtng(result);
  };
  const handleMouseEnter = (users) => {
    if (users.length > 0) setCurrentUsers(users);
    else setCurrentUsers([]);
  };
  useEffect(() => {
    console.log("List", props.list);
  }, []);
  return (
    <>
      <Card
        className={`usr_crd_bdr rounded-0 ${
          props.active ? "rev_crd_shd" : "mb-10"
        }`}
      >
        <div className="d-flex justify-content-end">
          {props.list.userType === "Professional" ? (
            <div className="usr_brnd usr_brnd_pro">
              <span className="m-1">Professional</span>
            </div>
          ) : props.list.userType === "Personal" ? (
            <div className="usr_brnd usr_brnd_psnl">
              <span className="m-1">Personal</span>
            </div>
          ) : (
            <div className="usr_brnd usr_brnd_bus">
              <span className="m-1">Business</span>
            </div>
          )}
        </div>
        <div className="user text-center d-flex justify-content-center mt-2">
          <div className="follow_profile">
            <img
              src={
                props.list.userType === "Business"
                  ? props.list.logo
                    ? props.list.logo
                    : profile
                  : props.list.imagePath
                  ? props.list.imagePath
                  : profile
              }
              className="rounded-circle"
              width="60"
              height="60"
            />
          </div>
        </div>
        <div className="mt-2 text-center">
          <span
            className="fol_name c_pointer"
            onClick={() => {
              dispatch(
                followId(
                  props.userId,
                  props.list.userType,
                  props.list.userType === "Professional" ||
                    props.list.userType === "Business"
                    ? props.list.id
                    : null
                )
              );
              navigate("/dashboard/viewuser", {
                state: {
                  userId: props.userId,
                  userType: props.list.userType,
                  profileId:
                    props.list.userType === "Professional" ||
                    props.list.userType === "Business"
                      ? props.list.id
                      : null,
                },
              });
            }}
          >
            {props.list?.companyName ? (
              ""
            ) : (
              <h6 className="mb-0">
                {props.firstName ? props.firstName : ""}{" "}
                {props.lastName ? props.lastName : ""}
              </h6>
            )}
            {props.list?.companyName ? (
              <>
                <h6 className="mb-0" title={props.list?.companyName}>
                  {props.list?.shortName
                    ? props.list?.shortName
                    : props.list?.companyName}
                </h6>
                <div className="h_23"></div>
              </>
            ) : props.list?.professionType ? (
              <p className="mb-0 fz_14 font-weight-400">
                {"(" +
                  (props.list?.professionType != undefined
                    ? props.list?.professionType
                    : "") +
                  ")"}
              </p>
            ) : (
              <div className="h_23"></div>
            )}
          </span>
          <div className="d-flex justify-content-center">
            {props.list.averageReviewScore > 0.0 ? (
              <span className="font-weight-bold total_fnt mar_9">
                {props.list.averageReviewScore.toFixed(1)}
              </span>
            ) : (
              ""
            )}
            <StarRating
              isReview={true}
              rating={props.list.averageReviewCount}
              disabled={true}
            />
            <span className="d-flex align-items-center total_fnt">
              ({props.list.totalReviewCount})
            </span>
            <OverlayTrigger
              trigger="click"
              key={props.list.id}
              placement="bottom"
              overlay={
                <Popover id={`popover-${props.list.id}`} className="pop_rating">
                  <div className="drp_rating">
                    <StarRating rating={avgCount} disabled={true} />
                    <span>{avgCount} out of 5</span>{" "}
                  </div>
                  {revGlobalRtng.map((item, index) => (
                    <Row key={index}>
                      <Col sm={12} md={3} lg={3} xl={2} className="p-0">
                        <span className="prog_span">{item.label}</span>{" "}
                      </Col>
                      <Col sm={12} md={8} lg={8} xl={7} className="p-0">
                        <Horizontalbar dataSet={item.value} />
                      </Col>
                      <Col
                        sm={12}
                        md={3}
                        lg={3}
                        xl={2}
                        className="p-0 prog_span"
                        style={{ marginLeft: "10px" }}
                      >
                        <span className="tooltip">
                          <span
                            className="c_pointer"
                            onMouseEnter={() =>
                              handleMouseEnter(item.userRatings)
                            }
                          >
                            {item.value}
                            {item.value > 1 ? (
                              <FiUsers style={{ marginLeft: "6px" }} />
                            ) : (
                              <FiUser style={{ marginLeft: "6px" }} />
                            )}
                          </span>
                          {commonUtils.ratingTooltip(currentUsers)}
                        </span>
                      </Col>
                    </Row>
                  ))}
                </Popover>
              }
              rootClose
            >
              <span
                className="d-flex align-items-center c_pointer"
                onClick={() =>
                  handleArrowClick(
                    props.list.starRating,
                    props.list.averageReviewCount
                  )
                }
              >
                <IoIosArrowDown />
              </span>
            </OverlayTrigger>
          </div>
          <div
            style={{ overflow: "visible" }}
            className="card_txt text-center fnt_11 mail_icn d-flex justify-content-center"
          >
            <span className="d-flex">
              <GrMail style={{ marginTop: "3px" }} />
              &nbsp;
              {props.list.email ? (
                props.list.isPublic ? (
                  <span title={props.list.email}>
                    {props.list.email.length > 19
                      ? `${props.list.email.slice(0, 19)}...`
                      : props.list.email}
                  </span>
                ) : (
                  props.list.email.slice(0, 4) +
                  "x".repeat(Math.max(0, props.list.email.length - 11))
                )
              ) : (
                "-"
              )}
            </span>
          </div>

          <div className="card_txt text-center fnt_11">
            {props.list.phone ? <PiPhoneFill /> : ""}
            &nbsp;
            {props.list.phone
              ? props.list.isPublic
                ? props.list.phone
                : "x".repeat(Math.max(0, props.list.phone.length - 4)) +
                  props.list.phone.slice(-4)
              : "-"}
          </div>
          <div className="card_txt text-center fnt_11">
            {props.list.location ? <HiLocationMarker /> : ""}
            &nbsp;{props.list.location ? props.list.location : "-"}{" "}
          </div>
          <div className="h_23"></div>
          <div className="btn_wd mt-2">
            {props.active ? (
              <div className="rev_crd_act">Active!</div>
            ) : (
              <commonUtils.CustomButton
                btnText="Review"
                btnWidth="100%"
                variant="primary"
                perform={() => props.onReview()}
                isDisabled={false}
              />
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

export default FollowCard;
