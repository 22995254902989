import React, { useState, useEffect } from "react";
import { useToast } from "../../../Toast";
import CustomModal from "../../../plugins/CustomModal";
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Table,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { BiChevronDownCircle, BiChevronUpCircle } from "react-icons/bi";
import { HiOutlineTrash } from "react-icons/hi"; // Import the HiOutlineTrash icon
import editRole from "../../../../assets/images/usr_edit.png";
import { commonUtils } from "../../../CommonUtils";
const ServicePlanDiscount = () => {
  const [planId, setPlanId] = useState(null);
  const [description, setDescription] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [planList, setPlanList] = useState([]);
  const [deletePopupShow, setDeletePopupShow] = useState(false);
  const [discounts, setDiscounts] = useState([]);
  const [selectedDiscountId, setSelectedDiscountId] = useState(null);
  const [showDiscountDetails, setShowDiscountDetails] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const toast = useToast();

  useEffect(() => {
    loadPlans();
    loadDiscounts();
  }, []);

  const loadPlans = async () => {
    try {
      const response = await window.apiService.loadPlans();
      setPlanList(response.data.recordInfo);
    } catch (error) {
      console.log(error);
    }
  };

  const loadDiscounts = async () => {
    try {
      const response = await window.apiService.getAllDiscounts();
      setDiscounts(response.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleSaveDiscount = async () => {
  //   if (
  //     !planId ||
  //     !description ||
  //     !discountValue ||
  //     !startDate ||
  //     !endDate

  //   ) {
  //     toast.error("Please fill all fields.");
  //     return;
  //   }

  //   if (new Date(startDate) > new Date(endDate)) {
  //     toast.error("Start date must be before end date.");
  //     return;
  //   }
  //   console.log("discounts", discounts);
  //   console.log("Selected planId", planId);

  //   // Restrict to one discount per plan
  //   if (
  //     !isEditing &&
  //     discounts.some((discount) => String(discount.planId) === String(planId))
  //   ) {
  //     toast.error("A discount already exists for this plan.");
  //     return;
  //   }

  //   const params = {
  //     planId,
  //     description,
  //     discountType,
  //     discountValue: parseFloat(discountValue),
  //     startDate,
  //     endDate,

  //   };

  //   try {
  //     const response = isEditing
  //       ? await window.apiService.updateDiscount(selectedDiscountId, params)
  //       : await window.apiService.addDiscount(params);

  //     if (response) {
  //       const successMessage = isEditing
  //         ? "Discount updated successfully."
  //         : "Discount added successfully.";
  //       toast.success(successMessage);
  //       loadDiscounts();
  //       resetForm();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     toast.error("Error saving discount details.");
  //   }
  // };

  const handleSaveDiscount = async () => {
    if (!planId || !description || !discountValue || !startDate || !endDate) {
      toast.error("Please fill all fields.");
      return;
    }

    if (new Date(startDate) > new Date(endDate)) {
      toast.error("Start date must be before end date.");
      return;
    }

    const newStartDate = new Date(startDate);
    const newEndDate = new Date(endDate);

    if (
      discounts.some((discount) => {
        const existingStartDate = new Date(discount.startDate);
        const existingEndDate = new Date(discount.endDate);

        // Check if the new discount's date range overlaps with any existing one
        return (
          newStartDate <= existingEndDate && newEndDate >= existingStartDate // overlap condition
        );
      })
    ) {
      toast.error(
        "A discount already exists for this plan during the specified date range."
      );
      return;
    }

    console.log("Selected planId", planId);

    const params = {
      planId,
      description,
      discountType,
      discountValue: parseFloat(discountValue),
      startDate,
      endDate,
    };

    try {
      const response = isEditing
        ? await window.apiService.updateDiscount(selectedDiscountId, params)
        : await window.apiService.addDiscount(params);

      if (response) {
        const successMessage = isEditing
          ? "Discount updated successfully."
          : "Discount added successfully.";
        toast.success(successMessage);
        loadDiscounts();
        resetForm();
      }
    } catch (error) {
      console.log(error);
      toast.error("Error saving discount details.");
    }
  };

  const resetForm = () => {
    setPlanId("");
    setDescription("");
    setDiscountType("");
    setDiscountValue("");
    setStartDate("");
    setEndDate("");
    setIsEditing(false);
  };

  const handleDeleteDiscount = async () => {
    try {
      const response = await window.apiService.deleteDiscount(
        selectedDiscountId
      );
      if (response) {
        toast.success("Discount deleted successfully");
        loadDiscounts();

        setDeletePopupShow(false);
        setSelectedDiscountId(null);
      }
    } catch (error) {
      console.log("Error occurred during delete:", error);
      toast.error("Error deleting discount.");
    }
  };

  const showDeletePopup = (discountId) => {
    setSelectedDiscountId(discountId);
    setDeletePopupShow(true);
  };

  const handleEditDiscount = (discount) => {
    setPlanId(discount.planId);
    setDescription(discount.description);
    setDiscountType(discount.discountType);
    setDiscountValue(discount.discountValue);
    setStartDate(discount.startDate);
    setEndDate(discount.endDate);
    // Set billing cycle for editing
    setSelectedDiscountId(discount.id);
    setIsEditing(true);
    setShowDiscountDetails(true);
  };

  const toggleDiscountDetails = () => {
    setShowDiscountDetails(!showDiscountDetails);
  };

  return (
    <>
      <div className="chat container h-100">
        <Card className="shade h-100" style={{ overflowX: "auto" }}>
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <div className="role_container">
                <Row>
                  <div className="discount-details-section">
                    <div className="d-flex justify-content-between">
                      <div className="m_b18 title_txt ms-1">
                        Add Discount Details
                      </div>
                      <div onClick={toggleDiscountDetails} className="mr-3">
                        {showDiscountDetails ? (
                          <BiChevronUpCircle />
                        ) : (
                          <BiChevronDownCircle />
                        )}
                      </div>
                    </div>
                    {showDiscountDetails && (
                      <div className="mt-3">
                        <Row className="g-2">
                          <Col sm={8} md={4}>
                            <select
                              className="form-control input_select"
                              value={planId}
                              onChange={(e) => setPlanId(e.target.value)}
                              required
                            >
                              <option value="">Select Plan</option>
                              {planList.map((plan) => (
                                <option key={plan.id} value={plan.id}>
                                  {plan.servicePlan + " / " + plan.billingCycle}
                                </option>
                              ))}
                            </select>
                          </Col>

                          <Col sm={8} md={4}>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Discount Value"
                              value={discountValue}
                              onChange={(e) => setDiscountValue(e.target.value)}
                            />
                          </Col>

                          <Col sm={8} md={4}>
                            <select
                              className="form-control input_select"
                              value={discountType}
                              onChange={(e) => setDiscountType(e.target.value)}
                              required
                            >
                              <option value="">Discount Type</option>
                              <option value="percentage">Percentage</option>
                              <option value="fixed_amount">Fixed Amount</option>
                            </select>
                          </Col>

                          <Col sm={8} md={4}>
                            {/* <label htmlFor="">Start Date:</label> */}
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Start Date"
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                            />
                          </Col>
                          <Col sm={8} md={4}>
                            {/* <label htmlFor="">End Date:</label> */}
                            <input
                              type="date"
                              className="form-control"
                              placeholder="End Date"
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                            />
                          </Col>
                          <Col sm={8} md={12}>
                            <textarea
                              className="form-control"
                              placeholder="Description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </Col>
                          <Col sm={2}>
                            <commonUtils.CustomButton
                              btnText={isEditing ? "Update" : "Save"}
                              btnWidth="fit-content"
                              variant="primary"
                              perform={() => handleSaveDiscount()}
                              isDisabled={false}
                            />
                          </Col>
                        </Row>
                      </div>
                    )}
                  </div>
                </Row>
                <Row className="mt-4">
                  <Col sm={12} className="px-2">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Plan</th>
                          <th>Discount Value</th>
                          <th>Discount Type</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {discounts.length > 0 ? (
                          discounts.map((discount) => {
                            const plan = planList.find(
                              (plan) => plan.id === discount.planId
                            );
                            return (
                              <tr key={discount.id}>
                                <td>
                                  {plan
                                    ? `${plan.servicePlan} / ${plan.billingCycle}`
                                    : "N/A"}
                                </td>
                                <td>{discount.discountValue}</td>
                                <td>{discount.discountType}</td>
                                <td>
                                  {new Date(
                                    discount.startDate
                                  ).toLocaleDateString()}
                                </td>
                                <td>
                                  {new Date(
                                    discount.endDate
                                  ).toLocaleDateString()}
                                </td>
                                <td>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>{discount.description}</Tooltip>
                                    }
                                  >
                                    <span>
                                      {discount.description.substring(0, 7)}
                                      {discount.description.length > 7 && "..."}
                                    </span>
                                  </OverlayTrigger>
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <img
                                      src={editRole}
                                      alt="Edit"
                                      className="mr-2 c_pointer"
                                      onClick={() =>
                                        handleEditDiscount(discount)
                                      }
                                    />
                                    <HiOutlineTrash
                                      onClick={() =>
                                        showDeletePopup(discount.id)
                                      }
                                      title="Delete"
                                      className="ques_trash c_pointer"
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="8" className="text-center">
                              No records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>

        {/* Delete Confirmation Modal */}
      </div>
      <Modal show={deletePopupShow} onHide={() => setDeletePopupShow(false)}>
        <Modal.Header closeButton className="p-3">
          <Modal.Title
            className="mb-1"
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            {" "}
            Delete Discount
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3 bordr">
          Are you sure you want to delete this discount?
        </Modal.Body>
        <Modal.Footer className="bordr pad_13">
          <commonUtils.CustomButton
            btnText="Yes"
            btnWidth="fit-content"
            variant="primary"
            perform={() => handleDeleteDiscount()}
            isDisabled={false}
          />
          <commonUtils.CustomButton
            btnText="Cancel"
            btnWidth="fit-content"
            variant="danger"
            perform={() => setDeletePopupShow(false)}
            isDisabled={false}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ServicePlanDiscount;
