import { Card, Col, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useToast } from "../Toast";
import SideBar from "./AdminSideBar";
import HeaderMenu from "./HeaderMenu";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Form, InputGroup, Button } from "react-bootstrap";
import { GrMail } from "react-icons/gr";
import { BiSolidPhone, BiUser } from "react-icons/bi";
import { MdLocationOn, MdScore } from "react-icons/md";
import loader from "../../assets/images/loader.gif";
const UserRoles = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });
  const bulkPush = async () => {
    try {
      setLoading(true);
      const result = await window.apiService.pushHubspotBulkData();
      const response = result.data;
      setLoading(false);
      if (result.status === 200) {
        toast.success(response);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  const onSubmit = async (data) => {
    console.log(data);
    const properties = {
      properties: {
        ...data,
        website: process.env.REACT_APP_URL + "/dashboard/profile",
      },
    };
    try {
      setLoading(true);
      const result = await window.apiService.pushHubspotData(properties);
      const response = result.data;
      setLoading(false);
      if (response.id) {
        toast.success("Data pushed successfully");
      }
      console.log(response.data);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      if (error.response) {
        const errorData = error.response.data; // Extract error data

        // Check if the error message contains "Contact already exists"
        if (
          errorData.message &&
          errorData.message.includes("Contact already exists")
        ) {
          const errorMessage = `Contact already exists! Existing ID: ${
            errorData.message.match(/ID: (\d+)/)?.[1] || "Unknown"
          }`;
          toast.error(errorMessage); // Show toast error
        } else {
          toast.error(
            errorData.message || "Something went wrong. Please try again."
          );
        }
      } else {
        toast.error("Network error. Please try again.");
      }
    }
  };

  return (
    <>
      <HeaderMenu />
      <SideBar />
      <div className="main-content ml_200">
        <Card className="shade h-500">
          <div className="role_container">
            <Row className="m_b18 title_txt">
              <Col sm={12} md={12} lg={12} xl={12}>
                Pushing Users to HubSpot
              </Col>
            </Row>
          </div>
          <Row className="justify-content-center">
            <Col sm={12} md={12} lg={6} xl={6} className="p-4">
              <div className="mb-3">Single Submission</div>
              <Form
                onSubmit={handleSubmit(onSubmit)}
                className="p-4 shadow rounded"
              >
                {/* First Name */}
                <Form.Group controlId="name" className="mb-3">
                  {/* <label>First Name</label> */}
                  <InputGroup>
                    <InputGroup.Text>
                      <BiUser />
                    </InputGroup.Text>
                    <Form.Control
                      className="pl-2"
                      type="text"
                      placeholder="Name"
                      {...register("firstname", {
                        required: "Name is required",
                      })}
                    />
                  </InputGroup>
                  {errors.firstname && (
                    <small className="text-danger">
                      {errors.firstname.message}
                    </small>
                  )}
                </Form.Group>

                {/* Last Name */}
                {/* <Form.Group controlId="lastName" className="mb-3">
                                    <InputGroup>
                                        <InputGroup.Text><BiUser /></InputGroup.Text>
                                        <Form.Control className="pl-2"
                                            type="text"
                                            placeholder="Last Name"
                                            {...register("lastName", { required: "Last Name is required" })}
                                        />
                                    </InputGroup>
                                    {errors.lastName && <small className="text-danger">{errors.lastName.message}</small>}
                                </Form.Group> */}

                {/* Email */}
                <Form.Group controlId="email" className="mb-3">
                  {/* <label>Email</label> */}
                  <InputGroup>
                    <InputGroup.Text>
                      <GrMail />
                    </InputGroup.Text>
                    <Form.Control
                      className="pl-2"
                      type="email"
                      placeholder="Email"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                    />
                  </InputGroup>
                  {errors.email && (
                    <small className="text-danger">
                      {errors.email.message}
                    </small>
                  )}
                </Form.Group>

                {/* Phone */}
                <Form.Group controlId="phone" className="mb-3">
                  {/* <label>Phone</label> */}
                  <InputGroup>
                    <InputGroup.Text>
                      <BiSolidPhone />
                    </InputGroup.Text>
                    <Form.Control
                      className="pl-2"
                      type="tel"
                      placeholder="Phone Number"
                      {...register("phone", {
                        required: "Phone number is required",
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message: "Invalid phone number (10 digits required)",
                        },
                      })}
                    />
                  </InputGroup>
                  {errors.phone && (
                    <small className="text-danger">
                      {errors.phone.message}
                    </small>
                  )}
                </Form.Group>

                {/* Location */}
                <Form.Group controlId="location" className="mb-3">
                  {/* <label>Location</label> */}
                  <InputGroup>
                    <InputGroup.Text>
                      <MdLocationOn />
                    </InputGroup.Text>
                    <Form.Control
                      className="pl-2"
                      type="text"
                      placeholder="Location"
                      {...register("location", {
                        required: "Location is required",
                      })}
                    />
                  </InputGroup>
                  {errors.location && (
                    <small className="text-danger">
                      {errors.location.message}
                    </small>
                  )}
                </Form.Group>

                {/* Composite Score */}
                <Form.Group controlId="compositeScore" className="mb-3">
                  {/* <label>Composite Score</label> */}
                  <InputGroup>
                    <InputGroup.Text>
                      <MdScore />
                    </InputGroup.Text>
                    <Form.Control
                      className="pl-2"
                      type="number"
                      step="0.01"
                      placeholder="Composite Score"
                      {...register("composite_score", {
                        required: "Composite Score is required",
                        min: { value: 0, message: "Score cannot be negative" },
                        max: { value: 5, message: "Score cannot exceed 5" },
                        pattern: {
                          value: /^[0-9]+(\.[0-9]+)?$/,
                          message: "Only decimal numbers are allowed",
                        },
                      })}
                    />
                  </InputGroup>
                  {errors.composite_score && (
                    <small className="text-danger">
                      {errors.composite_score.message}
                    </small>
                  )}
                </Form.Group>

                {/* Submit Button */}
                <div className="text-center mt-3">
                  <Button
                    className="font-weight-500 fz_14"
                    style={{
                      width: "fit-content",
                      height: "30px",
                      lineHeight: "15px",
                    }}
                    type="submit"
                    variant="primary"
                    disabled={!isValid}
                  >
                    Push
                  </Button>
                </div>
              </Form>
            </Col>
            <Col sm={12} md={12} lg={6} xl={6} className="p-4">
              <div className="mb-3">Bulk Submission</div>
              <Button
                className="font-weight-500 fz_14"
                style={{
                  width: "fit-content",
                  height: "30px",
                  lineHeight: "15px",
                }}
                onClick={() => bulkPush()}
              >
                Bulk
              </Button>
            </Col>
          </Row>
          {loading ? (
            <div className="pro-loader-center">
              <img className="loader-image" src={loader} alt="Loading..." />
            </div>
          ) : (
            ""
          )}
        </Card>
      </div>
    </>
  );
};

export default UserRoles;
