import {
  Card,
  Col,
  Row,
  Modal,
  Button,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { useToast } from "../../../Toast";
import { BsPlusLg } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
// import SideBar from "../AdminSideBar";
// import HeaderMenu from "../HeaderMenu";
// import BreadCrumb from "../../plugins/Breadcrumb";
import { connect } from "react-redux";
import CustomModal from "../../../plugins/CustomModal";
import { Input, Table } from "reactstrap";
import editRole from "../../../../assets/images/usr_edit.png";
import PlanFeatureMapping from "./PlanFeature";
import { HiOutlineTrash } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import roleAccess from "../../../../assets/images/usr_ques.png";
import accessActive from "../../../../assets/images/ques_active.png";
import loader from "../../../../assets/images/loader.gif";
import { commonUtils } from "../../../CommonUtils";
const STATUS = {
  start: 1,
  default: 2,
};
const ServicePlan = (props) => {
  const [planList, setPlanList] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [newPlanName, setNewPlanName] = useState("");
  const [newPlanDesc, setNewPlanDesc] = useState("");
  const [isTrial, setIsTrial] = useState(0);
  const [newPlanPrice, setNewPlanPrice] = useState("");
  const [newPlanPeriod, setNewPlanPeriod] = useState("");
  const [newBillCycle, setNewBillCycle] = useState("");
  const [planPriError, setPlanPriError] = useState("");
  const [billCycleErr, setBillCycleErr] = useState("");
  const [planNameError, setPlanNameError] = useState("");
  const [planDesError, setPlanDesError] = useState("");
  const [deletePopupShow, setDeletePopupShow] = useState(false);
  const [id, setId] = useState(null);
  const [editedPlanId, setEditedPlanId] = useState(null);
  const [emptyErr, setemptyErr] = useState(false);
  const handleClose = () => setDeletePopupShow(false);
  const [otpConfirmPopup, setOtpConfirmPopup] = useState(false);
  const toast = useToast();
  const [status, setStatus] = useState(STATUS.default);
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const timerMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const timerSeconds = seconds < 10 ? `0${seconds}` : seconds;
  const intervalRef = useRef();
  const [verificationCode, setVerifcationCode] = useState("");
  const [changeReason, setChangeReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [chgResPopup, setChgResPopup] = useState(false);
  const [trialPeriod, setTrialPeriod] = useState("");
  const [trialPeriodError, setTrialPeriodError] = useState("");
  useEffect(() => {
    loadPlans();
  }, []);
  useEffect(() => {
    if (status === STATUS.start) {
      intervalRef.current = setInterval(() => {
        countDown();
      }, 1000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [minutes, seconds, status]);
  const start = () => {
    setMinutes(5);
    setSeconds(0);
    setStatus(STATUS.start);
  };
  function countDown() {
    if (seconds === 0) {
      if (minutes !== 0) {
        setSeconds(59);
        setMinutes((min) => min - 1);
      } else {
        let mins = 0;
        let sec = 0;
        setSeconds(sec);
        setMinutes(mins);
      }
    } else {
      setSeconds((sec) => sec - 1);
    }
  }
  // const updateTrialPlan = async (id) => {
  //     try {
  //         const response = await window.apiService.updateTrialPlan(id);
  //         if (response.data.status === "OK") {
  //             loadPlans();
  //         }
  //     } catch (error) {
  //         console.error("Error updating trial plan:", error);
  //     }
  // };

  const loadPlans = async () => {
    try {
      const response = await window.apiService.loadPlans();
      const plans = response.data.recordInfo;
      setPlanList(plans);
      console.log("plannlist ", plans);
      // if (plans && plans.length > 0) {
      //     const defaultPlan = plans.find((plan) => plan.isTrial) || plans[0];
      //     setPlanDefault(defaultPlan.id);
      // }
    } catch (error) {
      console.error("Error loading plans:", error);
    }
  };
  // useEffect(() => {
  //     if (planDefault !== null) {
  //         updateTrialPlan(planDefault);
  //     }
  // }, [planDefault]);
  // const handlePlanSelection = (id) => {
  //     setPlanDefault(id);
  // };
  const handleBillCycle = (value) => {
    setNewBillCycle(value);
    setBillCycleErr("");
    if (value == "monthly") setNewPlanPeriod(30);
    else if (value == "yearly") setNewPlanPeriod(365);
  };
  const addPlan = async () => {
    setPlanNameError("");
    setPlanDesError("");
    if (!newPlanName) {
      setPlanNameError("Plan Name is Required.");
      return;
    }
    if (!newPlanDesc) {
      setPlanDesError("Description is Required.");
      return;
    }
    if (!isTrial && planList?.length > 0) {
      if (
        newPlanPrice === null ||
        newPlanPrice === undefined ||
        newPlanPrice.toString().trim() === ""
      ) {
        setPlanPriError("Price is Required.");
        return;
      }

      if (!newBillCycle) {
        setBillCycleErr("Bill Cycle is Required.");
        return;
      }
    }
    // if (!isTrial && planList?.length > 0) {
    //   if (!trialPeriod || parseInt(trialPeriod) <= 0) {
    //     setTrialPeriodError(
    //       "Trial Period is Required and must be greater than 0."
    //     );
    //     return;
    //   }
    // }
    const params = {
      servicePlan: newPlanName,
      description: newPlanDesc,
      planPeriod: +newPlanPeriod,
      price: planList?.length > 0 ? +newPlanPrice : 0,
      billingCycle: planList?.length > 0 ? newBillCycle : "yearly",
      isTrial: planList?.length > 0 ? 0 : 1,
      trialPeriod: planList?.length > 0 ? parseInt(trialPeriod) : null,
      id: editedPlanId,
      changeReason: changeReason,
    };
    try {
      const response = await window.apiService.addServicePlan(params);
      if (
        response.data.status === "OK" &&
        response.data.message === "Service Plan already exists."
      ) {
        toast.info(response.data.message);
      } else {
        toast.success(response.data.message);
        clearData();
        setEditMode(false);
        loadPlans();
        setChangeReason("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const verifyEmailCode = async () => {
    if (verificationCode) {
      try {
        const response = await window.apiService.verifyPlanUpOtp({
          id: props.userId,
          type: "planEdit",
          verificationCode: verificationCode,
        });
        if (response.data.status == "OK") {
          setVerifcationCode("");
          setOtpConfirmPopup(false);
          addPlan();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Error Occured");
      }
    } else {
      setemptyErr(true);
    }
  };
  const clearData = () => {
    setNewPlanName("");
    setNewPlanDesc("");
    setNewPlanPeriod("");
    setNewBillCycle("");
    setNewPlanPrice("");
    setEditedPlanId(null);
    setPlanNameError("");
    setPlanDesError("");
    setBillCycleErr("");
    setPlanPriError("");
    setIsTrial(0);
    setTrialPeriod("");
  };
  const showDeletePopup = (id) => {
    setId(id);
    setDeletePopupShow(true);
  };
  const sendPlanUpOtp = async () => {
    setChgResPopup(false);
    setLoading(true);
    try {
      const response = await window.apiService.sendPlanUpOtp({
        id: props.userId,
        type: "planEdit",
      });
      if (response.data.status === "OK") {
        toast.success(response.data.message);
        setOtpConfirmPopup(true);
        start();
        setLoading(false);
      } else {
        toast.success(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const deleteServicePlan = async () => {
    if (id) {
      try {
        const response = await window.apiService.deleteServicePlan(id);
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          loadPlans();
          setDeletePopupShow(false);
        }
      } catch (error) {
        console.log(error);
        setDeletePopupShow(false);
      }
    }
  };

  return (
    <>
      {/* <HeaderMenu />
            <SideBar />
            <div className="main-content ml_200">
                <BreadCrumb /> */}
      <div className="chat container h-100">
        <Card className="shade h-100">
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <div className="role_container">
                <Row className="m_b18 title_txt">
                  <Col sm={12} md={12} lg={12} xl={12}>
                    Manage Service Plans
                  </Col>
                </Row>
                <Row className="m_b8">
                  <Col
                    sm={6}
                    md={2}
                    lg={2}
                    xl={2}
                    className="custom-col pl-2 pr-2"
                  >
                    <Input
                      className="inptRole rle_place"
                      placeholder={
                        editMode ? "Edit Plan Name" : "Enter a new Plan Name"
                      }
                      type="text"
                      value={newPlanName}
                      maxLength={150}
                      onChange={(e) => {
                        setNewPlanName(e.target.value);
                        setPlanNameError("");
                      }}
                    />
                    {planNameError && (
                      <div className="text-danger fz_13">{planNameError}</div>
                    )}
                  </Col>
                  {planList && planList.length > 0 && !isTrial ? (
                    <Col
                      sm={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="custom-col pl-2 pr-2"
                    >
                      <select
                        className="select_clr form-control input_select fz_14"
                        value={newBillCycle}
                        onChange={(e) => handleBillCycle(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                      </select>

                      {billCycleErr && (
                        <div className="text-danger fz_13">{billCycleErr}</div>
                      )}
                    </Col>
                  ) : (
                    ""
                  )}

                  {planList && planList.length > 0 && !isTrial ? (
                    <Col
                      sm={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="custom-col pl-2 pr-2"
                    >
                      <Input
                        onKeyPress={(e) => {
                          if (
                            !/[0-9.]/.test(e.key) ||
                            (e.key === "." && e.target.value.includes("."))
                          ) {
                            e.preventDefault();
                          }
                        }}
                        className="inptRole rle_place"
                        placeholder="Price"
                        type="text"
                        value={newPlanPrice}
                        onChange={(e) => {
                          setNewPlanPrice(e.target.value);
                          setPlanPriError("");
                        }}
                      />
                      {planPriError && (
                        <div className="text-danger fz_13">{planPriError}</div>
                      )}
                    </Col>
                  ) : (
                    ""
                  )}
                  {planList && planList.length > 0 && !isTrial ? (
                    <Col
                      sm={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="custom-col pl-2 pr-2"
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {/* Trial Period Input */}
                        <Input
                          className="inptRole rle_place"
                          placeholder="Trial Period (days)"
                          type="text"
                          value={trialPeriod}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault(); // Allow only numbers
                            }
                          }}
                          onChange={(e) => {
                            setTrialPeriod(e.target.value); // Update `trialPeriod` state
                            // setTrialPeriodError(""); // Clear error message
                          }}
                          style={{ marginRight: "10px" }} // Add space between input and checkbox
                        />
                        {/* Checkbox */}
                        {/* <label style={{ display: "flex", alignItems: "center" }}>
                                                <input
                                                    type="checkbox"
                                                    checked={isTrial === 1}

                                                    onChange={(e) => {
                                                        const isChecked = e.target.checked;
                                                        setIsTrial(isChecked ? 1 : 0);
                                                        console.log("isTrial:", isChecked ? 1 : 0);
                                                    }}
                                                    style={{ marginLeft: "5px" }} // Slight spacing to the left of checkbox
                                                />
                                            </label> */}
                      </div>

                      {/* Error Message */}
                      {trialPeriodError && (
                        <div className="text-danger fz_13">
                          {trialPeriodError}
                        </div>
                      )}
                    </Col>
                  ) : (
                    ""
                  )}

                  <Col
                    sm={6}
                    md={2}
                    lg={2}
                    xl={2}
                    className="custom-col pl-2 pr-2"
                  >
                    <Input
                      className="inptRole rle_place"
                      placeholder="Enter Description"
                      type="text"
                      maxLength={500}
                      value={newPlanDesc}
                      onChange={(e) => {
                        setNewPlanDesc(e.target.value);
                        setPlanDesError("");
                      }}
                    />
                    {planDesError && (
                      <div className="text-danger fz_13">{planDesError}</div>
                    )}
                  </Col>
                  {/* <Col sm={6} md={1} lg={1} xl={1} className="d-flex align-items-center pl-2 pr-0">
                                        <input title="Set default"
                                            type="checkbox"
                                            checked={planDefault}
                                            onChange={(event) => handleCheckboxChange(event)}
                                            style={{ marginRight: "12px" }}
                                        />
                                    </Col> */}
                  <Col
                    sm={6}
                    md={1}
                    lg={1}
                    xl={1}
                    className="align-items-center d-flex pt-1 pl-0"
                  >
                    {editMode ? (
                      <>
                        <TiTick
                          className="c_pointer plus_icn m_r15"
                          title="Update"
                          onClick={() => setChgResPopup(true)}
                        />
                        <FaTimes
                          title="Cancel"
                          className="c_pointer close_icn"
                          onClick={() => {
                            setEditMode(false);
                            clearData();
                          }}
                        />
                      </>
                    ) : (
                      <BsPlusLg
                        onClick={() => addPlan()}
                        className="c_pointer"
                        title="Add"
                      />
                    )}
                  </Col>
                </Row>
                <Row className="m_b8">
                  <Table
                    className="align-items-center table-flush brd_solid"
                    responsive
                  >
                    <thead className="thead-clr">
                      <tr>
                        <th scope="col">Plan</th>
                        <th scope="col">Billing Cycle</th>
                        <th scope="col">Price</th>
                        <th scope="col">Trial Period</th>
                        <th scope="col">Description</th>
                        <th scope="col">Action</th>
                        {/* <th scope="col">Trial</th> */}
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {planList && planList.length > 0 ? (
                        planList.map((plan, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                {plan.servicePlan.length > 13
                                  ? plan.servicePlan
                                      .substring(0, 13)
                                      .concat("...")
                                  : plan.servicePlan}
                              </td>
                              <td>{plan.billingCycle}</td>
                              <td>
                                {plan.price == 0 ? "-" : "$ " + plan.price}
                              </td>
                              <td>
                                {plan.trialPeriod
                                  ? `${plan.trialPeriod} days`
                                  : "-"}
                              </td>
                              <td>
                                {plan.description.length > 13
                                  ? plan.description
                                      .substring(0, 13)
                                      .concat("...")
                                  : plan.description}
                              </td>
                              {/* <td><input className="c_pointer"
                                                                title="Trial"
                                                                type="radio"
                                                                name="trialPlan"
                                                                checked={planDefault === plan.id}
                                                                onChange={() => handlePlanSelection(plan.id)}
                                                            /></td> */}
                              <td>
                                <div className="d-flex">
                                  {/* Edit Icon */}
                                  <img
                                    src={editRole}
                                    alt="Edit"
                                    className="mr-2 c_pointer"
                                    onClick={() => {
                                      setEditMode(true);
                                      setNewPlanName(plan.servicePlan);
                                      setNewPlanDesc(plan.description);
                                      setNewPlanPeriod(plan.planPeriod);
                                      setNewBillCycle(plan.billingCycle);
                                      setNewPlanPrice(plan.price);
                                      setEditedPlanId(plan.id);
                                      setIsTrial(plan.isTrial);
                                      setPlanNameError("");
                                      setPlanDesError("");
                                    }}
                                  />

                                  {/* Conditional Delete Icon */}
                                  {!plan.isTrial && (
                                    <HiOutlineTrash
                                      onClick={() => showDeletePopup(plan.id)}
                                      title="Delete"
                                      className="ques_trash c_pointer"
                                    />
                                  )}
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">
                            No Service Plans Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
      {/* </div> */}
      {/* ----------- Delete Confirmation Popup ---------- */}
      <Modal
        size="sm"
        show={deletePopupShow}
        onHide={() => setDeletePopupShow(false)}
      >
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            Delete Confirmation
          </Modal.Title>
          <span onClick={handleClose} title="Close">
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="bordr">
          <span>Are you sure you want to delete?</span>
        </Modal.Body>
        <Modal.Footer className="bordr">
          <commonUtils.CustomButton
            btnText="Yes"
            btnWidth="fit-content"
            variant="primary"
            perform={() => deleteServicePlan()}
            isDisabled={false}
          />
          <commonUtils.CustomButton
            btnText="No"
            btnWidth="fit-content"
            variant="danger"
            perform={() => setDeletePopupShow(false)}
            isDisabled={false}
          />
        </Modal.Footer>
      </Modal>
      <CustomModal
        show={chgResPopup}
        handleClose={() => {
          setChgResPopup(false);
          setChangeReason("");
        }}
        // performSubmit={() => deleteServiceFeature()}
        title="Reason for Change"
        post={true}
        body={
          <>
            <div className="input-group code_Input">
              <input
                className="form-control py-2 border border-top-0 fz_13"
                placeholder="Enter the reason"
                onChange={(e) => setChangeReason(e.target.value)}
                value={changeReason}
              />
            </div>
            <commonUtils.CustomButton
              btnText="Submit"
              btnWidth="fit-content"
              variant="primary"
              perform={() => sendPlanUpOtp()}
              isDisabled={!changeReason.trim()}
            />
          </>
        }
      />
      <CustomModal
        show={otpConfirmPopup}
        handleClose={() => setOtpConfirmPopup(false)}
        // performSubmit={() => deleteServiceFeature()}
        title="OTP Verification"
        post={true}
        body={
          <>
            <div className="fz_11">
              Enter the OTP send to mail ({props.email})
            </div>
            <div className="input-group code_Input">
              <input
                className="form-control py-2 border border-top-0 fz_13"
                placeholder="Enter OTP"
                onChange={(e) => setVerifcationCode(e.target.value)}
                value={verificationCode}
                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              />
            </div>
            {emptyErr ? <span className="fz-13">Enter OTP</span> : ""}
            <div className="d-flex justify-content-between mb-2">
              <div>
                <span className="fz-12">
                  Didn't receive an OTP?&nbsp;
                  <a href="#" classsName="a_clr fz-12" onClick={sendPlanUpOtp}>
                    Resend Code
                  </a>
                </span>
              </div>
              {timerMinutes === "00" && timerSeconds === "00" ? (
                ""
              ) : (
                <div className="float-right fz_13">
                  {timerMinutes === "00" ? (
                    <span className="">
                      {timerMinutes}:{timerSeconds}
                    </span>
                  ) : (
                    <span>
                      {timerMinutes}:{timerSeconds}
                    </span>
                  )}
                </div>
              )}
              {timerMinutes === "00" && timerSeconds === "00" ? (
                <div className="float-right text-warning fz-12">
                  Code Expired
                </div>
              ) : (
                ""
              )}
            </div>
            <commonUtils.CustomButton
              btnText="Verify"
              btnWidth="fit-content"
              variant="primary"
              perform={() => verifyEmailCode()}
              isDisabled={false}
            />
          </>
        }
      />
      {loading ? (
        <div className="pro-loader-center">
          <img className="loader-image" src={loader} alt="Loading..." />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    email: state.user.email,
  };
};
export default connect(mapStateToProps)(ServicePlan);
