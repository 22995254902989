import { useState, useEffect, useRef } from "react";
import { Table } from "reactstrap";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { IoIosArrowDown } from "react-icons/io";
import loader from "../../assets/images/loader.gif";
import { useForm } from "react-hook-form";
import PhoneControl from "../CustomPhone/PhoneControl";
import { useToast } from "../Toast";
import { IoClose } from "react-icons/io5";
import { FiSearch } from "react-icons/fi";
import profile from "../../assets/images/profile.jpg";
import { FaUserPlus, FaUserCheck, FaUser } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { followId } from "../../Redux/Actions/userAction";
import { useNavigate } from "react-router-dom";
import StarRating from "../Dashboard/DashboardTabs/StarRating";
import { Overlay, Popover, OverlayTrigger } from "react-bootstrap";
import Horizontalbar from "../plugins/Horizontalbar";
import { commonUtils } from "../CommonUtils";
import { FiUsers, FiUser } from "react-icons/fi";
import { HiOutlineTrash } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
const BusinessUser = (props) => {
  const PhRef = useRef();
  const toast = useToast();
  const [phone, setPhone] = useState("");
  const [phoneValidation, setPhoneValidation] = useState("");
  const [businessList, setbusinessList] = useState("");
  const [roleList, setRoleList] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [businessUserList, setbusinessUserList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(
    props.defaultCmpy || ""
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUserList, setFilteredUserList] = useState();
  const [filterData, setFilterData] = useState([]);
  // const [showInvite, setShowInvite] = useState(false);
  const [filter, setfilter] = useState(false);
  const [cmpyParentId, setCmpyParentId] = useState("");
  const [avgCount, setAvgCount] = useState("");
  const [revGlobalRtng, setRevGlobalRtng] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [busUsr, setBusUsr] = useState([]);
  const [deletePopupShow, setDeletePopupShow] = useState(false);
  const [checkShow, setCheckShow] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const isHuspotConnect = useSelector((state) => state.user.isHuspotConnect);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm();
  useEffect(() => {
    loadBusnRoleList();
    loadBusinessList(props.userId);
    loadBusinessUserList(props.defaultCmpy);
  }, [props.userId, props.defaultCmpy]);
  useEffect(() => {
    if (props.defaultCmpy !== null) {
      setSelectedCompany(props.defaultCmpy);
      setValue("businessId", props.defaultCmpy.toString());
    }
  }, [props.defaultCmpy, setValue]);
  useEffect(() => {
    if (selectedCompany !== null && businessUserList.length > 0) {
      setFilteredUserList(
        businessUserList.filter(
          (user) =>
            user.businessId === +selectedCompany && user.isDeleted != "1"
        )
      );
      getUserRoleId();
      const filteredUsers = businessUserList.filter(
        (user) => user.businessId === +selectedCompany
      );
      const adminUser = filteredUsers.find((user) => user.roleId === 1);

      if (adminUser) {
        setCmpyParentId(adminUser.userId);
      }
    }
  }, [selectedCompany, businessUserList]);
  useEffect(() => {
    if (searchTerm == "") {
      setFilterData([]);
    }
    if (props.activeKey == "Business_details") {
      setSearchTerm("");
    }
  }, [searchTerm, props.activeKey]);
  const getUserRoleId = () => {
    // console.log("sd", filteredUserList, props.userId);
    if (filteredUserList) {
      const user = filteredUserList.find(
        (user) => user.userId === props.userId
      );
      const roleId = user ? user.roleId : null;
      return roleId;
    } else return null;
  };
  const loadBusinessList = async (id) => {
    try {
      const response = await window.apiService.inviteUserBusnList(id);
      let result = response.data.data;
      if (result) {
        setbusinessList(result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handlePush = async () => {
    const formattedContacts = selectedUsers.map((user) => ({
      id: user.id.toString(),
      properties: {
        userId: user.userId,
        email: user.email,
        firstname: user.firstName,
        lastname: user.lastName,
        phone: user.phone,
        compositeScore: user.averageReviewScore,
        location: "",
        website: process.env.REACT_APP_URL + "/dashboard/profile",
      },
    }));
    const payload = { inputs: formattedContacts };
    try {
      setLoading(true);
      const result = await window.apiService.pushHubspotBulkData(payload);
      const response = result.data;
      setLoading(false);
      if (result.status === 200) {
        toast.success(response.message);
        setSelectedUsers([]);
        setCheckShow(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  const renderRoleContent = (user) => {
    if (
      (((getUserRoleId() === 2 &&
        user.userId !== props.userId &&
        user.roleId === 2) ||
        user.roleId === 3 ||
        getUserRoleId() === 1) &&
        user.roleId !== 1) ||
      (getUserRoleId() === 4 &&
        user.userId !== props.userId &&
        user.roleId !== 4 &&
        user.roleId !== 3 &&
        user.roleId !== 1 &&
        user.roleId !== 2) ||
      (getUserRoleId() === 3 &&
        user.roleId !== 1 &&
        user.roleId !== 2 &&
        user.roleId !== 3 &&
        user.roleId !== 4)
    ) {
      return (
        <select
          className="form-control input_ctrl input_select"
          value={user.roleId}
          onChange={(e) => updateUserAccess(user.id, null, e.target.value)}
        >
          {roleList &&
            roleList.map((role) => {
              const isOptionVisible =
                ((getUserRoleId() === 2 || getUserRoleId() === 1) &&
                  role.id !== 1) ||
                (getUserRoleId() === 4 && role.id !== 1 && role.id !== 2) ||
                (getUserRoleId() === 3 &&
                  role.id !== 1 &&
                  role.id !== 2 &&
                  role.id !== 4);

              return isOptionVisible ? (
                <option key={role.id} value={role.id}>
                  {role.roleName}
                </option>
              ) : null;
            })}
        </select>
      );
    } else {
      return filterRoleName(user.roleId);
    }
  };
  const addBusinessUser = async (user) => {
    setIsLoading(true);
    if (props.defaultCmpy != null) {
      const updatedData = {
        businessId: props.defaultCmpy,
        roleId: 3,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        inviterId: props.userId,
        businessInviterId: props.userId,
        isChat: 0,
        isReview: 0,
        activated: 1,
        isAddUser: true,
        userId: user.id,
      };
      try {
        const response = await window.apiService.addBusinessInviteUser(
          updatedData
        );
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          loadBusinessUserList(props.defaultCmpy);
          handleSearch(searchTerm);
          setIsLoading(false);
          setSearchTerm("");
        }
      } catch (error) {
        setIsLoading(false);
        toast.error("Error Occured");
      }
    }
  };
  const unformatphone = (value) => {
    if (value != "" && value.trim().startsWith("+")) {
      let phoneData = value.trim().split(" ");
      if (phoneData[1] != "" && phoneData[1] != undefined) {
        let phone_no = phoneData[1].replace(/[()-]/g, "");
        return phone_no;
      } else {
        return phoneData;
      }
    } else {
      if (value != "" && value != undefined) {
        let phone_no = value.replace(/[()-]/g, "");
        return phone_no;
      } else return value;
    }
  };
  const handleChange = (event) => {
    let unformatData = unformatphone(event.target.value);
    setSearchTerm(event.target.value);
    handleSearch(unformatData);
  };

  const handleKeyPress = (event) => {
    let unformatData = unformatphone(event.target.value);
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch(unformatData);
    }
  };
  const latestRequestTimeRef = useRef(0);
  useEffect(() => {
    if (searchTerm !== "") {
      debouncedUserSearch(searchTerm);
    } else {
      setFilterData([]);
    }
  }, [searchTerm]);

  const debouncedUserSearch = debounce(async (searchTerm) => {
    await handleSearch(searchTerm);
  }, 300);
  const handleSearch = async (searchterm) => {
    const requestTime = Date.now();
    latestRequestTimeRef.current = requestTime;
    try {
      let formData = new FormData();
      formData.append("keyword", searchterm);
      formData.append("businessId", props.defaultCmpy.toString());
      setLoading(true);
      const response = await window.apiService.searchUser(formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (requestTime === latestRequestTimeRef.current) {
        setFilterData(response.data.data);
        setfilter(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const filterRoleName = (id) => {
    return roleList.filter((role) => role.id == id)[0]?.roleName;
  };
  const updateUserAccess = async (id, access, roleID) => {
    let params;
    if (roleID != null && roleID != "") {
      params = {
        roleId: roleID,
      };
    } else {
      params = {
        isChat: access.isChat,
        isReview: access.isReview,
      };
    }
    try {
      const response = await window.apiService.busnUserUpdate(id, params);
      if (response.data.status == "OK") {
        toast.success(response.data.message);
        loadBusinessUserList(props.defaultCmpy);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const loadBusinessUserList = async (id) => {
    if (id) {
      try {
        const response = await window.apiService.busnUserList(id);
        let result = response.data.data;
        if (result) {
          setbusinessUserList(result);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onSubmit = async (data) => {
    if (!phoneValidation) {
      setIsLoading(true);
      const updatedData = {
        ...data,
        businessId: +data.businessId,
        phone: phone,
        userId: props.userId,
        inviterId: props.userId,
        isChat: data.access == 0 ? 1 : 0,
        isReview: data.access == 1 ? 1 : 0,
        activated: 1,
      };
      try {
        const response = await window.apiService.addBusinessInviteUser(
          updatedData
        );
        setIsLoading(false);
        if (
          response.data.status == "OK" &&
          response.data.message == "Invitation sent successfully."
        ) {
          toast.success(response.data.message);
          loadBusinessUserList(props.defaultCmpy);
          reset();
          PhRef.current.clrField();
          setPhone("");
        } else if (
          response.data.status == "OK" &&
          response.data.message == "User already exists."
        ) {
          toast.error(response.data.message);
        }
      } catch (error) {
        setIsLoading(false);
        toast.error("Error Occured");
      }
    }
  };
  const phoneChange = (value) => {
    const result = value.indexOf(" ");
    const ph = value.substring(result + 1);
    if (value != "" && ph.length === 14) {
      setPhoneValidation("");
    } else {
      setPhoneValidation("Phone No is required");
    }
    setPhone(value);
  };
  const phoneValidate = () => {
    const result = phone.indexOf(" ");
    const ph = phone.substring(result + 1);
    if (phone != "" && ph.length === 14) {
      setPhoneValidation("");
    } else {
      setPhoneValidation("Phone No is required");
    }
  };
  const handleCheckboxChange = (e, userId) => {
    const { name, checked } = e.target;
    const currentUser = filteredUserList.find((user) => user.id === userId);

    if (!currentUser) {
      console.error(`User with ID ${userId} not found.`);
      return;
    }
    let newIsChat = currentUser.isChat;
    let newIsReview = currentUser.isReview;
    if (name === "chat") {
      newIsChat = checked ? 1 : 0;
    } else if (name === "review") {
      newIsReview = checked ? 1 : 0;
    }
    updateUserAccess(
      userId,
      { isChat: newIsChat, isReview: newIsReview },
      null
    );
  };

  const loadBusnRoleList = async () => {
    try {
      const response = await window.apiService.busnUserRoleList();
      let result = response.data.data;
      if (result) {
        setRoleList(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleArrowClick = (rating, avgCount) => {
    setAvgCount(avgCount);
    const starResponse = rating;
    const result = starResponse.map((starData) => ({
      label: starData.star,
      value: starData.totalReviewCount,
      userRatings: starData.userRatings,
    }));
    result.sort((a, b) => {
      const numA = parseInt(a.label.split(" ")[0]);
      const numB = parseInt(b.label.split(" ")[0]);
      return numB - numA;
    });
    setRevGlobalRtng(result);
  };
  const handleMouseEnter = (users) => {
    if (users.length > 0) setCurrentUsers(users);
    else setCurrentUsers([]);
  };
  const handleClose = () => setDeletePopupShow(false);
  const showDeletePopup = (user) => {
    setBusUsr(user);
    setDeletePopupShow(true);
  };
  const removeBsnUsr = async () => {
    if (busUsr) {
      const params = {
        userId: busUsr.userId,
        roleId: busUsr.roleId,
        businessId: busUsr.businessId,
        email: busUsr.email,
      };

      try {
        const response = await window.apiService.removeBusnUsr(params);
        if (response.data.status == "OK") {
          setDeletePopupShow(false);
          loadBusinessUserList(props.defaultCmpy);
          toast.success(response.data.message);
        }
      } catch (error) {
        console.log(error);
        setDeletePopupShow(false);
      }
    }
  };
  const handleCheckChange = (contact) => {
    console.log("contact", contact);
    setSelectedUsers((prev) => {
      const exists = prev.some((c) => c.id === contact.id);
      return exists
        ? prev.filter((c) => c.id !== contact.id)
        : [...prev, contact];
    });
  };

  return (
    <>
      <div className="profile_div rounded-0">
        <Container className="pb-0" fluid>
          <Form onSubmit={handleSubmit(onSubmit, phoneValidate)}>
            <Row>
              <Col lg={4} md={4} sm={4}>
                <h6>Invite / Add Users</h6>
                <p className="mb-0 fz_14 font-weight-400">
                  Enter the details of the user you want to invite.
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={3} md={3} sm={3} className="text-center mt-2">
                <Form.Group className="form-group">
                  <select
                    value={selectedCompany}
                    {...register("businessId", {
                      required: true,
                      validate: (value) => {
                        return !!value.trim();
                      },
                    })}
                    onChange={(e) => props.onSelectCompany(e.target.value)}
                    className={
                      errors?.businessId?.type === "required" ||
                      errors?.businessId?.type === "validate"
                        ? "form-control input_ctrl input_select h_40 error"
                        : "form-control input_ctrl input_select h_40"
                    }
                  >
                    <option id="" value="">
                      Select
                    </option>
                    {businessList &&
                      businessList.map((business) =>
                        business.isVerified === 1 &&
                        (business.roleId == 1 || business.roleId == 2) ? (
                          <option value={business.id}>
                            {business.companyName}
                          </option>
                        ) : null
                      )}
                  </select>
                </Form.Group>
              </Col>

              <Col lg={4} md={4} sm={4} className="p-0">
                <div class="input-group hr_search rounded-0 m-2">
                  <input
                    class="form-control py-2 border-right-0 border-top-0 rounded-0 border"
                    placeholder="Search for name, number, or email..."
                    value={searchTerm}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    id="usr_srch_input"
                  />
                  {searchTerm != "" && (
                    <span class="input-group-append c_pointer">
                      <div class="input-group-text bg-white border-top-0 rounded-0">
                        <IoClose
                          className="search_icon"
                          onClick={() => setSearchTerm("")}
                        />
                      </div>
                    </span>
                  )}
                  <span class="input-group-append c_pointer">
                    <div class="input-group-text bg-transparent border-top-0 rounded-0">
                      <FiSearch
                        className="search_icon"
                        onClick={() => handleSearch(searchTerm)}
                      />
                    </div>
                  </span>
                </div>
              </Col>
              {/* {filterData.length == 0 && filter && searchTerm && (
                <Col lg={2} md={2} sm={2} className="p-0 mt-2">
                  <div className="d-flex align-items-center">
                    <Button
                      className="btn btn-default"
                      title="Follow"
                      onClick={() => setShowInvite(true)}
                    >
                      Invite
                    </Button>
                  </div>
                </Col>
              )} */}
            </Row>
            <Row>
              {Loading && searchTerm ? (
                <div className="pro-loader-center">
                  <img className="loader-image" src={loader} alt="Loading..." />
                </div>
              ) : (
                ""
              )}
              {(filterData && filterData.length > 0 && searchTerm) ||
              (filter && searchTerm) ? (
                <Card className="m-2 pt-2 pb-2 pr-0 pl-0">
                  <div className="d-flex">
                    <Col lg={6} md={12} sm={12} className="p-0">
                      {filterData && filterData.length > 0 && !Loading ? (
                        <Card className="m-2 me_revList">
                          {filterData && filterData.length > 0
                            ? filterData.map((data, i) => (
                                <Card className="rounded-0 px-2 py-2">
                                  <div className="d-flex align-items-center">
                                    <Col lg={2}>
                                      <img
                                        className="brd_rad"
                                        width="40"
                                        height="40"
                                        src={
                                          data.imagePath
                                            ? data.imagePath
                                            : profile
                                        }
                                      />
                                    </Col>
                                    <Col lg={8}>
                                      <div className="d-flex">
                                        <p className="mb-0 font-weight-400 fz_15">
                                          {data.name}
                                        </p>
                                      </div>
                                      <div className="d-flex">
                                        <p
                                          className="mb-0 font-weight-400 fz-12"
                                          title={data.email}
                                        >
                                          {/* {data.email.length > 27
                                          ? data.email.substring(0, 27) + "..."
                                          : data.email} */}
                                          {data.email}
                                        </p>
                                      </div>
                                    </Col>
                                    <Col lg={2}>
                                      {data.isAdded ? (
                                        <FaUserCheck
                                          className="fz-22 text-green"
                                          title="Added"
                                        />
                                      ) : (
                                        <FaUserPlus
                                          className="fz-22 text-blue c_pointer"
                                          title="Add as user"
                                          onClick={() => addBusinessUser(data)}
                                        />
                                      )}
                                    </Col>
                                  </div>
                                </Card>
                              ))
                            : ""}
                        </Card>
                      ) : (
                        ""
                      )}
                    </Col>
                  </div>

                  {filterData.length == 0 &&
                    filter &&
                    searchTerm &&
                    !Loading && (
                      <div className="d-flex justify-content-center">
                        <Col lg={2} md={2} sm={2}>
                          <Form.Group className="form-group">
                            <Form.Label className="mb-1 profile_label">
                              First Name
                            </Form.Label>
                            <Form.Control
                              onKeyPress={(e) => {
                                if (/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              type="text"
                              {...register("firstName", {
                                required: true,
                                validate: (value) => {
                                  return !!value.trim();
                                },
                              })}
                              className={
                                errors?.firstName?.type === "required" ||
                                errors?.firstName?.type === "validate"
                                  ? "form-control input_ctrl error"
                                  : "form-control input_ctrl"
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={2} md={2} sm={2}>
                          <Form.Group className="form-group">
                            <Form.Label className="mb-1 profile_label">
                              Last Name
                            </Form.Label>
                            <Form.Control
                              onKeyPress={(e) => {
                                if (/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              type="text"
                              {...register("lastName", {
                                required: true,
                                validate: (value) => {
                                  return !!value.trim();
                                },
                              })}
                              className={
                                errors?.lastName?.type === "required" ||
                                errors?.lastName?.type === "validate"
                                  ? "form-control input_ctrl error"
                                  : "form-control input_ctrl"
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={2} md={2} sm={2}>
                          <Form.Group className="form-group">
                            <Form.Label className="mb-1 profile_label">
                              Email Address
                            </Form.Label>
                            <Form.Control
                              type="text"
                              {...register("email", {
                                required: true,
                                validate: (value) => {
                                  return !!value.trim();
                                },
                                pattern: {
                                  value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: "Invalid email address",
                                },
                              })}
                              className={
                                errors?.email?.type === "required" ||
                                errors?.email?.type === "validate"
                                  ? "form-control input_ctrl error"
                                  : "form-control input_ctrl"
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={2} md={2} sm={2}>
                          <Form.Group className="form-group">
                            <PhoneControl
                              ref={PhRef}
                              phoneNo={phone}
                              isProfile={false}
                              unmounted={true}
                              mandatory={true}
                              onPhoneChange={phoneChange}
                              phoneValidation={phoneValidation}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={2} md={2} sm={2}>
                          <Form.Group className="form-group">
                            <Form.Label className="mb-1 profile_label">
                              User Role
                            </Form.Label>
                            <select
                              {...register("roleId", {
                                required: true,
                                validate: (value) => !!value.trim(),
                              })}
                              className={`form-control input_ctrl input_select ${
                                errors?.role?.type === "required" ||
                                errors?.role?.type === "validate"
                                  ? "error"
                                  : ""
                              }`}
                            >
                              <option value="">Select</option>
                              {roleList &&
                                roleList.map((role) => {
                                  const isOptionVisible =
                                    ((getUserRoleId() === 2 ||
                                      getUserRoleId() === 1) &&
                                      role.id !== 1) ||
                                    (getUserRoleId() === 4 &&
                                      role.id !== 4 &&
                                      role.id !== 1 &&
                                      role.id !== 2) ||
                                    (getUserRoleId() === 3 &&
                                      role.id !== 1 &&
                                      role.id !== 2 &&
                                      role.id !== 4);

                                  return isOptionVisible ? (
                                    <option key={role.id} value={role.id}>
                                      {role.roleName}
                                    </option>
                                  ) : null;
                                })}
                            </select>
                          </Form.Group>
                        </Col>
                        <Col lg={2} md={2} sm={2}>
                          <div className="d-flex justify-content-center mt-3">
                            <Button
                              disabled={!isValid || phoneValidation || !phone}
                              type="submit"
                              className="btn btn-default"
                              variant="primary"
                            >
                              Invite Users{" "}
                              {isLoading && (
                                <img
                                  className="btn-loader-image"
                                  src={loader}
                                  alt="Loading..."
                                />
                              )}
                            </Button>
                          </div>
                        </Col>
                      </div>
                    )}
                </Card>
              ) : (
                ""
              )}
              <div className="d-flex justify-content-end">
                {selectedUsers.length > 0 ? (
                  <Button onClick={() => handlePush()}>Push</Button>
                ) : !isHuspotConnect ? (
                  <Button onClick={() => navigate("/dashboard/Hubspot")}>
                    Sync with HubSpot
                  </Button>
                ) : (
                  <Button onClick={() => setCheckShow(!checkShow)}>
                    Sync with HubSpot
                  </Button>
                )}
              </div>
              {filteredUserList && filteredUserList.length > 0 && (
                <Table
                  id="user_table"
                  className="align-items-center table-flush brd_solid mt-3"
                  responsive
                >
                  <thead className="thead-clr">
                    <tr>
                      <th scope="col">Sl.No</th>
                      <th scope="col">First Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">Phone No</th>
                      <th scope="col">Email id</th>
                      <th scope="col">User Role</th>
                      <th scope="col">Action</th>
                      {/* <th scope="col">Business</th> */}
                      {/* <th scope="col">Allow Access</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUserList &&
                      filteredUserList.map((user, index) => (
                        <tr
                        // className={user.userId === props.userId ? "App" : ""}
                        >
                          <th>{index + 1}</th>
                          <th scope="col">
                            <div className="d-flex align-items-center">
                              <FaUser
                                className="clr_darkblue c_pointer"
                                onClick={() => {
                                  dispatch(
                                    followId(user.userId, "Personal", null)
                                  );
                                  navigate("/dashboard/viewuser", {
                                    state: {
                                      userId: user.userId,
                                      userType: "Personal",
                                      profileId: null,
                                    },
                                  });
                                }}
                              />
                              &nbsp;
                              <span className="mrt_5">{user.firstName}</span>
                              {user.averageReviewScore > 0.0 ? (
                                <span className="font-weight-bold total_fnt">
                                  {user.averageReviewScore.toFixed(1)}
                                </span>
                              ) : (
                                ""
                              )}
                              <StarRating
                                rating={user.averageReviewScore}
                                disabled={true}
                                customClass="ques-star"
                              />
                              <span>({user.totalReviewCount})</span>
                              <OverlayTrigger
                                trigger="click"
                                key={user.id}
                                placement="bottom"
                                overlay={
                                  <Popover
                                    id={`popover-${user.id}`}
                                    className="pop_rating"
                                  >
                                    <div className="drp_rating">
                                      <StarRating
                                        rating={avgCount}
                                        disabled={true}
                                      />
                                      <span>{avgCount} out of 5</span>{" "}
                                    </div>
                                    {revGlobalRtng.map((item, index) => (
                                      <Row key={index}>
                                        <Col
                                          sm={12}
                                          md={3}
                                          lg={3}
                                          xl={2}
                                          className="p-0"
                                        >
                                          <span className="prog_span">
                                            {item.label}
                                          </span>{" "}
                                        </Col>
                                        <Col
                                          sm={12}
                                          md={8}
                                          lg={8}
                                          xl={7}
                                          className="p-0"
                                        >
                                          <Horizontalbar dataSet={item.value} />
                                        </Col>
                                        <Col
                                          sm={12}
                                          md={3}
                                          lg={3}
                                          xl={2}
                                          className="p-0 prog_span"
                                          style={{ marginLeft: "10px" }}
                                        >
                                          <span className="tooltip">
                                            <span
                                              className="c_pointer"
                                              onMouseEnter={() =>
                                                handleMouseEnter(
                                                  item.userRatings
                                                )
                                              }
                                            >
                                              {item.value}
                                              {item.value > 1 ? (
                                                <FiUsers
                                                  style={{ marginLeft: "6px" }}
                                                />
                                              ) : (
                                                <FiUser
                                                  style={{ marginLeft: "6px" }}
                                                />
                                              )}
                                            </span>
                                            {commonUtils.ratingTooltip(
                                              currentUsers
                                            )}
                                          </span>
                                        </Col>
                                      </Row>
                                    ))}
                                  </Popover>
                                }
                                rootClose
                              >
                                <span
                                  className="d-flex align-items-center c_pointer"
                                  onClick={() =>
                                    handleArrowClick(
                                      user.starRating,
                                      user.averageReviewCount
                                    )
                                  }
                                >
                                  {user.totalReviewCount != 0 ? (
                                    <IoIosArrowDown className="clr_gray" />
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </OverlayTrigger>
                            </div>
                          </th>
                          <th scope="col">{user.lastName}</th>
                          <th scope="col">{user.phone}</th>
                          <th scope="col" title={user.email}>
                            {user.email.length > 13
                              ? user.email.substring(0, 13).concat("...")
                              : user.email}
                          </th>
                          <th scope="col">
                            {roleList && roleList.length > 0
                              ? renderRoleContent(user)
                              : ""}
                          </th>
                          <th scope="col" className="text-center">
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ gap: "10px" }}
                            >
                              {user.roleId !== 1 &&
                                (user.inviterId === props.userId ||
                                  cmpyParentId === props.userId) && (
                                  <HiOutlineTrash
                                    onClick={() => showDeletePopup(user)}
                                    title="Remove User"
                                    className="ques_trash c_pointer"
                                  />
                                )}
                              {checkShow &&
                                user.roleId !== 1 &&
                                cmpyParentId === props.userId && (
                                  <input
                                    className="c_pointer"
                                    title="Mark as product/service"
                                    type="checkbox"
                                    checked={selectedUsers.some(
                                      (c) => c.id === user.id
                                    )}
                                    onChange={() => handleCheckChange(user)}
                                  />
                                )}
                            </div>
                          </th>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
            </Row>
          </Form>
        </Container>
      </div>
      {/* ----------- Delete Business User Confirmation Popup ---------- */}
      <Modal
        size="sm"
        show={deletePopupShow}
        onHide={() => setDeletePopupShow(false)}
      >
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            Delete Confirmation
          </Modal.Title>
          <span onClick={handleClose} title="Close">
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="bordr">
          <span>Are you sure to remove an user?</span>
        </Modal.Body>
        <Modal.Footer className="bordr">
          <commonUtils.CustomButton
            btnText="Yes"
            btnWidth="fit-content"
            variant="primary"
            perform={() => removeBsnUsr()}
            isDisabled={false}
          />
          <commonUtils.CustomButton
            btnText="No"
            btnWidth="fit-content"
            variant="danger"
            perform={() => setDeletePopupShow(false)}
            isDisabled={false}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BusinessUser;
