import React, { useRef, useState, useEffect, forwardRef } from "react";
import { HiLocationMarker } from "react-icons/hi";
import RatingBar from "../plugins/RatingBar";
import { Card, Col, Row } from "react-bootstrap";
import logo from "../../assets/images/tiip.png";
import { useParams } from 'react-router-dom';
import { useToast } from "../Toast";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { commonUtils } from "../CommonUtils";
import profile from "../../assets/images/profile.jpg";
const ReviewShare = () => {
    const { userid } = useParams();
    const { reviewid } = useParams();
    const [location, setLocation] = useState("");
    const [userId, setUserId] = useState("");
    const [reviewId, setReviewId] = useState("");
    const [shareData, setShareData] = useState([]);
    const toast = useToast();
    const navigate = useNavigate();
    useEffect(() => {
        if (userid && reviewid) {
            console.log("ids", userid, reviewid)
            setUserId(decodeLong(userid));
            setReviewId(decodeLong(reviewid));
        }
    }, [userid, reviewid]);

    function decodeLong(encodedId) {
        const decodedBytes = atob(encodedId);
        let result = 0;
        for (let i = 0; i < 8; i++) {
            result = (result << 8) | decodedBytes.charCodeAt(i);
        }

        return result;
    }

    const rmvMenFormat = (value) => {
        const mentionRegex = /\@\[([^)]+)\]\((\d+)\)/g;
        const transformedComment = value.replace(mentionRegex, "@$1");
        return transformedComment;
    };
    useEffect(() => {
        viewReview();
    }, [reviewId && userId]);
    const renderRow = (label, value) => (
        <Row className="pt-0 mt-2">
            <Col lg="5" md="5" xl="5" sm="12" className="fz-15 font-weight-500">{label}</Col>
            <Col lg="7" md="7" xl="7" sm="12" className="fz-15">{value}</Col>
        </Row>
    );
    const viewReview = async () => {
        if (reviewId && userId) {
            try {
                const response = await window.apiService.reviewShareView(userId, reviewId);
                setShareData(response.data);
            } catch (error) {
                toast.error("Error Occured");
            }
        }
    };

    const renderStars = (rating) => {
        const stars = [];
        const decimalPart = rating - Math.floor(rating);

        for (let index = 1; index <= 5; index++) {
            if (index < rating) {
                stars.push(<span key={index} className="fa fa-star checkedStr"></span>);
            } else if (index === Math.ceil(rating) && decimalPart > 0) {
                stars.push(
                    <span key={index} className="fa fa-star-half-o checkedStr"></span>
                );
            } else {
                stars.push(<span key={index} className="fa fa-star"></span>);
            }
        }

        return stars;
    };
    useEffect(() => {

        document.title = "title";

        // Update or create meta description
        const descriptionMeta = document.querySelector('meta[name="description"]');
        if (descriptionMeta) {
            descriptionMeta.setAttribute("content", "description123433");
        } else {
            const newDescriptionMeta = document.createElement("meta");
            newDescriptionMeta.setAttribute("name", "description");
            newDescriptionMeta.setAttribute("content", "testtttt");
            document.head.appendChild(newDescriptionMeta);
        }



    }, []);


    return (
        <>
            <div>
                <Row>
                    <Col lg="12" md="12" sm="12" xl="12">
                        <Row>
                            <Col lg="5" md="5" sm="12" xl="5" className="log_bg log_text">
                                {commonUtils.LeftDiv()}
                            </Col>
                            <Col
                                lg="7"
                                md="7"
                                sm="12"
                                xl="7"
                                style={{ backgroundColor: "white" }}
                            >
                                <div className="d-flex justify-content-center flex-column align-items-center copyright share_bg">
                                    <Col lg="8" md="8" sm="12" xl="8" className="shr_rev_shd">
                                        
                                        <div className="d-flex justify-content-center">
                                            <Col lg="8" md="8" sm="12" xl="8"style={{ paddingTop: "0px!important" }}>
                                                {/* <div> */}
                                                <div className="mb-4 mt-4 d-flex justify-content-center">
                                            <h4>Review Details</h4>
                                        </div>
                                                {renderRow("Name", shareData.userName)}
                                                {renderRow("Reviewed by", shareData.reviewerName)}
                                                {renderRow("Role", shareData.userRole)}
                                                {/* {renderRow("Location", shareData.location)} */}
                                                {shareData.comment && <Row className="pt-0 mt-2">
                                                    <Col lg="5" md="5" xl="5" sm="12" className="fz-15 font-weight-500 mt-1">Comment</Col>
                                                    <Col lg="7" md="7" xl="7" sm="12" className="fz-15">
                                                        <div className="">
                                                            {shareData.comment
                                                                ? rmvMenFormat(shareData.comment)
                                                                : ""}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                }
                                                <Row className="pt-0 mt-2">
                                                    <Col lg="5" md="5" xl="5" sm="12" className="fz-15 font-weight-500 mt-1">Rating</Col>
                                                    <Col lg="7" md="7" xl="7" sm="12" className="fz-15">
                                                        <div className="starRtng mt-2 ml-0">
                                                            <div id="ratingStars">
                                                                {console.log("shareData:", shareData)}
                                                                <span>{shareData?.averageReviewScore}</span>&nbsp;
                                                                {renderStars(shareData.averageReviewCount)}&nbsp;
                                                               
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/* <Row className="pt-0 mt-2">
                                                    <Col lg="5" md="5" xl="5" sm="12" className="fz-15 font-weight-500">Likes & Dislikes</Col>
                                                    <Col lg="7" md="7" xl="7" sm="12" className="fz-15">
                                                        <div className="cmtSec text-start w-100 ml--4" >
                                                            <RatingBar
                                                                disabled={true}
                                                                likecount={shareData.likes}
                                                                dislikecount={shareData.dislikes}
                                                                labelVAlue={shareData.score}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row> */}
                                                <div className="d-flex justify-content-center">
                                        <div className="Acc_sign mt-4 mb-4">
                                            <div><a href="#" onClick={() => navigate("/login")}>
                                                Login
                                            </a> here</div>
                                            Don't have an account?{" "}
                                            <a href="#" onClick={() => navigate("/registration")}>
                                                Sign Up
                                            </a>
                                        </div>
                                        </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>

    );
}

export default ReviewShare;