import React, { useState, useEffect } from "react";

const StarRating = (props) => {
  const [rating, setRating] = useState(props.rating);

  useEffect(() => {
    setRating(props.rating);
  }, [props.rating]);

  const handleRatingClick = (selectedRating) => {
    if (!props.disabled) {
      if (props.roleId && props.id) {
        if (selectedRating === rating) {
          setRating(0);
          props.onRatingChange(0, props.roleId, props.id, props.idx);
        } else {
          setRating(selectedRating);
          props.onRatingChange(
            selectedRating,
            props.roleId,
            props.id,
            props.idx
          );
        }
      } else {
        if (selectedRating === rating) {
          setRating(0);
          props.onRatingChange(0);
        } else {
          setRating(selectedRating);
          props.onRatingChange(selectedRating);
        }
      }
    }
  };

  // const renderStars = () => {
  //   const stars = [];
  //   const integerPart = Math.floor(rating);
  //   const decimalPart = rating - integerPart;

  //   const maxStars = 5;

  //   for (let i = 1; i <= Math.min(integerPart, maxStars); i++) {

  //     stars.push(
  //       <span
  //         key={i}
  //         className={`star selected ${props.disabled ? "disabled" : ""} ${props.isFollow ? "fs_20" : ""} ${props.isReview ? "isreview" : ""
  //           } ${props.customClass || ""} ${props.lineH || ""}`}
  //         onClick={() => handleRatingClick(i)}
  //       >
  //         &#9733;
  //       </span>
  //     );
  //   }

  //   if (decimalPart >= 0.25 && decimalPart < 0.75 && stars.length < maxStars) {
  //     stars.push(
  //       <span
  //         key={integerPart + 1}
  //         className={`star selected-half ${props.disabled ? "disabled" : ""}  ${props.isFollow ? "fs_20" : ""} ${props.isReview ? "isreview" : ""
  //           } ${props.customClass || ""} ${props.lineH || ""}`}
  //         onClick={() => handleRatingClick(integerPart + 0.5)}
  //       >
  //         &#9733;
  //       </span>
  //     );
  //   } else if (decimalPart >= 0.75 && stars.length < maxStars) {
  //     stars.push(
  //       <span
  //         key={integerPart + 1}
  //         className={`star selected ${props.disabled ? "disabled" : ""}  ${props.isFollow ? "fs_20" : ""} ${props.isReview ? "isreview" : ""
  //           } ${props.customClass || ""} ${props.lineH || ""}`}
  //         onClick={() => handleRatingClick(integerPart + 1)}
  //       >
  //         &#9733;
  //       </span>
  //     );
  //   }

  //   for (let i = stars.length + 1; i <= maxStars; i++) {
  //     stars.push(
  //       <span
  //         key={i}
  //         className={`star ${props.disabled ? "disabled" : ""}  ${props.isFollow ? "fs_20" : ""} ${props.isReview ? "isreview" : ""
  //           } ${props.customClass || ""} ${props.lineH || ""}`}
  //         onClick={() => handleRatingClick(i)}
  //       >
  //         &#9733;
  //       </span>
  //     );
  //   }

  //   return stars;
  // };
 const renderStars = () => {
  const stars = [];
  const maxStars = 5;

  for (let i = 1; i <= maxStars; i++) {
    let starColor = "";
    let fillPercentage = 0;
    
    // Calculate fill percentage for each star
    if (i <= Math.floor(rating)) {
      fillPercentage = 100; // Full stars
    } else if (i === Math.ceil(rating) && i > Math.floor(rating)) {
      fillPercentage = (rating % 1) * 100; // Partial star
    }
    
    // Determine color based on rating value
    if (rating >= 4.0) {
      starColor = "green"; // Green for 4.0 and above
    } else if (rating >= 3.0) {
      starColor = "#FFA500"; // Yellow for 3.0 to 3.9
    } else {
      starColor = "red"; // Red for below 3.0
    }

    stars.push(
      <span
        key={i}
        className={`star ${props.disabled ? "disabled" : ""} ${props.isFollow ? "fs_20" : ""} ${props.isReview ? "isreview" : ""} ${props.customClass || ""} ${props.lineH || ""}`}
        onClick={() => handleRatingClick(i)}
        style={{ 
          position: "relative",
          display: "inline-block",
          color: "" // Base star color (will be overridden by overlay)
        }}
      >
        {/* Base star (empty) */}
        &#9733;
        
        {/* Partial fill overlay */}
        {fillPercentage > 0 && (
          <span
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: `${fillPercentage}%`,
              overflow: "hidden",
              color: starColor,
              display: "inline-block"
            }}
          >
            &#9733;
          </span>
        )}
      </span>
    );
  }
  return stars;
   };

  return <div className="star-rating">{renderStars()}</div>;
};

export default StarRating;



// import React, { useState, useEffect } from "react";

// const StarRating = (props) => {
//   const [rating, setRating] = useState(props.rating);

//   useEffect(() => {
//     setRating(props.rating);
//   }, [props.rating]);

//   const handleRatingClick = (selectedRating) => {
//     if (!props.disabled) {
//       if (props.roleId && props.id) {
//         if (selectedRating === rating) {
//           setRating(0);
//           props.onRatingChange(0, props.roleId, props.id, props.idx);
//         } else {
//           setRating(selectedRating);
//           props.onRatingChange(
//             selectedRating,
//             props.roleId,
//             props.id,
//             props.idx
//           );
//         }
//       } else {
//         if (selectedRating === rating) {
//           setRating(0);
//           props.onRatingChange(0);
//         } else {
//           setRating(selectedRating);
//           props.onRatingChange(selectedRating);
//         }
//       }
//     }
//   };

//   const renderStars = () => {
//     const stars = [];
//     const maxStars = 5;

//     for (let i = 1; i <= maxStars; i++) {
//       let starColor = "";
//       let fillPercentage = 0;
      
//       // Calculate fill percentage for each star
//       if (i <= Math.floor(rating)) {
//         fillPercentage = 100; // Full stars
//       } else if (i === Math.ceil(rating) && i > Math.floor(rating)) {
//         fillPercentage = (rating % 1) * 100; // Partial star
//       }
      
//       // Determine color based on rating value
//       if (rating <= 0) {
//         starColor = ""; // All stars gray when rating is 0
//       } else if (rating < 2) {
//         starColor = "red"; // Stars red if rating is below 2
//       }  else if (rating >= 4 && rating <= 5) {
//       starColor = "green"; // Stars green if rating is 4 or above
//     } else {
//       starColor = "yellow"; // Stars yellow if rating is between 2 and 4
//     }

//       stars.push(
//         <span
//           key={i}
//           className={`star ${props.disabled ? "disabled" : ""} ${props.isFollow ? "fs_20" : ""} ${props.isReview ? "isreview" : ""} ${props.customClass || ""} ${props.lineH || ""}`}
//           onClick={() => handleRatingClick(i)}
//           style={{ 
//             position: "relative",
//             display: "inline-block",
//             color: "" // Base star color (will be overridden by overlay)
//           }}
//         >
//           {/* Base star (empty) */}
//           &#9733;
          
//           {/* Partial fill overlay */}
//           {fillPercentage > 0 && (
//             <span
//               style={{
//                 position: "absolute",
//                 left: 0,
//                 top: 0,
//                 width: `${fillPercentage}%`,
//                 overflow: "hidden",
//                 color: starColor,
//                 display: "inline-block"
//               }}
//             >
//               &#9733;
//             </span>
//           )}
//         </span>
//       );
//     }

//     return stars;
//   };

//   return <div className="star-rating">{renderStars()}</div>;
// };

// export default StarRating;