import React, { useState, useRef } from "react";
import { Collapse, Form, Navbar, Container } from "reactstrap";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import "../../../Dashboard/inbox/inbox.css";
import { Nav, Col, Row, Button, Card } from "react-bootstrap";
import HeaderMenu from "../../headerMenu";
import SideBar from "../../SideBar";
import FromHubspot from "./FromHubspot";
import HubspotConnect from "./HubspotConnect";
import { useDispatch, useSelector } from "react-redux";
export function Sidebar() {
  const [collapseOpen, setCollapseOpen] = useState();
  const [chatType, setChatType] = useState("Pushhubspot");
  const isHuspotConnect = useSelector((state) => state.user.isHuspotConnect);
  const userId = useSelector((state) => state.user.userId);
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  const renderComponent = (chatType) => {
    switch (chatType) {
      case "FetchHubspot":
        return <FromHubspot userId={userId} />;
      case "Pushhubspot":
        return <HubspotConnect isConnected={isHuspotConnect} />;
      default:
        return <HubspotConnect isConnected={isHuspotConnect} />;
    }
  };

  return (
    <>
      <HeaderMenu />
      <div className="">
        <SideBar />
        <div className="ml_200">
          <div className="inbox">
            <div className="container pl-0 pr-0">
              <Navbar
                className="navbar-vertical fixed-left navbar-light bg-white ml_200"
                expand="md"
                id="chatnav-main"
              >
                <Container className="flx_column" fluid>
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleCollapse}
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <Collapse
                    navbar
                    isOpen={collapseOpen}
                    className="cht_mr w-100 position-relative"
                  >
                    <div className="navbar-collapse-header d-md-none">
                      <Row>
                        <Col className="collapse-brand" xs="6">
                          <Link to="/dashboard">
                            <h2 style={{ color: "#11cdef" }}>tiipstr</h2>
                          </Link>
                        </Col>
                        <Col className="collapse-close" xs="6">
                          <button
                            className="navbar-toggler"
                            type="button"
                            onClick={toggleCollapse}
                          >
                            <span />
                            <span />
                          </button>
                        </Col>
                      </Row>
                    </div>
                    <Nav variant="pills" className="flex-column navStyle">
                      <div className="set_chat_div">
                        <Nav.Item
                          className={
                            chatType === "Pushhubspot"
                              ? "bg_lt_gray blk_clr"
                              : ""
                          }
                        >
                          <Nav.Link
                            className={
                              chatType === "Pushhubspot" ? "active" : ""
                            }
                            onClick={(event) => {
                              setChatType("Pushhubspot");
                            }}
                          >
                            <span className="dash_tab_name">
                              Establishing connection
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          className={
                            chatType === "FetchHubspot"
                              ? "bg_lt_gray blk_clr"
                              : ""
                          }
                        >
                          <Nav.Link
                            disabled={!isHuspotConnect}
                            className={
                              chatType === "FetchHubspot" ? "active" : ""
                            }
                            onClick={(event) => {
                              if (isHuspotConnect) {
                                setChatType("FetchHubspot");
                              }
                            }}
                          >
                            <span className="dash_tab_name">
                              Fetch Hubspot Data
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                    </Nav>
                  </Collapse>
                </Container>
              </Navbar>
              <div className="chat_ml">{renderComponent(chatType)}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
