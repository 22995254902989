import { createRoot } from "react-dom/client";
import ViewShare from "../../Home/ViewShare";
import StarRating from "./StarRating";
import profile from "../../../assets/images/profile.jpg";
import { Card, Col, Row, Button } from "react-bootstrap";
import html2canvas from "html2canvas";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { BsShareFill } from "react-icons/bs";
import logoImg from "../../../assets/images/tiipstrlogo.jpg";
import setldImg from "../../../assets/images/setld.jpg";
import { Helmet } from "react-helmet";
import React, { useState, useEffect, useRef } from "react";
import { useToast } from "../../Toast";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import { IoFilter } from "react-icons/io5";
import filter from "../../../assets/images/filter.jpg";
import { BsQuestionDiamond } from "react-icons/bs";
import { LuActivitySquare } from "react-icons/lu";
import { MdOutlineReport } from "react-icons/md";
import {
  IoMdArrowDropdown,
  IoMdArrowDropup,
  IoIosChatboxes,
} from "react-icons/io";
import SideBar from "../SideBar";
import HeaderMenu from "../headerMenu";
import BreadCrumb from "../../plugins/Breadcrumb";
import loader from "../../../assets/images/loader.gif";
import Horizontalbar from "../../plugins/Horizontalbar";
import RatingBar from "../../plugins/RatingBar";
import LineChart from "../../plugins/LineChart";
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  InstagramShareButton,
} from "react-share";
import { connect, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { followId } from "../../../Redux/Actions/userAction";
import { Overlay, Popover, OverlayTrigger } from "react-bootstrap";
import { BiSolidCommentDetail, BiSolidMedal } from "react-icons/bi";
import { HiLocationMarker } from "react-icons/hi";
import { FiUsers, FiUser } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import mentionInputStyle from "../../../assets/css/MentionStyle";
import { MentionsInput, Mention } from "react-mentions";
import InstagramIcon from "../../plugins/InstagramIcon";
import { FaSquareXTwitter, FaLinkedin } from "react-icons/fa6";
import { MdSwitchAccount, MdEmail } from "react-icons/md";
import { TbSocial } from "react-icons/tb";
import { AiFillFacebook } from "react-icons/ai";
import { userAction } from "../../../Redux/Actions/userAction";
import { userAuth } from "../../../Redux/Actions/userAction";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { IoPersonCircleSharp } from "react-icons/io5";
import { FaUserTie, FaPhoneAlt } from "react-icons/fa";
import { TiGroup } from "react-icons/ti";
import { MdBusinessCenter } from "react-icons/md";
import GroupsTab from "../DashboardTabs/GroupsTab";
import TiipTokTab from "../DashboardTabs/TiipTokTab";
import { commonUtils } from "../../CommonUtils";
import BusinessScores from "./BusinessScores";
import { IoMdCloseCircle } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { PiLinkBold } from "react-icons/pi";
import { HiBadgeCheck } from "react-icons/hi";
import CustomModal from "../../plugins/CustomModal";
import UpgradePopup from "../../plugins/UpgradePopup";
import { BsFillPinAngleFill } from "react-icons/bs";
import AttachmentModal from "../../plugins/AttachmentModal";
import fileImg from "../../../assets/images/file.jpg";
import { MdOutlineAttachment } from "react-icons/md";
import { useSubscription } from "../../SubscriptionContext";
import { ImNotification } from "react-icons/im";
import { getExpiryStatus } from "../../ExpiryUtils";
import { MdDoubleArrow } from "react-icons/md";
import chatImg from "../../../assets/images/dash_chat.png";
const MeTab = (props) => {
  const [duration, setDuration] = useState("");
  const [currentIndex, setcurrentIndex] = useState(null);
  const [globalRating, setGlobalRating] = useState([]);
  const [revGlobalRtng, setRevGlobalRtng] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [aggregationType, setAggregationType] = useState("monthly");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [userreviewList, setUserReviewList] = useState([]);
  const [userData, setUserData] = useState({});
  const [showAdditionalReviews, setShowAdditionalReviews] = useState(false);
  const [selectedReviewId, setSelectedReviewId] = useState(null);
  const [isShare, setIsShare] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [reviewId, setreviewId] = useState("");
  const [reportMsg, setReportMsg] = useState("");
  const [reviewSts, setReviewSts] = useState("");
  const [reportCommentErr, setReportCommentErr] = useState("");
  const [showPopover, setShowPopover] = useState(false);
  const [childComment, setchildComment] = useState("");
  const [childcurrentIndex, setchildcurrentIndex] = useState(null);
  const [childrepplycurrentIndex, setchildreplycurrentIndex] = useState([]);
  const [showChildReply, setShowChildReply] = useState(false);
  const [showPop, setShowPop] = useState(false);
  const [shareData, setShareData] = useState({});
  const [comment, setComment] = useState("");
  const [replies, setRevReplies] = useState([]);
  const [revCmnt, setRevCmnt] = useState([]);
  const [avgCount, setAvgCount] = useState("");
  const [expandedCommentIndex, setExpandedCommentIndex] = useState(-1);
  const [cmtError, setCmtError] = useState("");
  const [cmtErrId, setCmtErrId] = useState(null);
  const targetRef = useRef(null);
  const [users, setUsers] = useState([]);
  const [mentionuserIds, setmentionuserIds] = useState([]);
  const [formData, setFormData] = useState(null);
  const [urlLink, setUrlLink] = useState("");
  const [revImgLink, setRevImgLink] = useState("");
  const [showIcons, setShowIcons] = useState(false);
  const [eventKey, setEventKey] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [busnModalShow, setBusnModalShow] = useState(false);
  const [userProfList, setUserProfList] = useState("");
  const [userBusnList, setUserBusnList] = useState("");
  const [prevTab, setPrevTab] = useState("Personal");
  const [labels, setLabels] = useState("");
  const [graphRating, setgraphRating] = useState("");
  const [reviewCount, setReviewCount] = useState("");
  const [starRevList, setStarRevList] = useState([]);
  const [toggleStarRev, setToggleStarRev] = useState(false);
  const [clickedStarVal, setClickedStarVal] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [scoreType, setScoreType] = useState("default");
  const [showBusnScore, setShowBusnScore] = useState(false);
  const [showPinRev, setShowPinRev] = useState(false);
  const [pinBy, setPinBy] = useState("");
  const [id, setId] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [showAttachment, setShowAttachment] = useState(false);
  const [attachment, setAttachment] = useState([]);
  const [postBtnDisable, setPostBtnDisable] = useState(false);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1200);
  const { isFeatureEnabled, allFeatures } = useSubscription();
  const { refreshSubscriptions } = useSubscription();
  const [showNotify, setShowNotify] = useState(false);

  const [days, setDays] = useState("");
  const { endDate, planId } = useSubscription();
  const [notified, setNotified] = useState(localStorage.getItem("notified"));
  const [expiryStatus, setExpiryStatus] = useState(null);
  const [trialPlanId, setTrialPlanId] = useState("");
  const [isFree, setIsFree] = useState(false);
  const [pinLoading, setPinLoading] = useState(false);
  const [status, setStatus] = useState(getExpiryStatus());
  const [isSubmitted, setIsSubmitted] = useState(false);

  const reviewNotification = useSelector(
    (state) => state.user.notificationCount
  );
  const [businessurl, setBusinessLinks] = useState({});
  useEffect(() => {
    setStatus(getExpiryStatus(endDate));
  }, [endDate, status]);

  useEffect(() => {
    loadPlans();
  }, []);

  useEffect(() => {
    if (planId == trialPlanId) {
      setIsFree(true);
    }
  }, [planId && trialPlanId]);

  const loadPlans = async () => {
    try {
      const response = await window.apiService.loadPlans();
      const allPlans = response.data.recordInfo;
      const trialPlan = allPlans.find((plan) => plan.isTrial === 1);
      setTrialPlanId(trialPlan.id);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const readComment = (index) => {
    setExpandedCommentIndex(index === expandedCommentIndex ? -1 : index);
  };
  const dropdownRef = useRef(null);
  const [roleList, setRoleList] = useState("");
  const [isList, setIsList] = useState(true);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [avgRatng, setAvgRatng] = useState(0);
  const [ratngCount, setRatngCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const likeFeatureEnable = isFeatureEnabled(
    allFeatures["Like-Dislike%"]?.key,
    allFeatures["Like-Dislike%"]?.isDeleted
  );
  const renderRatingBar = (data, msgreply) => (
    <RatingBar
      likecount={data.likes}
      dislikecount={data.dislikes}
      msgreply={msgreply}
      liked={data.liked}
      handlelike={() => setShowPopup(true)}
      handledislike={() => setShowPopup(true)}
      handleRmvlike={() => setShowPopup(true)}
      labelVAlue={data.score}
    />
  );
  useEffect(() => {
    loadRoleList();
  }, [userreviewList]);

  useEffect(() => {
    const status = getExpiryStatus(endDate);
    if (
      status &&
      !notified &&
      (props.isPlanExpInApp || props.isTrialExpInApp)
    ) {
      setExpiryStatus(status);
      setShowNotify(true);
      localStorage.setItem("notified", true);
    }
  }, [endDate, notified, props.isPlanExpInApp, props.isTrialExpInApp]);

  const toggleFilterSection = () => {
    setShowFilterSection(!showFilterSection);
  };
  const handleClosePin = () => {
    setShowPinRev(false);
    setDuration("");
    setErrMsg("");
  };
  const handleSubmit = () => {
    submitReport();
    setIsSubmitted(true);
  };
  const handleCloseNotify = () => {
    setShowNotify(false);
  };

  const loadRoleList = async () => {
    try {
      const response = await window.apiService.loadUserRoles();
      const userReviewRoleIds = new Set(
        userreviewList.map((review) => review.roleId)
      );
      const filteredRoleList = response.data.recordInfo.filter((role) =>
        userReviewRoleIds.has(role.id)
      );
      setRoleList(filteredRoleList);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setEventKey(
      props.userType == "Professional"
        ? "Professional"
        : props.userType == "Business"
          ? "Business"
          : "Personal"
    );
  }, [props]);
  const loadRevsByStars = async (userId, userType, profileId, starValue) => {
    const params = {
      id: userId,
      userType: userType,
      profileId: profileId,
      starValue: starValue,
    };
    try {
      const response = await window.apiService.getRevsBysStars(params);
      setStarRevList(response.data.data.reviews);
      setToggleStarRev(true);
      // setShowPopover(false);
    } catch { }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleMouseEnter = (users) => {
    if (users.length > 0) setCurrentUsers(users);
    else setCurrentUsers([]);
  };
  const clear = () => {
    setSelectedRoleId("");
    setIsList(true);
    setAvgRatng(0);
    setShowFilterSection(false);
  };
  const viewAttachment = (data) => {
    setShowAttachment(true);
    setAttachment(data);
  };
  const checkExt = (file) => {
    let allowedfiletypes = ["jpg", "svg", "jpeg", "png", "bmp", "gif", "jfif"];
    if (
      allowedfiletypes.some(
        (ext) =>
          ext == file.fileName.substring(file.fileName.lastIndexOf(".") + 1)
      )
    ) {
      return true;
    }
    return false;
  };
  const handleOutsideClick = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.closest(".nav-link")
    ) {
      setModalShow(false);
      setBusnModalShow(false);
    }
  };
  const handleTabChange = (key) => {
    if (loading) return;
    clear();
    setSelectedRoleId("");
    setIsList(true);
    setEventKey(key);
    if (key === "Personal") {
      setModalShow(false);
      Switchaccount("Personal", props.userId, null);
    } else if (key === "Professional") {
      if (prevTab === "Professional") {
        setModalShow(true);
        setBusnModalShow(false);
      } else {
        setModalShow(false);
        if (userProfList[0] != null) {
          Switchaccount(
            userProfList[0].userTypeName,
            props.userId,
            userProfList[0].professionalId
          );
        }
      }
    } else if (key === "Business") {
      if (prevTab === "Business") {
        setModalShow(false);
        setBusnModalShow(true);
      } else {
        setBusnModalShow(false);
        if (userBusnList[0] != null) {
          Switchaccount(
            userBusnList[0].userTypeName,
            props.userId,
            userBusnList[0].businessId
          );
        }
      }
    }
    setPrevTab(key);
  };
  const userSpecificData =
    userData.userType === "Professional"
      ? userData.professional
      : userData.userType === "Business"
        ? userData.business
        : userData;

  const phone =
    userSpecificData.officePhone || userSpecificData.phone || userData.phone;
  const email = userSpecificData.email || userData.email;
  const renderContactItem = (data, IconComponent) =>
    data ? (
      <div className="contact-item">
        <IconComponent /> &nbsp;
        <span className="me_txt">{data}</span>
      </div>
    ) : null;
  const Switchaccount = async (type, userId, id) => {
    setLoading(true);
    dispatch(userAuth(false));
    let data = {
      email: props.email,
      userType: type,
      userId: userId,
      profileId: type != "Personal" ? id : null,
      switchAccount: true,
    };
    try {
      const response = await window.apiService.userAuthentication(data);
      if (response.data) {
        if (response.data.data.token) {
          dispatch(userAuth(true));
        }
        dispatch(userAction(response.data.data));
        localStorage.setItem("userType", response.data.data.userType);
        localStorage.setItem("switchAccount", data.switchAccount);
        localStorage.setItem("profileId", response.data.data.profileId);
        navigate("/dashboard");
        setModalShow(false);
        setStarRevList([]);
        setToggleStarRev(false);
      } else {
        toast.info(response.data.message);
        setModalShow(false);
      }
      setLoading(false);
    } catch (error) {
      dispatch(userAction(false));
      setLoading(false);
      console.log(error);
      toast.error("Error Occured");
    }
  };
  const handleMention = (mentionedUser) => {
    const match = mentionedUser.match(/@\[([^\]]+)\](?:\(([^)]+)\))?/);
    if (match) {
      const [, name, userIdPart] = match;
      let userId = userIdPart;
      let busnId = null;
      let profId = null;
      if (userIdPart && userIdPart.includes("&")) {
        [userId, busnId] = userIdPart.split("&");
      } else if (userIdPart && userIdPart.includes("-")) {
        [userId, profId] = userIdPart.split("-");
      }
      if (name && userId) {
        if (userId !== props.userId) {
          dispatch(followId(userId));
          navigate("/dashboard/viewuser", {
            state: {
              userId,
              userType:
                profId || busnId
                  ? profId
                    ? "Professional"
                    : "Business"
                  : "Personal",
              profileId: profId || busnId ? (profId ? profId : busnId) : null,
            },
          });
        }
      }
    }
  };
  const changeCmtText = (value, type) => {
    commonUtils.changeCmtText(
      value,
      type,
      setmentionuserIds,
      setComment,
      setchildComment
    );
  };
  const rmvMenFormat = (value) => {
    const mentionRegex = /@\[([^\]]+?)\](?:\(\d+([&-])\d+\))|@([^\s]+)/g;
    const match = mentionRegex.exec(value);
    if (match) {
      if (match[3]) {
        const nestedMatch = match[3].match(/\[([^\]]+)\]\(\d+\)/);
        return nestedMatch ? nestedMatch[1] : "";
      }
      return match[1] || "";
    }
    return null;
  };
  const getRevQnsRating = (value) => {
    const num =
      value.reduce((total, next) => total + next.rating, 0) / value.length;
    return num.toFixed(1);
  };
  function textWithLinks(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const mentionRegex = /@\[([^\]]+?)\](\((\d+([&-])\d+)?\))|@([^\s]+)/g;
    return text.split(/\s(?![^\[]*])/).map((word, index) => {
      if (urlRegex.test(word)) {
        return (
          <a key={index} href={word} target="_blank" rel="noopener noreferrer">
            {word}
          </a>
        );
      } else if (mentionRegex.test(word)) {
        const mentions = word.match(mentionRegex);
        if (mentions) {
          return mentions.map((mention, mentionIndex) => {
            const match = mention.match(/@\[([^\]]+?)\](\((\d+([&-])\d+)?\))/g);
            const id = match ? match[2] : null;
            return (
              <span
                key={index + mentionIndex}
                className={id == props.userId ? "" : "cmt_icon c_pointer"}
                onClick={() => handleMention(mention)}
              >
                {rmvMenFormat(mention)}
              </span>
            );
          });
        }
      }

      return word;
    });
  }
  const handleButtonClick = () => {
    setShowPopover(!showPopover);
  };
  const handleArrowClick = (rating, avgCount) => {
    setAvgCount(avgCount);
    const starResponse = rating;
    const result = starResponse.map((starData) => ({
      label: starData.star,
      value: starData.totalReviewCount,
      userRatings: starData.userRatings,
    }));
    result.sort((a, b) => {
      const numA = parseInt(a.label.split(" ")[0]);
      const numB = parseInt(b.label.split(" ")[0]);
      return numB - numA;
    });
    setRevGlobalRtng(result);
    setShowPop(!showPop);
  };
  const handleOverlayClose = () => {
    setShowPopover(false);
    setShowPop(false);
  };
  useEffect(() => {
    userDetails();
    userLists();
    if (props.userTypeInfo != undefined && props.userTypeInfo != "") {
      setUserProfList(
        props.userTypeInfo.filter((st) => st.userTypeName == "Professional")
      );
    }
    if (props.userTypeInfo != undefined && props.userTypeInfo != "") {
      setUserBusnList(
        props.userTypeInfo.filter((st) => st.userTypeName == "Business")
      );
    }
  }, [props]);
  useEffect(() => {
    profileChartInfo();
  }, [props, aggregationType, scoreType]);
  const profileChartInfo = async () => {
    const params = {
      userId: props.userId,
      userType: props.userType,
      profileId: props.profileId ? props.profileId : null,
      aggregationType: aggregationType,
      scoreType: scoreType,
    };
    try {
      const response = await window.apiService.profileChartInfo(params);
      const { labels, ratings, reviewCounts } =
        commonUtils.convertDataToChartData(
          response.data.data.ratingChart,
          aggregationType,
          scoreType
        );
      setLabels(labels);
      setgraphRating(ratings);
      setReviewCount(reviewCounts);
    } catch { }
  };
  const userLists = async () => {
    try {
      const response = await window.apiService.lookupAllUsersList();
      const usersArr = response.data.recordInfo.filter((row) => {
        return (
          row.userId != props.userId &&
          row.userId.split("-")[0] != props.userId &&
          row.userId.split("&")[0] != props.userId
        );
      });
      const users = usersArr.map(function (row) {
        return { display: row.name, id: row.userId };
      });
      setUsers(users);
    } catch (error) {
      console.log(error);
    }
  };
  const Refresh = async () => {
    if (props.authToken) {
      const switchAccount = localStorage.getItem("switchAccount");
      const params = {
        profileId: props.profileId,
        authToken: props.authToken,
        userType: props.userType ? props.userType : "Personal",
        switchAccount: switchAccount ? switchAccount : false,
      };
      try {
        const data = await window.apiService.refresh(params);
        let result = data.data;
        if (data) {
          dispatch(userAction(result));
        }
        refreshSubscriptions();
      } catch (error) {
        console.log(error);
      }
    }
  };
  const userDetails = async () => {
    if (props.userId) {
      setLoading(true);
      const params = {
        id: props.userId,
        userType: props.userType,
        profileId: props.userType != "Personal" ? props.profileId : undefined,
      };
      try {
        const response = await window.apiService.userProfileView(params);
        setUserData(response.data.data);
        if (response.data.data?.business) {
          setBusinessLinks(response.data.data?.business);
          if (response.data.data.business?.showBusinessScore)
            setScoreType("BS");
          else if (response.data.data.business?.showPeopleScore)
            setScoreType("PS");
          else setScoreType("CS");
        } else setScoreType("default");

        // const { labels, ratings, reviewCounts } = convertDataToChartData(
        //   response.data.data.ratingChart
        // );
        // setLabels(labels);
        // setgraphRating(ratings);
        // setReviewCount(reviewCounts);
        setUserReviewList(response.data.data.reviewList);
        const starResponse =
          response.data.data.userType == "Professional"
            ? response.data.data.professional.starRating
            : response.data.data.userType == "Business"
              ? response.data.data.business.starRating
              : response.data.data.starRating;
        const result = starResponse.map((starData) => ({
          label: starData.star,
          value: starData.totalReviewCount,
          userRatings: starData.userRatings,
        }));
        result.sort((a, b) => {
          const numA = parseInt(a.label.split(" ")[0]);
          const numB = parseInt(b.label.split(" ")[0]);
          return numB - numA;
        });

        setGlobalRating(result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Error Occured");
      }
    }
  };
  console.log(businessurl.twitterUrl);
  useEffect(() => {
    Refresh();
  }, [reviewNotification]);

  const handleShare = (data) => {
    setIsShare(true);
    setShareData(data);
  };
  const togglechildcomment = (id) => {
    setchildComment("");
    if (childrepplycurrentIndex == id) {
      setchildreplycurrentIndex(null);
    } else {
      setchildreplycurrentIndex(id);
    }
  };
  const viewRevComments = (data, replies, sts) => {
    setShowReply(!showReply);
    setReviewSts(sts);
    setShowChildReply(false);
    loadRevComments(data[0].reviewId);
    setcurrentIndex(null);
    setchildreplycurrentIndex(null);
  };
  const viewChildComments = (data, id, revId) => {
    setcurrentIndex(null);
    setShowChildReply(!showChildReply);
    if (childcurrentIndex == id) {
      setchildcurrentIndex(null);
    } else {
      setchildcurrentIndex(id);
    }
    loadRevComments(revId);
  };
  const handlerplylikechange = (id, revId) => {
    console.log("revId", revId);
    updaterplyLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: props.userId,
      liked: 1,
    });
  };
  const handlerplydislikechange = (id, revId) => {
    updaterplyLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: props.userId,
      liked: 0,
    });
  };
  const rplylikecntDecrement = (id, revId) => {
    updaterplyLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: props.userId,
      liked: -1,
    });
  };
  const updaterplyLikeStatus = async (likeParams) => {
    try {
      const response = await window.apiService.addCommentLike(likeParams);
      if (response.data.status == "OK") {
        loadRevComments(likeParams.reviewId);
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const handlelikechange = (id, userId) => {
    updateLikeStatus({ reviewId: id, userId: userId, liked: 1 });
  };
  const handledislikechange = (id, userId) => {
    updateLikeStatus({ reviewId: id, userId: userId, liked: 0 });
  };
  const updateLikeStatus = async (likeParams) => {
    try {
      const response = await window.apiService.addLike(likeParams);
      if (response.data.status == "OK") {
        userDetails();
        setShowAdditionalReviews(false);
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const likecntDecrement = (id, userId) => {
    updateLikeStatus({ reviewId: id, userId: userId, liked: -1 });
  };
  const sendReport = (id) => {
    setreviewId(id);
    setShowReport(true);
  };
  const closeReportModal = () => {
    setReportCommentErr("");
    setShowReport(false);
  };
  const CloseShare = () => {
    setIsShare(false);
    setUrlLink("");
    setShareData({});
  };
  const handleNameClick = (
    userId,
    reviewedByProfessional,
    reviewerUserType,
    reviewedByBusiness
  ) => {
    dispatch(
      followId(
        userId,
        reviewerUserType,
        reviewedByProfessional,
        reviewedByBusiness
      )
    );
    navigate("/dashboard/viewuser", {
      state: {
        userId: userId,
        profileId: reviewedByProfessional
          ? reviewedByProfessional
          : reviewedByBusiness,
        userType: reviewerUserType,
      },
    });
  };
  const validateReportMessage = (msg) => {
    if (msg.trim().length === 0) return "Report Comment is required.";
   
    return "";
  };

  const handleReport = (event) => {
   
    let msg = event.target.value;
    if (msg.length > 288) {
      msg = msg.substring(0, 288); // ✅ Now it's allowed
    }

    setReportMsg(msg);

    const error = validateReportMessage(msg);
    setReportCommentErr(error);
  };

  const submitReport = async () => {
    const error = validateReportMessage(reportMsg);
    if (error) {
      setReportCommentErr(error);
      
      return;
    }

    if (!reviewId) return;

    try {
      const params = {
        reviewId,
        reporterId: props.userId,
        additionalInfo: reportMsg,
      };

      const response = await window.apiService.addReport(params);
      if (response.data.status === "OK") {
        toast.success("Report submitted  successfully");
        setShowReport(false);
        userDetails();
        setShowAdditionalReviews(false);
      }
    } catch (error) {
      toast.error("An error occurred while submitting the report.");
    }
  };
  const updateStatus = async (id, status) => {
    const params = {
      id: id,
      status: status,
    };
    if (status == "new") {
      try {
        const response = await window.apiService.reviewStsUpdate(params);
        if (response.data.status == "OK") {
          userDetails();
          Refresh();
        }
      } catch (error) {
        toast.error("Error Occured");
      }
    }
  };
  const togglecomment = (id) => {
    setComment("");
    setShowChildReply(false);
    setchildcurrentIndex(null);
    if (currentIndex == id) {
      setcurrentIndex(null);
    } else {
      setcurrentIndex(id);
    }
  };
  const postComment = async (id, revId, type) => {
    if (comment.trim().length !== 0 || childComment.trim().length !== 0) {
      let params = "";
      if (type == "child") {
        params = {
          userId: props.userId,
          commentText: childComment,
          reviewId: revId,
          parentCommentId: id,
          mentionUserIds: mentionuserIds,
          professionalId:
            props.userType == "Professional" ? props.profileId : null,
          businessId: props.userType == "Business" ? props.profileId : null,
        };
      } else {
        params = {
          userId: props.userId,
          commentText: comment,
          reviewId: id,
          parentCommentId: null,
          mentionUserIds: mentionuserIds,
          professionalId:
            props.userType == "Professional" ? props.profileId : null,
          businessId: props.userType == "Business" ? props.profileId : null,
        };
      }
      setPostBtnDisable(true);
      try {
        const response = await window.apiService.addComment(params);
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          setComment("");
          setchildComment("");
          setShowReply(false);
          setShowChildReply(false);
          userDetails();
          togglecomment(response.data.id);
          loadRevComments(params.reviewId);
          setmentionuserIds([]);
          setPostBtnDisable(false);
        }
      } catch (error) {
        console.log(error);
        toast.error("Error Occured");
        setPostBtnDisable(false);
      }
    } else {
      setCmtError("Comment text is required");
      setCmtErrId(id);
    }
  };
  const loadRevComments = async (revId) => {
    try {
      const response = await window.apiService.reviewCmtList({ id: revId });
      setRevCmnt(response.data.recordInfo.reviewComments);
      setRevReplies(response.data.recordInfo.reviewReplies);
    } catch (error) {
      toast.error("Error Occured");
    }
  };

  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mimeMatch = arr[0].match(/:(.*?);/);

    if (!mimeMatch || mimeMatch.length < 2) {
      console.error("Invalid dataURL format");
      return null;
    }

    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };

  useEffect(() => {
    if (shareData?.userId && shareData?.id) {
      const shareUrl = `https://devapi.tiipstr.app/api/v1/fbshare/view/${shareData.userId}/${shareData.id}`;
      setUrlLink(shareUrl);
    }
  }, [shareData]);
  function renderReviewCards(reviewData) {
    return reviewData != null &&
      reviewData.length > 0 &&
      isList &&
      !loading &&
      !pinLoading ? (
      (selectedRoleId ? filteredList : reviewData)
        .sort((a, b) => {
          if (
            !isFeatureEnabled(
              allFeatures["Pin-review%"]?.key,
              allFeatures["Pin-review%"]?.isDeleted
            )
          ) {
            return 0;
          }
          const userId = props.userId;

          const isAPinned =
            a.pinnedBy === userId && new Date(a.pinExpiration) > new Date();
          const isBPinned =
            b.pinnedBy === userId && new Date(b.pinExpiration) > new Date();

          if (isAPinned && !isBPinned) return -1;
          if (!isAPinned && isBPinned) return 1;

          if (isAPinned && isBPinned) {
            return new Date(b.updatedAt) - new Date(a.updatedAt);
          }
          return 0;
        })
        .map((data, i) => (
          <Card
            onClick={() => updateStatus(data.id, data.status)}
            className="meCard me_ht"
            key={data.id}
          >
            {isFeatureEnabled(
              allFeatures["Pin-review%"]?.key,
              allFeatures["Pin-review%"]?.isDeleted
            ) ? (
              <React.Fragment>
                {data.pinnedBy == props.userId &&
                  new Date(data.pinExpiration) > new Date() ? (
                  <div
                    className="pinRev c_pointer"
                    title="Un Pin"
                    onClick={() => pinReview(data.id, null)}
                  >
                    <BsFillPinAngleFill />
                  </div>
                ) : (
                  <div
                    className="tr_dot c_pointer"
                    title="Pin review"
                    onClick={() => pinReview(data.id, data.userId)}
                  >
                    <BsFillPinAngleFill />
                  </div>
                )}
              </React.Fragment>
            ) : (
              <div
                className="tr_dot c_pointer"
                title="Pin a review"
                onClick={() => setShowPopup(true)}
              >
                <BsFillPinAngleFill />
              </div>
            )}
            <Card.Body
              className={`${data.status === "new"
                  ? "rev_padg  blurry-background c_pointer"
                  : "rev_padg "
                } ${showAdditionalReviews && selectedReviewId === data.reviewedBy
                  ? "card_drp"
                  : ""
                } ${data.status === "reported" || data.status == "blocked"
                  ? "bg_gray rev_padg "
                  : ""
                }`}
            >
              <div className="text-end dot_3">
                {data.status == "reported" && (
                  <span className="uder_Rev">Under Review</span>
                )}
                {data.status == "blocked" && (
                  <span className="uder_Rev">Blocked</span>
                )}
              </div>
              <Row>
                <Col
                  sm={12}
                  md={6}
                  lg={3}
                  xl={3}
                  className="text-center custom-xl-3"
                >
                  <div className="border_btm">
                    <img
                      className="meImg_review"
                      src={
                        data.reviewerImagePath
                          ? data.reviewerImagePath
                          : profile
                      }
                    />
                  </div>
                  {data.status != "blocked" && data.status != "reported" ? (
                    <Card.Text
                      title={data.businessTypeName}
                      className="mb-0 card_txt name_blue h_22 c_pointer "
                      onClick={() =>
                        handleNameClick(
                          data.reviewedBy,
                          data.reviewedByProfessional,
                          data.reviewerUserType,
                          data.reviewedByBusiness
                        )
                      }
                      style={{
                        minWidth: "120px", // Ensures it doesn’t shrink too much
                        maxWidth: "200px", // Adjust for truncation
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        fontSize: "14px",
                        display: "inline-block",
                        verticalAlign: "middle",
                        flexGrow: 1, // Allows it to expand when space is available
                      }}
                    >
                      {data.reviewerUserType === "Business"
                        ? data.busnShortName
                          ? data.busnShortName
                          : data.businessTypeName
                        : data.reviewerName}
                    </Card.Text>
                  ) : (
                    <Card.Text className="mb-0 card_txt">
                      {data.reviewerUserType === "Business"
                        ? data.busnShortName
                          ? data.busnShortName
                          : data.businessTypeName
                        : data.reviewerName}
                    </Card.Text>
                  )}
                  {data.profTypeName && (
                    <span className="fz_14">
                      {"(" + data.profTypeName + ")"}
                    </span>
                  )}
                  <div
                    className=" d-flex justify-content-center align-items-center fs_12"
                    style={{ height: "20px" }}
                  >
                    <div className="d-flex align-items-center">
                      {data.averageReviewScore > 0.0 ? (
                        <label className="font-weight-bold mt_7 pr-2">
                          {data.averageReviewScore.toFixed(1)}
                        </label>
                      ) : (
                        ""
                      )}
                      <StarRating
                        rating={data.averageReviewScore}
                        disabled={true}
                        customClass="ques-star"
                      />
                      <span className="pl-2">({data.totalReviewCount})</span>
                      <OverlayTrigger
                        trigger="click"
                        key={data.id}
                        placement="bottom"
                        overlay={
                          <Popover
                            id={`popover-${data.id}`}
                            className="pop_rating"
                          >
                            <div className="drp_rating">
                              <StarRating
                                customClass="user-star"
                                rating={avgCount}
                                disabled={true}
                              />
                              <span
                                style={{
                                  marginLeft: "-45px",
                                  marginTop: "3px",
                                }}
                              >
                                {avgCount} out of 5
                              </span>{" "}
                            </div>
                            {revGlobalRtng.map((item, index) => (
                              <Row key={index}>
                                <Col
                                  sm={12}
                                  md={3}
                                  lg={3}
                                  xl={2}
                                  className="p-0"
                                >
                                  <span className="prog_span">
                                    {item.label}
                                  </span>{" "}
                                </Col>
                                <Col
                                  sm={12}
                                  md={8}
                                  lg={8}
                                  xl={7}
                                  className="p-0"
                                >
                                  <Horizontalbar dataSet={item.value} />
                                </Col>
                                <Col
                                  sm={12}
                                  md={3}
                                  lg={3}
                                  xl={2}
                                  className="p-0 prog_span"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <span className="tooltip">
                                    <span
                                      className="c_pointer"
                                      onMouseEnter={() =>
                                        handleMouseEnter(item.userRatings)
                                      }
                                    >
                                      {item.value}
                                      {item.value > 1 ? (
                                        <FiUsers
                                          style={{ marginLeft: "6px" }}
                                        />
                                      ) : (
                                        <FiUser style={{ marginLeft: "6px" }} />
                                      )}
                                    </span>
                                    {commonUtils.ratingTooltip(currentUsers)}
                                  </span>
                                </Col>
                              </Row>
                            ))}
                          </Popover>
                        }
                        rootClose
                      >
                        {data.status != "blocked" ? (
                          <span
                            className="d-flex align-items-center c_pointer"
                            onClick={() =>
                              handleArrowClick(
                                data.starRating,
                                data.averageReviewCount
                              )
                            }
                          >
                            <IoIosArrowDown className="clr_gray" />
                          </span>
                        ) : (
                          <span className="d-flex align-items-center"></span>
                        )}
                      </OverlayTrigger>
                      {data.status == "blocked" && (
                        <IoIosArrowDown className="clr_gray" />
                      )}
                    </div>
                  </div>
                </Col>
                <Col
                  className="card_bdr_left m-0 "
                  sm={12}
                  md={6}
                  lg={3}
                  xl={3}
                >
                  <Card.Text className="mb-0 card_txt" title={data.comment}>
                    <div
                      className={` ${expandedCommentIndex === i ? "show-full" : ""
                        }`}
                      title={data.comment}
                    >
                      {data.comment.length >= 40 && expandedCommentIndex !== i
                        ? textWithLinks(data.comment.substring(0, 40))
                          .map((word, index) => (
                            <span key={index}>{word} </span>
                          ))
                          .concat("...")
                        : textWithLinks(data.comment).map((word, index) => (
                          <span key={index}>{word} </span>
                        ))}
                    </div>

                    {data.comment.length >= 40 && (
                      <button
                        disabled={
                          data.status == "blocked" || data.status == "reported"
                            ? true
                            : false
                        }
                        onClick={() => readComment(i)}
                        className={
                          data.status == "blocked" || data.status == "reported"
                            ? "read-more-dis"
                            : "read-more-button"
                        }
                      >
                        {expandedCommentIndex === i ? "Read less" : "Read more"}
                      </button>
                    )}
                  </Card.Text>
                  {data.status == "blocked" || data.status == "reported"
                    ? data.reviewComments.length !== 0 && (
                      <span className="rply_dis" title="view review comment">
                        {data.reviewComments.length === 1
                          ? `${data.reviewComments.length} Reply`
                          : `${data.reviewComments.length} Replies`}
                      </span>
                    )
                    : data.reviewComments.length !== 0 && (
                      <span
                        className="rply c_pointer"
                        title="view review comment"
                        onClick={() =>
                          viewRevComments(
                            data.reviewComments,
                            data.reviewReplies,
                            data.status
                          )
                        }
                      >
                        {data.reviewComments.length === 1
                          ? `${data.reviewComments.length} Reply`
                          : `${data.reviewComments.length} Replies`}
                      </span>
                    )}
                  <span>
                    {data.status == "reported" ||
                      data.status == "blocked" ||
                      data.reviewedBy === props.userId ? (
                      <BiSolidCommentDetail
                        className="cmt_icn clr_gray"
                        title="Add comment"
                      />
                    ) : (
                      <BiSolidCommentDetail
                        className="cmt_icn c_pointer"
                        title="Add comment"
                        onClick={() => togglecomment(data.id)}
                      />
                    )}
                    {currentIndex == data.id ? (
                      <Row>
                        <div className="m-2 position-relative">
                          {cmtError && cmtErrId == data.id ? (
                            <div className="text-danger fz-12">{cmtError}</div>
                          ) : (
                            ""
                          )}
                          <MentionsInput
                            // style={mentionInputStyle}
                            style={{
                              ...mentionInputStyle,
                              height: "40px",
                              minHeight: "44px",
                              border: "2px solid #e0e0e0", // Lighter gray border
                            }}
                            className="form-control me_cmt_box"
                            maxLength={288}
                            onChange={(e) => {
                              changeCmtText(e.target.value, "parent");
                            }}
                            value={comment}
                            placeholder="type someting..."
                          >
                            <Mention
                              style={{ backgroundColor: "#efebe3" }}
                              trigger="@"
                              data={users}
                            />
                          </MentionsInput>
                          {/* <textarea style={{marginLeft: '-10px'}} className="form-control Cmt_box" placeholder="type someting..." onChange={(e) => setComment(e.target.value)} value={comment}/> */}
                          <button
                            disabled={postBtnDisable}
                            className="btn btn-primary cmt_btnn"
                            onClick={() => {
                              if (
                                isFeatureEnabled(
                                  allFeatures["Add-Comment%"]?.key,
                                  allFeatures["Add-Comment%"]?.isDeleted
                                )
                              ) {
                                postComment(data.id, 0, "parent");
                              } else {
                                setShowPopup(true);
                              }
                            }}
                          >
                            Post
                          </button>
                        </div>
                      </Row>
                    ) : (
                      ""
                    )}
                  </span>
                </Col>
                <Col sm={12} md={6} lg={3} xl={3}>
                  <div className="d-flex">
                    <div className="w-90">
                      <Card.Text className="mb-0">
                        <span className="txt_rev">Reviewed as:</span>{" "}
                        <span className="fs_13 fw_500">{data.userRole}</span>
                      </Card.Text>
                      <Card.Text className="mb-0">
                        <span className="txt_rev">Date of Review:</span>
                        <span className="fs_13 fw_500">
                          {" "}
                          {moment(data.createdAt).format("DD-MMM-YYYY")}
                        </span>
                      </Card.Text>
                      <Card.Text className="mb2">
                        <div className="d-flex align-items-center">
                          {data.rating != 0 ? (
                            <span className="font-weight-bold fs_13  pr-2">
                              {/* {getRevQnsRating(data.reviewQuestion)}&nbsp; */}
                              {data.rating.toFixed(1)}
                            </span>
                          ) : (
                            ""
                          )}
                          <StarRating
                            rating={data.rating}
                            disabled={true}
                            customClass="user-star"
                          />
                          {data.reviewQuestion.length != 0 && (
                            <OverlayTrigger
                              trigger="click"
                              key={data.id}
                              placement="bottom"
                              overlay={
                                <Popover
                                  id={`popover-${data.id}`}
                                  className="pop_rating"
                                >
                                  <div className="drp_rating"></div>
                                  {data.reviewQuestion.length === 0 ? (
                                    <div>No questions found</div>
                                  ) : (
                                    data.reviewQuestion.map((item, index) => (
                                      <Row className="d-flex" key={index}>
                                        <Col
                                          sm={1}
                                          md={1}
                                          lg={1}
                                          xl={1}
                                          className="cus-col p-0 prog_span d-flex align-items-center"
                                        >
                                          {index + 1}.
                                        </Col>
                                        <Col
                                          sm={5}
                                          md={4}
                                          lg={4}
                                          xl={4}
                                          className="cus-col-ques p-0 d-flex align-items-center"
                                        >
                                          <span className="prog_span">
                                            {item.question}
                                          </span>
                                        </Col>
                                        <Col
                                          sm={6}
                                          md={7}
                                          lg={7}
                                          xl={7}
                                          className="cus-col-ratng p-0"
                                        >
                                          <StarRating
                                            rating={item.rating}
                                            disabled={true}
                                            customClass="ques-star"
                                          />
                                        </Col>
                                      </Row>
                                    ))
                                  )}
                                </Popover>
                              }
                              rootClose
                            >
                              <span
                                style={{ marginLeft: "5px" }}
                                className="d-flex align-items-center c_pointer"
                                onClick={() =>
                                  handleArrowClick(
                                    data.starRating,
                                    data.averageReviewCount
                                  )
                                }
                              >
                                <BsQuestionDiamond className="clr_gray" />
                              </span>
                            </OverlayTrigger>
                          )}
                          {data.reviewAttachment.length != 0 && (
                            <span
                              title="Attachment"
                              className="attachmtt"
                              onClick={() =>
                                viewAttachment(data.reviewAttachment)
                              }
                            >
                              <MdOutlineAttachment />
                            </span>
                          )}
                        </div>
                      </Card.Text>
                    </div>
                    <div className="w-10 mt-1">
                      <div className="d-flex flex-column">
                        {isFeatureEnabled(
                          allFeatures["Report%"]?.key,
                          allFeatures["Report%"]?.isDeleted
                        ) ? (
                          data.status === "reported" ||
                            data.status == "blocked" ? (
                            <MdOutlineReport
                              className="dis_rep_icon"
                              title="Report"
                            />
                          ) : (
                            <MdOutlineReport
                              className="rep_icon"
                              title="Report"
                              onClick={() => {
                                sendReport(data.id);
                              }}
                            />
                          )
                        ) : (
                          <MdOutlineReport
                            className="rep_icon"
                            title="Report"
                            onClick={(e) => {
                              setShowPopup(true);
                            }}
                          />
                        )}
                        <div className="div_line"></div>
                        {isFeatureEnabled(
                          allFeatures["SM-Share-Feature%"]?.key,
                          allFeatures["SM-Share-Feature%"]?.isDeleted
                        ) ? (
                          <React.Fragment>
                            {data.status === "reported" ||
                              data.status == "blocked" ? (
                              <BsShareFill
                                className="dis_share_icon"
                                title="Share"
                              />
                            ) : (
                              <BsShareFill
                                className="share_icon"
                                title="Share"
                                onClick={() => handleShare(data)}
                              />
                            )}
                          </React.Fragment>
                        ) : (
                          <BsShareFill
                            className="share_icon"
                            title="Share"
                            onClick={() => setShowPopup(true)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={6} lg={3} xl={3}>
                  {likeFeatureEnable ? (
                    <RatingBar
                      disabled={
                        data.status === "reported" || data.status === "blocked"
                          ? true
                          : false
                      }
                      likecount={data.likes}
                      dislikecount={data.dislikes}
                      liked={data.liked}
                      handlelike={() => handlelikechange(data.id, data.userId)}
                      handledislike={() =>
                        handledislikechange(data.id, data.userId)
                      }
                      handleRmvlike={() =>
                        likecntDecrement(data.id, data.userId)
                      }
                      labelVAlue={data.score}
                    />
                  ) : (
                    <>{renderRatingBar(data, false)}</>
                  )}
                </Col>
              </Row>
            </Card.Body>
            {data.status === "new" && <div className="new-text">New</div>}
          </Card>
        ))
    ) : loading || pinLoading ? (
      <div className="loader-center">
        <img className="loader-image" src={loader} alt="Loading..." />
      </div>
    ) : (
      <div className="row col-md-12 p-0 d-align mx-0">
        <div className="no-data w-100 font-weight-600 text-center fz_14">
          Sorry, there is no data to be displayed
        </div>
      </div>
    );
  }
  const handleUserRole = (event) => {
    const roleId = event.target.value;
    setSelectedRoleId(roleId);

    if (roleId) {
      const filtered = userreviewList.filter(
        (review) => review.roleId === parseInt(roleId, 10)
      );
      setFilteredList(filtered);
      if (filtered.length > 0) {
        const totalRating = filtered.reduce(
          (acc, review) => acc + (review.rating || 0),
          0
        );
        const averageRating = totalRating / filtered.length;
        setAvgRatng(averageRating);
        setRatngCount(filtered.length);
      } else {
        console.log("No reviews available for the selected role.");
      }
      if (filtered.length == 0) {
        setIsList(false);
      } else {
        setIsList(true);
      }
    } else {
      setFilteredList(userreviewList);
      setIsList(true);
    }
  };

  const pinReview = async (id, pinnedBy) => {
    setId(id);
    setPinBy(pinnedBy);
    setShowPinRev(true);
  };

  const handlePinReview = async () => {
    if (!duration && pinBy != null) {
      setErrMsg("Please select a pin duration");
      return;
    }
    const params = {
      id: id,
      pinnedBy: pinBy,
      duration: duration,
      userId: props && props.userId,
    };
    if (id) {
      setPinLoading(true);
      setShowPinRev(false);
      try {
        const response = await window.apiService.pinReview(params);
        if (response.status === "OK") {
          toast.success(
            duration ? response.message : "Review Unpinned successfully"
          );
          userDetails();
          setDuration("");
          setPinBy("");
          setErrMsg("");
          setPinLoading(false);
        } else {
          setPinLoading(false);
          toast.error(response.message);
        }
      } catch (error) {
        setPinLoading(false);
        console.log(error);
        toast.error("Error occurred");
      }
    }
  };

  return (
    <>
      <HeaderMenu />
      <div className="">
        <SideBar />
        <div className="dash_hgt ml_200">
          {/* <BreadCrumb /> */}
          {status && (
            <div
              style={{ padding: "10px" }}
              className="d-flex justify-content-center"
            >
              <marquee direction="left" className="mrq_txt">
                {status.message}
              </marquee>
            </div>
          )}
          <div className="row h-100">
            <div className="col-12 col-sm-4 col-md-3 pd_rt">
              <Card className="shadow h-100">
                <Card.Body
                  className={`me_card_nw me_revList_nw ${loading ? "me_sty" : ""
                    }`}
                  style={{ scrollbarWidth: "none" }}
                >
                  <Col sm={12} md={12} lg={12} xl={12}>
                    <Row className="pr_cd">
                      <Col
                        className={`${userData.userType === "Business" &&
                            (isFeatureEnabled(
                              allFeatures["BS,-PS%"]?.key,
                              allFeatures["BS,-PS%"]?.isDeleted
                            ) ||
                              isFeatureEnabled(
                                allFeatures["Culture-Score%"]?.key,
                                allFeatures["Culture-Score%"]?.isDeleted
                              )) &&
                            (userData.business?.showBusinessScore ||
                              userData.business?.showPeopleScore ||
                              userData.business?.showCultureScore)
                            ? "dash_prof"
                            : ""
                          }`}
                        sm={12}
                        md={12}
                        lg={
                          userData.userType === "Business" &&
                            (isFeatureEnabled(
                              allFeatures["BS,-PS%"]?.key,
                              allFeatures["BS,-PS%"]?.isDeleted
                            ) ||
                              isFeatureEnabled(
                                allFeatures["Culture-Score%"]?.key,
                                allFeatures["Culture-Score%"]?.isDeleted
                              )) &&
                            (userData.business?.showBusinessScore ||
                              userData.business?.showPeopleScore ||
                              userData.business?.showCultureScore) &&
                            isWideScreen
                            ? 3
                            : 12
                        }
                        xl={
                          userData.userType === "Business" &&
                            (isFeatureEnabled(
                              allFeatures["BS,-PS%"]?.key,
                              allFeatures["BS,-PS%"]?.isDeleted
                            ) ||
                              isFeatureEnabled(
                                allFeatures["Culture-Score%"]?.key,
                                allFeatures["Culture-Score%"]?.isDeleted
                              )) &&
                            (userData.business?.showBusinessScore ||
                              userData.business?.showPeopleScore ||
                              userData.business?.showCultureScore) &&
                            isWideScreen
                            ? 3
                            : 12
                        }
                      >
                        <div className="mecrd_bdr_bm me_cd">
                          <div className="d-flex justify-content-center">
                            <div
                              className=" d-flex align-items-center kyc_vf"
                              style={{ margin: " 0px 30px" }}
                            >
                              <img
                                className="meImg_prof"
                                src={
                                  userData.userType == "Professional"
                                    ? userData.professional.imagePath
                                      ? userData.professional.imagePath
                                      : profile
                                    : userData.userType == "Business"
                                      ? userData.business.logo
                                        ? userData.business.logo
                                        : profile
                                      : userData.imagePath
                                        ? userData.imagePath
                                        : profile
                                }
                              />
                            </div>
                            {isFeatureEnabled(
                              allFeatures["Chat%"]?.key,
                              allFeatures["Chat%"]?.isDeleted
                            ) ? (
                              <div
                                className="d-flex align-items-center c_pointer"
                                style={{ marginLeft: "50px" }}
                                onClick={() => {
                                  navigate("/inbox/3");
                                }}
                              >
                                <img
                                  title="Chat"
                                  src={chatImg}
                                  alt="chat"
                                  height={25}
                                  width={25}
                                />
                                <span className="cht_icn">Chat</span>
                              </div>
                            ) : (
                              <div
                                className="d-flex align-items-center c_pointer"
                                style={{ marginLeft: "50px" }}
                                onClick={() => setShowPopup(true)}
                              >
                                <img
                                  title="Chat"
                                  src={chatImg}
                                  alt="chat"
                                  height={25}
                                  width={25}
                                />
                                <span className="cht_icn">Chat</span>
                              </div>
                            )}
                          </div>
                          {userData.userType !== "Business" && (
                            <div
                              className=" d-flex justify-content-center align-items-center mt-2"
                              style={{ height: "20px", marginLeft: "6px" }}
                            >
                              <div className="d-flex">
                                {userData.userType == "Professional" ? (
                                  userData.professional.averageReviewScore >
                                    0.0 ? (
                                    <label className="font-weight-bold mt_7 fs_13">
                                      {userData.professional.averageReviewScore.toFixed(
                                        1
                                      )}
                                    </label>
                                  ) : (
                                    ""
                                  )
                                ) : userData.userType == "Business" ? (
                                  userData.business.averageReviewScore > 0.0 ? (
                                    <label className="font-weight-bold mt_7 fs_13">
                                      {userData.business.averageReviewScore.toFixed(
                                        1
                                      )}
                                    </label>
                                  ) : (
                                    ""
                                  )
                                ) : userData.averageReviewScore > 0.0 ? (
                                  <label className="font-weight-bold mt_7 fs_13 pr-2">
                                    {userData.averageReviewScore.toFixed(1)}
                                  </label>
                                ) : (
                                  ""
                                )}

                                <StarRating
                                  customClass="user-star"
                                  rating={
                                    userData.userType == "Professional"
                                      ? userData.professional.averageReviewScore
                                      : userData.userType == "Business"
                                        ? userData.business.averageReviewScore
                                        : userData.averageReviewScore
                                  }
                                  disabled={true}
                                />
                              </div>
                              <span className="fs_13 pl-2">
                                (
                                {userData.userType == "Professional"
                                  ? userData.professional.totalReviewCount
                                  : userData.userType == "Business"
                                    ? userData.business.totalReviewCount
                                    : userData.totalReviewCount}
                                )
                              </span>
                              <span
                                className="fs_13 c_pointer"
                                onClick={handleButtonClick}
                                ref={targetRef}
                              >
                                <IoIosArrowDown />
                              </span>
                            </div>
                          )}
                          <div className=" d-flex justify-content-center align-items-center mt-2">
                            <div className="disply_fol pad_rt_15">
                              <div className="circle-background  text-center  ">
                                {userData.userType == "Professional"
                                  ? userData.professional
                                    ?.professionalFollowerCount
                                  : userData.userType == "Business"
                                    ? userData.business?.businessFollowerCount
                                    : userData.followersCount}
                              </div>
                              <div className="me_txt">Followers</div>
                            </div>
                            <div className="disply_fol pad_lt_15 border-right-0">
                              <div className="circle-background  text-center">
                                {userData.userType == "Professional"
                                  ? userData.professional
                                    ?.professionalFollowingCount
                                  : userData.userType == "Business"
                                    ? userData.business?.businessFollowingCount
                                    : userData.followingCount}
                              </div>
                              <div className="me_txt">Following</div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <div className="brdr_lne"></div>
                      {userData.userType === "Business" &&
                        (isFeatureEnabled(
                          allFeatures["BS,-PS%"]?.key,
                          allFeatures["BS,-PS%"]?.isDeleted,
                          allFeatures["BS,-PS%"]?.isDeleted
                        ) ||
                          isFeatureEnabled(
                            allFeatures["Culture-Score%"]?.key,
                            allFeatures["Culture-Score%"]?.isDeleted
                          )) &&
                        (userData.business?.showBusinessScore ||
                          userData.business?.showPeopleScore ||
                          userData.business?.showCultureScore) && (
                          <Col
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className="pad_15"
                          >
                            {userData.userType === "Business" &&
                              (isFeatureEnabled(
                                allFeatures["BS,-PS%"]?.key,
                                allFeatures["BS,-PS%"]?.isDeleted,
                                allFeatures["BS,-PS%"]?.isDeleted
                              ) ||
                                isFeatureEnabled(
                                  allFeatures["Culture-Score%"]?.key,
                                  allFeatures["Culture-Score%"]?.isDeleted
                                )) &&
                              (userData.business?.showBusinessScore ||
                                userData.business?.showPeopleScore ||
                                userData.business?.showCultureScore) && (
                                <>
                                  <div className="bs_icon">
                                    <div className="d-flex justify-content-end">
                                      <LuActivitySquare
                                        className="fz_22"
                                        onClick={() => setShowBusnScore(true)}
                                      />
                                    </div>
                                  </div>
                                  {isWideScreen ? (
                                    <BusinessScores
                                      businessScore={
                                        userData.business?.showBusinessScore
                                      }
                                      cultureScore={
                                        userData.business?.showCultureScore
                                      }
                                      peopleScore={
                                        userData.business?.showPeopleScore
                                      }
                                      userData={userData}
                                      loadChart={() => profileChartInfo()}
                                      setScoreType={setScoreType}
                                      scoreType={scoreType}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                          </Col>
                        )}
                      {userData.userType === "Business" &&
                        (isFeatureEnabled(
                          allFeatures["BS,-PS%"]?.key,
                          allFeatures["BS,-PS%"]?.isDeleted,
                          allFeatures["BS,-PS%"]?.isDeleted
                        ) ||
                          isFeatureEnabled(
                            allFeatures["Culture-Score%"]?.key,
                            allFeatures["Culture-Score%"]?.isDeleted
                          )) &&
                        (userData.business?.showBusinessScore ||
                          userData.business?.showPeopleScore ||
                          userData.business?.showCultureScore) && (
                          <>
                            <div className="brdr_lne"></div>
                          </>
                        )}
                      <Col
                        sm={12}
                        md={12}
                        lg={
                          userData.userType === "Business" &&
                            (isFeatureEnabled(
                              allFeatures["BS,-PS%"]?.key,
                              allFeatures["BS,-PS%"]?.isDeleted
                            ) ||
                              isFeatureEnabled(
                                allFeatures["Culture-Score%"]?.key,
                                allFeatures["Culture-Score%"]?.isDeleted
                              )) &&
                            (userData.business?.showBusinessScore ||
                              userData.business?.showPeopleScore ||
                              userData.business?.showCultureScore)
                            ? 12
                            : 12
                        }
                        xl={12}
                        className="pad_15 pd_btm"
                      >
                        <div className="me_center_div me_cn_dv ">
                          <Card.Text className="mb-0 alert-link">
                            {userData.userType == "Business"
                              ? userData.business.companyName
                              : userData.name}
                            {isFeatureEnabled(
                              allFeatures["Kyc%"]?.key,
                              allFeatures["Kyc%"]?.isDeleted
                            ) &&
                              userData.isKycVerified &&
                              userData.userType == "Personal" ? (
                              <HiBadgeCheck
                                style={{
                                  fontSize: "22px",
                                  color: "green",
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                }}
                                title="KYC Verified"
                                data-toggle="tooltip"
                                data-placement="top"
                              />
                            ) : (
                              <></>
                            )}
                          </Card.Text>
                          <div className="contact-item">
                            {userData.userType == "Professional" ? (
                              <div className="">
                                {userData.professional.location ? (
                                  <>
                                    <HiLocationMarker />
                                    &nbsp;
                                    <span className="me_txt">
                                      {userData.professional.location}
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : userData.userType == "Business" ? (
                              <div className="">
                                {userData.business.city ||
                                  userData.business.state ? (
                                  <>
                                    <HiLocationMarker />
                                    &nbsp;
                                    <span className="me_txt">
                                      {userData.business.city
                                        ? userData.business.city
                                        : ""}
                                      {userData.business.city &&
                                        userData.business.state
                                        ? ","
                                        : ""}
                                      {userData.business.state
                                        ? userData.business.state
                                        : ""}
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              <div className="">
                                {userData.city || userData.state ? (
                                  <>
                                    <HiLocationMarker />
                                    &nbsp;
                                    <span className="me_txt">
                                      {userData.city ? userData.city : ""}
                                      {userData.city && userData.state
                                        ? ","
                                        : ""}{" "}
                                      {userData.state ? userData.state : ""}
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </div>
                          {renderContactItem(phone, FaPhoneAlt)}
                          {userData.business?.website ? (
                            <div className="contact-item">
                              <PiLinkBold /> &nbsp;
                              <a
                                href={userData.business?.website}
                                target="_blank"
                                className="a_social"
                              >
                                <span
                                  className="me_txt name_blue h_22"
                                  title={userData.business?.website}
                                >
                                  {" "}
                                  {userData.business?.website.length >= 30
                                    ? userData.business?.website
                                      .substring(0, 30)
                                      .concat("...")
                                    : userData.business?.website}
                                </span>
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                          {renderContactItem(email, MdEmail)}
                          <div className="me_txt fz_14">
                            {userData.userType == "Professional"
                              ? userData.professional?.bio
                              : userData.userType == "Business"
                                ? userData.business?.bio
                                : userData.bio}
                          </div>
                          {userData.isPublic && (
                            <div className="mt-2">
                              {businessurl.facebookUrl ? (
                                <a
                                  href={businessurl.facebookUrl}
                                  target="_blank"
                                  className="a_social"
                                >
                                  <AiFillFacebook
                                    className="c_pointer social_icon_f mr-2"
                                    size={28}
                                    title="facebook"
                                  />
                                </a>
                              ) : userData.facebookUrl ? (
                                <a
                                  href={userData.facebookUrl}
                                  target="_blank"
                                  className="a_social"
                                >
                                  <AiFillFacebook
                                    className="c_pointer social_icon_f mr-2"
                                    size={28}
                                    title="facebook"
                                  />
                                </a>
                              ) : null}
                              {businessurl.linkedinUrl ? (
                                <a
                                  href={businessurl.linkedinUrl}
                                  target="_blank"
                                  className="a_social"
                                >
                                  <FaLinkedin
                                    size={25}
                                    className="social_icon_li mr-2"
                                    title="LinkedIn"
                                  />
                                </a>
                              ) : userData.linkedinUrl ? (
                                <a
                                  href={userData.linkedinUrl}
                                  target="_blank"
                                  className="a_social"
                                >
                                  <FaLinkedin
                                    size={25}
                                    className="social_icon_li mr-2"
                                    title="LinkedIn"
                                  />
                                </a>
                              ) : null}
                              {businessurl.instagramUrl ? (
                                <a
                                  href={businessurl.instagramUrl}
                                  target="_blank"
                                  className="a_social mr-2"
                                >
                                  <InstagramIcon title="Instagram" />
                                </a>
                              ) : userData.instagramUrl ? (
                                <a
                                  href={userData.instagramUrl}
                                  target="_blank"
                                  className="a_social mr-2"
                                >
                                  <InstagramIcon title="Instagram" />
                                </a>
                              ) : null}
                              {businessurl.twitterUrl ? (
                                <a
                                  href={businessurl.twitterUrl}
                                  target="_blank"
                                  className="a_social"
                                >
                                  <FaSquareXTwitter
                                    size={25}
                                    className="rounded-0 social_icon mr-2"
                                    title="X"
                                  />
                                </a>
                              ) : userData.twitterUrl ? (
                                <a
                                  href={userData.twitterUrl}
                                  target="_blank"
                                  className="a_social"
                                >
                                  <FaSquareXTwitter
                                    size={25}
                                    className="rounded-0 social_icon mr-2"
                                    title="X"
                                  />
                                </a>
                              ) : null}
                              {userData.facebookUrl ||
                                userData.linkedinUrl ||
                                userData.instagramUrl ||
                                userData.twitterUrl ? (
                                <a
                                  href="https://setld.app/"
                                  target="_blank"
                                  className="a_social mr-2"
                                >
                                  <img
                                    src={setldImg}
                                    height={22}
                                    width={22}
                                    title="Setld"
                                  />
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </div>
                        {isFree && (
                          <div>
                            <Button
                              onClick={() => {
                                navigate("/dashboard/profile", {
                                  state: { viewPlans: true },
                                });
                              }}
                              className="btn btn-danger"
                              style={{
                                borderRadius: "20px",
                                marginTop: "20px",
                                fontSize: "12px",
                                padding: "3px 7px ",
                              }}
                            >
                              Subscribe <MdDoubleArrow />
                            </Button>
                          </div>
                        )}
                      </Col>
                      <div className="brdr_lne"></div>
                      <Col
                        className="pad_15 pd_btmm"
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <LineChart
                          data={graphRating}
                          labels={labels}
                          additionalData={reviewCount}
                        />
                        <div className="d-flex justify-content-end">
                          <Col sm={12} md={12} lg={12} xl={12}>
                            <select
                              className="rle_place form-control input_select fz-12"
                              value={aggregationType}
                              onChange={(e) =>
                                setAggregationType(e.target.value)
                              }
                            >
                              <option value="yearly">Yearly</option>
                              <option value="monthly">Monthly</option>
                              <option value="weekly">Weekly</option>
                              <option value="daily">Days</option>
                            </select>
                          </Col>
                        </div>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col sm={12} md={4} lg={4} xl={4}>
                        <Card.Text className="card_txt"></Card.Text>
                        {/* <Card.Text >Total Reviews: {userData.totalReviewCount}</Card.Text> */}
                        <Overlay
                          show={showPopover}
                          target={targetRef.current}
                          placement="bottom"
                          container={document.body}
                          onHide={handleOverlayClose}
                          rootClose
                        >
                          <Popover id="popover" className="pop_rating">
                            <div className="drp_rating">
                              <StarRating
                                customClass="user-star"
                                rating={
                                  userData.userType === "Professional"
                                    ? userData.professional.averageReviewCount
                                    : userData.userType === "Business"
                                      ? userData.business.averageReviewCount
                                      : userData.averageReviewCount
                                }
                                disabled={true}
                              />
                              <span
                                style={{
                                  marginLeft: "-45px",
                                  marginTop: "3px",
                                }}
                              >
                                {userData.userType === "Professional"
                                  ? userData.professional.averageReviewCount
                                  : userData.userType === "Business"
                                    ? userData.business.averageReviewCount
                                    : userData.averageReviewCount}{" "}
                                out of 5
                              </span>
                            </div>
                            {globalRating.map((item, index) => (
                              <Row key={index}>
                                <Col
                                  sm={12}
                                  md={2}
                                  lg={2}
                                  xl={2}
                                  className="p-0"
                                >
                                  <span className="prog_span">
                                    {item.label}
                                  </span>{" "}
                                </Col>
                                <Col
                                  sm={12}
                                  md={7}
                                  lg={7}
                                  xl={7}
                                  className="p-0"
                                >
                                  <Horizontalbar dataSet={item.value} />
                                </Col>
                                <Col
                                  sm={12}
                                  md={3}
                                  lg={3}
                                  xl={3}
                                  className="p-0 prog_span"
                                // style={{ marginLeft: "10px" }}
                                >
                                  {/* <span className="tooltip"> */}
                                  <span
                                    className="c_pointer"
                                    onClick={() => {
                                      loadRevsByStars(
                                        props.userId,
                                        props.userType,
                                        props.profileId,
                                        +item.label[0]
                                      );
                                      setClickedStarVal(item.label);
                                    }}
                                  >
                                    &nbsp;
                                    {item.value}
                                    {item.value > 1 ? (
                                      <FiUsers style={{ marginLeft: "6px" }} />
                                    ) : (
                                      <FiUser style={{ marginLeft: "6px" }} />
                                    )}
                                  </span>{" "}
                                  {clickedStarVal &&
                                    item.label == clickedStarVal ? (
                                    <span className="text-red c_pointer fz_15">
                                      <IoMdCloseCircle
                                        title="Clear"
                                        onClick={() => {
                                          setToggleStarRev(false);
                                          setClickedStarVal("");
                                        }}
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {/* {commonUtils.ratingTooltip(currentUsers)} */}
                                  {/* </span> */}
                                </Col>
                              </Row>
                            ))}
                          </Popover>
                        </Overlay>
                      </Col>
                    </Row>
                    {/* </Row> */}
                  </Col>
                </Card.Body>
              </Card>
            </div>
            <div className="col-12 col-sm-8 col-md-9 pd_sz">
              <Card className="shadow h-100">
                <div className="pro_tab_div">
                  <div style={{ position: "relative" }}>
                    <Tabs
                      activeKey={eventKey}
                      defaultActiveKey="Personal"
                      id="profile_tab"
                      className="mb-2 dash-tab fs14"
                      onSelect={(key) => {
                        if (!loading) {
                          handleTabChange(key);
                        }
                      }}
                    >
                      <Tab
                        eventKey="Personal"
                        title={
                          <span className="pro_tab_span">
                            <IoPersonCircleSharp
                              className={`dashboardTabIcon ${eventKey == "Personal" ? "clr_darkblue" : ""
                                }`}
                            />
                            Personal
                          </span>
                        }
                      >
                        <div className="me_revList_nw">
                          {toggleStarRev
                            ? renderReviewCards(starRevList)
                            : renderReviewCards(userreviewList)}
                        </div>
                      </Tab>
                      {isFeatureEnabled(
                        allFeatures["Professional-Profile%"]?.key,
                        allFeatures["Professional-Profile%"]?.isDeleted
                      ) &&
                        userProfList.length > 0 && (
                          <Tab
                            eventKey="Professional"
                            title={
                              <span className="pro_tab_span">
                                <FaUserTie
                                  className={`dashboardTabIcon ${eventKey === "Professional"
                                      ? "clr_darkblue"
                                      : ""
                                    }`}
                                />
                                <span>
                                  Professional{" "}
                                  {!modalShow ? (
                                    <IoMdArrowDropdown className="fs_20" />
                                  ) : (
                                    <IoMdArrowDropup className="fs_20" />
                                  )}
                                </span>
                              </span>
                            }
                          >
                            <div className="me_revList_nw">
                              {modalShow && (
                                <div
                                  className="overlay"
                                  onClick={() => setModalShow(false)}
                                />
                              )}
                              {modalShow && (
                                <div
                                  className="switch_dropdn"
                                  ref={dropdownRef}
                                >
                                  {userProfList.length > 0 &&
                                    userProfList.map((usertype) => (
                                      <div
                                        className="fz_14 mb-2 c_pointer"
                                        disabled={
                                          usertype.professionalId ==
                                          props.profileId
                                        }
                                        onClick={() =>
                                          Switchaccount(
                                            usertype.userTypeName,
                                            props.userId,
                                            usertype.professionalId
                                          )
                                        }
                                      >
                                        {usertype.professionalType}&nbsp;
                                        {props.profileId ==
                                          usertype.professionalId ? (
                                          <span className="act_acc">
                                            Active
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ))}
                                </div>
                              )}
                              {renderReviewCards(userreviewList)}
                            </div>
                          </Tab>
                        )}
                      {isFeatureEnabled(
                        allFeatures["Business-Profile%"]?.key,
                        allFeatures["Business-Profile%"]?.isDeleted
                      ) &&
                        userBusnList.length > 0 && (
                          // {false && (
                          <Tab
                            eventKey="Business"
                            title={
                              <span className="pro_tab_span">
                                <MdBusinessCenter
                                  className={`dashboardTabIcon ${eventKey == "Business" ? "clr_darkblue" : ""
                                    }`}
                                />
                                <span>
                                  Business
                                  {!busnModalShow ? (
                                    <IoMdArrowDropdown className="fs_20" />
                                  ) : (
                                    <IoMdArrowDropup className="fs_20" />
                                  )}
                                </span>
                              </span>
                            }
                          >
                            <div className="me_revList_nw">
                              {busnModalShow && (
                                <div
                                  className="overlay"
                                  onClick={() => setBusnModalShow(false)}
                                />
                              )}
                              {busnModalShow && (
                                <div
                                  className="switch_dropdn swh_dpdn_lf"
                                  ref={dropdownRef}
                                >
                                  {userBusnList.length > 0 &&
                                    userBusnList.map((usertype) => (
                                      <div
                                        className="fz_14 mb-2 c_pointer"
                                        disabled={
                                          usertype.businessId == props.profileId
                                        }
                                        onClick={() =>
                                          Switchaccount(
                                            usertype.userTypeName,
                                            props.userId,
                                            usertype.businessId
                                          )
                                        }
                                      >
                                        <span title={usertype.companyName}>
                                          {usertype.shortName !== undefined
                                            ? usertype.shortName
                                            : usertype.companyName?.length > 20
                                              ? `${usertype.companyName.substring(
                                                0,
                                                20
                                              )}...`
                                              : usertype.companyName}
                                        </span>{" "}
                                        &nbsp;
                                        {props.profileId ==
                                          usertype.businessId ? (
                                          <span className="act_acc">
                                            Active
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ))}
                                </div>
                              )}
                              {renderReviewCards(userreviewList)}
                            </div>
                          </Tab>
                          // )}
                        )}
                      <Tab
                        eventKey="Groups"
                        title={
                          <span className="pro_tab_span">
                            <TiGroup
                              className={`dashboardTabIcon ${eventKey === "Groups" ? "clr_darkblue" : ""
                                }`}
                            />
                            <span>Groups</span>
                          </span>
                        }
                      >
                        <GroupsTab />
                      </Tab>
                      {userData.isKycVerified &&
                        isFeatureEnabled(
                          allFeatures["Tiptok%"]?.key,
                          allFeatures["Tiptok%"]?.isDeleted
                        ) && (
                          <Tab
                            eventKey="tiiptok"
                            title={
                              <span className="pro_tab_span">
                                <IoIosChatboxes
                                  className={`dashboardTabIcon ${eventKey === "tiiptok" ? "clr_darkblue" : ""
                                    }`}
                                />
                                <span>TiipTok</span>
                              </span>
                            }
                          >
                            <TiipTokTab
                              userId={props.userId}
                              userType={props.userType}
                              profileId={props.profileId}
                              imagePath={
                                userData.userType === "Personal" ||
                                  userData.userType === "Professional"
                                  ? userData.imagePath || profile
                                  : userData.userType === "Business"
                                    ? userData.business.logo || profile
                                    : profile
                              }
                            />
                          </Tab>
                        )}
                      {avgRatng != 0 ? (
                        <Tab
                          className="str"
                          title={
                            <span
                              className="d-flex align-items-center"
                              style={{ fontSize: "12px", height: "24px" }}
                            >
                              <span className="mar_ratng">
                                {Number(avgRatng).toFixed(1)}
                              </span>
                              <StarRating
                                className="avgRat"
                                rating={avgRatng}
                                disabled={true}
                                customClass="special-star"
                              />
                              <span className="mar_cnt">({ratngCount})</span>
                            </span>
                          }
                        ></Tab>
                      ) : (
                        <Tab
                          eventKey="rating_none"
                          style={{ border: "none" }}
                          title={<span></span>}
                        ></Tab>
                      )}
                      {eventKey == "Personal" ||
                        eventKey == "Professional" ||
                        eventKey == "Business" ? (
                        <Tab
                          title={
                            <span
                              className="pro_tab_span"
                              style={{ paddingRight: "0 px" }}
                            >
                              <span
                                className="filImg"
                                title="filter"
                                onClick={toggleFilterSection}
                              >
                                <img src={filter} height={18} className="" />
                              </span>
                              {showFilterSection && (
                                <>
                                  <select
                                    className="border border-0 fs_12 clr_flter"
                                    value={selectedRoleId}
                                    onChange={handleUserRole}
                                  >
                                    <option value="">Select Reviewed as</option>
                                    {roleList &&
                                      roleList.map((role) => (
                                        <option key={role.id} value={role.id}>
                                          {role.name}
                                        </option>
                                      ))}
                                  </select>
                                  {selectedRoleId && (
                                    <span className="clr_icn" onClick={clear}>
                                      <IoMdClose />
                                    </span>
                                  )}
                                </>
                              )}
                            </span>
                          }
                        ></Tab>
                      ) : (
                        <Tab
                          eventKey="rating_none"
                          style={{ border: "none" }}
                          title={<span></span>}
                        ></Tab>
                      )}
                    </Tabs>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
        <Modal size="md" show={isShare} onHide={CloseShare}>
          <Modal.Header style={{ padding: "15px 0px!important" }}>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "17px", fontWeight: 600 }}
            >
              Share
            </Modal.Title>
            <span
              onClick={CloseShare}
              title="Close"
              style={{ cursor: "pointer",marginTop:"-3px" }}
            >
              <AiOutlineClose />
            </span>
          </Modal.Header>
          <Modal.Body className="text-center p-2">
            <div className="container">
              {shareData && (
                <ViewShare shareData={shareData} userData={userData} />
              )}
            </div>
            {urlLink && (
              <FacebookShareButton
                url={urlLink}
                title="facebook"
                className="m-2"
              >
                <FacebookIcon className="c_pointer" size={32} />
              </FacebookShareButton>
            )}
            {urlLink && (
              <TwitterShareButton url={urlLink} title="X" className="m-2">
                <FaSquareXTwitter size={36} title="X" />
              </TwitterShareButton>
            )}
            {urlLink && (
              <LinkedinShareButton url={urlLink} className="m-2">
                <FaLinkedin size={34} title="Share on LinkedIn" />
              </LinkedinShareButton>
            )}
          </Modal.Body>
        </Modal>
        <Modal
          size="md"
          className="report_modal"
          show={showReport}
          onHide={closeReportModal}
        >
          <Modal.Header
            className="border_rst__btm"
            style={{ padding: "15px 0px!important" }}
          >
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "15px", fontWeight: 600 }}
            >
              <h6 className="font-weight-bold">Submit a Report</h6>
            </Modal.Title>
            <span
              onClick={closeReportModal}
              title="Close"
              style={{ cursor: "pointer",marginTop:"-2px" }}
            >
              <AiOutlineClose />
            </span>
          </Modal.Header>
          <Modal.Body style={{ fontSize: "14px" }}>
            <textarea
              className="form-control Comment_sty h_25 mb-2"
              placeholder="Please add details about your report…"
              onChange={handleReport}
              maxLength={288} 

            ></textarea>
            {reportCommentErr && 
              <div className="text-danger">{reportCommentErr}</div>}
              <p className="limt_cmt">Limit: Max. 288 characters</p>
            
            <Col
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="text-center mt-2 p-0"
            >
              <commonUtils.CustomButton
                btnText="Submit"
                btnWidth="100%"
                variant={isSubmitted ? "danger" : "primary"}
                perform={() => submitReport()}
                isDisabled={false}
              />
            </Col>
          </Modal.Body>
        </Modal>
        <Modal
          size="md"
          className="report_modal"
          show={showReply}
          onHide={() => setShowReply(false)}
        >
          <Modal.Header
            className="border_rst__btm"
            style={{ padding: "15px 0px!important" }}
          >
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "15px", fontWeight: 600 }}
            >
              <h6 className="font-weight-bold">Review Comments</h6>
            </Modal.Title>
            <span
              onClick={() => {
                setShowReply(false);
              }}
              title="Close"
              style={{ cursor: "pointer" }}
            >
              <AiOutlineClose />
            </span>
          </Modal.Header>
          <Modal.Body style={{ fontSize: "14px" }}>
            {revCmnt &&
              revCmnt.map((data, i) => (
                <Row>
                  <Col
                    className={`revCmtPop pb-0 ${showChildReply && replies.length != 0 ? "revcmt_bg" : ""
                      }`}
                  >
                    <div className="d-flex">
                      <div>
                        <img
                          className="brd_rad h_w_25"
                          src={
                            data.userImagePath ? data.userImagePath : profile
                          }
                        />
                      </div>
                      <div
                        className="d-flex"
                        style={{ marginLeft: "8px", alignItems: "center" }}
                      >
                        {data.userName}
                        <span className="dat_icn">
                          <GoDotFill />
                        </span>{" "}
                        <div style={{ fontSize: "11px", color: "#959595" }}>
                          {" "}
                          {moment
                            .utc(data.createdAt)
                            .local()
                            .startOf("seconds")
                            .fromNow()}
                        </div>
                      </div>
                    </div>
                    <div className="ctTxt">
                      {textWithLinks(data.commentText).map((word, index) => (
                        <span key={index}>{word} </span>
                      ))}
                    </div>
                    <div class="send d-flex gap_20 mb-2 mt-2">
                      {likeFeatureEnable ? (
                        <RatingBar
                          disabled={
                            reviewSts == "reported" ||
                              reviewSts == "blocked" ||
                              data.userId === props.userId
                              ? true
                              : false
                          }
                          msgreply={true}
                          liked={data.liked}
                          likecount={data.likes}
                          dislikecount={data.dislikes}
                          handlelike={() =>
                            handlerplylikechange(data.id, data.reviewId)
                          }
                          handledislike={() =>
                            handlerplydislikechange(data.id, data.reviewId)
                          }
                          handleRmvlike={() =>
                            rplylikecntDecrement(data.id, data.reviewId)
                          }
                          labelVAlue={data.score}
                        />
                      ) : (
                        <>{renderRatingBar(data, true)}</>
                      )}
                      {reviewSts == "reported" ||
                        reviewSts == "blocked" ||
                        data.userId === props.userId ? (
                        <BiSolidCommentDetail className="cmt_icn clr_gray" />
                      ) : (
                        <BiSolidCommentDetail
                          className="cmt_icon c_pointer"
                          title="Add comment"
                          onClick={() => togglecomment(data.id)}
                        />
                      )}
                      <Row>
                        {replies.filter(
                          (obj) =>
                            obj.id == data.id || obj.parentCommentId == data.id
                        ).length != 0 && (
                            <span
                              className="rply c_pointer"
                              onClick={() =>
                                viewChildComments(replies, data.id, data.reviewId)
                              }
                            >
                              {replies.filter(
                                (obj) =>
                                  obj.id == data.id ||
                                  obj.parentCommentId == data.id
                              ).length === 1
                                ? `${replies.filter(
                                  (obj) =>
                                    obj.id == data.id ||
                                    obj.parentCommentId == data.id
                                ).length
                                } Reply `
                                : `${replies.filter(
                                  (obj) =>
                                    obj.id == data.id ||
                                    obj.parentCommentId == data.id
                                ).length
                                } Replies `}
                              <span>
                                {childcurrentIndex == data.id ? (
                                  <IoMdArrowDropup />
                                ) : (
                                  <IoMdArrowDropdown />
                                )}
                              </span>
                            </span>
                          )}
                      </Row>
                    </div>
                    {currentIndex == data.id ? (
                      <Row>
                        <div className="position-relative p-0">
                          {cmtError && cmtErrId == data.id ? (
                            <div className="text-danger fz-12 ml-2">
                              {cmtError}
                            </div>
                          ) : (
                            ""
                          )}
                          <MentionsInput
                            style={mentionInputStyle}
                            className="form-control Cmt_box"
                            maxLength={288}
                            onChange={(e) => {
                              changeCmtText(e.target.value, "child");
                            }}
                            value={childComment}
                            placeholder="type someting..."
                          >
                            <Mention
                              style={{ backgroundColor: "#efebe3" }}
                              trigger="@"
                              data={users}
                            />
                          </MentionsInput>
                          {/* <textarea className="form-control Cmt_box" placeholder="type someting..." onChange={(e) => setchildComment(e.target.value)} value={childComment}/> */}
                          <button
                            disabled={postBtnDisable}
                            className="btn btn-primary cmt_btn"
                            onClick={() => {
                              if (
                                isFeatureEnabled(
                                  allFeatures["Add-Comment%"]?.key,
                                  allFeatures["Add-Comment%"]?.isDeleted
                                )
                              ) {
                                postComment(data.id, data.reviewId, "child");
                              } else {
                                setShowPopup(true);
                              }
                            }}
                          >
                            Post
                          </button>
                        </div>
                      </Row>
                    ) : (
                      ""
                    )}
                    {childcurrentIndex == data.id && replies.length != 0
                      ? replies.map((item, index) =>
                        item.id == data.id ||
                          item.parentCommentId == data.id ? (
                          <Row>
                            <Col className="revchdcmt pb-0">
                              <div className="d-flex">
                                <div>
                                  <img
                                    className="brd_rad h_w_25"
                                    src={
                                      item.replyUserImagePath
                                        ? item.replyUserImagePath
                                        : profile
                                    }
                                  />
                                </div>
                                <div
                                  className="d-flex"
                                  style={{
                                    marginLeft: "8px",
                                    alignItems: "center",
                                  }}
                                >
                                  {item.replyUserName}
                                  <span className="dat_icn">
                                    <GoDotFill />
                                  </span>{" "}
                                  <div
                                    style={{
                                      fontSize: "11px",
                                      color: "#959595",
                                    }}
                                  >
                                    {" "}
                                    {moment
                                      .utc(item.createdAt)
                                      .local()
                                      .startOf("seconds")
                                      .fromNow()}
                                  </div>
                                </div>
                              </div>
                              <div className="ctTxt">
                                <span className="rep_men">
                                  {"@" + item.userName + " "}
                                </span>
                                {textWithLinks(item.replyCommentText).map(
                                  (word, index) => (
                                    <span key={index}>{word} </span>
                                  )
                                )}
                              </div>
                              <div class="send d-flex gap_20 mb-2 mt-2">
                                {likeFeatureEnable ? (
                                  <RatingBar
                                    disabled={
                                      reviewSts == "reported" ||
                                        item.replyUserId === props.userId
                                        ? true
                                        : false
                                    }
                                    msgreply={true}
                                    liked={item.liked}
                                    likecount={item.likes}
                                    dislikecount={item.dislikes}
                                    handlelike={() =>
                                      handlerplylikechange(
                                        item.replyCommentId,
                                        data.reviewId
                                      )
                                    }
                                    handledislike={() =>
                                      handlerplydislikechange(
                                        item.replyCommentId,
                                        data.reviewId
                                      )
                                    }
                                    handleRmvlike={() =>
                                      rplylikecntDecrement(
                                        item.replyCommentId,
                                        data.reviewId
                                      )
                                    }
                                    labelVAlue={item.score}
                                  />
                                ) : (
                                  <>{renderRatingBar(item, true)}</>
                                )}
                                {reviewSts == "reported" ||
                                  reviewSts == "blocked" ||
                                  item.replyUserId === props.userId ? (
                                  <BiSolidCommentDetail className="cmt_icn clr_gray" />
                                ) : (
                                  <BiSolidCommentDetail
                                    className="cmt_icon c_pointer"
                                    title="Add comment"
                                    onClick={() =>
                                      togglechildcomment(item.replyCommentId)
                                    }
                                  />
                                )}
                              </div>
                              {childrepplycurrentIndex ==
                                item.replyCommentId ? (
                                <Row>
                                  <div className="position-relative p-0">
                                    {cmtError &&
                                      cmtErrId == item.replyCommentId ? (
                                      <div className="text-danger ml-2 fz-12">
                                        {cmtError}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <MentionsInput
                                      style={mentionInputStyle}
                                      className="form-control Cmt_box"
                                      maxLength={288}
                                      onChange={(e) => {
                                        changeCmtText(
                                          e.target.value,
                                          "child"
                                        );
                                      }}
                                      value={childComment}
                                      placeholder="type someting..."
                                    >
                                      <Mention
                                        style={{ backgroundColor: "#efebe3" }}
                                        trigger="@"
                                        data={users}
                                      />
                                    </MentionsInput>
                                    {/* <textarea className="form-control Cmt_box" placeholder="type someting..." onChange={(e) => setchildComment(e.target.value)} value={childComment}/> */}
                                    <button
                                      disabled={postBtnDisable}
                                      className="btn btn-primary cmt_btn"
                                      onClick={() => {
                                        if (
                                          isFeatureEnabled(
                                            allFeatures["Add-Comment%"]?.key,
                                            allFeatures["Add-Comment%"]
                                              ?.isDeleted
                                          )
                                        ) {
                                          postComment(
                                            item.replyCommentId,
                                            data.reviewId,
                                            "child"
                                          );
                                        } else {
                                          setShowPopup(true);
                                        }
                                      }}
                                    >
                                      Post
                                    </button>
                                  </div>
                                </Row>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )
                      )
                      : ""}
                  </Col>
                </Row>
              ))}
          </Modal.Body>
        </Modal>
        <CustomModal
          show={showBusnScore}
          handleClose={() => setShowBusnScore(false)}
          post={true}
          title="Business scores"
          body={
            <>
              <BusinessScores
                businessScore={userData.business?.showBusinessScore}
                cultureScore={userData.business?.showCultureScore}
                peopleScore={userData.business?.showPeopleScore}
                userData={userData}
                loadChart={() => profileChartInfo()}
                setScoreType={setScoreType}
                scoreType={scoreType}
              />
            </>
          }
        />

        {/* ----------- Pin a review popup ---------- */}
        <Modal size="sm" show={showPinRev} onHide={handleClosePin}>
          <Modal.Header>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "15px", fontWeight: 600 }}
            >
              {pinBy != null
                ? "How long do you want to pin the review?"
                : "Unpin Review Confirmation"}
            </Modal.Title>
            <span onClick={handleClosePin} className="c_pointer" title="Close">
              <AiOutlineClose />
            </span>
          </Modal.Header>
          <Modal.Body className="bordr padpin">
            {pinBy != null ? (
              <div>
                <label htmlFor="pin-duration">you can un pin at any time</label>
                <div>
                  <input
                    className="c_pointer"
                    type="radio"
                    id="24hours"
                    name="duration"
                    value="24 hours"
                    checked={duration === "24 hours"}
                    onChange={(e) => {
                      setDuration(e.target.value);
                      setErrMsg("");
                    }}
                  />
                  <label htmlFor="24hours" className="marLf">
                    24 hours
                  </label>
                </div>
                <div>
                  <input
                    className="c_pointer"
                    type="radio"
                    id="7days"
                    name="duration"
                    value="7 days"
                    checked={duration === "7 days"}
                    onChange={(e) => {
                      setDuration(e.target.value);
                      setErrMsg("");
                    }}
                  />
                  <label htmlFor="7days" className="marLf">
                    7 days
                  </label>
                </div>
                <div>
                  <input
                    className="c_pointer"
                    type="radio"
                    id="30days"
                    name="duration"
                    value="30 days"
                    checked={duration === "30 days"}
                    onChange={(e) => {
                      setDuration(e.target.value);
                      setErrMsg("");
                    }}
                  />
                  <label htmlFor="30days" className="marLf">
                    30 days
                  </label>
                  <div className="clr_red">{errMsg}</div>
                </div>
              </div>
            ) : (
              "Are you sure you want to unpin this review?"
            )}
            {loading && (
              <div className="loader-center">
                <img className="loader-image" src={loader} alt="Loading..." />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className="bordr pinBtm">
            <commonUtils.CustomButton
              btnText={pinBy != null ? "Pin Review" : "Yes"}
              btnWidth="fit-content"
              variant="primary"
              perform={() => handlePinReview()}
              isDisabled={false}
            />
            <commonUtils.CustomButton
              btnText={pinBy != null ? "Cancel" : "No"}
              btnWidth="fit-content"
              variant="danger"
              perform={() => handleClosePin(false)}
              isDisabled={false}
            />
          </Modal.Footer>
        </Modal>
        {/* ----------- Plan expiry Notification ---------- */}
        <Modal size="sm" show={showNotify}>
          {/* <Modal.Header>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "15px", fontWeight: 600 }}
            >
              Notification <ImNotification className="clr_red"  />
            </Modal.Title>
            <span
              onClick={handleCloseNotify}
              className="c_pointer"
              title="Close"
            >
              <AiOutlineClose />
            </span>
          </Modal.Header> */}
          <Modal.Header
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 1.2rem",
            }}
          >
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{
                fontSize: "15px",
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              Notification <ImNotification className="clr_red" style={{ marginLeft: "8px",marginTop:'3px' }} />
            </Modal.Title>
            <span
              onClick={handleCloseNotify}
              className="c_pointer"
              title="Close"
              style={{ cursor: 'pointer', fontSize: '15px' }}
            >
              <AiOutlineClose />
            </span>
          </Modal.Header>
          <Modal.Body className="bordr">
            {expiryStatus && <div>{expiryStatus.message}</div>}
          </Modal.Body>

          <Modal.Footer className="bordr pinBtm">
            <commonUtils.CustomButton
              btnText="Renew"
              btnWidth="fit-content"
              variant="primary"
              perform={() =>
                navigate("/dashboard/user_settings/plans_billings")
              }
              isDisabled={false}
            />
            <commonUtils.CustomButton
              btnText="Cancel"
              btnWidth="fit-content"
              variant="danger"
              perform={() => handleCloseNotify()}
              isDisabled={false}
            />
          </Modal.Footer>
        </Modal>

        {/* Attachment popup */}
        <UpgradePopup
          show={showPopup}
          handleClose={() => setShowPopup(false)}
        />

        <AttachmentModal
          show={showAttachment}
          onClose={() => setShowAttachment(false)}
          attachment={attachment}
          checkExt={checkExt}
          fileImg={fileImg}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    email: state.user.email,
    authToken: state.user.token,
    userType: state.user.userType,
    profileId: state.user.profileId,
    userTypeInfo: state.user.userTypeInfo,
    imagePath: state.user.imagePath,
    isPlanExpInApp: state.user.isPlanExpInApp,
    isTrialExpInApp: state.user.isTrialExpInApp,
  };
};
export default connect(mapStateToProps)(MeTab);
