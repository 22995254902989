import HeaderMenu from "../headerMenu";
import SideBar from "../SideBar";
import { Button, Card, Col, Row, Container, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import loader from "../../../assets/images/loader.gif";
import { useToast } from "../../Toast";
import profile from "../../../assets/images/profile.jpg";
import { useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import BreadCrumb from "../../plugins/Breadcrumb";
import { useDispatch } from "react-redux";
import Tab from "react-bootstrap/Tab";
import { AiOutlineClose } from "react-icons/ai";
import Tabs from "react-bootstrap/Tabs";
import filter from "../../../assets/images/filter.jpg";
import { IoMdClose } from "react-icons/io";
import moment from "moment";
import {
  userAction,
  removeMentionNotificationByIndex,
} from "../../../Redux/Actions/userAction";
const Notification = (props) => {
  const [reviewList, setReviewList] = useState([]);
  const [cmtList, setCmtList] = useState([]);
  const [filteredCmtList, setFilteredCmtList] = useState([]);
  const [filteredRevList, setFilteredRevList] = useState([]);
  const [notifyList, setNotifyList] = useState([]);
  const [modalContent, setModalContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [showRevFilter, setShowRevFilter] = useState(false);
  const [eventKey, setEventKey] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedRevUserId, setSelectedRevUserId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [validity, setValidity] = useState(null);

  const MentionNotification = useSelector(
    (state) => state.user.mentionnotification
  );
  const handleFilterUser = (event) => {
    const userId = event.target.value;
    setSelectedUserId(userId);
    const filteredData = cmtList.filter((e) => e.userId == userId);
    setFilteredCmtList(filteredData);
  };
  const handleRevFilterUser = (event) => {
    const userId = event.target.value;
    setSelectedRevUserId(userId);
    const filteredData = reviewList.filter((e) => e.reviewedBy == userId);
    setFilteredRevList(filteredData);
  };
  const clearRevFilter = () => {
    setSelectedRevUserId("");
    loadRevNotifyList();
  };
  const defaultTab = props.isReviewCommentInApp
    ? "review"
    : props.isMentionCommentInApp
      ? "mention"
      : (props.isPlanExpInApp || props.isTrialExpInApp)
        ? "plan notifications"
        : "review";
  const clearCmtFilter = () => {
    setSelectedUserId("");
    loadCmtNotifyList();
  };
  const loadRevNotifyList = async () => {
    setLoading(true);
    try {
      const response = await window.apiService.revMentionListById({
        userId: props.userId,
        professionalId:
          props.userType == "Professional" ? props.profileId : null,
        businessId: props.userType == "Business" ? props.profileId : null,
      });
      let result = response.data.recordInfo;
      setReviewList(result);
      setFilteredRevList(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error occurred");
    }
  };
  const updateReadStatus = async (id) => {
    const params = {
      id: id,
    };
    try {
      const response = await window.apiService.updateRevMentionSts(params);
      if (response.data.status == "OK") {
        loadRevNotifyList();
        Refresh();
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const updatePlanNotifyReadSts = async (id) => {
    const params = {
      senderId: props.userId,
      receiverId: id,
      isRead: true,
    };
    try {
      const response = await window.apiService.readMsg(params);
      if (response.data.status == "OK") {
        loadPlanNotifyList();
        Refresh();
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const updatecmtReadStatus = async (id) => {
    const params = {
      id: id,
    };
    try {
      const response = await window.apiService.updateCmtMentionSts(params);
      if (response.data.status == "OK") {
        loadCmtNotifyList();
        Refresh();
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const handleViewClick = (review) => {
    navigate("/dashboard/notification/view", {
      state: { type: "review", revId: review.id },
    });
    updateReadStatus(review.reviewMentionId);
    dispatch(removeMentionNotificationByIndex(0));
  };
  const handleCmtClick = (cmt) => {
    navigate("/dashboard/notification/view", {
      state: { type: "comment", status: cmt.status, revId: cmt.id },
    });
    updatecmtReadStatus(cmt.commentMentionId);
    dispatch(removeMentionNotificationByIndex(0));
  };
  const loadCmtNotifyList = async () => {
    try {
      const response = await window.apiService.cmtMentionListById({
        userId: props.userId,
        professionalId:
          props.userType == "Professional" ? props.profileId : null,
        businessId: props.userType == "Business" ? props.profileId : null,
      });
      let result = response.data.recordInfo;
      setCmtList(result);
      setFilteredCmtList(result);
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const loadPlanNotifyList = async () => {
    try {
      if (props.userId) {
        const response = await window.apiService.loadPlanNotifyList(
          props.userId
        );
        let result = response.data.recordInfo;
        setNotifyList(result);
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  useEffect(() => {
    loadRevNotifyList();
    loadCmtNotifyList();
    loadPlanNotifyList();
    getPlansById();
  }, [props]);

  const Refresh = async () => {
    if (props.authToken) {
      const switchAccount = localStorage.getItem("switchAccount");
      const params = {
        profileId: props.profileId,
        authToken: props.authToken,
        userType: props.userType ? props.userType : "Personal",
        switchAccount: switchAccount ? switchAccount : false,
      };
      try {
        const data = await window.apiService.refresh(params);
        let result = data.data;
        if (data) {
          dispatch(userAction(result));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    loadRevNotifyList();
    loadCmtNotifyList();
  }, [MentionNotification]);

  console.log("redux data", MentionNotification);

  const getPlansById = async () => {
    if (props) {
        try {
            const response = await window.apiService.getPlanById(props.userId);
            if (response.data.status === "OK") {
                setValidity(response.data.recordInfo.endDate);
            }
        } catch (error) {
            console.log(error);
        }
    }
};

  return (
    <>
      <HeaderMenu />
      <SideBar />
      <div className="main-content ml_200">
        {/* <BreadCrumb /> */}
        <Card className="shade h-500">
          <Container>
            <Tabs defaultActiveKey={defaultTab} className="notify_tab">
              {props.isReviewCommentInApp && (
                <Tab eventKey="review" title="Reviews">
                  <div className="float-right pr-2 mb-2">
                    <span
                      className="pro_tab_span cd_bd d-flex align-items-center"
                      style={{ paddingRight: "0 px" }}
                    >
                      <span
                        className="mt-1 mb-1 mr-2 ml-2"
                        title="filter"
                        onClick={() => setShowRevFilter(!showRevFilter)}
                      >
                        <img src={filter} height={16} className="" />
                      </span>
                      {showRevFilter && (
                        <>
                          <select
                            className="border border-0 fs_12 clr_flter"
                            value={selectedRevUserId}
                            onChange={(e) => handleRevFilterUser(e)}
                          >
                            <option value="">Select</option>
                            {reviewList &&
                              reviewList
                                .filter(
                                  (user, index, self) =>
                                    index ===
                                    self.findIndex(
                                      (u) => u.reviewedBy === user.reviewedBy
                                    )
                                )
                                .map((user) => (
                                  <option
                                    key={user.reviewedBy}
                                    value={user.reviewedBy}
                                  >
                                    {user.reviewerName}
                                  </option>
                                ))}
                          </select>
                          {selectedRevUserId && (
                            <span
                              className="clr_icn c_pointer"
                              onClick={() => clearRevFilter()}
                            >
                              <IoMdClose />
                            </span>
                          )}
                        </>
                      )}
                    </span>
                  </div>
                  {filteredRevList.length > 0 ? (
                    filteredRevList.map((review, i) => (
                      <Card
                        className={`notify_card_rev mb-2 col-12 ${
                          review.isRead == true
                            ? "not_crd_bg_rd crd_shd"
                            : "not_crd_bg shadow"
                        }`}
                        onClick={() => {
                          handleViewClick(review);
                        }}
                      >
                        <Card.Body className="p-0">
                          <Row>
                            <span className="noty_bar_men p-0"></span>
                            <Col sm={2} md={2} lg={1} xl={1}>
                              <div className="follow_profile m-2">
                                <img
                                  src={
                                    review.reviewerImagePath
                                      ? review.reviewerImagePath
                                      : profile
                                  }
                                  className="rounded-circle"
                                  width="50"
                                  height="50"
                                />
                              </div>
                            </Col>
                            <Col sm={6} md={7} lg={8} xl={8} className="m-3">
                              <p className="fz_15 font-weight-normal mt-3 mb-0">
                                you have a mentioned review by{" "}
                                <b style={{ color: "#ac3737" }}>
                                  {review.reviewerName}
                                </b>
                              </p>
                              <Row>
                                <div className="d-flex">
                                  <div
                                    style={{
                                      fontSize: "11px",
                                      color: "#959595",
                                    }}
                                  >
                                    {moment
                                      .utc(review.createdAt)
                                      .local()
                                      .startOf("seconds")
                                      .fromNow()}
                                  </div>
                                </div>
                              </Row>
                            </Col>
                            <Col sm={2} md={2} lg={2} xl={2}>
                              <span className="float-right text-center mt-3 notify_btn_men fz_13">
                                View
                              </span>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    ))
                  ) : loading ? (
                    <div className="text-center">
                      <img
                        className="loader-image"
                        src={loader}
                        alt="Loading..."
                      />
                    </div>
                  ) : (
                    <div className="row col-md-12 d-align mx-0">
                      <div className="no-data w-100 text-center font-weight-600 fz_14">
                        Sorry, there is no data to be displayed
                      </div>
                    </div>
                  )}
                </Tab>
              )}
              {props.isMentionCommentInApp && (
                <Tab eventKey="mention" title="Comments">
                  <div className="float-right pr-2 mb-2">
                    <span
                      className="pro_tab_span cd_bd d-flex align-items-center"
                      style={{ paddingRight: "0 px" }}
                    >
                      <span
                        className="mt-1 mb-1 mr-2 ml-2"
                        title="filter"
                        onClick={() => setShowFilterSection(!showFilterSection)}
                      >
                        <img src={filter} height={16} className="" />
                      </span>
                      {showFilterSection && (
                        <>
                          <select
                            className="border border-0 fs_12 clr_flter"
                            value={selectedUserId}
                            onChange={(e) => handleFilterUser(e)}
                          >
                            <option value="">Select</option>
                            {cmtList &&
                              cmtList
                                .filter(
                                  (user, index, self) =>
                                    index ===
                                    self.findIndex(
                                      (u) => u.userId === user.userId
                                    )
                                )
                                .map((user) => (
                                  <option key={user.userId} value={user.userId}>
                                    {user.userName}
                                  </option>
                                ))}
                          </select>
                          {selectedUserId && (
                            <span
                              className="clr_icn c_pointer"
                              onClick={() => clearCmtFilter()}
                            >
                              <IoMdClose />
                            </span>
                          )}
                        </>
                      )}
                    </span>
                  </div>
                  {filteredCmtList.length > 0 ? (
                    filteredCmtList.map((cmt, i) => (
                      <Card
                        className={`notify_card_men mb-2 col-12 ${
                          cmt.isRead == true
                            ? "not_crd_bg_rd crd_shd"
                            : "not_crd_bg shadow"
                        }`}
                        onClick={() => {
                          handleCmtClick(cmt);
                        }}
                      >
                        {/* {cmtList.length>0 ? cmtList.map((cmt, i) => (<Card className="shadow notify_card_men"> */}
                        <Card.Body className="p-0">
                          <Row>
                            <span className="noty_bar_rev p-0"></span>
                            <Col sm={2} md={2} lg={1} xl={1}>
                              <div className="follow_profile m-2">
                                <img
                                  src={
                                    cmt.userImagePath
                                      ? cmt.userImagePath
                                      : profile
                                  }
                                  className="rounded-circle"
                                  width="50"
                                  height="50"
                                />
                              </div>
                            </Col>
                            <Col sm={6} md={7} lg={8} xl={8} >
                              <p className="fz_15 font-weight-normal mb-0 mt-3">
                                you have a mentioned comment by{" "}
                                <b style={{ color: "#a0ac37" }}>
                                  {cmt.userName}
                                </b>
                              </p>
                              <Row>
                                <div className="d-flex">
                                  <div
                                    style={{
                                      fontSize: "11px",
                                      color: "#959595",
                                    }}
                                  >
                                    {moment
                                      .utc(cmt.createdAt)
                                      .local()
                                      .startOf("seconds")
                                      .fromNow()}
                                  </div>
                                </div>
                              </Row>
                            </Col>
                            <Col sm={2} md={2} lg={2} xl={2}>
                              <span className="float-right text-center mt-3 notify_btn_rev fz_13">
                                View
                              </span>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    ))
                  ) : (
                    <div className="row col-md-12 d-align mx-0">
                      <div className="no-data w-100 text-center font-weight-600 fz_14">
                        Sorry, there is no data to be displayed
                      </div>
                    </div>
                  )}
                </Tab>
              )}
              {(props.isPlanExpInApp || props.isTrialExpInApp) && (
                <Tab eventKey="plan notification" title="Plan Notifications">
                  {notifyList.length > 0 ? (
                    notifyList.map((data, i) => (
                      <Card
                        className={`notify_card_men mb-2 col-12 ${
                          data.isRead == true
                            ? "not_crd_bg_rd crd_shd"
                            : "not_crd_bg shadow"
                        }`}
                        onClick={() => {
                          updatePlanNotifyReadSts(data.senderId);
                          setModalContent(data.content);
                          setModalShow(true);
                        }}
                      >
                        <Card.Body className="p-0">
                          <Row>
                            <span className="noty_bar_plan p-0"></span>
                            <Col sm={2} md={2} lg={1} xl={1}>
                              <div className="follow_profile m-2">
                                <img
                                  src={
                                    data.senderUser?.imagePath
                                      ? data.senderUser?.imagePath
                                      : profile
                                  }
                                  className="rounded-circle"
                                  width="50"
                                  height="50"
                                />
                              </div>
                            </Col>
                            <Col sm={6} md={7} lg={8} xl={8} className="m-2">
                              <p className="fz_15 font-weight-normal mb-0">
                                You've received a notification regarding your
                                plan
                                {/* {data.content} */}
                                <b style={{ color: "#a0ac37" }}>
                                  {data.userName}
                                </b>
                              </p>
                              <Row>
                                <div className="d-flex">
                                  <div
                                    style={{
                                      fontSize: "11px",
                                      color: "#959595",
                                    }}
                                  >
                                    {moment
                                      .utc(data.createdAt)
                                      .local()
                                      .startOf("seconds")
                                      .fromNow()}
                                  </div>
                                </div>
                              </Row>
                            </Col>
                            {/* <Col sm={2} md={2} lg={2} xl={2}>
                            <span className="float-right text-center mt-2 notify_btn_pln fz_13"
                              onClick={() => navigate("/dashboard/user_settings/plans_billings")}>
                              Renew
                            </span>
                          </Col> */}
                          </Row>
                        </Card.Body>
                      </Card>
                    ))
                  ) : (
                    <div className="row col-md-12 d-align mx-0">
                      <div className="no-data w-100 text-center font-weight-600 fz_14">
                        Sorry, there is no data to be displayed
                      </div>
                    </div>
                  )}
                </Tab>
              )}
            </Tabs>
          </Container>
        </Card>
      </div>
      <Modal show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header className="px-4 py-2">
          {" "}
          <Modal.Title></Modal.Title>
          <span
            onClick={() => setModalShow(false)}
            title="Close"
            className="c_pointer"
          >
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="p-3 border text-center">
          <p className="fz_15 font-weight-500 mb-2">
            {modalContent.replace(/\d{4}-\d{2}-\d{2}/, (date) =>
              new Date(validity).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            )}
          </p>
          <Button
            onClick={() => navigate("/dashboard/user_settings/plans_billings")}
            className="mt-2 fz_14"
          >
            Renew
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    authToken: state.user.token,
    profileId: state.user.profileId,
    userType: state.user.userType,
    isMentionCommentInApp: state.user.isMentionCommentInApp,
    isReviewCommentInApp: state.user.isReviewCommentInApp,
    isPlanExpInApp: state.user.isPlanExpInApp,
    isTrialExpInApp: state.user.isTrialExpInApp,
  };
};

export default connect(mapStateToProps)(Notification);
