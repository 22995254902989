import { Button, Card, Col, Container, Row } from "react-bootstrap";
import profile from "../../assets/images/profile.jpg";
import { useState, useEffect, useRef } from "react";
import { useToast } from "../Toast";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./findnew.css";
import HeaderMenu from "../Dashboard/headerMenu";
import SideBar from "../Dashboard/SideBar";
import BreadCrumb from "../plugins/Breadcrumb";
import { followId } from "../../Redux/Actions/userAction";
import { IoIosArrowDown } from "react-icons/io";
import { Overlay, Popover, OverlayTrigger } from "react-bootstrap";
import Horizontalbar from "../../components/plugins/Horizontalbar";
import StarRating from "../Dashboard/DashboardTabs/StarRating";
import { FiUsers, FiUser } from "react-icons/fi";
import { HiLocationMarker } from "react-icons/hi";
import { RiQrScan2Line } from "react-icons/ri";
import Modal from "react-bootstrap/Modal";
import { AiOutlineClose } from "react-icons/ai";
import { connect } from "react-redux";
import { FaUser } from "react-icons/fa6";
import loader from "../../assets/images/loader.gif";
import { commonUtils } from "../CommonUtils";
import { TiTick } from "react-icons/ti";
import InviteModal from "../../components/plugins/InviteModal";
import { HiBadgeCheck } from "react-icons/hi";
import { useSubscription } from "../SubscriptionContext";
import UpgradePopup from "../../components/plugins/UpgradePopup";
const Findnew = (props) => {
  const [listPopup, setListPopup] = useState(null);
  const [follList, setFollList] = useState(null);
  const [follName, setFollName] = useState(null);
  const [id, setId] = useState(false);
  const [userList, setUserList] = useState([]);
  const userId = useSelector((state) => state.user.userId);
  const userType = useSelector((state) => state.user.userType);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [avgCount, setAvgCount] = useState("");
  const [revGlobalRtng, setRevGlobalRtng] = useState([]);
  const [showQrCode, setShowQrCode] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [popupData, setpopupData] = useState("");
  const [userData, setUserData] = useState("");
  const [modalHeight, setModalHeight] = useState("");
  // const [busnAdminId, setBusnAdminId] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const [dropdownOpenIdx, setDropdownOpenIdx] = useState("");
  const [showInviteModal, setShowInviteModal] = useState(false);
  const firstCardRef = useRef(null);
  const mappedCardRef = useRef(null);
  const [mappedCardDimensions, setMappedCardDimensions] = useState({
    width: null,
    height: null,
  });
  const [isEndOfData, setIsEndOfData] = useState(false);
  const [placement, setPlacement] = useState("bottom");
  const { isFeatureEnabled, allFeatures } = useSubscription();
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    if (mappedCardRef.current) {
      const { width, height } = mappedCardRef.current.getBoundingClientRect();
      setMappedCardDimensions({ width, height });
    }
  }, [userList]);
  const handleInviteModal = () => {
    setShowInviteModal(!showInviteModal);
  };
  const [offset, setOffset] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const limit = 13;
  // useEffect(() => {
  //   loadsUsers();
  // }, [props]);
  // const handleScroll = (event) => {
  //   const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
  //   if (scrollHeight - scrollTop === clientHeight && !loading) {
  //     loadsUsers();
  //   }
  // };
  useEffect(() => {
    const reloadAddUserHandler = () => {
      loadsUsers();
    };
    window.addEventListener("reloadUsers", reloadAddUserHandler);
    return () => {
      window.removeEventListener("reloadUsers", reloadAddUserHandler);
    };
  }, []);
  const adjustPopoverPosition = (e) => {
    const cardRect = e.currentTarget.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    if (cardRect.bottom + 300 > windowHeight) {
      setPlacement("top");
    } else {
      setPlacement("bottom");
    }
  };
  useEffect(() => {
    if (isDropdownOpen) {
      const myDiv = document.getElementById("pro_modal");
      if (myDiv) {
        setModalHeight(myDiv.offsetHeight - 100);
      }
    }
  }, [isDropdownOpen]);
  const checkToShowCard = (follower) => {
    return (
      (follower.followedUserType == "Business" && follower.business != null) ||
      follower.followedUserType == "Personal" ||
      (follower.followedUserType == "Professional" &&
        follower.professional != null)
    );
  };
  const viewUser = (id, type, profileId) => {
    dispatch(followId(id, type, profileId));
    navigate("/dashboard/finduser/view", {
      state: { userId: id, userType: type, profileId: profileId },
    });
  };
  const handleMouseEnter = (users) => {
    if (users.length > 0) setCurrentUsers(users);
    else setCurrentUsers([]);
  };
  const handleShowPopup = (data) => {
    setShowQrCode(true);
    setpopupData(data);
  };

  const loadsUsers = async () => {
    if (loading) return;

    setLoading(true);
    const params = {
      userId: props.userType === "Business" ? props.busnAdminId : userId,
      profileId: props.userType !== "Personal" ? props.profileId : null,
      userType: props.userType,
    };
    if (offset === 0) {
      setUserList([]);
    }
    try {
      const response = await window.apiService.loadUsers(limit, offset, params);
      if (response.data.message === "Data loaded successfully.") {
        const newUsers = response.data.data;
        setUserList((prevUsers) => [...prevUsers, ...newUsers]);
        setOffset((prevOffset) => prevOffset + limit);
        setHasMoreData(newUsers.length > 0);
        const isEndOfData =
          newUsers.length > 0 && newUsers[0].hasOwnProperty("isEndOfData")
            ? newUsers[0].isEndOfData
            : false;

        setIsEndOfData(isEndOfData);
        if (dropdownOpenIdx) setUserData(response.data.data[dropdownOpenIdx]);
      } else {
        toast.error(response.data.message);
        setHasMoreData(false);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setHasMoreData(false);
    } finally {
      setLoading(false);
      setInitialLoadComplete(true);
    }
  };
  function renderImage(follower) {
    return follName === "Following"
      ? follower?.followedUserType === "Professional"
        ? follower?.professional?.imagePath
          ? follower.professional.imagePath
          : profile
        : follower?.followedUserType === "Business"
        ? follower?.business?.logo
          ? follower.business.logo
          : profile
        : follower?.imagePath
        ? follower.imagePath
        : profile
      : follower?.followingUserType === "Professional"
      ? follower?.professional?.imagePath
        ? follower.professional.imagePath
        : profile
      : follower?.followingUserType === "Business"
      ? follower?.business?.logo
        ? follower.business.logo
        : profile
      : follower?.imagePath
      ? follower.imagePath
      : profile;
  }

  const handleList = (id, type, profileId, name) => {
    setId(id);
    setFollList([]);
    console.log("sss", id, type, profileId);
    // setFollList(list);
    setFollName(name);
    let params;
    if (name === "Followers") {
      params = {
        followedUserId: id,
        followedUserType: type,
        followedTypeId: profileId,
      };
      FollowersList(params);
    } else {
      params = {
        followingUserId: id,
        followingUserType: type,
        followingTypeId: profileId,
      };
      FollowingList(params);
    }

    setListPopup(true);
  };
  const FollowingList = async (params) => {
    setLoading(true);
    try {
      const response = await window.apiService.userFollowingList(params);
      setFollList(response.data.recordInfo);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error Occured");
    }
  };
  const FollowersList = async (params) => {
    setLoading(true);
    try {
      const response = await window.apiService.userFollowersList(params);
      setFollList(response.data.recordInfo);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error Occured");
    }
  };
  const handleFollowRequest = (
    isFollow,
    userId,
    id,
    followingtype,
    followedtype,
    followedTypeId,
    followingTypeId
  ) => {
    if (userType == "Personal") {
      followingTypeId = null;
    }
    let userInfo = {};
    if (!isFollow) {
      userInfo = {
        followingUserId:
          followingtype === "Business" ? props.busnAdminId : userId,
        followedUserId: id,
        followedUserType: followedtype,
        followingUserType: followingtype,
        followedTypeId: followedTypeId,
        followingTypeId: followingTypeId,
        isActive: 1,
      };
      followuser(userInfo);
    } else {
      userInfo = {
        followingUserId: userId,
        followedUserId: id,
        followedUserType: followedtype,
        followingUserType: followingtype,
        followedTypeId: followedTypeId,
        followingTypeId: followingTypeId,
      };
      unfollowuser(userInfo);
    }
  };
  useEffect(() => {
    if (offset === 0) {
      loadsUsers();
    }
  }, [offset]);

  const followuser = async (userInfo) => {
    try {
      const response = await window.apiService.addFollower(userInfo);
      if (response.data.status === "OK") {
        toast.success("Followed Successfully");
        setOffset(0);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error Occured");
    }
  };

  const unfollowuser = async (userInfo) => {
    try {
      const response = await window.apiService.unFollowUser(userInfo);
      if (response.data.status === "OK") {
        toast.success("Unfollowed successfully");
        setOffset(0);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error Occured");
    }
  };

  const handledropdownOpen = (idx, user) => {
    console.log("sdfsf", user);
    setDropdownOpen(true);
    setUserData(user);
    setDropdownOpenIdx(idx);
  };
  const handleArrowClick = (rating, avgCount) => {
    setAvgCount(avgCount);
    const result = rating.map((starData) => ({
      label: starData.star,
      value: starData.totalReviewCount,
      userRatings: starData.userRatings,
    }));
    result.sort((a, b) => {
      const numA = parseInt(a.label.split(" ")[0]);
      const numB = parseInt(b.label.split(" ")[0]);
      return numB - numA;
    });
    setRevGlobalRtng(result);
  };
  return (
    <>
      <HeaderMenu />
      <div className="">
        <SideBar />
        <div className="main-content ml_200">
          {/* <BreadCrumb /> */}
          {loading ? (
            <div className="pro-loader-center">
              <img className="loader-image" src={loader} alt="Loading..." />
            </div>
          ) : (
            ""
          )}
          <Card
            className="shade"
            // style={{ overflowY: "scroll" }}
            // onScroll={handleScroll}
          >
            <Container>
              <h4 className="headr_sty">Add a new tiipstr to review</h4>
              <Row className="pt-2">
                <Card
                  className="invite-card"
                  ref={firstCardRef}
                  style={
                    mappedCardDimensions.width && mappedCardDimensions.height
                      ? {
                          width: mappedCardDimensions.width,
                          height: mappedCardDimensions.height,
                        }
                      : {}
                  }
                >
                  <div style={{ padding: "20px 0" }}>
                    <Card.Title>Invite User</Card.Title>
                    <Card.Text className="inv">
                      Invite a user to join our platform. Click the button below
                      to get started!
                    </Card.Text>
                    <commonUtils.CustomButton
                      btnText="Invite"
                      btnWidth="fit-content"
                      variant="primary"
                      perform={() => {
                        if (
                          isFeatureEnabled(
                            allFeatures["Invite-User%"]?.key,
                            allFeatures["Invite-User%"]?.isDeleted
                          )
                        ) {
                          handleInviteModal();
                        } else {
                          setShowPopup(true);
                        }
                      }}
                      isDisabled={false}
                    />
                  </div>
                </Card>
                {userList.map((user, index) => (
                  <Col sm={12} md={4} lg={3} xl={2} className="usr_card">
                    <Card
                      className="usr_crd"
                      ref={index === 0 ? mappedCardRef : null}
                    >
                      <div className="user text-center d-flex justify-content-center">
                        <div className="follow_profile kyc_vf">
                          <img
                            className="rounded-circle"
                            src={user.imagePath ? user.imagePath : profile}
                            width="60"
                            height="60"
                          />
                        </div>
                        {user.qrCode && (
                          <div className="scan_qr">
                            <p className="mb-0 fz-10 font-weight-500">SCAN</p>
                            <RiQrScan2Line
                              className="fz-22 c_pointer"
                              onClick={() => {
                                if (
                                  isFeatureEnabled(
                                    allFeatures["QR-Code%"]?.key,
                                    allFeatures["QR-Code%"]?.isDeleted
                                  )
                                ) {
                                  handleShowPopup(user);
                                } else {
                                  setShowPopup(true);
                                }
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="mt-2 text-center">
                        <h6 className="mb-0">
                          {user.name}
                          {isFeatureEnabled(
                            allFeatures["Kyc%"]?.key,
                            allFeatures["Kyc%"]?.isDeleted
                          ) &&
                          user.isKycVerified &&
                          user.userType == "Personal" ? (
                            <HiBadgeCheck
                              style={{
                                fontSize: "17px",
                                color: "green",
                                cursor: "pointer",
                                marginLeft: "5px",
                              }}
                              title="KYC Verified"
                              data-toggle="tooltip"
                              data-placement="top"
                            />
                          ) : (
                            <></>
                          )}
                        </h6>
                        <div
                          className="fs_12 d-flex align-items-center justify-content-center"
                          style={{ marginBottom: "0px" }}
                        >
                          {user.averageReviewScore > 0.0 ? (
                            <span className="font-weight-bold total_fnt mar_3">
                              {user.averageReviewScore.toFixed(1)}
                            </span>
                          ) : (
                            ""
                          )}
                          <StarRating
                            rating={user.averageReviewCount}
                            disabled={true}
                            customClass="user-star"
                          />
                          <span>({user.totalReviewCount})</span>
                          <OverlayTrigger
                            trigger="click"
                            key={user.id}
                            placement={placement}
                            overlay={
                              <Popover
                                id={`popover-${user.id}`}
                                className="pop_rating position-fixed"
                              >
                                <div className="drp_rating">
                                  <StarRating
                                    rating={avgCount}
                                    disabled={true}
                                  />
                                  <span>{avgCount} out of 5</span>{" "}
                                </div>
                                {revGlobalRtng.map((item, index) => (
                                  <Row key={index}>
                                    <Col
                                      sm={12}
                                      md={3}
                                      lg={3}
                                      xl={2}
                                      className="p-0"
                                    >
                                      <span className="prog_span">
                                        {item.label}
                                      </span>{" "}
                                    </Col>
                                    <Col
                                      sm={12}
                                      md={8}
                                      lg={8}
                                      xl={7}
                                      className="p-0"
                                    >
                                      <Horizontalbar dataSet={item.value} />
                                    </Col>
                                    <Col
                                      sm={12}
                                      md={3}
                                      lg={3}
                                      xl={2}
                                      className="p-0 prog_span"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <span className="tooltip">
                                        <span
                                          className="c_pointer"
                                          onMouseEnter={() =>
                                            handleMouseEnter(item.userRatings)
                                          }
                                        >
                                          {item.value}
                                          {item.value > 1 ? (
                                            <FiUsers
                                              style={{ marginLeft: "6px" }}
                                            />
                                          ) : (
                                            <FiUser
                                              style={{ marginLeft: "6px" }}
                                            />
                                          )}
                                        </span>
                                        {commonUtils.ratingTooltip(
                                          currentUsers
                                        )}
                                      </span>
                                    </Col>
                                  </Row>
                                ))}
                              </Popover>
                            }
                            rootClose
                          >
                            <span
                              className="d-flex align-items-center c_pointer"
                              onClick={(e) => {
                                adjustPopoverPosition(e);
                                handleArrowClick(
                                  user.starRating,
                                  user.averageReviewCount
                                );
                              }}
                            >
                              <IoIosArrowDown className="clr_gray" />
                            </span>
                          </OverlayTrigger>{" "}
                        </div>
                        <div
                          className="fs_12 text-center"
                          title={user.location}
                        >
                          {user.location ? <HiLocationMarker style={{ marginTop: '-3px' }}/> : ""}&nbsp;
                          {user.location
                            ? user.location.length > 20
                              ? user.location.substring(0, 19) + "..."
                              : user.location
                            : "-"}{" "}
                        </div>

                        {user.professionalList &&
                          user.professionalList.length > 0 && (
                            <div className="Pro_user">
                              <FaUser
                                className="fs_16"
                                title={
                                  user.professionalList.length +
                                  ` ${
                                    user.professionalList.length > 1
                                      ? "Professionals"
                                      : "Professional"
                                  }`
                                }
                              />
                              <span className="Pro_user_cnt">
                                {user.professionalList.length}
                              </span>
                            </div>
                          )}
                        {user.businessData && user.businessData.length > 0 && (
                          <div
                            className={`Busi_user ${
                              user.businessData.length > 0 ? "top_20" : "top_7"
                            }`}
                          >
                            <FaUser
                              className="fs_16"
                              title={
                                user.businessData.length +
                                ` ${
                                  user.businessData.length > 1
                                    ? "Businesses "
                                    : "Business"
                                }`
                              }
                            />
                            <span className="bus_user_cnt">
                              {user.businessData.length}
                            </span>
                          </div>
                        )}
                        <div className="d-flex justify-content-between">
                          <div
                            className="stats c_pointer"
                            onClick={() =>
                              handleList(user.id, "Personal", null, "Followers")
                            }
                          >
                            <span>{user.followersCount}</span>
                            <h6>Followers</h6>
                          </div>
                          <div
                            className="stats c_pointer"
                            onClick={() =>
                              handleList(user.id, "Personal", null, "Following")
                            }
                          >
                            <span>{user.followingCount}</span>
                            <h6>Following</h6>
                          </div>
                        </div>
                      </div>
                      {(user.professionalList &&
                        user.professionalList.length > 0) ||
                      (user.businessData && user.businessData.length) > 0 ? (
                        <div className="d-flex justify-content-center">
                          <div className="stats">
                            <commonUtils.CustomButton
                              btnText="View"
                              btnWidth="fit-content"
                              variant="outline-primary"
                              perform={() => handledropdownOpen(index, user)}
                              isDisabled={false}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-around">
                          <div className="stats">
                            <commonUtils.CustomButton
                              btnText="View"
                              btnWidth="fit-content"
                              variant="outline-primary"
                              perform={() => viewUser(user.id, user.userType)}
                              isDisabled={false}
                            />
                          </div>
                          <div className="stats">
                            {user.isFollow ? (
                              <commonUtils.CustomButton
                                btnText="Unfollow"
                                btnWidth="fit-content"
                                variant="danger"
                                perform={() =>
                                  handleFollowRequest(
                                    user.isFollow,
                                    userId,
                                    user.id,
                                    userType,
                                    user.userType,
                                    null,
                                    props.profileId
                                  )
                                }
                                isDisabled={false}
                              />
                            ) : (
                              <commonUtils.CustomButton
                                btnText="Follow"
                                btnWidth="fit-content"
                                variant="primary"
                                perform={() =>
                                  handleFollowRequest(
                                    user.isFollow,
                                    userId,
                                    user.id,
                                    userType,
                                    user.userType,
                                    null,
                                    props.profileId
                                  )
                                }
                                isDisabled={false}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </Card>
                  </Col>
                ))}
              </Row>
              {initialLoadComplete &&
                hasMoreData &&
                !isEndOfData &&
                (loading ? (
                  ""
                ) : (
                  <div className="d-flex justify-content-center mb-2">
                    <commonUtils.CustomButton
                      btnText=" Load More"
                      btnWidth="fit-content"
                      variant="primary"
                      perform={() => loadsUsers()}
                      disabled={loading}
                    />
                  </div>
                ))}
            </Container>
          </Card>
        </div>
        <Modal size="sm" show={showQrCode} onHide={() => setShowQrCode(false)}>
          {/* <Modal.Header className="p-3">
              <Modal.Title id="example-custom-modal-styling-title" className="mb-4" style={{fontSize: "15px", fontWeight: 600}}>
                QR Code
              </Modal.Title>
              <span onClick={()=>{setShowQrCode(false)}} title="Close">
                  <AiOutlineClose className="c_pointer"/>
              </span>
              </Modal.Header> */}
          <Modal.Body className="p-3">
            <div className="d-flex align-items-center">
              <Col sm={6} md={6} lg={6} xl={6} className="card_bdr_right">
                <div className="user text-center d-flex justify-content-center">
                  <div className="h80_w80 rounded-circle">
                    <img
                      src={popupData.imagePath ? popupData.imagePath : profile}
                      className="rounded-circle h80_w80"
                    />
                  </div>
                </div>
                <div className="mt-2 text-center">
                  <h6 className="mb-0">{popupData.name}</h6>
                  <div
                    className="fs_12 d-flex align-items-center justify-content-center"
                    style={{ marginBottom: "0px" }}
                  >
                    {popupData.averageReviewScore > 0.0 ? (
                      <span className="font-weight-bold total_fnt mar_3">
                        {popupData.averageReviewScore.toFixed(1)}
                      </span>
                    ) : (
                      ""
                    )}
                    <StarRating
                      rating={popupData.averageReviewCount}
                      disabled={true}
                      customClass="user-star"
                    />
                    <span>({popupData.totalReviewCount})</span>
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} lg={6} xl={6}>
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    className="qr_img_bdr"
                    src={"data:image/jpeg;base64," + popupData.qrCode}
                  />
                </div>
              </Col>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          id="pro_modal"
          size="lg"
          show={isDropdownOpen}
          onHide={() => setDropdownOpen(false)}
        >
          <Modal.Header>
            <Modal.Title style={{ fontSize: "20px", fontWeight: 600 }}>
              User Profiles
            </Modal.Title>
            <span
              className="c_pointer"
              onClick={() => {
                setDropdownOpen(false);
              }}
              title="Close"
            >
              <AiOutlineClose />
            </span>
          </Modal.Header>
          <Modal.Body className="p-3 bordr">
            <Col sm={12} md={12} lg={12} xl={12}>
              <Row className="d-flex align-items-center justify-content-center">
                <Col sm={12} md={5} lg={4} xl={3} className="usr_card">
                  <Card className="usr_crd rounded-0">
                    <div className="d-flex justify-content-end">
                      <div className="usr_brnd usr_brnd_psnl">
                        <span className="m-1">Personal</span>
                      </div>
                    </div>
                    <div className="user text-center d-flex justify-content-center">
                      <div className="follow_profile">
                        <img
                          src={
                            userData.imagePath ? userData.imagePath : profile
                          }
                          className="rounded-circle"
                          width="60"
                          height="60"
                        />
                      </div>
                    </div>
                    <div className="mt-2 text-center">
                      <h6 className="mb-0">{userData.name}</h6>
                      <div className="d-flex justify-content-between">
                        <div
                          className="stats c_pointer"
                          onClick={() =>
                            handleList(
                              userData.id,
                              "Personal",
                              null,
                              "Followers"
                            )
                          }
                        >
                          <span>{userData.followersCount}</span>
                          <h6>Followers</h6>
                        </div>
                        <div
                          className="stats c_pointer"
                          onClick={() =>
                            handleList(
                              userData.id,
                              "Personal",
                              null,
                              "Following"
                            )
                          }
                        >
                          <span>{userData.followingCount}</span>
                          <h6>Following</h6>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-around">
                      <div className="stats">
                        <commonUtils.CustomButton
                          btnText="View"
                          btnWidth="fit-content"
                          variant="outline-primary"
                          perform={() =>
                            viewUser(userData.id, userData.userType)
                          }
                          isDisabled={false}
                        />
                      </div>
                      <div className="stats">
                        {userData.isFollow ? (
                          <commonUtils.CustomButton
                            btnText="Unfollow"
                            btnWidth="fit-content"
                            variant="danger"
                            perform={() =>
                              handleFollowRequest(
                                userData.isFollow,
                                userId,
                                userData.id,
                                userType,
                                userData.userType,
                                null,
                                props.profileId
                              )
                            }
                            isDisabled={false}
                          />
                        ) : (
                          <commonUtils.CustomButton
                            btnText="Follow"
                            btnWidth="fit-content"
                            variant="primary"
                            perform={() =>
                              handleFollowRequest(
                                userData.isFollow,
                                userId,
                                userData.id,
                                userType,
                                userData.userType,
                                null,
                                props.profileId
                              )
                            }
                            isDisabled={false}
                          />
                        )}
                      </div>
                    </div>
                  </Card>
                </Col>
                {userData.professionalList &&
                  userData.professionalList.length > 0 &&
                  userData.professionalList.map((profession, index) => (
                    <Col
                      sm={12}
                      md={5}
                      lg={4}
                      xl={3}
                      className="usr_card"
                      key={index}
                    >
                      <Card className="usr_crd rounded-0">
                        <div className="d-flex justify-content-end">
                          <div className="usr_brnd usr_brnd_pro">
                            <span className="m-1">Professional</span>
                          </div>
                        </div>
                        <div className="user text-center d-flex justify-content-center">
                          <div className="follow_profile">
                            <img
                              src={
                                profession.imagePath
                                  ? profession.imagePath
                                  : profile
                              }
                              className="rounded-circle"
                              width="60"
                              height="60"
                            />
                          </div>
                        </div>
                        <div className="mt-2 text-center">
                          <h6 className="mb-0">{profession.professionType}</h6>
                          <div className="d-flex justify-content-between">
                            <div
                              className="stats c_pointer"
                              onClick={() =>
                                handleList(
                                  userData.id,
                                  "Professional",
                                  profession.id,
                                  "Followers"
                                )
                              }
                            >
                              <span>
                                {profession.professionalFollowerCount}
                              </span>
                              <h6>Followers</h6>
                            </div>
                            <div
                              className="stats c_pointer"
                              onClick={() =>
                                handleList(
                                  userData.id,
                                  "Professional",
                                  profession.id,
                                  "Following"
                                )
                              }
                            >
                              <span>
                                {profession.professionalFollowingCount}
                              </span>
                              <h6>Following</h6>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-around">
                          <div className="stats">
                            <commonUtils.CustomButton
                              btnText="View"
                              btnWidth="fit-content"
                              variant="outline-primary"
                              perform={() =>
                                viewUser(
                                  profession.userId,
                                  "Professional",
                                  profession.id
                                )
                              }
                              isDisabled={false}
                            />
                          </div>
                          <div className="stats">
                            {profession.isFollowProfessional ? (
                              <commonUtils.CustomButton
                                btnText="Unfollow"
                                btnWidth="fit-content"
                                variant="danger"
                                perform={() =>
                                  handleFollowRequest(
                                    profession.isFollowProfessional,
                                    userId,
                                    profession.userId,
                                    userType,
                                    "Professional",
                                    profession.id,
                                    props.profileId
                                  )
                                }
                                isDisabled={false}
                              />
                            ) : (
                              <commonUtils.CustomButton
                                btnText="Follow"
                                btnWidth="fit-content"
                                variant="primary"
                                perform={() =>
                                  handleFollowRequest(
                                    profession.isFollowProfessional,
                                    userId,
                                    profession.userId,
                                    userType,
                                    "Professional",
                                    profession.id,
                                    props.profileId
                                  )
                                }
                                isDisabled={false}
                              />
                            )}
                          </div>
                        </div>
                      </Card>
                    </Col>
                  ))}
                {userData.businessData &&
                  userData.businessData.length > 0 &&
                  userData.businessData
                    .filter((business) => business.isDeleted !== 1)
                    .map((business, index) => (
                      <Col
                        sm={12}
                        md={5}
                        lg={4}
                        xl={3}
                        className="usr_card"
                        key={index}
                      >
                        <Card className="usr_crd rounded-0">
                          <div className="d-flex justify-content-end">
                            <div className="usr_brnd usr_brnd_bus">
                              <span className="m-1">Business</span>
                            </div>
                          </div>
                          <div className="user text-center d-flex justify-content-center">
                            <div className="follow_profile">
                              <img
                                src={
                                  business.imagePath
                                    ? business.imagePath
                                    : profile
                                }
                                className="rounded-circle"
                                width="60"
                                height="60"
                              />
                            </div>
                          </div>
                          <div className="mt-2 text-center">
                            <h6 className="mb-0" title={business.companyName}>
                              {business.shortName
                                ? business.shortName
                                : business.companyName}
                              &nbsp;
                              {/* <p className="mb-0 fz_13 font-weight-400">
                              {business.roleName}
                            </p> */}
                            </h6>
                            <div className="d-flex justify-content-between">
                              <div
                                className="stats c_pointer"
                                onClick={() =>
                                  handleList(
                                    business.userId,
                                    "Business",
                                    business.id,
                                    "Followers"
                                  )
                                }
                              >
                                <span>{business.businessFollowerCount}</span>
                                <h6>Followers</h6>
                              </div>
                              <div
                                className="stats c_pointer"
                                onClick={() =>
                                  handleList(
                                    business.userId,
                                    "Business",
                                    business.id,
                                    "Following"
                                  )
                                }
                              >
                                <span>{business.businessFollowingCount}</span>
                                <h6>Following</h6>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-around">
                            <div className="stats">
                              <commonUtils.CustomButton
                                btnText="View"
                                btnWidth="fit-content"
                                variant="outline-primary"
                                perform={() =>
                                  viewUser(
                                    business.userId,
                                    "Business",
                                    business.id
                                  )
                                }
                                isDisabled={false}
                              />
                            </div>
                            {business.userId !== props.busnAdminId &&
                              props.profileId !== business.id && (
                                <div className="stats">
                                  {business.isFollowBusiness ? (
                                    <commonUtils.CustomButton
                                      btnText="Unfollow"
                                      btnWidth="fit-content"
                                      variant="danger"
                                      perform={() =>
                                        handleFollowRequest(
                                          business.isFollowBusiness,
                                          userId,
                                          business.businessUser.userId,
                                          userType,
                                          "Business",
                                          business.id,
                                          props.profileId
                                        )
                                      }
                                      isDisabled={false}
                                    />
                                  ) : (
                                    <commonUtils.CustomButton
                                      btnText="Follow"
                                      btnWidth="fit-content"
                                      variant="primary"
                                      perform={() =>
                                        handleFollowRequest(
                                          business.isFollowBusiness,
                                          userId,
                                          business.businessUser.userId,
                                          userType,
                                          "Business",
                                          business.id,
                                          props.profileId
                                        )
                                      }
                                      isDisabled={false}
                                    />
                                  )}
                                </div>
                              )}
                          </div>
                        </Card>
                      </Col>
                    ))}
              </Row>
            </Col>
          </Modal.Body>
        </Modal>
        {/* ----------- follwr/folwng list Popup ---------- */}
        <Modal size="sm" show={listPopup} onHide={() => setListPopup(false)}>
          <Modal.Header className="p-3">
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "15px", fontWeight: 600 }}
            >
              {follName}
            </Modal.Title>
            <span
              className="c_pointer"
              onClick={() => setListPopup(false)}
              title="Close"
            >
              <AiOutlineClose />
            </span>
          </Modal.Header>
          <Modal.Body
            className="bordr p-3"
            style={{
              maxHeight: isDropdownOpen ? `${modalHeight}px` : "70vh",
              overflow: "auto",
            }}
          >
            {follList && follList != "" ? (
              follList.map(
                (follower, index) =>
                  checkToShowCard(follower) && (
                    <div key={index} className="foll_lst">
                      <img
                        src={renderImage(follower)}
                        height={35}
                        width={35}
                        className="rounded-circle"
                      />
                      <div>
                        {follName === "Following"
                          ? follower.followedUserType == "Business"
                            ? follower.business?.companyName
                            : follower.name
                          : follower.followingUserType == "Business"
                          ? follower.business?.companyName
                          : follower.name}
                        {/* {follower.followedUserType == "Business" ||
                    follower.followingUserType == "Business"
                      ? follower.business?.companyName
                      : follower.name} */}
                        &nbsp;
                        {follName === "Following"
                          ? follower.followedUserType == "Professional"
                            ? "(" + follower.professional?.professionType + ")"
                            : ""
                          : follower.followingUserType == "Professional"
                          ? "(" + follower.professional?.professionType + ")"
                          : ""}
                      </div>
                    </div>
                  )
              )
            ) : loading ? (
              <div className="pro-loader-center">
                <img className="loader-image" src={loader} alt="Loading..." />
              </div>
            ) : (
              <div className="text-center">No {follName} found</div>
            )}
          </Modal.Body>
        </Modal>
        <UpgradePopup
          show={showPopup}
          handleClose={() => setShowPopup(false)}
        />
        {/* ----------- Invite user popup ---------- */}
        {showInviteModal && (
          <InviteModal
            showInvite={showInviteModal}
            handleClose={handleInviteModal}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profileId: state.user.profileId,
    userType: state.user.userType,
    userTypeInfo: state.user.userTypeInfo,
    busnAdminId: state.user.busnAdminId,
  };
};
export default connect(mapStateToProps)(Findnew);
