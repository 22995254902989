import React, { useState, useEffect } from "react";
import { useToast } from "../Toast";
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Table,
  Modal,
  Form,
} from "react-bootstrap";
import { connect } from "react-redux";
import { BiChevronDownCircle, BiChevronUpCircle } from "react-icons/bi";
import { format, parseISO } from "date-fns";
import { FaCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { isBefore } from "date-fns";
import { useSubscription } from "../SubscriptionContext";
import loader from "../../assets/images/loader.gif";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js"; // Import for AES decryption

const PlanDetails = () => {
  const [isBillingHistoryVisible, setIsBillingHistoryVisible] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [activePlanId, setActivePlanId] = useState(null);
  const [validity, setValidity] = useState(null);
  const [trialStatus, setTrialStatus] = useState("");
  const [trialEndDate, setTrialEndDate] = useState("");
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { refreshSubscriptions } = useSubscription();
  const [loading, setLoading] = useState(false);
  const { userid } = useParams();
  const [decodedUserId, setDecodedUserId] = useState();
  // useEffect(() => {
  //     getPlansById();
  // }, []);
  useEffect(() => {
    loadPlans();
  }, []);

  const SECRET_KEY = "Planexpire123456";

  useEffect(() => {
    if (userid) {
      try {
        // Decode Base64 URL-safe encoding
        const decodedBase64 = userid.replace(/-/g, "+").replace(/_/g, "/");

        // Decrypt the AES value
        const decryptedBytes = CryptoJS.AES.decrypt(
          decodedBase64,
          CryptoJS.enc.Utf8.parse(SECRET_KEY),
          {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
          }
        );

        const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);

        if (!decryptedData) {
          throw new Error("Invalid decryption result");
        }

        console.log("Decrypted Code:", decryptedData);
        setDecodedUserId(decryptedData);
        setActivePlanId(decryptedData);
        console.log("decodedUserId", decodedUserId);
      } catch (error) {
        console.error("Decryption failed:", error);
        toast.error("Invalid or expired link.");
      }
    }
  }, [userid]);

  useEffect(() => {
    if (decodedUserId) {
      console.log("decodedUserId updated:", decodedUserId);
      getPlansById();
    }
  }, [decodedUserId]);

  useEffect(() => {
    loadPlans();

    console.log("userid", userid);
  }, []);

  const loadPlans = async () => {
    setLoading(true);
    try {
      const response = await window.apiService.loadPlans();
      setPlanList(response.data.recordInfo);
      setLoading(false);
      refreshSubscriptions();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getPlansById = async () => {
    if (userid) {
      try {
        const response = await window.apiService.getPlanById(decodedUserId);
        if (response.data.status === "OK") {
          setActivePlanId(response.data.recordInfo.planId);
          setValidity(response.data.recordInfo.endDate);
          setTrialStatus(response.data.recordInfo.trialStatus);
          setTrialEndDate(response.data.recordInfo.trialEndDate);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const filteredPlans = planList.filter((plan) => plan.id === activePlanId);
  useEffect(() => {
    if (planList.length > 0 && activePlanId) {
      const filteredPlans = planList.filter((plan) => plan.id === activePlanId);
      if (filteredPlans.length > 0) {
        const filteredPlan = filteredPlans[0];
        const maxPrice = Math.max(...planList.map((plan) => plan.price));

        setStatus(maxPrice);
        console.log(
          `Flag: ${
            filteredPlan.price >= maxPrice ? "Downgrade Plan" : "Upgrade Plan"
          }`
        );
      } else {
        console.log("No active plan found.");
      }
    }
  }, [planList, activePlanId]);

  const hasPlanExpired = () => {
    const validityDate = new Date(validity);
    const today = new Date();
    validityDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    return validityDate < today;
  };

  return (
    <>
      <div className="main-content">
        <Card className="shade h-100">
          <Container fluid>
            <div className="header-body pt-3">
              <Row>
                <Col lg="12" xl="12">
                  <div className="d-flex mt-2 toggle_gap">
                    <label className="font-weight-500 fz_14">
                      Plan and Billing
                    </label>
                  </div>
                </Col>
                <hr />
                <Col lg="12" xl="12">
                  <div className="d-flex mt-2 toggle_gap">
                    <label className="font-weight-500 fz_14 a_clr">
                      Plan Details
                    </label>
                  </div>
                </Col>
                {filteredPlans && filteredPlans.length > 0 ? (
                  filteredPlans.map((plan, idx) => (
                    <React.Fragment key={idx}>
                      <Col
                        xs="12"
                        sm="6"
                        md="6"
                        lg="4"
                        xl="3"
                        className="mb-4 mt-4"
                      >
                        <Card className="plan-card">
                          <div className="ribbon">
                            {hasPlanExpired() && plan.isTrial == 0
                              ? "Expired"
                              : "Active"}
                          </div>
                          <Card.Header
                            className="text-primary"
                            style={{ backgroundColor: "#ece9e2" }}
                          >
                            Current Plan
                            {plan.isTrial == 1 && (
                              <span className="freePln">Free</span>
                            )}
                          </Card.Header>
                          <Card.Body>
                            <div>
                              <span>
                                <FaCircle className="icn_blur text-basic" />
                              </span>{" "}
                              {plan.servicePlan}
                              {trialStatus == "active" && (
                                <span className="freePln">Trial Activated</span>
                              )}
                              {trialStatus == "none" &&
                                new Date(validity).toDateString() ===
                                  new Date().toDateString() && (
                                  <span className="freePln fs_12">
                                    Expires today
                                  </span>
                                )}
                            </div>
                            <div>{plan.description}</div>
                            {plan.isTrial == 1 && (
                              <ul className="list-unstyled mt-3 mb-4">
                                {plan.features.map((feature, featureIdx) => (
                                  <li key={featureIdx}>
                                    <TiTick className="tck" />{" "}
                                    {feature.featureName}
                                    <span className="text-muted">
                                      {" "}
                                      ({feature.description})
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            )}
                            {plan.isTrial === 0 && (
                              <>
                                <h5 className="mt-2">
                                  ${plan.price}
                                  <small>
                                    {" "}
                                    /{" "}
                                    {plan.billingCycle === "monthly"
                                      ? "month"
                                      : "year"}
                                  </small>
                                </h5>
                                <div className="mb-2">
                                  {trialStatus === "active" ? (
                                    new Date(trialEndDate).toDateString() ===
                                    new Date().toDateString() ? (
                                      "Trial expires today"
                                    ) : (
                                      `Trial expires on ${format(
                                        new Date(trialEndDate),
                                        "dd MMM yyyy"
                                      )}`
                                    )
                                  ) : (
                                    <span>
                                      Renew at{" "}
                                      {format(
                                        new Date(validity),
                                        "dd MMM yyyy"
                                      )}
                                    </span>
                                  )}
                                </div>
                              </>
                            )}
                            {hasPlanExpired() && plan.isTrial == 0 ? (
                              <Button
                                className="w_100"
                                variant="outline-primary"
                                onClick={() => navigate("/login")}
                              >
                                Renew Plan
                              </Button>
                            ) : (
                              plan.isTrial == 0 &&
                              trialStatus != "active" && (
                                <>
                                  <div className="up_btn align-items-center d-flex justify-content-end">
                                    <Button
                                      className="w_70"
                                      variant="outline-primary"
                                      onClick={() => navigate("/login")}
                                      style={{ margin: "11px 0" }}
                                    >
                                      Renew Plan
                                    </Button>
                                    <Button
                                      className="w_70"
                                      variant="outline-primary"
                                      onClick={() => navigate("/login")}
                                    >
                                      <span>Change Plan</span>
                                    </Button>
                                  </div>
                                </>
                              )
                            )}
                          </Card.Body>
                        </Card>
                      </Col>

                      {plan.isTrial == 0 && (
                        <Col
                          xs="12"
                          sm="6"
                          md="6"
                          lg="4"
                          xl="3"
                          className="mb-4 mt-4"
                        >
                          <Card className="plan-card">
                            <Card.Header
                              className="text-primary"
                              style={{ backgroundColor: "#ece9e2" }}
                            >
                              Current Plan Features
                            </Card.Header>
                            <Card.Body>
                              <ul className="list-unstyled mt-3 mb-4 feat">
                                {plan.features.map((feature, featureIdx) => (
                                  <li key={featureIdx}>
                                    <TiTick className="tck" />{" "}
                                    {feature.featureName}
                                    {/* <span className="text-muted"> ({feature.description})</span> */}
                                  </li>
                                ))}
                              </ul>
                            </Card.Body>
                          </Card>
                        </Col>
                      )}
                    </React.Fragment>
                  ))
                ) : loading ? (
                  <div className="loader-center">
                    <img
                      className="loader-image"
                      src={loader}
                      alt="Loading..."
                    />
                  </div>
                ) : (
                  <div className="row d-align mx-0 pb-4">
                    <div className="no-data font-weight-600 w-100 text-center fz_14">
                      Sorry, there is no data to be displayed
                    </div>
                  </div>
                )}
              </Row>
            </div>
          </Container>
        </Card>
      </div>
    </>
  );
};
export default PlanDetails;
