import { Card, Col, Row, Modal, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useToast } from "../Toast";
import { BsPlusLg } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineTrash } from "react-icons/hi";
import { Input } from "reactstrap";
import { FaTimes } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { FaAngleDown } from "react-icons/fa";
import editRole from "../../assets/images/usr_edit.png";
import { commonUtils } from "../CommonUtils";
const ReviewQuestion = (props) => {
  const toast = useToast();
  const { id, name } = props;
  const [reviewQuestions, setReviewQuestions] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editedQuestion, setEditedQuestion] = useState("");
  const [newQuestion, setNewQuestion] = useState("");
  const [questionError, setQuestionError] = useState("");
  const [cmpyError, setCmpyError] = useState("");
  const [weightage, setWeightage] = useState("");
  const [editedWeightage, setEditedWeightage] = useState("");
  const roleId = props.id;
  const roleName = props.name;
  const [deletePopupShow, setDeletePopupShow] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [businessList, setBusinessList] = useState([]);
  const [businessId, setBusinessId] = useState(null);
  const [editedBusinessId, setEditedBusinessId] = useState("");
  const [editedbusnId, setEditedBusnId] = useState("");
  const handleClose = () => setDeletePopupShow(false);

  useEffect(() => {
    loadQuestions();
    loadBusnList();
  }, [roleId]);

  const loadQuestions = async () => {
    if (roleId) {
      try {
        const response = await window.apiService.revQnsById({ roleId: roleId });
        setReviewQuestions(response.data.recordInfo);
      } catch (error) {
        toast.error("Error Occured");
      }
    }
  };
  const addRoleQues = async () => {
    setQuestionError("");
    setCmpyError("");
    if (!newQuestion) {
      if (!newQuestion) {
        setQuestionError("Question is Required.");
      }
      return;
    }
    if (!reviewQuestions.some((item) => item.question === newQuestion)) {
      const params = {
        question: newQuestion,
        userRoleId: roleId,
        weightage: weightage,
        businessId: businessId,
      };
      try {
        const response = await window.apiService.userRoleQuesAdd(params);
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          setNewQuestion("");
          setWeightage("");
          setBusinessId("");
          loadQuestions();
        }
      } catch (error) {
        console.log(error);
      }
    } else toast.error("Question already exists");
  };
  const updateRoleQuestion = async () => {
    const questionExists = reviewQuestions.some(
      (item) =>
        item.businessId === +editedBusinessId.businessId &&
        item.question === editedQuestion.question &&
        item.id != editedQuestion.id
    );
    if (!questionExists) {
      const params = {
        question: editedQuestion.question,
        userRoleId: roleId,
        weightage: editedWeightage.weightage,
        businessId: editedBusinessId.businessId,
      };
      try {
        const response = await window.apiService.editRoleQns(
          editedQuestion.id,
          params
        );
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          setEditMode(false);
          setEditedQuestion("");
          setEditedWeightage("");
          setEditedBusinessId("");
          loadQuestions();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Question already exists");
    }
  };
  const showDeletePopup = (questionId) => {
    setQuestionId(questionId);
    setDeletePopupShow(true);
  };
  const loadBusnList = async () => {
    if (props.typeId == 3) {
      try {
        const response = await window.apiService.verifiedBusnList();
        let result = response.data.data;
        if (result) {
          setBusinessList(result);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const deleteQuestion = async () => {
    if (questionId) {
      try {
        const response = await window.apiService.deleteRoleQns(questionId);
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          setEditMode(false);
          setEditedQuestion("");
          setEditedWeightage("");
          setEditedBusinessId("");
          loadQuestions();
          setDeletePopupShow(false);
        }
      } catch (error) {
        console.log(error);
        setDeletePopupShow(false);
      }
    }
  };
  return (
    <>
      {/* <HeaderMenu/> */}
      {/* <div className=""> */}
      {/* <SideBar/> */}
      {/* <div className="main-content ml_200"> */}
      {/* <BreadCrumb/> */}
      {/* <Card className="shade h-500"> */}
      <div className="role_container">
        <Row className="m_b18 title_txt">
          <Col sm={12} md={12} lg={12} xl={12}>
            Selected Role: {roleName}
          </Col>
        </Row>
        {reviewQuestions && (
          <Row className="m_b8">
            <Col sm={6} md={4} lg={4} xl={4} className="">
              <Input
                className="inptRole rle_place m_b8"
                placeholder={
                  editMode ? "Edit Question" : "Enter a new Question"
                }
                type="text"
                value={editMode ? editedQuestion.question : newQuestion}
                onChange={(e) => {
                  if (editMode) {
                    setEditedQuestion({
                      ...editedQuestion,
                      question: e.target.value,
                    });
                  } else {
                    setNewQuestion(e.target.value);
                    setQuestionError("");
                  }
                }}
              />
              {questionError && (
                <p className="text-danger mb-0 fz_14 font-weight-400">
                  {questionError}
                </p>
              )}
            </Col>
            <Col sm={6} md={3} lg={3} xl={3}>
              <Input
                placeholder="Weightage in %"
                className="m_b8 inptRole rle_place"
                type="number"
                min="0"
                max="100"
                value={editMode ? editedWeightage.weightage : weightage}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (
                    inputValue === "" ||
                    (inputValue >= 0 && inputValue <= 100)
                  ) {
                    if (editMode) {
                      setEditedWeightage({
                        ...editedWeightage,
                        weightage: inputValue,
                      });
                    } else {
                      setWeightage(inputValue);
                    }
                  }
                }}
              />
            </Col>
            <Col sm={2} md={2} lg={2} xl={2} className="align-items-center p-0">
              {editMode ? (
                <>
                  <TiTick
                    className="c_pointer plus_icn m_r15"
                    onClick={updateRoleQuestion}
                  />
                  <FaTimes
                    className="c_pointer close_icn"
                    onClick={() => {
                      setEditMode(false);
                      setEditedQuestion("");
                      setEditedWeightage("");
                      setEditedBusinessId("");
                    }}
                  />
                </>
              ) : (
                <BsPlusLg onClick={addRoleQues} className="c_pointer " />
              )}
            </Col>
          </Row>
        )}
        {reviewQuestions &&
          reviewQuestions.map((data) => (
            <Row>
              <Col sm={8} md={4} lg={4} xl={4}>
                <Input
                  placeholder="Enter a new Question"
                  className="m_b8 inptRole rle_place"
                  type="text"
                  value={data.question}
                ></Input>
              </Col>
              <Col sm={3} md={3} lg={3} xl={3}>
                {data.weightage ? (
                  <Input
                    className="m_b8 inptRole rle_place"
                    type="text"
                    value={data.weightage + " " + "%"}
                  ></Input>
                ) : (
                  <Input
                    className="m_b8 inptRole rle_place"
                    type="number"
                    value={data.weightage}
                  ></Input>
                )}
              </Col>
              <Col
                sm={1}
                md={2}
                lg={2}
                xl={2}
                className="align-items-center p-0"
              >
                <div className="d-flex">
                  <div className="m_r15">
                    <span
                      onClick={() => {
                        setEditMode(true);
                        setEditedQuestion(data);
                        setEditedWeightage(data);
                        setEditedBusinessId(data);
                        setQuestionError("");
                        setCmpyError("");
                        setEditedBusnId(data.id);
                      }}
                      title="Edit"
                      className="c_pointer icon_usrRole"
                    >
                      <img src={editRole} />
                    </span>
                  </div>
                  <div className="m_r15">
                    <HiOutlineTrash
                      onClick={() => showDeletePopup(data.id)}
                      title="Delete"
                      className="ques_trash c_pointer"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          ))}
      </div>
      {/* </Card> */}
      {/* ----------- Delete Confirmation Popup ---------- */}
      <Modal
        size="sm"
        show={deletePopupShow}
        onHide={() => setDeletePopupShow(false)}
      >
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            Delete Confirmation
          </Modal.Title>
          <span onClick={handleClose} title="Close">
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="bordr">
          <span>Are you sure you want to delete?</span>
        </Modal.Body>
        <Modal.Footer className="bordr">
          <commonUtils.CustomButton
            btnText="Yes"
            btnWidth="fit-content"
            variant="primary"
            perform={() => deleteQuestion()}
            isDisabled={false}
          />
          <commonUtils.CustomButton
            btnText="No"
            btnWidth="fit-content"
            variant="danger"
            perform={() => setDeletePopupShow(false)}
            isDisabled={false}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReviewQuestion;
