import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Tab, Tabs, Form } from "react-bootstrap";
import { useToast } from "../../../Toast";
import HeaderMenu from "../../headerMenu";
import SideBar from "../../SideBar";
import { commonUtils } from "../../../CommonUtils";
import { PiPhoneFill } from "react-icons/pi";
import { HiLocationMarker } from "react-icons/hi";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "../../../../Redux/Actions/userAction";
const ToHubspot = ({ isConnected }) => {
  const userId = useSelector((state) => state.user.userId);
  const userType = useSelector((state) => state.user.userType);
  const profileId = useSelector((state) => state.user.profileId);
  const authToken = useSelector((state) => state.user.token);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const toast = useToast();
  const dispatch = useDispatch();
  const Refresh = async () => {
    if (authToken) {
      const switchAccount = localStorage.getItem("switchAccount");
      const params = {
        profileId: profileId,
        authToken: authToken,
        userType: userType ? userType : "Personal",
        switchAccount: switchAccount ? switchAccount : false,
      };
      try {
        const data = await window.apiService.refresh(params);
        let result = data.data;
        if (data) {
          dispatch(userAction(result));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleCheckConnection = async () => {
    if (!accessToken) {
      toast.error("Please enter an access token.");
      return;
    }
    try {
      const response = await window.apiService.checkHubSpotConnection(
        accessToken
      );
      if (response) {
        toast.success("HubSpot connection is successful!");
        Refresh();
      } else {
        toast.error("Invalid access token or insufficient permissions.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      <Card className="shade d-flex justify-content-center align-items-center">
        <div className="text-center">
          <p className="card_txt">
            Before entering the access token, ensure that your HubSpot account
            has all the required access scopes enabled for contact access.
          </p>
          <p className="card_txt text-left">
            If you can't find the Access Token, follow these steps:
          </p>
          <div className="text-left inline-block">
            <ul className="card_txt">
              <li>
                In your HubSpot account, click the{" "}
                <strong>settings icon</strong> in the main navigation bar.
              </li>
              <li>
                In the left sidebar menu, navigate to{" "}
                <strong>Integrations &gt; Private apps</strong>.
              </li>
              <li>
                Click the <strong>name</strong> of your app.
              </li>
              <li>
                Click the <strong>Auth</strong> tab, click{" "}
                <strong>Show token</strong> to reveal your access token. Click{" "}
                <strong>Copy</strong> to copy the token to your clipboard.
              </li>
            </ul>
          </div>
          <div className="mt-4 d-flex gap_20 justify-content-center">
            {/* <label>Access Token</label> */}
            {/* <input /> */}
            {!isConnected ? (
              <>
                <Form.Control
                  className="pl-2"
                  type="text"
                  placeholder="Access Token"
                  style={{ width: "50%" }}
                  value={accessToken}
                  onChange={(e) => setAccessToken(e.target.value)}
                />
                <Button
                  disabled={!accessToken.trim()}
                  onClick={() => handleCheckConnection()}
                >
                  Connect
                </Button>
              </>
            ) : (
              <span style={{ color: "green" }}>Connection Established!</span>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

export default ToHubspot;
