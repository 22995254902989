import React from "react";
import "../Register/register.css";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Form, InputGroup } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import LoaderOverlay from "../plugins/LoaderOverlay";
import { BiSolidLockOpen } from "react-icons/bi";
import { Button, Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { commonUtils } from "../CommonUtils";
const InviteUser = () => {
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmpassword, setShowConfirmpassword] = useState(false);
  const [userId, setUserId] = useState();

  useEffect(() => {
    Decodetoken(token);
  }, [token]);

  const Decodetoken = async (token) => {
    if (token) {
      try {
        const response = await window.apiService.deCodeToken(token);
        setUserId(response.data.data.id);
      } catch (error) {
        toast.error("Error Occured");
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmpassword = () => {
    setShowConfirmpassword(!showConfirmpassword);
  };

  const onSubmit = async (data) => {
    const params = {
      password: data.password,
      id: userId,
    };
    setIsLoading(true);
    if (userId) {
      try {
        const response = await window.apiService.updateResetPassword(params);
        setIsLoading(false);
        if (response.data.status == "OK") {
          toast.success("Account activated successfully", {
            theme: "colored",
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              setTimeout(() => {
                navigate("/login");
              }, 1500);
            },
          });
        } else {
          toast.error(response.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (error) {
        setIsLoading(false);
        toast.error("Error Occured");
      }
    }
  };
  return (
    <>
      <LoaderOverlay isLoading={isLoading} />
      <div>
        <Row>
          <Col lg="12" md="12" sm="12" xl="12">
            <Row>
              <Col lg="5" md="5" sm="12" xl="5" className="log_bg log_text">
                {commonUtils.LeftDiv()}
              </Col>
              <Col
                lg="7"
                md="7"
                sm="12"
                xl="7"
                style={{ backgroundColor: "white" }}
              >
                <div className="d-flex justify-content-center align-items-center copyright bg-reg flex-column">
                  <div style={{ marginBottom: "30px" }}>
                    <h4>Activate your account</h4>
                  </div>
                  <Col lg="7" md="7" sm="12" xl="5">
                    <div style={{ paddingTop: "0px!important" }}>
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group
                          controlId="password"
                          className="log_form form-group"
                        >
                          <div className="inputContainer">
                            <label className="fz_14">Password</label>
                            <InputGroup
                              className={` ${errors.password ? "is-invalid" : "inputBox"
                                }`}
                            >
                              <InputGroup.Text className="border-0">
                                <BiSolidLockOpen />
                              </InputGroup.Text>
                              <Form.Control
                                className="formControl fz_13"
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                name="password"
                                id="log_pass"
                                {...register("password", {
                                  required: "Password is required",
                                  minLength: {
                                    value: 8,
                                    message:
                                      "Password must be at least 8 characters!",
                                  },
                                  maxLength: {
                                    value: 12,
                                    message:
                                      "Password must not exceed 12 characters!"
                                  },
                                  pattern: {
                                    value:
                                      /^(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/i,
                                    message:
                                      "*Password must have one special character & number",
                                  },
                                })}
                              />
                              <InputGroup.Text className="border-0">
                                {!showPassword ? (
                                  <IoEye
                                    className="c_pointer"
                                    onClick={togglePasswordVisibility}
                                  />
                                ) : (
                                  <IoEyeOff
                                    className="c_pointer"
                                    onClick={togglePasswordVisibility}
                                  />
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                            {errors.password && (
                              <Form.Text className="text-danger">
                                {errors.password.message}
                              </Form.Text>
                            )}
                          </div>
                        </Form.Group>
                        <Form.Group
                          controlId="confirmPassword"
                          className="log_form form-group"
                        >
                          <div className="inputContainer">
                            <label className="fz_14">Confirm Password</label>
                            <InputGroup
                              className={` ${errors.confirmPassword
                                ? "is-invalid"
                                : "inputBox"
                                }`}
                            >
                              <InputGroup.Text className="border-0">
                                <BiSolidLockOpen />
                              </InputGroup.Text>
                              <Form.Control
                                style={{ position: "unset", border: "none" }}
                                type={showConfirmpassword ? "text" : "password"}
                                className="formControl fz_13"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                {...register("confirmPassword", {
                                  required: "Confirm Password is required",
                                  minLength: {
                                    value: 8,
                                    message:
                                      "Password must be at least 8 characters!",
                                  },
                                  maxLength: {
                                    value: 16,
                                    message:
                                      "Password must not exceed 16 characters!"
                                  },
                                  pattern: {
                                    value:
                                      /^(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/i,
                                    message:
                                      "*Password must have one special character & number",
                                  },
                                  validate: (match) => {
                                    const password = getValues("password");
                                    return (
                                      match === password ||
                                      "Passwords should match!"
                                    );
                                  },
                                })}
                              />
                              <InputGroup.Text className="border-0">
                                {!showConfirmpassword ? (
                                  <IoEye
                                    className="c_pointer"
                                    onClick={toggleConfirmpassword}
                                  />
                                ) : (
                                  <IoEyeOff
                                    className="c_pointer"
                                    onClick={toggleConfirmpassword}
                                  />
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                            {errors.confirmPassword && (
                              <Form.Text className="text-danger">
                                {errors.confirmPassword.message}
                              </Form.Text>
                            )}
                          </div>
                        </Form.Group>
                        <div className="text-center">
                          <Button
                            variant="primary"
                            type="submit"
                            className="login"
                          >
                            Activate
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </>
  );
};

export default InviteUser;
