import { commonUtils } from "../CommonUtils";
import { Dropdown } from "react-bootstrap";
import { MdSwitchAccount, MdEmail } from "react-icons/md";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const UserProfileDropdown = ({ userId, userType, profileId }) => {
    const [profiles, setProfiles] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [userProfList, setUserProfList] = useState("");
    const [userBusnList, setUserBusnList] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if (profiles != undefined && profiles != "") {
            setUserProfList(
                profiles.filter((st) => st.userTypeName == "Professional")
            );
        }
        if (profiles != undefined && profiles != "") {
            setUserBusnList(
                profiles.filter((st) => st.userTypeName == "Business")
            );
        }
    }, [profiles]);
    useEffect(() => {
        if (userId) {
            handleFetchProfiles();
        }
    }, [userId]);
    const handleDropDownOpen = () => {
        if (isOpen) {
            setIsOpen(false);
            return;
        }
        setIsOpen(true);
    }
    const handleFetchProfiles = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await commonUtils.getUserProfiles(userId);
            setProfiles(data.data.recordInfo);
        } catch (err) {
            setError("Error fetching data");
        } finally {
            setLoading(false);
        }
    };
    const handleNameClick = (userType, userId, profileId) => {
        commonUtils.handleNameClick(userId, userType, profileId, navigate, dispatch);
    }

    return (
        <span className="switch_icn position-unset c_pointer">
        <Dropdown
            show={isOpen}
            onToggle={() => handleDropDownOpen()}
            style={{ cursor: "default" }}
        >
            {(userProfList.length > 0 || userBusnList.length > 0) && <Dropdown.Toggle
                as="span"
                id="dropdown-me"
                className="me-dropdown-toggle c_pointer fz_14"
            >
                <MdSwitchAccount
                    title="Switch Account"
                    className="c_pointer"
                />
            </Dropdown.Toggle>}
            <Dropdown.Menu>
                <Dropdown.Item
                    // onClick={() =>
                    //     Switchaccount("Personal", userId, null)
                    // }
                    disabled={userType == "Personal"}
                >
                    <span
                        className="header_nav_span"
                        style={{ marginLeft: "5px" }}
                        onClick={() =>
                            handleNameClick("Personal", userId, null)
                        }
                    >
                        Personal&nbsp;
                    </span>
                    {userType == "Personal" ? (
                        <span className="act_acc">Active</span>
                    ) : (
                        ""
                    )}
                </Dropdown.Item>
                <Dropdown.Divider />

                {userProfList.length > 0 && <Dropdown.Item disabled>
                    <span className="c_blue">Professional</span>
                </Dropdown.Item>}

                {userProfList.length > 0 && userProfList.map((usertype) => (
                    <Dropdown.Item
                        className="marLf"
                        key={usertype.professionalId}
                        onClick={() =>
                            handleNameClick(
                                usertype.userTypeName,
                                userId,
                                usertype.professionalId
                            )
                        }
                        disabled={
                            usertype.professionalId === profileId && userType === "Professional"
                        }
                    >
                        {usertype.professionalType}{" "}
                        {profileId ===
                            usertype.professionalId && userType === "Professional" && (
                                <span className="act_acc">Active</span>
                            )}
                    </Dropdown.Item>
                ))}
                {/* <Dropdown.Divider /> */}

                {userBusnList.length > 0 && <Dropdown.Item disabled>
                    <span className="header_nav_span c_blue">
                        Business
                    </span>
                </Dropdown.Item>}

                {userBusnList.length > 0 && userBusnList.map((usertype) => (
                    <Dropdown.Item
                        className="marLf"
                        key={usertype.businessId}
                        onClick={() =>
                            handleNameClick(
                                usertype.userTypeName,
                                userId,
                                usertype.businessId
                            )
                        }
                        disabled={
                            usertype.businessId == profileId && userType === "Business"
                        }
                    >
                        <span title={usertype.companyName}>{usertype.shortName !== undefined ? usertype.shortName : (usertype.companyName?.length > 20 ? `${usertype.companyName.substring(0, 20)}...` : usertype.companyName)}</span>&nbsp;
                        {profileId == usertype.businessId && userType === "Business" ? (
                            <span className="act_acc">Active</span>
                        ) : (
                            ""
                        )}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
        </span>
    );
};

export default UserProfileDropdown;
