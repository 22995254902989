import { useForm } from "react-hook-form";
import { BsExclamationCircle } from "react-icons/bs";
import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { HiCheckCircle } from "react-icons/hi";
import "../ProfileUpdate/Profile.css";
import { Form } from "react-bootstrap";

const PhoneControl = forwardRef((props, ref) => {
  const [phoneNo, setPhoneNo] = useState(""); // State for phone number
  const [countryCode, setCountryCode] = useState("+1"); // State for country code
  const [validation, setValidation] = useState({ phone: "" }); // State for validation
  const [populate, setPopulate] = useState(false); // State to trigger value population

  useEffect(() => {
    assignValue(); // Ensure phone number is populated on component mount
  }, []);

  useEffect(() => {
    if (populate) assignValue(); // Ensure phone number is populated when populate state is true
  }, [populate]);

  // Function to populate phone number and country code from props
  const assignValue = () => {
    if (props.phoneNo) {
      if (props.phoneNo.trim().startsWith("+")) {
        // Case where phone number includes country code
        const frstIdx = props.phoneNo.indexOf(" ");
        const countryCode = props.phoneNo.substring(0, frstIdx);
        const restOfNumber = props.phoneNo.substring(frstIdx + 1);
        setCountryCode(countryCode); // Set the country code
        setPhoneNo(restOfNumber); // Set the phone number without country code
      } else {
        setPhoneNo(props.phoneNo); // Set phone number directly if no country code
        setCountryCode(""); // Clear country code if not present
      }
      setValidation({ phone: "" }); // Clear any previous validation errors
    } else {
      // If no phone number is passed in props, handle default cases
      if (
        props.isProfile &&
        (props.phoneNo === "" || props.phoneNo === undefined)
      ) {
        setValidation({ phone: "Phone No is required" });
        setPhoneNo(""); // Clear phone number if it's required but missing
      } else {
        setPhoneNo(""); // Otherwise, clear phone number
      }
    }
  };

  // Function to handle phone number format and updates
  const handlePhoneFormet = (value) => {
    if (value.startsWith("+")) {
      setCountryCode(value); // Update country code if value starts with "+"
      const phoneNumber = value + " " + phoneNo;
      props.onPhoneChange(phoneNumber); // Pass updated phone number to parent
    } else {
      const formattedPhoneNumber = formatPhoneNumber(value);
      setPhoneNo(formattedPhoneNumber); // Update formatted phone number
      const phoneNumber = countryCode + " " + formattedPhoneNumber;
      props.onPhoneChange(phoneNumber); // Pass updated phone number to parent
    }
  };

  // Function to format phone number based on its length
  const formatPhoneNumber = (value) => {
    if (value === "" || value === undefined || value === 0) {
      setValidation({ phone: "Phone No. is required" }); // Trigger validation if value is empty
      return "";
    } else {
      setValidation({ phone: "" }); // Clear validation on valid input
      const phoneNumber = value.replace(/[^\d]/g, ""); // Remove non-numeric characters
      const phoneNumberLength = phoneNumber.length;

      // Format the phone number based on its length
      if (phoneNumberLength < 4) return phoneNumber;
      if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    }
  };

  // Function to show the verification popup
  const verification = () => {
    props.PopupShow(true);
  };

  // Expose imperative methods to parent component
  useImperativeHandle(ref, () => ({
    clrField() {
      setPhoneNo(""); // Clear phone number
      setCountryCode("+1"); // Reset country code
      setPopulate(false); // Reset populate flag
      setValidation({ phone: "" }); // Clear validation
    },
    populate() {
      setPopulate(true); // Trigger population of values
    },
  }));

  return (
    <div>
      <Form.Group
        className={
          props.isProfile || props.unmounted || props.isInvite
            ? "form-group required-field-block"
            : "pro_form form-group inputBox"
        }
      >
        {!props.hideLabel &&(props.isProfile || props.unmounted )? (
          <Form.Label className="mb-1 profile_label">Phone Number</Form.Label>
        ) : (
          ""
        )}
        <div
          className={
            props.mandatory && (validation.phone || props.phoneValidation)
              ? `${!props.unmounted ? "rounded" : ""} d-flex error`
              : "d-flex"
          }
        >
          <Form.Select
            type="select"
            className={
              props.isProfile || props.unmounted || props.isInvite
                ? "form-control input_ctrl country-code"
                : "formControl fz_13 country-code"
            }
            defaultValue={countryCode}
            value={countryCode}
            onChange={(e) => handlePhoneFormet(e.target.value)}
          >
            <option value="+1">+1</option>
            <option value="+91">+91</option>
          </Form.Select>
          <Form.Control
            type="text"
            maxLength={14}
            className={
              props.isProfile || props.unmounted || props.isInvite
                ? "form-control input_ctrl phone-input"
                : "formControl fz_13 phone-input"
            }
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            onChange={(e) => handlePhoneFormet(e.target.value)}
            value={phoneNo}
          />
          {!validation.phone && props.isProfile && props.unmounted ? (
            <span className="required-icon">
              {props.mobileVerify && !props.isverified ? (
                <a href="#" onClick={verification}>
                  <BsExclamationCircle
                    className={
                      props.isverified && props.isPhoneChanged
                        ? ""
                        : "text-danger"
                    }
                    style={{ fontSize: "20px" }}
                  />
                </a>
              ) : (
                <HiCheckCircle className="check_circle" />
              )}
            </span>
          ) : ''}
        </div>
      </Form.Group>

      {/* Show error message only if validation.phone exists or props.phoneValidation */}
      {(validation.phone || props.phoneValidation) ? (
        <p className="fz-13 mb-0">
          {validation.phone || props.phoneValidation}
        </p>
      ) : (
        ""
      )}
    </div>
  );
});

export default PhoneControl;
