import { Suspense } from "react";
import loader from "./assets/images/loader.gif";
import { useSelector } from "react-redux";
import Home from "./components/Home/index";
import Forgotpassword from "./components/ResetPassword/index";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import Login from "./components/Login/index";
import Dashboard from "./components/Dashboard/DashboardTabs/MeTab";
import FollowersTab from "./components/Dashboard/DashboardTabs/FollowersTab";
import Register from "./components/Register/index";
import Profile from "./components/Profile/index";
import Findnew from "./components/Findnew/index";
import ProfileUpdate from "./components/ProfileUpdate/index";
import Inbox from "./components/Dashboard/DashboardTabs/InboxTab";
import DetailReview from "./components/Dashboard/DashboardTabs/DetailReview";
import ViewUser from "./components/Dashboard/DashboardTabs/ViewUser";
import Followers from "./components/Dashboard/DashboardTabs/FollowersTab";
import Following from "./components/Dashboard/DashboardTabs/FollowingTab";
import Notification from "./components/Dashboard/DashboardTabs/NotificationTab";
import ViewNotification from "./components/Dashboard/DashboardTabs/ViewNotification";
import Reviews from "./components/Dashboard/DashboardTabs/ReviewsTab";
import Help from "./components/Dashboard/DashboardTabs/HelpTab";
import MarketPlaceTab from "./components/Dashboard/DashboardTabs/MarketPlace";
import MyBin from "./components/Dashboard/DashboardTabs/MyBin";
import PostReviews from "./components/Dashboard/DashboardTabs/Review";
import AboutUs from "./components/Home/aboutUs";
import Plandetails from "./components/Home/Plandetails";
import TermsOfService from "./components/Home/termsOfService";
import PrivacyPolicy from "./components/Home/privacyPolicy";
import ShareView from "./components/Home/shareView";
import ReviewShare from "./components/Home/ReviewShare";
import TwoFactorAuth from "./components/Login/TwoFactorAuth";
import UserSettings from "./components/Dashboard/Settings/UserSettings";
import FromHubspot from "./components/Dashboard/DashboardTabs/Hubspot/FromHubspot";
import Hubspot from "./components/Dashboard/DashboardTabs/Hubspot/SideBar";
import AuthSettings from "./components/Dashboard/Settings/AuthSettings";
import NotifySettings from "./components/Dashboard/Settings/NotifySettings";
import ScoreSettings from "./components/Dashboard/Settings/ScoreSettings";
import UserPreference from "./components/Dashboard/Settings/UserPreference";
import TiiptokPreference from "./components/Dashboard/Settings/TiiptokPreference";
import CategorySettings from "./components/Dashboard/Settings/CategorySettings";
import SubscriptionPlanDetails from "./components/Dashboard/Settings/SubscriptionPlanDetails";
import QRCodeView from "./components/Home/QRCodeView";
import InviteUser from "./components/Register/InviteUser";
// Admin Imports
import AdminDashboard from "./components/Admin/Dashboard";
import UserRoles from "./components/Admin/UserRoles";
import AddHubspotUser from "./components/Admin/AddHubspotUser";
import ReviewQuestions from "./components/Admin/ReviewQuestions";
import UserTypes from "./components/Admin/UserTypes";
import AdminProfile from "./components/Admin/ProfileUpdate/index";
import Settings from "./components/Admin/Settings";
import ProfessionalTypes from "./components/Admin/Settings/ProfessionalTypes";
import InAppFeatures from "./components/Admin/Settings/InAppFeatures";
import CompositeScoreSettings from "./components/Admin/Settings/CompositeScoreSettings";
// import ServicePlan from "./components/Admin/Settings/ServicePlan";
import ServicePlan from "./components/Admin/Settings/Subscription/SideBar";
import AdminBusiness from "./components/Admin/Business/AdminBusiness";
import BusinessDetailsPage from "./components/Admin/Business/BusinessDetailsPage";

// Support Imports
import SupportDashboard from "./components/Support/Dashboard";
import Report from "./components/Support/Report";
import SupportProfile from "./components/Support/ProfileUpdate/index";

const Loader = (Component) => (props) => {
  return (
    <Suspense
      fallback={
        <div className="loader-container">
          <img className="loader-image" src={loader} alt="Loading..." />
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

const UserTypeSpecificDashboard = () => {
  const userType = useSelector((state) => state.user.userType);
  if (
    userType === "Personal" ||
    userType === "Professional" ||
    userType === "Business"
  ) {
    return <Dashboard />;
  } else if (userType === "Admin") {
    return <UserRoles />;
  } else if (userType === "Support") {
    return <Report />;
  }
};

const adminRoutes = [
  {
    path: "dashboard/user_roles",
    element: <UserRoles />,
    name: "User Roles",
  },
  {
    path: "dashboard/user_roles/review_questions",
    element: <ReviewQuestions />,
    name: "Review Questions",
  },
  {
    path: "dashboard/usertypes",
    element: <UserTypes />,
    name: "User Types",
  },
  {
    path: "dashboard/admin_profile",
    element: <AdminProfile />,
    name: "User Profile",
  },
  {
    path: "dashboard/settings",
    element: <Settings />,
    name: "Settings",
  },
  {
    path: "dashboard/settings/professional_types",
    element: <ProfessionalTypes />,
    name: "Settings",
  },
  {
    path: "dashboard/settings/score_settings",
    element: <CompositeScoreSettings />,
    name: "Settings",
  },
  {
    path: "dashboard/business",
    element: <AdminBusiness />,
    name: "AdminBusiness",
  },
  {
    path: "dashboard/business/view",
    element: <BusinessDetailsPage />,
    name: "BusinessDetailsPage",
  },
  {
    path: "dashboard/settings/plans",
    element: <ServicePlan />,
    name: "ServicePlans",
  },
  {
    path: "admin/settings/inapp",
    name: "InAppFeatures",
    element: <InAppFeatures />,
  },
  {
    path: "admin/hubspot",
    element: <AddHubspotUser />,
    name: "Hubspot User",
  },
];

const supportRoutes = [
  {
    path: "dashboard/support",
    element: <SupportDashboard />,
    name: "Admin Dashboard",
    icon: "ni ni-tv-2 text-primary",
  },
  {
    path: "dashboard/report",
    element: <Report />,
    name: "Report",
  },
  {
    path: "dashboard/support_profile",
    element: <SupportProfile />,
    name: "User Types",
  },
];

export const routes = [
  ...adminRoutes,
  ...supportRoutes,
  {
    path: "*",
    element: <Home />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "dashboard",
    element: <UserTypeSpecificDashboard />,
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
  },
  {
    path: "forgotpassword",
    element: <Forgotpassword />,
    name: "Forgotpassword",
    icon: "ni ni-tv-2 text-primary",
  },
  {
    path: "resetpassword/:token",
    element: <ResetPassword />,
    name: "ResetPassword",
    icon: "ni ni-tv-2 text-primary",
  },

  {
    path: "myfollowers",
    element: <FollowersTab />,
    name: "FollowersTab",
    icon: "ni ni-tv-2 text-primary",
  },
  {
    path: "registration",
    element: <Register />,
    name: "Register",
    icon: "ni ni-tv-2 text-primary",
  },
  {
    path: "profile",
    element: <Profile />,
  },
  {
    path: "dashboard/finduser",
    name: "Finduser",
    element: <Findnew />,
  },
  {
    path: "dashboard/profile",
    name: "Profile",
    element: <ProfileUpdate />,
  },
  {
    path: "inbox/:typeId",
    name: "Inbox",
    element: <Inbox />,
  },
  {
    path: "dashboard/reviews/more/view",
    name: "ViewReview",
    element: <DetailReview />,
  },
  {
    path: "dashboard/search/user",
    name: "viewUser",
    element: <ViewUser />,
  },
  {
    path: "dashboard/viewuser",
    name: "viewUser",
    element: <ViewUser />,
  },
  {
    path: "dashboard/notifications",
    name: "notifications",
    element: <Notification />,
  },
  {
    path: "dashboard/notification/view",
    name: "viewnotification",
    element: <ViewNotification />,
  },
  {
    path: "dashboard/finduser/view",
    name: "viewUser",
    element: <ViewUser />,
  },
  {
    path: "dashboard/Followers",
    name: "Followers",
    element: <Followers />,
  },
  {
    path: "dashboard/Following",
    name: "Following",
    element: <Following />,
  },
  {
    path: "dashboard/reviews",
    name: "Reviews",
    element: <Reviews />,
  },
  {
    path: "dashboard/help",
    name: "Help",
    element: <Help />,
  },
  {
    path: "dashboard/followers/postreview",
    name: "PostReview",
    element: <PostReviews />,
  },
  {
    path: "dashboard/following/PostReview",
    name: "PostReview",
    element: <PostReviews />,
  },
  {
    path: "dashboard/viewuser/postreview",
    name: "PostReviews",
    element: <PostReviews />,
  },
  {
    path: "/aboutus",
    name: "aboutus",
    element: <AboutUs />,
  },
  {
    path: "/terms-of-service",
    name: "terms",
    element: <TermsOfService />,
  },
  {
    path: "/privacy-policy",
    name: "policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/plan_details/:userid",
    name: "plandetails",
    element: <Plandetails />,
  },
  {
    path: "/shareview/:token",
    name: "shareview",
    element: <ShareView />,
  },
  {
    path: "/share/view/:userid/:reviewid",
    name: "ReviewShare",
    element: <ReviewShare />,
  },
  {
    path: "/two-factor-authentication",
    name: "2fa",
    element: <TwoFactorAuth />,
  },
  {
    path: "dashboard/user_settings",
    name: "UserSettings",
    element: <UserSettings />,
  },
  {
    path: "dashboard/user_settings/Auth_settings",
    name: "AuthSettings",
    element: <AuthSettings />,
  },
  {
    path: "dashboard/user_settings/Notification_Preference",
    name: "NotifySettings",
    element: <NotifySettings />,
  },
  {
    path: "dashboard/user_settings/score_settings",
    name: "ScoreSettings",
    element: <ScoreSettings />,
  },
  {
    path: "dashboard/user_settings/Manage_Category",
    name: "CategorySettings",
    element: <CategorySettings />,
  },
  {
    path: "dashboard/user_settings/review_preference",
    name: "UserPreference",
    element: <UserPreference />,
  },
  {
    path: "dashboard/user_settings/tiiptok_preference",
    name: "TiiptokPreference",
    element: <TiiptokPreference />,
  },
  {
    path: "dashboard/user_settings/plans_billings",
    name: "SubscriptionPlanDetails",
    element: <SubscriptionPlanDetails />,
  },
  {
    path: "/viewuser/:token",
    name: "QRCodeView",
    element: <QRCodeView />,
  },
  {
    path: "user_activation/:token",
    name: "InviteUser",
    element: <InviteUser />,
  },
  {
    path: "dashboard/marketplace",
    name: "MarketPlaceTab",
    element: <MarketPlaceTab />,
  },
  {
    path: "dashboard/mybin",
    name: "MyBin",
    element: <MyBin />,
  },
  {
    path: "dashboard/From_Hubspot",
    name: "FromHubspot",
    element: <FromHubspot />,
  },
  {
    path: "dashboard/Hubspot",
    name: "Hubspot",
    element: <Hubspot />,
  },
];

export default routes;
