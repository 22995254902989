import React, { useState, useEffect } from "react";
import HeaderMenu from "../headerMenu";
import SideBar from "../SideBar";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../plugins/Breadcrumb";
import { MdSecurity, MdEditNotifications } from "react-icons/md";
import { TbCategoryPlus } from "react-icons/tb";
import { connect } from "react-redux";
import { TbPencilStar } from "react-icons/tb";
import { MdOutlineRateReview } from "react-icons/md";
import { MdAppSettingsAlt } from "react-icons/md";
import { RiMoneyDollarBoxLine } from "react-icons/ri";
import { useSubscription } from "../../SubscriptionContext";
import UpgradePopup from "../../plugins/UpgradePopup";
import hubspot from "../../../assets/images/hubspot.svg";
const UserSettings = (props) => {
  const navigate = useNavigate();
  const [settingsInfo, setSettingsInfo] = useState([]);
  const { allFeatures, isFeatureEnabled } = useSubscription();
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    ViewSettingsInfo();
    console.log("props", props);
  }, [props]);

  const ViewSettingsInfo = async () => {
    if (props.userId) {
      try {
        const response = await window.apiService.settingsUserView(props.userId);
        console.log(response.data.recordInfo);
        if (response.data.status == "OK") {
          setSettingsInfo(response.data.recordInfo);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <HeaderMenu />
      <SideBar />
      <div className="main-content ml_200">
        {/* <BreadCrumb /> */}
        <Card className="shade">
          <Container fluid>
            <div className="header-body  pt-3">
              {/* Card stats */}
              <Row className="pb-3">
                <Col lg="6" xl="3">
                  <Card
                    className="card-stats mb-4 mb-xl-0"
                    onClick={() => {
                      if (
                        isFeatureEnabled(
                          allFeatures["Two-Factor-Authentication%"]?.key,
                          allFeatures["Two-Factor-Authentication%"]?.isDeleted
                        )
                      ) {
                        navigate("/dashboard/user_settings/Auth_settings", {
                          state: {
                            settingsData: settingsInfo,
                            userId: props.userId,
                          },
                        });
                      } else {
                        setShowPopup(true);
                      }
                    }}
                  >
                    <CardBody className="setngLst">
                      <Row className="align-items-center">
                        <Col className="col-auto pad_lf">
                          <MdSecurity style={{ fontSize: "25px" }} />
                        </Col>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="fs_14px text-uppercase text-muted mb-0"
                          >
                            Two Factor Authentication
                          </CardTitle>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card
                    className="card-stats mb-4 mb-xl-0"
                    onClick={() => {
                      navigate(
                        "/dashboard/user_settings/Notification_Preference",
                        {
                          state: {
                            settingsData: settingsInfo,
                            userId: props.userId,
                          },
                        }
                      );
                    }}
                  >
                    <CardBody className="setngLst">
                      <Row className="align-items-center">
                        <Col className="col-auto pad_lf">
                          <MdEditNotifications style={{ fontSize: "25px" }} />
                        </Col>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="fs_14px text-uppercase text-muted mb-0"
                          >
                            Notification Preference
                          </CardTitle>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card
                    className="card-stats mb-4 mb-xl-0"
                    onClick={() => {
                      if (
                        isFeatureEnabled(
                          allFeatures["BS,-PS%"]?.key,
                          allFeatures["BS,-PS%"]?.isDeleted
                        ) ||
                        isFeatureEnabled(
                          allFeatures["Culture-Score%"]?.key,
                          allFeatures["Culture-Score%"]?.isDeleted
                        )
                      ) {
                        navigate("/dashboard/user_settings/score_settings", {
                          state: {
                            settingsData: settingsInfo,
                            userId: props.userId,
                          },
                        });
                      } else {
                        setShowPopup(true);
                      }
                    }}
                  >
                    <CardBody className="setngLst">
                      <Row className="align-items-center">
                        <Col className="col-auto pad_lf">
                          <TbPencilStar style={{ fontSize: "25px" }} />
                        </Col>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="fs_14px text-uppercase text-muted mb-0"
                          >
                            Manage Scores
                          </CardTitle>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card
                    className="card-stats mb-4 mb-xl-0"
                    onClick={() => {
                      navigate("/dashboard/user_settings/Manage_Category", {
                        state: {
                          settingsData: settingsInfo,
                          userId: props.userId,
                        },
                      });
                    }}
                  >
                    <CardBody className="setngLst">
                      <Row className="align-items-center">
                        <Col className="col-auto pad_lf">
                          <TbCategoryPlus style={{ fontSize: "22px" }} />
                        </Col>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="fs_14px text-uppercase text-muted mb-0"
                          >
                            Manage Category
                          </CardTitle>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="pb-3">
                <Col lg="6" xl="3">
                  <Card
                    className="card-stats mb-4 mb-xl-0"
                    onClick={() => {
                      navigate("/dashboard/user_settings/review_preference");
                    }}
                  >
                    <CardBody className="setngLst">
                      <Row className="align-items-center">
                        <Col className="col-auto pad_lf">
                          <MdOutlineRateReview style={{ fontSize: "22px" }} />
                        </Col>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="fs_14px text-uppercase text-muted mb-0"
                          >
                            Review Notification Setting
                          </CardTitle>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                {props.userType != "Professional" && (
                  <Col lg="6" xl="3">
                    <Card
                      className="card-stats mb-4 mb-xl-0"
                      onClick={() => {
                        if (
                          isFeatureEnabled(
                            allFeatures["Tiptok%"]?.key,
                            allFeatures["Tiptok%"]?.isDeleted
                          )
                        ) {
                          navigate(
                            "/dashboard/user_settings/tiiptok_preference",
                            {
                              state: {
                                settingsData: settingsInfo,
                                userId: props.userId,
                                userType: props.userType,
                                profileId: props.profileId,
                              },
                            }
                          );
                        } else {
                          setShowPopup(true);
                        }
                      }}
                    >
                      <CardBody className="setngLst">
                        <Row className="align-items-center">
                          <Col className="col-auto pad_lf">
                            <MdAppSettingsAlt style={{ fontSize: "22px" }} />
                          </Col>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="fs_14px text-uppercase text-muted mb-0"
                            >
                              Tiiptok Preference Setting
                            </CardTitle>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                )}
                <Col lg="6" xl="3">
                  <Card
                    className="card-stats mb-4 mb-xl-0"
                    onClick={() => {
                      navigate("/dashboard/user_settings/plans_billings");
                    }}
                  >
                    <CardBody className="setngLst">
                      <Row className="align-items-center">
                        <Col className="col-auto pad_lf">
                          <RiMoneyDollarBoxLine style={{ fontSize: "22px" }} />
                        </Col>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="fs_14px text-uppercase text-muted mb-0"
                          >
                            Manage Plans and billings
                          </CardTitle>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col lg="6" xl="3">
                  <Card
                    className="card-stats mb-4 mb-xl-0"
                    onClick={() => {
                      navigate("/dashboard/Hubspot");
                    }}
                  >
                    <CardBody className="setngLst">
                      <Row className="align-items-center">
                        <Col className="col-auto pad_lf">
                          <img
                            src={hubspot}
                            style={{
                              height: "20px",
                              width: "20px",
                              marginRight: "15px",
                            }}
                          />
                        </Col>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="fs_14px text-uppercase text-muted mb-0"
                          >
                            Hubspot
                          </CardTitle>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col> */}
              </Row>
            </div>
          </Container>
        </Card>
      </div>
      <UpgradePopup show={showPopup} handleClose={() => setShowPopup(false)} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    userType: state.user.userType,
    profileId: state.user.profileId,
  };
};
export default connect(mapStateToProps)(UserSettings);
