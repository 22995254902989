import "./inbox.css";
import chatImg from "../../../assets/images/chat.jpeg";
import { useToast } from "../../Toast";
import moment from "moment";
import "moment-timezone";
import { followId } from "../../../Redux/Actions/userAction";
import { FaPaperclip } from "react-icons/fa";
import loader from "../../../assets/images/loader.gif";
import fileImg from "../../../assets/images/file.jpg";
import { TiDelete } from "react-icons/ti";
import { IoIosCloseCircle } from "react-icons/io";
import lzString from "lz-string";
import { FaStop } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa6";
import { commonUtils } from "../../CommonUtils";
import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
} from "reactstrap";
import axios from "../../../axios";
import { useSelector } from "react-redux";
import profile from "../../../assets/images/profile.jpg";
import { Modal, Button, Col, Row, Card } from "react-bootstrap";
import { HiLocationMarker } from "react-icons/hi";
import chatInputStyle from "../../../assets/css/chatInputStyle";
import { MentionsInput, Mention } from "react-mentions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactMic } from "react-mic";
import { PiMicrophoneDuotone } from "react-icons/pi";
import { IoClose } from "react-icons/io5";
import { FiSearch } from "react-icons/fi";
import CustomModal from "../../plugins/CustomModal";
import add_grp from "../../../assets/images/add_grp.png";
import { IoMdExit } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";
import { TiCamera } from "react-icons/ti";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdMore } from "react-icons/io";
import { debounce } from "lodash";
export const GroupChat = forwardRef((props, ref) => {
  const [recordings, setRecordings] = useState([]);
  const [files, setFiles] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const lastMessageRef = useRef(null);
  const userId = useSelector((state) => state.user.userId);
  const [yesterData, setYesterData] = useState([]);
  const [toData, setToData] = useState([]);
  const [dateData, setDateData] = useState([]);
  const [message, setMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [users, setUsers] = useState([]);
  const handleCloseErrorModal = () => setShowErrorModal(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [disabled, setDisabled] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [remove, setRemove] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [show, setShow] = useState(true);
  const fileInputRef = useRef(null);
  const [voiceRec, setVoiceRec] = useState(null);
  const [showMembers, setShowMembers] = useState(false);
  const [addGrpMemModal, setAddGrpMemModal] = useState(false);
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [filter, setfilter] = useState(false);
  const [allData, setAllData] = useState([]);
  const [grpMembers, setGrpMembers] = useState([]);
  const [existMembers, setExistMembers] = useState([]);
  const [pastMembers, setPastMembers] = useState([]);
  const [showPastMems, setShowPastMems] = useState(false);
  const [rmvMemId, setrmvMemId] = useState(null);
  const [rmverId, setRmverId] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [exitGrp, setExitGrp] = useState(false);
  const [showNewAdmin, setShowNewAdmin] = useState(false);
  const [newAdminId, setNewAdminId] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const [desEdit, setDesEdit] = useState(false);
  const [grpEdit, setGrpEdit] = useState(false);
  const [nameEdit, setNameEdit] = useState(false);
  const [desContent, setDesContent] = useState("");
  const [nameCont, setNameCont] = useState("");
  const [grpImg, setGrpImg] = useState("");
  const [deletePopupShow, setDeletePopupShow] = useState(false);
  const [grpImgPreview, setGrpImgPreview] = useState("");
  const [config, setConfig] = useState([]);
  const [imgLoader, setImgLoader] = useState(false);
  useEffect(() => {
    fetch("/config.json")
      .then((res) => res.json())
      .then((res) => {
        setConfig(res);
      });
  }, []);
  const handleRadioChange = (e) => {
    setShowHistory(e.target.value === "on");
  };
  const startRecording = () => {
    onStart();
  };
  const rmvGrpMemById = (id) => {
    const updatedItems = grpMembers.filter((item) => item.userId !== id);
    setGrpMembers(updatedItems);
  };
  useEffect(() => {
    setMessage("");
  }, [props.id]);
  useEffect(() => {
    const reloadloadGrpMems = (event) => {
      if (props.chatType === "group") {
        const { activeUserId } = event.detail;
        loadGrpMems(activeUserId);
      }
    };
    window.addEventListener("loadGrpMems", reloadloadGrpMems);
    return () => {
      window.removeEventListener("loadGrpMems", reloadloadGrpMems);
    };
  }, []);
  const getGrpInfo = async () => {
    try {
      const response = await window.apiService.getGrpInfo(props.id);
      if (response.data.status === "OK") {
        console.log("response.data.data", response.data.data);
        setDesContent(response.data.data.description);
        setNameCont(response.data.data.name);
        setGrpImg(response.data.data.imagePath);
        setGrpImgPreview(response.data.data.imagePath);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleLeaveGrp = async (id, rmvMemId) => {
    const params = {
      newAdminId: id,
    };
    try {
      const response = await window.apiService.leaveGrp(
        props.id,
        rmvMemId,
        rmverId,
        params
      );
      if (response.data.status === "OK") {
        toast.success("Success");
        loadGrpMems(props.id);
        setShowModal(false);
        setShowNewAdmin(false);
        setExitGrp(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const uploadImage = async (id) => {
    const myNewFile = new File([grpImg], grpImg.name.replace(/\s/g, ""), {
      type: grpImg.type,
    });
    setImgLoader(true);
    createImage(myNewFile);
    let formData = new FormData();
    formData.append("file", myNewFile);
    formData.append("groupId", id);
    try {
      const response = await window.apiService.grpImgUpload(formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.status == "OK") {
        setImgLoader(false);
        toast.success(config.GrpImgUpSuc);
        // setGrpImgPreview("");
        // setGrpImg("");
        window.dispatchEvent(
          new CustomEvent("fetchGroups", { detail: { id } })
        );
        getGrpInfo();
        setGrpEdit(false);
      } else if (response.data.status == "EXPECTATION_FAILED") {
        setImgLoader(false);
      }
    } catch (error) {
      setImgLoader(false);
      console.log(error);
    }
  };
  const createImage = (file) => {
    var image = new Image();
    var reader = new FileReader();
    reader.onload = (e) => {
      setGrpImg(e.target.result);
    };
    reader.readAsDataURL(file);
  };
  const deleteImage = async () => {
    if (grpImg) {
      setLoading(true);
      try {
        const response = await window.apiService.grpImgDelete(props.id);
        if (response.data.status == "OK") {
          setGrpEdit(false);
          const id = props.id;
          window.dispatchEvent(
            new CustomEvent("fetchGroups", { detail: { id } })
          );
          getGrpInfo();
          setLoading(false);
          toast.success(response.data.message);
          setDeletePopupShow(false);
        } else if (response.data.status == "EXPECTATION_FAILED") {
          setLoading(false);
          toast.error(response.data.message);
          setDeletePopupShow(false);
        }
      } catch (error) {
        setLoading(false);
        setDeletePopupShow(false);
        console.log(error);
      }
    } else {
      toast.warning(config.DelImageErr);
    }
  };
  const handleImageChange = (event) => {
    setGrpImgPreview(URL.createObjectURL(event.target.files[0]));
    setGrpImg(event.target.files[0]);
  };
  const updateGrp = async () => {
    if (nameCont.trim()) {
      const params = {
        description: desContent,
        name: nameCont,
      };
      try {
        const response = await window.apiService.updateGrp(props.id, params);
        if (response.data.status === "OK") {
          if (response.data.message == "Group already exists.") {
            toast.info(response.data.message);
          } else {
            if (grpImg.name) {
              uploadImage(props.id);
            } else {
              toast.success(response.data.message);
              const id = response.data.id;
              window.dispatchEvent(
                new CustomEvent("fetchGroups", { detail: { id } })
              );
              getGrpInfo();
              setGrpEdit(false);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else toast.info("Please enter group name");
  };
  const convertUTCToLocal = (value, format) => {
    let zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var utcDate = moment.utc(value);
    var localDate = utcDate.clone().tz(zone);
    return localDate.format(format);
  };

  const handleUserChange = (event) => {
    setUserSearchTerm(event.target.value);
  };
  const handleCreateGrp = async () => {
    const updatedMembers = grpMembers.map((member) => ({
      ...member,
      history: showHistory,
      addedBy: userId,
    }));
    setGrpMembers(updatedMembers);
    const params = {
      groupId: props.id,
      members: updatedMembers,
    };
    try {
      const response = await window.apiService.createGroup(params);
      if (response.data.status === "OK") {
        toast.success(response.data.message);
        setGrpMembers([]);
        setUserSearchTerm("");
        loadGrpMems(props.id);
        setAddGrpMemModal(false);
        setFilterData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const latestRequestTimeRef = useRef(0);
  useEffect(() => {
    if (userSearchTerm !== "") {
      debouncedUserSearch(userSearchTerm);
    } else {
      setFilterData([]);
    }
  }, [userSearchTerm]);

  const debouncedUserSearch = debounce(async (searchTerm) => {
    await handleUserSearch(searchTerm);
  }, 300);
  const handleUserSearch = async (userSearchTerm) => {
    const requestTime = Date.now();
    latestRequestTimeRef.current = requestTime;

    try {
      let formData = new FormData();
      formData.append("keyword", userSearchTerm);

      const response = await window.apiService.userInboxSearch(formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (requestTime === latestRequestTimeRef.current) {
        setFilterData(filterUserData(response.data.data));
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    setFilterData(filterUserData(allData));
  }, [grpMembers, allData]);
  const filterUserData = (data) => {
    const memberIds = grpMembers.map((member) => member.userId);
    const existMemIds = existMembers.map((member) => member.userId);
    const updatedData = data.map((item) => {
      return {
        ...item,
        isExists: existMemIds.includes(item.id) ? 1 : 0,
      };
    });
    const filteredData = updatedData.filter(
      (item) => !memberIds.includes(item.id)
    );
    return filteredData;
  };
  const handleUserKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUserSearch(userSearchTerm);
    }
  };
  const stopRecording = () => {
    setIsRecording(false);
    setShow(false);
  };
  const onStart = () => {
    setIsRecording(true);
  };

  const onStop = (recordedBlob) => {
    const audioBlob = new Blob([recordedBlob.blob], {
      type: "audio/wav",
    });
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Data = reader.result.split(",")[1];
      const compressedData = lzString.compressToBase64(base64Data);
      setRecordings((prevRecordings) => [...prevRecordings, compressedData]);
    };
    reader.readAsDataURL(audioBlob);

    console.log("Recording stopped:", recordings[0]);
    const file = new File([recordedBlob.blob], "recording.mp3", {
      type: recordedBlob.blob.type,
    });
    setVoiceRec(file);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      let maxSizeInBytes;
      let fileType;

      if (file.type.startsWith("video/")) {
        maxSizeInBytes = 100 * 1024 * 1024; // 100 MB for video
        fileType = "video";
      } else if (file.type.startsWith("audio/")) {
        maxSizeInBytes = 10 * 1024 * 1024; // 10 MB for audio
        fileType = "audio";
      } else if (file.type.startsWith("image/")) {
        maxSizeInBytes = 10 * 1024 * 1024; // 10 MB for image
        fileType = "image";
      } else {
        maxSizeInBytes = 10 * 1024 * 1024; // 10 MB for other file types
        fileType = "file";
      }

      if (file.size > maxSizeInBytes) {
        toast.info(
          `File size exceeds the limit (${maxSizeInBytes / (1024 * 1024)} MB)`
        );
        event.target.value = null;
        setRemove(true);
        setSelectedFile(null);
        setFileType("");
        setFileName("");
        return;
      }

      setFileType(fileType);
      setSelectedFile(file);
      setFileName(file ? file.name : "");
      setRemove(true);
    }
  };

  const updateRemve = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setRemove(true);
    setSelectedFile(null);
    setFileType("");
    setFileName("");
  };

  const handleUpload = async () => {
    if (selectedFile.length > 0) {
      let formData = new FormData();
      formData.append("file", selectedFile[0]);

      try {
        const response = await window.apiService.chatUploadFile(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data.status === "OK") {
          console.log("log", response.data);
        } else if (response.data.status === "EXPECTATION_FAILED") {
          console.log("log", response.data);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !isSending) {
      event.preventDefault();
      setIsSending(true);
      sendMsg();
    }
  };
  function textWithLinks(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const mentionRegex = /@\[([^\]]+?)\](\((\d+([&-])\d+)?\))|@([^\s]+)/g;
    return text.split(/\s(?![^\[]*])/).map((word, index) => {
      if (urlRegex.test(word)) {
        return (
          <a key={index} href={word} target="_blank" rel="noopener noreferrer">
            {word}
          </a>
        );
      } else if (mentionRegex.test(word)) {
        const mentions = word.match(mentionRegex);
        if (mentions) {
          return mentions.map((mention, mentionIndex) => {
            const match = mention.match(/@\[([^\]]+?)\](\((\d+([&-])\d+)?\))/g);
            console.log("fsf", match);
            const id = match ? match[2] : null;
            return (
              <span
                key={index + mentionIndex}
                className={id == userId ? "" : "cmt_icon c_pointer"}
                onClick={() => handleMention(mention)}
              >
                {rmvMenFormat(mention)}
              </span>
            );
          });
        }
      }

      return word + " ";
    });
  }

  const rmvMenFormat = (value) => {
    const mentionRegex = /@\[([^\]]+?)\](?:\(\d+([&-])\d+\))|@([^\s]+)/g;
    const match = mentionRegex.exec(value);
    if (match) {
      if (match[3]) {
        const nestedMatch = match[3].match(/\[([^\]]+)\]\(\d+\)/);
        return nestedMatch ? nestedMatch[1] : "";
      }
      return match[1] || "";
    }
    return null;
  };
  const handleMention = (mentionedUser) => {
    const match = mentionedUser.match(/@\[([^\]]+)\](?:\(([^)]+)\))?/);
    if (match) {
      const [, name, userIdPart] = match;
      let Id = userIdPart;
      let busnId = null;
      let profId = null;
      if (userIdPart && userIdPart.includes("&")) {
        [Id, busnId] = userIdPart.split("&");
      } else if (userIdPart && userIdPart.includes("-")) {
        [Id, profId] = userIdPart.split("-");
      }
      if (name && Id) {
        if (Id !== userId) {
          dispatch(followId(Id));
          navigate("/dashboard/viewuser", {
            state: {
              Id,
              userType:
                profId || busnId
                  ? profId
                    ? "Professional"
                    : "Business"
                  : "Personal",
              profileId: profId || busnId ? (profId ? profId : busnId) : null,
            },
          });
        }
      }
    }
  };
  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  const addAsGrpMember = (id, name, image, isExists) => {
    if (isExists !== 1) {
      if (grpMembers.length <= 11) {
        const newMember = {
          userId: id,
          role: "Member",
          imagePath: image,
          name: name,
          addedBy: userId,
        };
        setGrpMembers((prev) => [...prev, newMember]);
      } else {
        toast.error("You cannot add more than 10 members to this group");
      }
    }
  };
  useImperativeHandle(ref, () => ({
    reloadMems() {
      loadGrpMems(props.id);
    },
  }));
  useEffect(() => {
    loadMsgs(props.id, userId);
    loadGrpMems(props.id);
    // userLists();
    getGrpInfo();
  }, [props.id || userId]);
  // const userLists = async () => {
  //     try {
  //         const response = await window.apiService.lookupAllUsersList();
  //         const usersArr = response.data.recordInfo.filter((row) => {
  //             return (
  //                 row.userId != props.userId &&
  //                 row.userId.split("-")[0] != props.userId &&
  //                 row.userId.split("&")[0] != props.userId
  //             );
  //         });
  //         const users = usersArr.map(function (row) {
  //             return { display: row.name, id: row.userId };
  //         });

  //         console.log("userList", users);
  //         setUsers(users);
  //     } catch (error) {
  //         console.log(error);
  //     }
  // };
  useImperativeHandle(ref, () => ({
    reloadMsg() {
      loadMsgs(props.id, userId);
      console.log("reloadMsg");
      setShowMembers(false);
    },
  }));
  const handleReloadChats = () => {
    window.dispatchEvent(new Event("reloadLoadChats"));
  };
  const loadGrpMems = async (id) => {
    if (id) {
      try {
        const response = await window.apiService.listGrpMems(id);
        setExistMembers(
          response.data.data.filter((item) => item.leftAt === null)
        );
        const usersArr = response.data.data.filter((row) => {
          return (
            String(row.userId) !== userId.toString() && row.leftAt === null
          );
        });
        const users = usersArr.map(function (row) {
          return {
            display: row.userName.replace(/\s+/g, ""),
            id: String(row.userId),
          };
        });
        setUsers(users);
        console.log("users", users);
        setPastMembers(
          response.data.data.filter((item) => item.leftAt !== null)
        );
      } catch (error) {
        toast.error("Error Occured");
      }
    }
  };
  const loadMsgs = async (id, userId) => {
    if (id && userId) {
      try {
        const response = await window.apiService.listGrpMsgs(id, userId);
        mergeMsgs(response.data.data);
        setTimeout(() => {
          if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }, 100);
      } catch (error) {
        toast.error("Error Occured");
      }
    }
  };
  function timesort(a, b) {
    let dateA = new Date(a.date).getTime();
    let dateB = new Date(b.date).getTime();
    return dateA > dateB ? 1 : -1;
  }
  const mergeMsgs = (msg) => {
    // const ff = msg.filter((obj) => {
    //     if (obj.senderId == userId || obj.senderId == props.id) {
    //         return obj;
    //     }
    // });
    const gg = msg.map((obj) => {
      return { ...obj, createdAt: new Date(obj.createdAt) };
    });
    const sortedAsc = gg.sort(
      (objA, objB) => Number(objA.createdAt) - Number(objB.createdAt)
    );
    sortedAsc.sort(timesort);
    const yesterday = new Date().getDate() - 1;
    const Yester_data = sortedAsc.filter(
      (st) => yesterday == new Date(st.createdAt).getDate()
    );
    Yester_data.reverse();
    setYesterData(Yester_data);
    const Today_data = sortedAsc.filter(
      (st) => new Date().getDate() == new Date(st.createdAt).getDate()
    );
    Today_data.reverse();
    setToData(Today_data);
    const date_data = sortedAsc.filter(
      (st) =>
        new Date().getDate() != new Date(st.createdAt).getDate() &&
        yesterday != new Date(st.createdAt).getDate()
    );
    date_data.reverse();
    setDateData(date_data);
  };
  const getTimeStamp = (value) => {
    const now = new Date();
    if (moment(now).format("HH:mm") == moment(value).format("HH:mm")) {
      return "Just Now";
    } else {
      return moment(value).format("HH:mm");
    }
  };
  const changeCmtText = (value) => {
    var words = value.split(/\s(?![^\[]*])/);
    var contents = words.map(function (word, i) {
      var separator = i < words.length - 1 ? " " : "";
      return word + separator;
    });
    let cmtText = contents.reduce(
      (accumulator, item) => (accumulator += item),
      ""
    );
    if (cmtText.length > 288) {
      var limit_char = cmtText.substring(0, 288);
      setMessage(limit_char);
    } else setMessage(cmtText);
  };

  const sendMsg = async () => {
    if (
      (message.trim() && message.length <= 288) ||
      selectedFile ||
      recordings.length !== 0
    ) {
      setDisabled(true);
      setIsSending(true);
      const msgContent = {
        senderId: userId,
        groupId: props.id,
        content: message,
      };
      try {
        const response = await window.apiService.addGrpMsg(msgContent);
        if (response) {
          setMessage("");
          loadMsgs(response.data.receiverId);
          handleReloadChats();
          setRemove(true);
          setFileName("");

          if (selectedFile || recordings.length !== 0) {
            let formData = new FormData();
            setLoading(true);
            if (selectedFile) {
              formData.append("file", selectedFile);
              formData.append("id", response.data.id);
              formData.append("type", fileType);
              formData.append("groupId", props.id);
            } else if (recordings.length !== 0) {
              formData.append("file", voiceRec);
              formData.append("id", response.data.id);
              formData.append("type", "audio");
              formData.append("groupId", props.id);
            }
            try {
              const uploadResponse = await window.apiService.grpChatUploadFile(
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              if (uploadResponse.data.status === "OK") {
                setMessage("");
                loadMsgs(uploadResponse.data.id);
                handleReloadChats();
                setLoading(false);
                setSelectedFile(null);
                setFileType("");
                setFileName("");
                setRecordings([]);
                setFiles(null);
                setIsRecording(false);
                setShow(true);
              } else if (uploadResponse.data.status === "EXPECTATION_FAILED") {
                setLoading(false);
              }
            } catch (error) {
              console.log(error);
              setLoading(false);
            }
          }
        }
      } catch (error) {
        toast.error("Error Occurred");
      } finally {
        setIsSending(false);
        setLoading(false);
        setDisabled(true);
      }
    } else if (!(message.length <= 288)) {
      setShowErrorModal(true);
    }
  };

  const renderFile = (fileType, filePath, fileName) => {
    if (!fileType) {
      return null;
    }
    if (fileType == "image") {
      return (
        <a href={filePath} target="_blank" rel="noopener noreferrer">
          <img
            height="200"
            width="200"
            src={filePath}
            alt="Image"
            className="cht_file"
          />
        </a>
      );
    }

    if (fileType == "video") {
      return (
        <video
          controls
          width="300"
          height="200"
          className="cht_file video-player"
          onPlay={handleVideoPlay}
        >
          <source src={filePath} />
          Your browser does not support the video tag.
        </video>
      );
    }

    if (fileType == "audio") {
      return (
        <audio controls>
          <source src={filePath} />
          Your browser does not support the audio tag.
        </audio>
      );
    }

    if (fileType == "file") {
      return (
        <div
          className="d-flex border_att br_5px p-2 align-items-center"
          style={{ backgroundColor: "#efebe3" }}
        >
          <img
            width="45"
            height="45"
            src={fileImg}
            className="mr-2"
            alt="Image"
          />
          <a
            href={filePath}
            target="_blank"
            rel="noopener noreferrer"
            style={{ maxWidth: "200px", wordWrap: "break-word" }}
          >
            {fileName}
          </a>
        </div>
      );
    }

    return null;
  };
  const handleVideoPlay = (event) => {
    const videos = document.querySelectorAll(".video-player");
    videos.forEach((video) => {
      if (video !== event.target) {
        video.pause();
      }
    });
  };
  const handleDeleteRecording = (index) => {
    const updatedRecordings = [...recordings];
    updatedRecordings.splice(index, 1);
    setRecordings(updatedRecordings);
    setShow(true);
  };
  const handleDeleteFiles = () => {
    setFiles(null);
  };

  return (
    <>
      {!showMembers ? (
        <div className="chat container">
          {props.id && !props.notShow ? (
            <div className="chatInfo max_50">
              <div className="ban_messageInfo m-2">
                <img src={grpImgPreview ? grpImgPreview : profile} />
              </div>
              <div
                className={`d-flex flex-column ml-2 ${
                  props.city != undefined && props.state != undefined
                    ? "mt-4"
                    : ""
                }`}
              >
                <a href="#" onClick={() => setShowMembers(true)}>
                  {props.name}
                </a>
                {/* {props.city != undefined && props.state != undefined ? ( */}
                <span>
                  <p className="fz-12 font-weight-normal mb-0">
                    {existMembers.length +
                      `${existMembers.length > 1 ? " Members" : " Member"}`}
                  </p>
                </span>
                {/* ) : (
                                ""
                            )} */}
              </div>
              {existMembers &&
                existMembers.some((member) => member.userId === userId) && (
                  <div className="ml-auto">
                    <IoMdExit
                      className="text-red fz-22 c_pointer"
                      title="Exit"
                      onClick={() => setExitGrp(true)}
                    />
                  </div>
                )}
            </div>
          ) : (
            ""
          )}
          {props.id && !props.notShow ? (
            <div>
              <div className="messages max_50">
                {dateData.map((chatInfo, i) =>
                  chatInfo.isAddMsg == 1 ? (
                    <div className="d-flex justify-content-center">
                      <div className="App text-center fz-12 p-1">
                        {chatInfo.senderId == userId
                          ? "You created this group"
                          : chatInfo.content}
                      </div>
                    </div>
                  ) : chatInfo.senderId == userId ? (
                    <div className="left_msgdiv usr_right">
                      <div className="left_message w_35" key={i}>
                        <div className="messageInfo">
                          <span className="rt_nme">You</span>
                          <img
                            src={
                              chatInfo.imagePath ? chatInfo.imagePath : profile
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="msg_pad"></div>
                        <div className="messageContent">
                          {chatInfo.content && (
                            <p>{textWithLinks(chatInfo.content)}</p>
                          )}
                          {loading ? (
                            <div className="pro-loader-center">
                              <img
                                className="loader-image"
                                src={loader}
                                alt="Loading..."
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <span>
                            {chatInfo.fileType && chatInfo.filePath && (
                              <>
                                {renderFile(
                                  chatInfo.fileType,
                                  chatInfo.filePath,
                                  chatInfo.fileName
                                )}
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="msg_pad"></div>
                        <div className="messageContent">
                          <div className="day_div">
                            <span>
                              {moment(chatInfo.createdAt).format("D MMM,HH:mm")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="left_msgdiv">
                      <div className="left_message" key={i}>
                        <div className="messageInfo">
                          <img
                            src={
                              chatInfo.imagePath ? chatInfo.imagePath : profile
                            }
                          />
                          <span className="nmeSty">{chatInfo.name}</span>
                        </div>
                      </div>
                      <div className="d-flex mr_15">
                        <div className="msg_pad"></div>
                        <div
                          className={
                            chatInfo.content ? "rt_messageContent" : ""
                          }
                        >
                          {chatInfo.content && (
                            <p>{textWithLinks(chatInfo.content)}</p>
                          )}
                          {loading ? (
                            <div className="pro-loader-center">
                              <img
                                className="loader-image"
                                src={loader}
                                alt="Loading..."
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <span>
                            {chatInfo.fileType && chatInfo.filePath && (
                              <>
                                {renderFile(
                                  chatInfo.fileType,
                                  chatInfo.filePath,
                                  chatInfo.fileName
                                )}
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="msg_pad"></div>
                        <div className="messageContent">
                          <div className="day_div">
                            <span>
                              {moment(chatInfo.createdAt).format("D MMM,HH:mm")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
                {yesterData.length > 0 ? (
                  <div className="d-flex justify-content-center">
                    <div className="day_label">Yesterday</div>
                  </div>
                ) : (
                  ""
                )}
                {yesterData.map((chatInfo, i) =>
                  chatInfo.isAddMsg == 1 ? (
                    <div className="d-flex justify-content-center">
                      <div className="App text-center fz-12 p-1">
                        {chatInfo.senderId == userId
                          ? "You created this group"
                          : chatInfo.content}
                      </div>
                    </div>
                  ) : chatInfo.senderId == userId ? (
                    <div className="left_msgdiv usr_right">
                      <div className="left_message w_35" key={i}>
                        <div className="messageInfo">
                          <span className="rt_nme">You</span>
                          <img
                            src={
                              chatInfo.imagePath ? chatInfo.imagePath : profile
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="msg_pad"></div>
                        <div className="messageContent">
                          {chatInfo.content && (
                            <p>{textWithLinks(chatInfo.content)}</p>
                          )}
                          {loading ? (
                            <div className="pro-loader-center">
                              <img
                                className="loader-image"
                                src={loader}
                                alt="Loading..."
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <span>
                            {chatInfo.fileType && chatInfo.filePath && (
                              <>
                                {renderFile(
                                  chatInfo.fileType,
                                  chatInfo.filePath,
                                  chatInfo.fileName
                                )}
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="msg_pad"></div>
                        <div className="messageContent">
                          <div className="day_div">
                            <span>
                              {"Yesterday " + getTimeStamp(chatInfo.createdAt)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="left_msgdiv">
                      <div className="left_message" key={i}>
                        <div className="messageInfo">
                          <img
                            src={
                              chatInfo.imagePath ? chatInfo.imagePath : profile
                            }
                          />
                          <span className="nmeSty">{chatInfo.name}</span>
                        </div>
                      </div>
                      <div className="d-flex mr_15">
                        <div className="msg_pad"></div>
                        <div
                          className={
                            chatInfo.content ? "rt_messageContent" : ""
                          }
                        >
                          {chatInfo.content && (
                            <p>{textWithLinks(chatInfo.content)}</p>
                          )}
                          {loading ? (
                            <div className="pro-loader-center">
                              <img
                                className="loader-image"
                                src={loader}
                                alt="Loading..."
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <span>
                            {chatInfo.fileType && chatInfo.filePath && (
                              <>
                                {renderFile(
                                  chatInfo.fileType,
                                  chatInfo.filePath,
                                  chatInfo.fileName
                                )}
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="msg_pad"></div>
                        <div className="messageContent">
                          <div className="day_div">
                            <span>
                              {"Yesterday " + getTimeStamp(chatInfo.createdAt)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
                {toData.length > 0 ? (
                  <div className="d-flex justify-content-center">
                    <div className="day_label">Today</div>
                  </div>
                ) : (
                  ""
                )}
                {toData.map((chatInfo, i) =>
                  chatInfo.isAddMsg == 1 ? (
                    <div className="d-flex justify-content-center">
                      <div className="App text-center fz-12 p-1">
                        {chatInfo.senderId == userId
                          ? "You created this group"
                          : chatInfo.content}
                      </div>
                    </div>
                  ) : chatInfo.senderId == userId ? (
                    <div className="left_msgdiv usr_right">
                      <div className="left_message w_35" key={i}>
                        <div className="messageInfo">
                          <span className="rt_nme">You</span>
                          <img
                            src={
                              chatInfo.imagePath ? chatInfo.imagePath : profile
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="msg_pad"></div>
                        <div className="messageContent">
                          {chatInfo.content && (
                            <p>{textWithLinks(chatInfo.content)}</p>
                          )}
                          {loading ? (
                            <div className="pro-loader-center">
                              <img
                                className="loader-image"
                                src={loader}
                                alt="Loading..."
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <span>
                            {chatInfo.fileType && chatInfo.filePath && (
                              <>
                                {renderFile(
                                  chatInfo.fileType,
                                  chatInfo.filePath,
                                  chatInfo.fileName
                                )}
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="msg_pad"></div>
                        <div className="messageContent">
                          <div className="day_div">
                            <span>{getTimeStamp(chatInfo.createdAt)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="left_msgdiv ">
                      <div className="left_message " key={i}>
                        <div className="messageInfo">
                          <img
                            src={
                              chatInfo.imagePath ? chatInfo.imagePath : profile
                            }
                          />
                          <span className="nmeSty">{chatInfo.name}</span>
                        </div>
                      </div>
                      <div className="d-flex mr_15">
                        <div className="msg_pad"></div>
                        <div
                          className={
                            chatInfo.content ? "rt_messageContent" : ""
                          }
                        >
                          {chatInfo.content && (
                            <p>{textWithLinks(chatInfo.content)}</p>
                          )}
                          {loading ? (
                            <div className="pro-loader-center">
                              <img
                                className="loader-image"
                                src={loader}
                                alt="Loading..."
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <span>
                            {chatInfo.fileType && chatInfo.filePath && (
                              <>
                                {renderFile(
                                  chatInfo.fileType,
                                  chatInfo.filePath,
                                  chatInfo.fileName
                                )}
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="msg_pad"></div>
                        <div className="messageContent">
                          <div className="day_div">
                            <span>{getTimeStamp(chatInfo.createdAt)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
                <div ref={lastMessageRef}></div>
                {console.log("existMembers", existMembers)}

                {pastMembers.some((member) => member.userId === userId) && (
                  <div className="d-flex justify-content-center">
                    <div className="App text-center fz-12 p-1">
                      {(() => {
                        const member = pastMembers.find(
                          (member) => member.userId === userId
                        );
                        if (member) {
                          return member.removerId === 0
                            ? "You left the group"
                            : `You were removed by ${
                                member.removedBy ? member.removedBy : "someone"
                              }`;
                        }
                        return "";
                      })()}
                    </div>
                  </div>
                )}
              </div>
              {console.log("existMembers", existMembers)}
              {existMembers &&
              existMembers.some((member) => member.userId === userId) ? (
                <div
                  className="input inpt_msg max_50"
                  style={{ position: fileName ? "relative" : "static" }}
                >
                  <MentionsInput
                    className="men_inp"
                    // style={{
                    //     ...chatInputStyle,
                    //     maxHeight: '100px',
                    //     overflowY: 'scroll',
                    //     resize: 'none',
                    // }}
                    style={chatInputStyle}
                    maxLength={288}
                    onChange={(e) => {
                      changeCmtText(e.target.value);
                    }}
                    value={message}
                    // onKeyPress={handleKeyPress}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                    placeholder="Type here..."
                  >
                    <Mention
                      style={{ backgroundColor: "#efebe3" }}
                      trigger="@"
                      data={users}
                    />
                  </MentionsInput>
                  <div>
                    {recordings.map((compressedData, index) => (
                      <div key={index} className="d-flex mr_35">
                        <audio controls className="vice">
                          <source
                            src={`data:audio/wav;base64,${lzString.decompressFromBase64(
                              compressedData
                            )}`}
                            type="audio/wav"
                          />
                        </audio>
                        <IoIosCloseCircle
                          className="cls_icn"
                          onClick={() => handleDeleteRecording(index)}
                        />
                      </div>
                    ))}
                  </div>
                  {fileName && (
                    <div className="message-container">
                      <span>{fileName}</span>
                      <TiDelete className="remove-icon" onClick={updateRemve} />
                    </div>
                  )}
                  {files && (
                    <div>
                      {files}
                      <IoIosCloseCircle
                        style={{
                          marginTop: "16px",
                          fontSize: "20px",
                          color: "red",
                        }}
                        onClick={() => handleDeleteFiles()}
                      />
                    </div>
                  )}
                  <div className="audio-controls">
                    {show && (
                      <div
                        className="stpSty"
                        onClick={isRecording ? stopRecording : startRecording}
                      >
                        {isRecording ? (
                          <div className="blinking-icon">
                            {" "}
                            <FaStop
                              title="Stop"
                              className="icnSty"
                              style={{ color: "red" }}
                            />{" "}
                          </div>
                        ) : (
                          <PiMicrophoneDuotone
                            title="Record"
                            className="icnSty"
                          />
                        )}
                      </div>
                    )}
                    <ReactMic
                      record={isRecording}
                      onStop={onStop}
                      onStart={onStart}
                      strokeColor="#000000"
                      backgroundColor="#FF4081"
                      className="react-mic-behind-button"
                    />
                  </div>
                  <div className="send">
                    <input
                      ref={fileInputRef}
                      id="file-upload"
                      type="file"
                      accept="image/*, video/*, audio/*, application/pdf"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="file-upload"
                      className="file-upload-button c_pointer"
                    >
                      <FaPaperclip className="fa_paperclip" />
                    </label>
                    <commonUtils.CustomButton
                      btnText="Send"
                      btnWidth="fit-content"
                      variant="primary"
                      perform={() => sendMsg()}
                      disabled={disabled}
                    />
                  </div>
                </div>
              ) : (
                <div className="input inpt_msg max_50 fz_14 justify-content-center">
                  You can't send messages to this group because you're no longer
                  a member
                </div>
              )}
            </div>
          ) : (
            <div className="welcome_container">
              {/* <div className="d-flex justify-content-center"> */}
              <div className="">
                <h6>Let's Start Chatting!</h6>
              </div>
              <div className="">
                <img src={chatImg} style={{ height: "100px" }} />
              </div>
              {/* </div> */}
            </div>
          )}
          {/* ----------- limit exceed msg Popup ---------- */}
          <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
            <Modal.Header closeButton>
              <Modal.Title>Your message is too long</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Please edit it down to 288 characters or split over multiple
              messages.
            </Modal.Body>
            <Modal.Footer>
              <commonUtils.CustomButton
                btnText="Ok"
                btnWidth="fit-content"
                variant="primary"
                perform={() => handleCloseErrorModal()}
                isDisabled={false}
              />
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        <div className="chat container">
          <div className="grp_msgs max_50">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="d-flex align-items-center">
                <FaArrowLeft
                  title="Back"
                  className="c_pointer"
                  onClick={() => setShowMembers(false)}
                />
                &nbsp;
                <div className="d-flex align-items-center">
                  <div className="ban_messageInfo m-2">
                    <img src={grpImgPreview ? grpImgPreview : profile} />
                  </div>
                  <div className="d-flex flex-column">
                    <span onClick={() => setShowMembers(true)}>
                      <span className="fz_14 font-weight-normal mb-0">
                        {props.name}
                      </span>
                    </span>
                    <span className="fz_14 font-weight-normal mb-0">
                      {props.description}
                    </span>
                  </div>
                </div>
              </div>
              {existMembers.filter((item) => item.role === "Admin")[0]
                ?.userId === userId && (
                <UncontrolledDropdown nav className="top_5">
                  <DropdownToggle nav className="p-0">
                    <IoMdMore title="More" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow m p-3" right>
                    <div
                      className="d-flex align-items-center c_pointer"
                      onClick={() => setAddGrpMemModal(true)}
                    >
                      <img
                        src={add_grp}
                        height={16}
                        width={16}
                        title="Add Members"
                        className=" opacity-6"
                      />
                      &nbsp;
                      <span className="fz_14">Add Members</span>
                    </div>
                    <div
                      className="d-flex align-items-center c_pointer"
                      onClick={() => {
                        setGrpEdit(true);
                      }}
                    >
                      <MdModeEdit />
                      &nbsp;
                      <span className="fz_14">Edit group</span>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </div>
            <div className="mb-2 font-weight-500">Members</div>
            {existMembers &&
              [...existMembers]
                .sort((a, b) => (a.role === "Admin" ? -1 : 1))
                .map((member) => (
                  <Card className="rounded-0 px-2 py-1">
                    <div className="d-flex align-items-center">
                      <Col lg="2" md="2" sm="2" xl="2">
                        <img
                          className="brd_rad"
                          width="30"
                          height="30"
                          src={member.imagePath ? member.imagePath : profile}
                        />
                      </Col>
                      <Col lg="8" md="8" sm="8" xl="8">
                        <div className="d-flex">
                          <p className="mb-0 font-weight-400 fz_14">
                            {member.userId === userId ? "You" : member.userName}
                          </p>
                        </div>
                        <div className="d-flex">
                          <p
                            className="mb-0 font-weight-400 fz-12"
                            title={member.email}
                          >
                            {member.email}
                          </p>
                        </div>
                      </Col>
                      <Col lg="2" md="2" sm="2" xl="2">
                        {member.role == "Admin" && (
                          <label className="grp_rle usr_brnd_psnl">
                            {member.role}
                          </label>
                        )}
                        {existMembers.filter((item) => item.role === "Admin")[0]
                          ?.userId === userId && member.role !== "Admin" ? (
                          <commonUtils.CustomButton
                            btnText="Remove"
                            btnWidth="fit-content"
                            variant="danger"
                            perform={() => {
                              setrmvMemId(member.userId);
                              setRmverId(userId);
                              setShowModal(true);
                            }}
                            disabled={false}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                    </div>
                  </Card>
                ))}
            {pastMembers.length > 0 && (
              <p
                className="fz_14 mt-2 font-weight-400 name_blue c_pointer"
                onClick={() => setShowPastMems(!showPastMems)}
              >
                View past members
              </p>
            )}
            {showPastMems &&
              pastMembers &&
              pastMembers.map((member) => (
                <Card className="rounded-0 px-2 py-1">
                  <div className="d-flex align-items-center">
                    <Col lg="2" md="2" sm="2" xl="2">
                      <img
                        className="brd_rad"
                        width="30"
                        height="30"
                        src={member.imagePath ? member.imagePath : profile}
                      />
                    </Col>
                    <Col lg="8" md="8" sm="8" xl="8">
                      <div className="d-flex">
                        <p className="mb-0 font-weight-400 fz_14">
                          {member.userId === userId ? "You" : member.userName}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p className="mb-0 font-weight-400 fz-12">
                          {convertUTCToLocal(
                            member.leftAt,
                            "DD/MM/YYYY [at] h:mm a"
                          )}
                          {/* {new Date(member.leftAt.replace(' ', 'T') + 'Z').toLocaleString('en-GB', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                hour12: true
                                            }).replace(',', ' at')} */}
                        </p>
                      </div>
                    </Col>
                    {/* <Col lg="2" md="2" sm="2" xl="2">
                                        {member.role == 'Admin' && <label className="grp_rle usr_brnd_psnl">{member.role}</label>}
                                    </Col> */}
                  </div>
                </Card>
              ))}
          </div>
          <CustomModal
            show={addGrpMemModal}
            handleClose={() => setAddGrpMemModal(false)}
            post={true}
            title="Add members"
            body={
              <>
                {loading ? (
                  <div className="pro-loader-center">
                    <img
                      className="loader-image"
                      src={loader}
                      alt="Loading..."
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="d-flex"></div>
                <div className="rev_drpdn mem_bor mt-2 mb-2">
                  <div style={{ padding: "10px" }}>
                    <Row className="mr-2 ml-2">
                      {grpMembers.map(
                        (member) =>
                          member.userId !== userId && (
                            <Col
                              lg="4"
                              md="4"
                              sm="4"
                              xl="4"
                              className="pr-2 pl-2"
                            >
                              <div className="d-flex align-items-center justify-content-center gap_5 name_tag mb-2">
                                <img
                                  className="rounded-circle"
                                  src={
                                    member.imagePath
                                      ? member.imagePath
                                      : profile
                                  }
                                  height={20}
                                  width={20}
                                />
                                <p
                                  className="mb-0 font-weight-400 fz_14"
                                  title={member.name}
                                >
                                  {member.name.length > 8
                                    ? member.name.substring(0, 8) + "..."
                                    : member.name}
                                </p>
                                <IoClose
                                  className="rounded-circle text-white bg-red fz-12 c_pointer"
                                  onClick={() => rmvGrpMemById(member.userId)}
                                />
                              </div>
                            </Col>
                          )
                      )}
                    </Row>
                    <div class="input-group hr_search rounded-0 m-2">
                      <input
                        class="form-control py-2 border-right-0 border-top-0 rounded-0 border"
                        placeholder="Search for name, number, or email..."
                        value={userSearchTerm}
                        onChange={handleUserChange}
                        onKeyPress={handleUserKeyPress}
                        id="usr_srch_input"
                      />
                      {userSearchTerm !== "" && (
                        <span class="input-group-append c_pointer">
                          <div class="input-group-text bg-white border-top-0 rounded-0">
                            <IoClose
                              className="search_icon"
                              onClick={() => setUserSearchTerm("")}
                            />
                          </div>
                        </span>
                      )}
                      <span class="input-group-append c_pointer">
                        <div class="input-group-text bg-transparent border-top-0 rounded-0">
                          <FiSearch
                            className="search_icon"
                            onClick={() => handleUserSearch(userSearchTerm)}
                          />
                        </div>
                      </span>
                    </div>
                    <div className="d-flex m-2">
                      <div className="mr-2">
                        <input
                          type="radio"
                          name="member"
                          value="on"
                          checked={showHistory === true}
                          onChange={handleRadioChange}
                        />
                        {showHistory}
                        <label className="fz_14">&nbsp;History On</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="member"
                          value="off"
                          checked={showHistory === false}
                          onChange={handleRadioChange}
                        />
                        <label className="fz_14">&nbsp;History Off</label>
                      </div>
                    </div>
                    {filterData && filterData.length > 0 ? (
                      <Card className="m-2 me_revList mx_h_45vh">
                        {filterData && filterData.length > 0
                          ? filterData.map((data, i) => (
                              <Card
                                className={`rounded-0 px-2 py-2 ${
                                  data.isExists == 1
                                    ? "bg_lt_gray"
                                    : "c_pointer"
                                }`}
                                onClick={() =>
                                  addAsGrpMember(
                                    data.id,
                                    data.name,
                                    data.imagePath,
                                    data.isExists
                                  )
                                }
                              >
                                <div className="d-flex align-items-center">
                                  <Col lg="2" md="2" sm="2" xl="2">
                                    <img
                                      className="brd_rad"
                                      width="40"
                                      height="40"
                                      src={
                                        data.imagePath
                                          ? data.imagePath
                                          : profile
                                      }
                                    />
                                  </Col>
                                  <Col lg="8" md="8" sm="8" xl="8">
                                    <div className="d-flex">
                                      <p className="mb-0 font-weight-400 fz_14">
                                        {data.name}
                                      </p>
                                    </div>
                                    {data.isExists == 1 ? (
                                      <p className="mb-0 font-weight-400 fz-12 clr_gray">
                                        Already added
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                  <Col lg="2" md="2" sm="2" xl="2"></Col>
                                </div>
                              </Card>
                            ))
                          : ""}
                      </Card>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <commonUtils.CustomButton
                    btnText="Cancel"
                    btnWidth="fit-content"
                    variant="danger"
                    perform={() => {
                      setAddGrpMemModal(false);
                      setUserSearchTerm("");
                      setGrpMembers([]);
                    }}
                    isDisabled={false}
                  />
                  <commonUtils.CustomButton
                    btnText="Add"
                    btnWidth="fit-content"
                    variant="primary"
                    perform={() => handleCreateGrp()}
                    disabled={grpMembers.length === 0}
                  />
                </div>
              </>
            }
          />
          <CustomModal
            show={showModal}
            handleClose={() => setShowModal(false)}
            performSubmit={() => handleLeaveGrp(null, rmvMemId)}
            title="Remove Member"
            btnText="Remove"
            body={
              <>
                <p>Are you sure you want to remove the member?</p>
              </>
            }
          />
        </div>
      )}
      <CustomModal
        show={grpEdit}
        handleClose={() => {
          setGrpEdit(false);
          getGrpInfo();
        }}
        performSubmit={() => updateGrp()}
        title="Edit"
        btnText="Update"
        body={
          <>
            <div className="d-flex justify-content-center">
              <div className="grp-profile-img">
                <img
                  className="grpImgPreview"
                  src={grpImgPreview ? grpImgPreview : profile}
                />
                <label
                  className="uploadIcon"
                  title="Logo size should be 320 x 320.Acceptable file formats are jpeg, png or svg."
                >
                  <TiCamera className="" />
                  <input
                    type="file"
                    accept="image/jpeg, image/png, image/svg+xml"
                    class="uploadFile img"
                    style={{
                      width: "0px",
                      height: "0px",
                      overflow: "hidden",
                    }}
                    onChange={handleImageChange}
                  />
                </label>
                <label className="deleteIcon" title="Delete profile image">
                  <AiOutlineClose
                    className=""
                    onClick={() => setDeletePopupShow(true)}
                  />
                </label>
              </div>
            </div>
            <div>
              <label>Group name</label>
              <input
                className="form-control py-2 border"
                type="text"
                placeholder="Add group name"
                value={nameCont}
                onChange={(e) => setNameCont(e.target.value)}
              />
            </div>
            <div>
              {" "}
              <label>Description</label>
              <input
                className="form-control py-2 border"
                type="text"
                placeholder="Add description"
                value={desContent}
                onChange={(e) => setDesContent(e.target.value)}
              />
            </div>

            {imgLoader ? (
              <div className="pro-loader-center">
                <img className="loader-image" src={loader} alt="Loading..." />
              </div>
            ) : (
              ""
            )}
          </>
        }
      />

      <CustomModal
        show={exitGrp}
        handleClose={() => setExitGrp(false)}
        performSubmit={() =>
          existMembers.length > 1 &&
          existMembers.find((member) => member.userId === userId)?.role ===
            "Admin"
            ? setShowNewAdmin(true)
            : (handleLeaveGrp(null, userId), setExitGrp(false))
        }
        title="Exit"
        btnText="Exit"
        body={
          <>
            <p className="fz_14 mb-0">
              Are you sure you want to leave the group?
            </p>
          </>
        }
      />
      <CustomModal
        show={deletePopupShow}
        handleClose={() => setDeletePopupShow(false)}
        performSubmit={() => deleteImage()}
        title="Delete Confirmation"
        btnText="Ok"
        body={
          <>
            <p className="fz_14 mb-0">
              Are you sure to delete this profile image?
            </p>
            {loading ? (
              <div className="pro-loader-center">
                <img className="loader-image" src={loader} alt="Loading..." />
              </div>
            ) : (
              ""
            )}
          </>
        }
      />
      <CustomModal
        show={showNewAdmin}
        handleClose={() => setShowNewAdmin(false)}
        // performSubmit={() => setShowNewAdmin(true)}
        post={true}
        title="Select new admin"
        body={
          <>
            {existMembers &&
              existMembers
                .filter((member) => member.userId !== userId)
                .map((member, index) => (
                  <Card className="rounded-0 px-2 py-2" key={member.userId}>
                    <div className="d-flex">
                      <input
                        type="radio"
                        id={`member-${index}`}
                        name="member"
                        value={member.userId}
                        onChange={() => setNewAdminId(member.userId)}
                      />
                      <div className="d-flex align-items-center">
                        <Col lg="2" md="2" sm="2" xl="2">
                          <img
                            className="brd_rad"
                            width="25"
                            height="25"
                            src={member.imagePath ? member.imagePath : profile}
                            alt="Profile"
                          />
                        </Col>
                        <Col lg="10" md="10" sm="10" xl="10">
                          {member.userName}
                        </Col>
                      </div>
                    </div>
                  </Card>
                ))}

            <div className="d-flex justify-content-end">
              <commonUtils.CustomButton
                btnText="Submit"
                btnWidth="fit-content"
                variant="primary"
                perform={() => {
                  handleLeaveGrp(newAdminId, userId);
                  setRmverId(0);
                }}
                isDisabled={newAdminId === null}
              />
              <commonUtils.CustomButton
                btnText="Cancel"
                btnWidth="fit-content"
                variant="danger"
                perform={() => setShowNewAdmin(false)}
                isDisabled={false}
              />
            </div>
          </>
        }
      />
    </>
  );
});

export default GroupChat;
