import React, { useState, useEffect } from "react";
import { Card, Container, Table, Input, Button, Row, Col } from "reactstrap";
import HeaderMenu from "../HeaderMenu";
import SideBar from "../AdminSideBar";
import BreadCrumb from "../../plugins/Breadcrumb.jsx";
import { BiChevronDownCircle, BiChevronUpCircle } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import { BiNotepad } from "react-icons/bi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PiCaretCircleDownBold, PiCaretCircleUpBold } from "react-icons/pi";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../plugins/CustomModal";
import moment from "moment";
import profile from "../../../assets/images/profile.jpg";
import { commonUtils } from "../../CommonUtils";
const AdminBusiness = (props) => {
  const [businessList, setBusinessList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredBusinessList, setFilteredBusinessList] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentIdxId, setCurrentIdxId] = useState(null);
  const [noteText, setNoteText] = useState("");
  const [expandedRowIndex, setExpandedRowIndex] = useState(null); // State to keep track of expanded row
  const [businessNotes, setBusinessNotes] = useState([]); // State to store business notes
  const navigate = useNavigate();
  const [userid, setUserid] = useState("");

  useEffect(() => {
    fetchBusinessList();
  }, []);

  const fetchBusinessList = async () => {
    try {
      const response = await window.apiService.allBusinessList();
      setBusinessList(response.data.data);
      setFilteredBusinessList(response.data.data);
    } catch (error) {
      console.error("Error fetching business data:", error);
    }
  };

  const handleCheckboxChange = async (id, isChecked) => {
    try {
      const business = businessList.find((business) => business.id === id);
      if (business.isVerified === 1 && !isChecked) {
        return;
      }
      const isVerifiedValue = isChecked ? 1 : 0;
      await window.apiService.updateBusinessStatus(id, isVerifiedValue);
      if (isChecked) {
        toast.success("Business Verified successfully");
      } else {
        toast.error("Marked as unverified ");
      }
      setFilteredBusinessList((prevState) =>
        prevState.map((business) =>
          business.id === id
            ? { ...business, isVerified: isVerifiedValue }
            : business
        )
      );
    } catch (error) {
      console.error("Error updating business status:", error);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
    filterBusinessList(e.target.value);
  };

  const filterBusinessList = (query) => {
    const filteredList = businessList.filter((business) =>
      business.companyName.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredBusinessList(filteredList);
  };

  const handleFindButtonClick = () => {
    filterBusinessList(searchQuery);
  };

  const handleResetButtonClick = () => {
    setSearchQuery("");
    setFilteredBusinessList(businessList);
  };

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };

  const handleViewButtonClick = (business) => {
    setSelectedBusiness(business);
    navigate("/dashboard/business/view", {
      state: { id: business.id },
    });
  };

  const handleAddNotesClick = (businessId, userid) => {
    setShowModal(true);
    setCurrentIdxId(businessId);
    setUserid(userid);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleSaveNote = async () => {
    if (noteText.trim()) {
      const params = {
        note: noteText,
        userId: props.userId,
        businessId: currentIdxId,
      };
      try {
        const response = await window.apiService.addNotes(params);
        if (response.data.status === "OK") {
          toast.success(response.data.message);
          setShowModal(false);
          setNoteText("");
        }
      } catch (error) {
        console.error("Error adding notes:", error);
      }
    } else {
      toast.error("Note Text is required.");
    }
  };

  const handleRowClick = async (id, index) => {
    setExpandedRowIndex(index === expandedRowIndex ? null : index);
    await loadBusinessNote(id); // Load business notes when row is clicked
  };

  const loadBusinessNote = async (id) => {
    try {
      const response = await window.apiService.loadBusinessNote(id);
      let result = response.data.data;
      if (result) {
        setBusinessNotes(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <HeaderMenu />
      <SideBar />
      <div className="main-content ml_200">
        {/* <BreadCrumb /> */}
        {filteredBusinessList && filteredBusinessList.length > 0 ? (
          <Card className="shade h-500">
            <Container fluid>
              <div className="search-section">
                <div className="d-flex justify-content-between">
                  <div className="srch_txt">Search</div>
                  <div onClick={toggleSearch}>
                    {showSearch ? (
                      <BiChevronUpCircle />
                    ) : (
                      <BiChevronDownCircle />
                    )}
                  </div>
                </div>
                {showSearch && (
                  <div className="">
                    <Row className="mt-3">
                      <Col sm={3} lg={3}>
                        <div className="search-input-group">
                          <label htmlFor="companyName">Company Name:</label>
                          <Input
                            type="text"
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                            placeholder="Enter company name"
                          />
                        </div>
                      </Col>
                      <Col sm={3} lg={3} className="d-flex align-items-end">
                        <div className="d-flex gp_10 p-0 mt_8px">
                          <commonUtils.CustomButton
                            btnText="Reset"
                            btnWidth="fit-content"
                            variant="primary"
                            perform={() => handleResetButtonClick()}
                            isDisabled={false}
                          />
                          <commonUtils.CustomButton
                            btnText="Find"
                            btnWidth="fit-content"
                            variant="primary"
                            perform={() => handleFindButtonClick()}
                            isDisabled={false}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
              <Table
                className="align-items-center table-flush brd_solid"
                responsive
              >
                <thead className="thead-clr">
                  <tr>
                    <th scope="col">S.No.</th>
                    <th scope="col">Company Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Email</th>
                    <th scope="col">City</th>
                    <th scope="col">Is Verified</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredBusinessList.map((business, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td>
                          {expandedRowIndex === index ? (
                            <PiCaretCircleUpBold
                              className="fz_18 mr-3 c_pointer"
                              onClick={() => handleRowClick(business.id, index)}
                            />
                          ) : (
                            <PiCaretCircleDownBold
                              className="fz_18 mr-3 c_pointer"
                              onClick={() => handleRowClick(business.id, index)}
                            />
                          )}
                          {index + 1}
                        </td>
                        <td>{business.companyName}</td>
                        <td>{business.phone}</td>
                        <td>{business.email}</td>
                        <td>{business.city}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={business.isVerified === 1}
                            onChange={(e) =>
                              handleCheckboxChange(
                                business.id,
                                e.target.checked
                              )
                            }
                          />
                        </td>
                        <td>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FaEye
                              className="c_pointer searchIcon"
                              title="View Business Details"
                              onClick={() => handleViewButtonClick(business)}
                            />
                            <BiNotepad
                              className="c_pointer searchIcon"
                              title="Add Notes"
                              onClick={() => handleAddNotesClick(business.id)}
                              style={{ marginLeft: "34px" }}
                            />
                          </div>
                        </td>
                      </tr>
                      {expandedRowIndex === index && (
                        <tr key={`${index}-expanded`}>
                          <td colSpan="7" className="p-2">
                            {businessNotes && businessNotes.length > 0 ? (
                              <Table>
                                <thead className="thead-clr">
                                  <tr>
                                    <th>Note</th>
                                    <th>Added On</th>
                                    <th>Added By</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {businessNotes.map((data) => (
                                    <tr key={data.id}>
                                      <td>{data.note}</td>
                                      <td>
                                        <p className="mb-0 font-weight-400 fz_13">
                                          {moment(data.createdAt).format(
                                            "D MMM YYYY"
                                          )}
                                        </p>
                                      </td>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          {data.userId === props.userId ? (
                                            "You"
                                          ) : (
                                            <>
                                              <img
                                                className="brd_rad"
                                                width="20"
                                                height="20"
                                                src={
                                                  data.imagePath
                                                    ? data.imagePath
                                                    : profile
                                                }
                                              />
                                              &nbsp;
                                              <p className="mb-0 font-weight-400 fz_14">
                                                {data.name}
                                              </p>
                                            </>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            ) : (
                              <p className="text-center">No notes found.</p>
                            )}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </Container>
          </Card>
        ) : (
          <div className="row col-md-12 d-align mx-0 pb-4">
            <div className="no-data font-weight-600 w-100 text-center fz_14">
              Sorry, there is no data to be displayed
            </div>
          </div>
        )}
      </div>
      <CustomModal
        show={showModal}
        handleClose={handleModalClose}
        performSubmit={handleSaveNote}
        btnText="Save"
        title="Add Notes"
        body={
          <>
            <textarea
              className="form-control Comment_sty h_25"
              placeholder="Type Something…"
              value={noteText}
              onChange={(e) => setNoteText(e.target.value)}
            ></textarea>
          </>
        }
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    userType: state.user.userType,
  };
};
export default connect(mapStateToProps)(AdminBusiness);
