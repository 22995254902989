import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { AiOutlineClose } from "react-icons/ai";
import { commonUtils } from "../CommonUtils";
const UpgradePopup = (props) => {
  const { show, handleClose } = props;
  const navigate = useNavigate(); // Initialize navigate

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header style={{ padding: "10px" }}>
        <Modal.Title
          id="example-custom-modal-styling-title"
          style={{ fontSize: "15px", fontWeight: 600 }}
        ></Modal.Title>
        <span onClick={handleClose} title="Close" style={{ cursor: "pointer" }}>
          <AiOutlineClose />
        </span>
      </Modal.Header>
      <Modal.Body className="p-3 border text-center">
        <p className="fz_15 font-weight-500 mb-2">
          Unlock this feature by upgrading your plan.
        </p>
        <commonUtils.CustomButton
          btnText="Upgrade"
          btnWidth="fit-content"
          variant="primary"
          perform={() => navigate("/dashboard/user_settings/plans_billings")}
          disabled={false}
        />
      </Modal.Body>
    </Modal>
  );
};

export default UpgradePopup;
