import {Collapse,Navbar,Container,Row,Col} from "reactstrap";
import { commonUtils } from "../CommonUtils";
import { Nav, Modal, Button } from "react-bootstrap";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { MdLiveHelp } from "react-icons/md";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { TbLogout } from "react-icons/tb";
import { logoutAction } from "../../Redux/Actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import userRole from "../../assets/images/user_role.jpg";
import { IoSettingsSharp } from "react-icons/io5";
import logo from "../../assets/images/tiipstr_lgo.svg";
import inboxImg from "../../assets/images/in_img.jpg";
import hubspot from "../../assets/images/hubspot.svg";
import inbox from "../../assets/images/inbx.jpg";
import { IoIosBusiness } from "react-icons/io";
const AdminSideBar = () => {
  const location = useLocation();
  const [collapseOpen, setCollapseOpen] = useState();
  const [activePath, setActivePath] = useState("/dashboard");
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const userId = useSelector((state) => state.user.userId);
  const [isCollapse, setIsCollapse] = useState(false);
  document.documentElement.style.setProperty("--sidebar-margin", isCollapse ? "95px" : "220px");
  const dispatch = useDispatch();
  const Logout = async () => {
    dispatch(logoutAction());
    localStorage.removeItem("userdetail");
    navigate("/login");
    if (userId) {
      try {
        const response = await window.apiService.logout(userId, {
          isActive: 0,
        });
        console.log("response", response);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  const navigate = useNavigate();
  return (
    <>
      <Navbar
        className={`navbar-vertical fixed-left navbar-light bg-white ${isCollapse ? "max_75" : "mx_wd"}`}
        expand="md"
        id="sidenav-main"
      >
        <Container fluid className="flx_column">
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          <button
            style={{ marginLeft: "-20px" }}
            className="icn_tog"
            type="button"
            onClick={() => setIsCollapse(!isCollapse)}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <Collapse navbar isOpen={collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="">
                    <img
                      alt=""
                      src={require("../../assets/images/tiipstrlogo.jpg")}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav variant="pills" className="flex-column navStyle">
              <Nav.Item>
                <Nav.Link
                  className={`navTab ${location.pathname === "/dashboard" ? "active" : ""
                    } ${isCollapse ? "d-flex" : ""}`}
                  onClick={() => {
                    navigate("/dashboard");
                    setActivePath("/dashboard");
                  }}
                >
                  <span className={`tab_span ${isCollapse ? "m-0" : ""}`}>
                    <img src={userRole} className={`dashboardTabIcon ${location.pathname === "/dashboard" ? '' : 'opacity-5'}`} />
                  </span>
                  {!isCollapse && <span className="dash_tab_name">User Roles</span>}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={`navTab ${location.pathname === "/dashboard/settings" ? "active" : ""
                    }`}
                  onClick={() => {
                    navigate("/dashboard/settings");
                    setActivePath("/dashboard/settings");
                  }}
                >
                  <span className="tab_span">
                    <IoSettingsSharp className="dashboardTabIcon" />
                  </span>
                  {!isCollapse && <span className="dash_tab_name">Settings </span>}{" "}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={`navTab ${location.pathname === "/dashboard/business" ? "active" : ""
                    }`}
                  onClick={() => {
                    navigate("/dashboard/business");
                    setActivePath("/dashboard/business");
                  }}
                >
                  <span className="tab_span">
                    <IoIosBusiness className="dashboardTabIcon" />
                    {/* Add appropriate icon for Business tab */}
                  </span>
                  {!isCollapse && <span>Business</span>}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={`navTab ${location.pathname === "/inbox/1" ? "active" : ""
                    }`}
                  onClick={() => {
                    navigate("/inbox/1");
                    setActivePath("/inbox/1");
                  }}
                >
                  <span className="tab_span">
                    <img
                      src={location.pathname === "/inbox/1" ? inbox : inboxImg}
                      className="dashboardTabIcon"
                    />
                  </span>
                  {!isCollapse && <span className="dash_tab_name">Inbox </span>}{" "}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={`navTab ${location.pathname === "/admin/hubspot" ? "active" : ""
                    }`}
                  onClick={() => {
                    navigate("/admin/hubspot");
                    setActivePath("/admin/hubspot");
                  }}
                >
                  <span className="tab_span">
                    <img
                      src={hubspot}
                      className={`dashboardTabIcon ${location.pathname === "/admin/hubspot" ? '' : 'opacity-5'}`}
                    />
                  </span>
                  {!isCollapse && <span className="dash_tab_name">Hubspot </span>}{" "}
                </Nav.Link>
              </Nav.Item>
              <div className="fix_btm">
                <hr className="my-3" />
                <Nav.Item>
                  <Nav.Link className="navTab">
                    <span className="tab_span">
                      <MdLiveHelp className="dashboardTabIcon" />
                    </span>
                    {!isCollapse && <span>Help</span>}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={`navTab ${showConfirmPopup ? "active" : ""}`}
                    onClick={() => setShowConfirmPopup(true)}
                  >
                    <span className="tab_span">
                      <TbLogout className="dashboardTabIcon" />
                    </span>
                    {!isCollapse && <span>Logout</span>}
                  </Nav.Link>
                </Nav.Item>
              </div>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>

      {/* ----------- Logout Confirmation Popup ---------- */}
      <Modal
        size="sm"
        show={showConfirmPopup}
        onHide={() => setShowConfirmPopup(false)}
      >
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            Logout Confirmation
          </Modal.Title>
          <span onClick={() => setShowConfirmPopup(false)} title="Close">
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="bordr">
          <span>Are you sure you want to Logout?</span>
        </Modal.Body>
        <Modal.Footer className="bordr pad_13">
          <commonUtils.CustomButton
              btnText="Yes"
              btnWidth="fit-content"
              variant="primary"
              perform={()=>Logout()}
              isDisabled={false}
          />
          <commonUtils.CustomButton
              btnText="No"
              btnWidth="fit-content"
              variant="danger"
              perform={()=>setShowConfirmPopup(false)}
              isDisabled={false}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminSideBar;
