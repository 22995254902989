import DetailReview from "./DashboardTabs/DetailReview";
import Findnew from "../Findnew/index";
import ProfileUpdate from "../ProfileUpdate/index";
import React, { useState, useEffect } from "react";
import { userAction } from "../../Redux/Actions/userAction";
import { TbMessages } from "react-icons/tb";
import {
  Collapse,
  Form,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Nav, Tab, Modal, Button, Dropdown } from "react-bootstrap";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import routes from "../../router.jsx";
import { AiOutlineClose } from "react-icons/ai";
import dashMe from "../../assets/images/dash_me.jpg";
import followImg from "../../assets/images/follow_img.jpg";
import dashFollow from "../../assets/images/dash_following.jpg";
import dashRev from "../../assets/images/dash_rev.jpg";
import inboxImg from "../../assets/images/in_img.jpg";
import addUser from "../../assets/images/add_user.jpg";
import following from "../../assets/images/following.jpg";
import followers from "../../assets/images/followers.jpg";
import inbox from "../../assets/images/inbx.jpg";
import reviews from "../../assets/images/revdash.jpg";
import usrpls from "../../assets/images/usr_plus.jpg";
import usrlit from "../../assets/images/usr_lit.jpg";
import store from "../../assets/images/store.png";
import bin from "../../assets/images/bin.png";
import { MdLiveHelp } from "react-icons/md";
import { TbLogout } from "react-icons/tb";
import { logoutAction } from "../../Redux/Actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import HeaderMenu from "./headerMenu";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import logo from "../../assets/images/tiipstr_lgo.svg";
import { MdSwitchAccount } from "react-icons/md";
import { useToast } from "../Toast";
import { useSubscription } from "../SubscriptionContext";
import UpgradePopup from "../plugins/UpgradePopup.jsx";
import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import { commonUtils } from "../CommonUtils.js";
import {
  setReviewNotification,
  incrementNotificationCount,
  resetNotificationCount,
  setMentionNotification,
} from "../../Redux/Actions/userAction";

const SideBar = (props) => {
  const location = useLocation();
  const toast = useToast();
  const [collapseOpen, setCollapseOpen] = useState();
  const [activePath, setActivePath] = useState("/dashboard");
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [userProfList, setUserProfList] = useState("");
  const [userBusnList, setUserBusnList] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const userId = useSelector((state) => state.user.userId);
  const dispatch = useDispatch();
  const { allFeatures, isFeatureEnabled } = useSubscription();

  const [notificationsreview, setNotifications] = useState(0);
  const [mentionNotification, setMentionNotifications] = useState();

  const reviewNotification = useSelector(
    (state) => state.user.notificationCount
  );
  const [isCollapse, setIsCollapse] = useState(false);
  document.documentElement.style.setProperty(
    "--sidebar-margin",
    isCollapse ? "95px" : "220px"
  );
  //kafka notification

  useEffect(() => {
    if (props.userTypeInfo != undefined && props.userTypeInfo != "") {
      setUserProfList(
        props.userTypeInfo.filter((st) => st.userTypeName == "Professional")
      );
    }
    if (props.userTypeInfo != undefined && props.userTypeInfo != "") {
      setUserBusnList(
        props.userTypeInfo.filter((st) => st.userTypeName == "Business")
      );
    }
  }, [props]);

  const notifications = useSelector((state) => state.user.notifications);

  const countNotifications = (notifications) => {
    return notifications.reduce((acc, notif) => {
      acc[notif.senderId] = (acc[notif.senderId] || 0) + 1;
      return acc;
    }, {});
  };

  const notificationCounts = countNotifications(notifications);

  const totalNotificationCount = Object.values(notificationCounts).reduce(
    (total, count) => total + count,
    0
  );

  const combinedNotificationCount =
    (props.newInboxCount || 0) + (totalNotificationCount || 0);

  useEffect(() => {
    if (props.userTypeInfo != undefined && props.userTypeInfo != "") {
      setUserProfList(
        props.userTypeInfo.filter((st) => st.userTypeName == "Professional")
      );
    }
    if (props.userTypeInfo != undefined && props.userTypeInfo != "") {
      setUserBusnList(
        props.userTypeInfo.filter((st) => st.userTypeName == "Business")
      );
    }
  }, [props]);

  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  const Switchaccount = async (type, userId, id) => {
    setLoading(true);
    let data = {
      email: props.email,
      userType: type,
      userId: userId,
      profileId: type != "Personal" ? id : null,
      switchAccount: true,
    };
    try {
      const response = await window.apiService.userAuthentication(data);
      if (response.data) {
        dispatch(userAction(response.data.data));
        localStorage.setItem("userType", response.data.data.userType);
        localStorage.setItem("switchAccount", data.switchAccount);
        localStorage.setItem("profileId", response.data.data.profileId);
        navigate("/dashboard");
      } else {
        toast.info(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Error Occured");
    }
  };
  const Logout = async () => {
    dispatch(logoutAction());
    // sessionStorage.removeItem("rememberMeToken");
    localStorage.removeItem("userdetail");
    navigate("/login");
    if (userId) {
      try {
        const response = await window.apiService.logout(userId, {
          isActive: 0,
        });
        console.log("response", response);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const navigate = useNavigate();

  const SOCKET_URL = process.env.REACT_APP_BASEURL + "/ws";

  useEffect(() => {
    // Create a WebSocket connection
    const socket = new SockJS(SOCKET_URL); // Backend WebSocket endpoint
    const stompClient = new Client({
      webSocketFactory: () => socket,
      reconnectDelay: 5000,
      debug: (str) => console.log(str),
    });

    stompClient.onConnect = () => {
      console.log("Connected to WebSocket");

      // Subscribe to the user's notification topic
      stompClient.subscribe(
        `/user/${userId}/topic/notifications`,
        (message) => {
          const notification = message.body;
          setNotifications((prevCount) => {
            const updatedCount = prevCount + 1;
            console.log("Notification count:", updatedCount); // Log the updated count
            dispatch(setReviewNotification(notification));
            dispatch(incrementNotificationCount());
            return updatedCount; // Update the state with the new count
          });
        }
      );

      stompClient.subscribe(
        `/user/${userId}/topic/mentionnotifications`,
        (message) => {
          const mentionNotification = message.body;
          setMentionNotifications((prevCount) => {
            const updatedCount = prevCount + 1;
            console.log("New Mention Notification:", mentionNotification);
            dispatch(setMentionNotification(mentionNotification));
            return updatedCount;
          });
        }
      );

      stompClient.subscribe(
        `/user/${userId}/topic/commentnotifications`,
        (message) => {
          const mentionComment = message.body;
          console.log("comment", mentionComment);
          dispatch(setMentionNotification(mentionComment));
        }
      );
    };
    stompClient.onStompError = (error) => {
      console.error("Error with WebSocket connection", error);
    };

    stompClient.activate();

    // Cleanup function to disconnect WebSocket
    return () => {
      stompClient.deactivate();
    };
  }, [userId]);

  useEffect(() => {
    dispatch(resetNotificationCount());
  }, [props.newReviewCount]);

  return (
    <>
      <Navbar
        className={`navbar-vertical fixed-left navbar-light bg-white ${
          isCollapse ? "max_75" : "mx_wd"
        }`}
        expand="md"
        id="sidenav-main"
      >
        <Container className="flx_column" fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          <button
            style={{ marginLeft: "-20px" }}
            className="icn_tog"
            type="button"
            onClick={() => setIsCollapse(!isCollapse)}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* <NavbarBrand
            className="pt-0 pb-0 c_pointer"
            onClick={() => {
              navigate("/dashboard");
            }}
          >

            <img
              src={logo}
              height={80}
              width={100}
              style={{ marginTop: "-10px" }}
            />
          </NavbarBrand> */}

          <Collapse navbar isOpen={collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="">
                    <img
                      alt=""
                      src={require("../../assets/images/tiipstrlogo.jpg")}
                      onClick={() => {
                        navigate("/dashboard");
                      }}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Form */}
            {/* <Form className="mt-4 mb-3 d-md-none">
              <InputGroup className="input-group-rounded input-group-merge">
                <Input
                  aria-label="Search"
                  className="form-control-rounded form-control-prepended"
                  placeholder="Search"
                  type="search"
                />
              </InputGroup>
            </Form> */}
            <Nav variant="pills" className="flex-column navStyle">
              <Nav.Item className="d-flex">
                <Nav.Link
                  className={`navTab ${
                    location.pathname === "/dashboard" ? "active" : ""
                  } ${isCollapse ? "d-flex" : ""}`}
                  onClick={() => {
                    navigate("/dashboard");
                    setActivePath("/dashboard");
                  }}
                >
                  <span className={`tab_span ${isCollapse ? "m-0" : ""}`}>
                    <img
                      src={location.pathname === "/dashboard" ? dashMe : usrlit}
                      className="dashboardTabIcon"
                    />
                  </span>
                  {!isCollapse && <span className="dash_tab_name">Me </span>}{" "}
                  {(props.newReviewCount !== 0 || reviewNotification !== 0) &&
                    props.isReviewInApp && (
                      <span
                        className={`notfy ${
                          isCollapse ? "notfy-collapsed" : ""
                        }`}
                      >
                        {props.newReviewCount + reviewNotification}
                      </span>
                    )}
                  {(userProfList.length > 0 || userBusnList.length > 0) && (
                    <span className="switch_icn c_pointer">
                      {!isCollapse && (
                        <Dropdown
                          show={dropdownOpen}
                          onToggle={setDropdownOpen}
                          style={{ cursor: "default" }}
                        >
                          <Dropdown.Toggle
                            as="span"
                            id="dropdown-me"
                            className="me-dropdown-toggle c_pointer"
                          >
                            <MdSwitchAccount
                              title="Switch Account"
                              className="c_pointer"
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                Switchaccount("Personal", props.userId, null)
                              }
                              disabled={props.userType == "Personal"}
                            >
                              <span
                                className="header_nav_span"
                                style={{ marginLeft: "5px" }}
                              >
                                Personal&nbsp;
                              </span>
                              {props.userType == "Personal" ? (
                                <span className="act_acc">Active</span>
                              ) : (
                                ""
                              )}
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            {userProfList.length > 0 &&
                              isFeatureEnabled(
                                allFeatures["Professional-Profile%"]?.key,
                                allFeatures["Professional-Profile%"]?.isDeleted
                              ) && (
                                <Dropdown.Item disabled>
                                  <span className="c_blue">Professional</span>
                                </Dropdown.Item>
                              )}
                            {userProfList.length > 0 &&
                            isFeatureEnabled(
                              allFeatures["Professional-Profile%"]?.key,
                              allFeatures["Professional-Profile%"]?.isDeleted
                            )
                              ? userProfList.map((usertype) => (
                                  <Dropdown.Item
                                    className="marLf"
                                    key={usertype.professionalId}
                                    onClick={() =>
                                      Switchaccount(
                                        usertype.userTypeName,
                                        props.userId,
                                        usertype.professionalId
                                      )
                                    }
                                    disabled={
                                      usertype.professionalId ===
                                        props.profileId &&
                                      props.userType === "Professional"
                                    }
                                  >
                                    {usertype.professionalType}{" "}
                                    {props.profileId ===
                                      usertype.professionalId &&
                                      props.userType === "Professional" && (
                                        <span className="act_acc">Active</span>
                                      )}
                                  </Dropdown.Item>
                                ))
                              : ""}
                            {/* <Dropdown.Divider /> */}

                            {userBusnList.length > 0 &&
                              isFeatureEnabled(
                                allFeatures["Business-Profile%"]?.key,
                                allFeatures["Business-Profile%"]?.isDeleted
                              ) && (
                                <Dropdown.Item disabled>
                                  <span className="header_nav_span c_blue">
                                    Business
                                  </span>
                                </Dropdown.Item>
                              )}
                            {userBusnList.length > 0 &&
                            isFeatureEnabled(
                              allFeatures["Business-Profile%"]?.key,
                              allFeatures["Business-Profile%"]?.isDeleted
                            )
                              ? userBusnList.map((usertype) => (
                                  <Dropdown.Item
                                    className="marLf"
                                    key={usertype.businessId}
                                    onClick={() =>
                                      Switchaccount(
                                        usertype.userTypeName,
                                        props.userId,
                                        usertype.businessId
                                      )
                                    }
                                    disabled={
                                      usertype.businessId == props.profileId &&
                                      props.userType === "Business"
                                    }
                                  >
                                    <span title={usertype.companyName}>
                                      {usertype.shortName !== undefined
                                        ? usertype.shortName
                                        : usertype.companyName?.length > 20
                                        ? `${usertype.companyName.substring(
                                            0,
                                            20
                                          )}...`
                                        : usertype.companyName}
                                    </span>
                                    &nbsp;
                                    {props.profileId == usertype.businessId &&
                                    props.userType === "Business" ? (
                                      <span className="act_acc">Active</span>
                                    ) : (
                                      ""
                                    )}
                                  </Dropdown.Item>
                                ))
                              : ""}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </span>
                  )}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={`navTab ${
                    location.pathname === "/dashboard/followers" ? "active" : ""
                  }`}
                  onClick={() => {
                    navigate("/dashboard/followers");
                    setActivePath("/dashboard/followers");
                  }}
                >
                  <span className="tab_span">
                    <img
                      src={
                        location.pathname === "/dashboard/followers"
                          ? followers
                          : followImg
                      }
                      className="dashboardTabIcon"
                    />
                  </span>
                  {!isCollapse && (
                    <span className="dash_tab_name">Followers</span>
                  )}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={`navTab ${
                    location.pathname === "/dashboard/following" ? "active" : ""
                  }`}
                  onClick={() => {
                    navigate("/dashboard/following");
                    setActivePath("/dashboard/following");
                  }}
                >
                  <span className="tab_span">
                    <img
                      src={
                        location.pathname === "/dashboard/following"
                          ? following
                          : dashFollow
                      }
                      className="dashboardTabIcon"
                    />
                  </span>
                  {!isCollapse && (
                    <span className="dash_tab_name">Following</span>
                  )}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={`navTab ${
                    location.pathname === "/dashboard/reviews" ? "active" : ""
                  }`}
                  onClick={() => {
                    navigate("/dashboard/reviews");
                    setActivePath("/dashboard/reviews");
                  }}
                >
                  <span className="tab_span">
                    <img
                      src={
                        location.pathname === "/dashboard/reviews"
                          ? reviews
                          : dashRev
                      }
                      className="dashboardTabIcon"
                    />
                  </span>
                  {!isCollapse && (
                    <span className="dash_tab_name">Reviews</span>
                  )}
                </Nav.Link>
              </Nav.Item>
              {isFeatureEnabled(
                allFeatures["Chat%"]?.key,
                allFeatures["Chat%"]?.isDeleted
              ) ? (
                <Nav.Item>
                  <Nav.Link
                    className={`navTab ${
                      location.pathname === "/inbox/3" ? "active" : ""
                    } ${isCollapse ? "d-flex" : ""}`}
                    onClick={() => {
                      navigate("/inbox/3");
                      setActivePath("/inbox");
                    }}
                  >
                    <span className={`tab_span ${isCollapse ? "m-0" : ""}`}>
                      <img
                        src={
                          location.pathname === "/inbox/3" ? inbox : inboxImg
                        }
                        className="dashboardTabIcon"
                      />
                    </span>
                    {!isCollapse && (
                      <span className="dash_tab_name">Inbox </span>
                    )}{" "}
                    {combinedNotificationCount > 0 && props.isInboxInApp ? (
                      <span
                        className={`notfy ${
                          isCollapse ? "notfy-collapsed" : ""
                        }`}
                      >
                        {combinedNotificationCount}
                      </span>
                    ) : (
                      !props.groupNotify && (
                        <span
                          className={`grp_notfy ${
                            isCollapse ? "notfy-collapsed" : ""
                          }`}
                          title="Group message received"
                        >
                          <TbMessages />
                        </span>
                      )
                    )}
                  </Nav.Link>
                </Nav.Item>
              ) : (
                <Nav.Item>
                  <Nav.Link
                    className="navTab"
                    onClick={() => setShowPopup(true)}
                  >
                    <span className="tab_span">
                      <img src={inboxImg} className="dashboardTabIcon" />
                    </span>
                    {!isCollapse && (
                      <span className="dash_tab_name">Inbox </span>
                    )}{" "}
                  </Nav.Link>
                </Nav.Item>
              )}
              {false && (
                <Nav.Item>
                  <Nav.Link
                    className={`navTab ${
                      location.pathname === "/dashboard/finduser"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      navigate("/dashboard/finduser");
                      setActivePath("/dashboard/finduser");
                    }}
                  >
                    <span className="tab_span">
                      <img
                        src={
                          location.pathname === "/dashboard/finduser"
                            ? usrpls
                            : addUser
                        }
                        className="dashboardTabIcon"
                      />
                    </span>
                    <span className="dash_tab_name">Add User</span>
                  </Nav.Link>
                </Nav.Item>
              )}
              <div className="fix_btm">
                <hr
                  className="my-3"
                  style={{ width: isCollapse ? "50px" : "" }}
                />
                {isFeatureEnabled(
                  allFeatures["Marketplace%"]?.key,
                  allFeatures["Marketplace%"]?.isDeleted
                ) ? (
                  <Nav.Item>
                    <Nav.Link
                      className={`navTab ${
                        location.pathname === "/dashboard/marketplace"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        navigate("/dashboard/marketplace");
                        setActivePath("/dashboard/marketplace");
                      }}
                    >
                      <span className="tab_span">
                        <img
                          src={store}
                          className={`dashboardTabIcon ${
                            location.pathname === "/dashboard/marketplace"
                              ? ""
                              : "opacity-6"
                          }`}
                        />
                      </span>
                      {!isCollapse && (
                        <span className="dash_tab_name">Marketplace</span>
                      )}
                    </Nav.Link>
                  </Nav.Item>
                ) : (
                  <Nav.Item>
                    <Nav.Link
                      className="navTab"
                      onClick={() => setShowPopup(true)}
                    >
                      <span className="tab_span">
                        <img
                          src={store}
                          className="dashboardTabIcon opacity-6"
                        />
                      </span>
                      {!isCollapse && (
                        <span className="dash_tab_name">Marketplace</span>
                      )}
                    </Nav.Link>
                  </Nav.Item>
                )}
                <Nav.Item>
                  <Nav.Link
                    className={`navTab ${
                      location.pathname === "/dashboard/mybin" ? "active" : ""
                    }`}
                    onClick={() => {
                      navigate("/dashboard/mybin");
                      setActivePath("/dashboard/mybin");
                    }}
                  >
                    <span className="tab_span">
                      <img
                        src={bin}
                        className={`dashboardTabIcon ${
                          location.pathname === "/dashboard/mybin"
                            ? ""
                            : "opacity-6"
                        }`}
                      />
                    </span>
                    {!isCollapse && (
                      <span className="dash_tab_name">My Bin</span>
                    )}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={`navTab ${
                      location.pathname === "/dashboard/help" ? "active" : ""
                    }`}
                    onClick={() => {
                      navigate("/dashboard/help");
                      setActivePath("/dashboard/help");
                    }}
                  >
                    <span className="tab_span">
                      <MdLiveHelp className="dashboardTabIcon" />
                    </span>
                    {!isCollapse && <span className="dash_tab_name">Help</span>}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={`navTab ${showConfirmPopup ? "active" : ""}`}
                    onClick={() => setShowConfirmPopup(true)}
                  >
                    <span className="tab_span">
                      <TbLogout className="dashboardTabIcon" />
                    </span>
                    {!isCollapse && (
                      <span className="dash_tab_name">Logout</span>
                    )}
                  </Nav.Link>
                </Nav.Item>
              </div>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      {/* ----------- Logout Confirmation Popup ---------- */}
      <Modal
        size="sm"
        show={showConfirmPopup}
        onHide={() => setShowConfirmPopup(false)}
      >
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            Logout Confirmation
          </Modal.Title>
          <span onClick={() => setShowConfirmPopup(false)} title="Close">
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="bordr">
          <span>Are you sure you want to Logout?</span>
        </Modal.Body>
        <Modal.Footer className="bordr pad_13">
          <commonUtils.CustomButton
            btnText="Yes"
            btnWidth="fit-content"
            variant="primary"
            perform={() => Logout()}
            isDisabled={false}
          />
          <commonUtils.CustomButton
            btnText="Cancel"
            btnWidth="fit-content"
            variant="danger"
            perform={() => setShowConfirmPopup(false)}
            isDisabled={false}
          />
        </Modal.Footer>
      </Modal>
      <UpgradePopup show={showPopup} handleClose={() => setShowPopup(false)} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    newReviewCount: state.user.newReviewCount,
    newInboxCount: state.user.newInboxCount,
    groupNotify: state.user.groupNotify,
    userType: state.user.userType,
    email: state.user.email,
    userTypeInfo: state.user.userTypeInfo,
    profileId: state.user.profileId,
    isReviewInApp: state.user.isReviewInApp,
    isInboxInApp: state.user.isInboxInApp,
  };
};
export default connect(mapStateToProps)(SideBar);
