import { Col, Row, Button, Card, DropdownButton, Dropdown } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useToast } from "../../../Toast";
import { BsPlusLg } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { Input, Table } from "reactstrap";
import CustomModal from "../../../plugins/CustomModal";
import { HiOutlineTrash } from "react-icons/hi";
import editRole from "../../../../assets/images/usr_edit.png";
const PlanFeatureMapping = (props) => {
    const roleId = props.id;
    const roleName = props.name;
    const [featureList, setFeatureList] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [newFeatureName, setNewFeatureName] = useState("");
    const [newFeatureDesc, setNewFeatureDesc] = useState("");
    const [featureNameError, setFeatureNameError] = useState("");
    const [featureDesError, setFeatureDesError] = useState("");
    const [editedFeatureId, setEditedFeatureId] = useState(null);
    const [deletePopupShow, setDeletePopupShow] = useState(false);
    const [toDelFeatureId, setToDelFeatureId] = useState(null);
    const toast = useToast();
    useEffect(() => {
        loadAllPlanFeatures();
    }, []);
    const showDeletePopup = (id) => {
        setToDelFeatureId(id);
        setDeletePopupShow(true);
    };
    const addFeature = async () => {
        setFeatureNameError("");
        setFeatureDesError("");
        if (!newFeatureName) {
            setFeatureNameError("Feature Name is required.");
            return;
        }
        if (!newFeatureDesc) {
            setFeatureDesError("Feature description is required.");
            return;
        }
        const params = {
            featureName: newFeatureName,
            description: newFeatureDesc,
            id: editedFeatureId,
        };
        try {
            const response = await window.apiService.addPlanFeature(params);
            if (response.data.status === "OK") {
                if (response.data.message === "Service Plan Feature already exists.") {
                    toast.info(response.data.message);
                } else {
                    toast.success(response.data.message);
                    setNewFeatureName("");
                    setNewFeatureDesc("");
                    setEditedFeatureId(null);
                    setEditMode(false);
                    loadAllPlanFeatures();
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const loadAllPlanFeatures = async () => {
        try {
            const response = await window.apiService.loadAllFeatures();
            const allFeatures = response.data.recordInfo;
            const features = allFeatures.filter(item => item.isDeleted === 0 );
            // setFeatureList(features);
            setFeatureList(allFeatures)
        } catch (error) {
            console.log(error);
        }
    };
    const loadFeatureById = async (id) => {
        if (id) {
            try {
                const response = await window.apiService.loadFeatureById(id);
                const mappedFeatures = response.data.recordInfo || [];
                const mappedFeatureIds = new Set(mappedFeatures.map((mf) => mf.id));
                setFeatureList((prevList) =>
                    prevList.map((feature) => ({
                        ...feature,
                        isChecked: mappedFeatureIds.has(feature.id)
                    }))
                );
                setCheckedList(mappedFeatures.map((mf) => ({ planId: roleId, featureId: mf.id })));
            } catch (error) {
                console.error("Error loading features by plan ID:", error);
            }
        }
    };

    const deleteServiceFeature = async () => {
        if (toDelFeatureId) {
            try {
                const response = await window.apiService.deleteServiceFeature(toDelFeatureId);
                if (response.data.status == "OK") {
                    toast.success(response.data.message);
                    loadAllPlanFeatures();
                    loadFeatureById(roleId);
                    setDeletePopupShow(false);
                }
            } catch (error) {
                console.log(error);
                setDeletePopupShow(false);
            }
        }
    };

    return (
        <>
            <div className="chat container h-100">
                <Card className="shade h-100">
                    <Row>
                        <Col sm={12} md={12} lg={10} xl={10}>
                            <div className="role_container">
                                <Row className="mb-2 title_txt">
                                    <Col sm={12} md={12} lg={12} xl={12}>
                                        Manage Service Features
                                    </Col>
                                </Row>
                                <Row className="m_b8">
                                    <Col sm={12} md={10} lg={10} xl={10}>
                                        <Row className="m_b8">
                                            <Col sm={8} md={5} lg={5} xl={5}>
                                                <Input
                                                    className="inptRole rle_place"
                                                    placeholder={editMode ? "Edit Feature Name" : "New Feature Name"}
                                                    type="text"
                                                    maxLength={150}
                                                    value={newFeatureName}
                                                    onChange={(e) => {
                                                        setNewFeatureName(e.target.value);
                                                        setFeatureNameError("");
                                                    }}
                                                />
                                                {featureNameError && <div className="text-danger fz_13">{featureNameError}</div>}
                                            </Col>
                                            <Col sm={8} md={5} lg={5} xl={5}>
                                                <Input
                                                    className="inptRole rle_place"
                                                    placeholder="Description"
                                                    type="text"
                                                    maxLength={500}
                                                    value={newFeatureDesc}
                                                    onChange={(e) => {
                                                        setNewFeatureDesc(e.target.value);
                                                        setFeatureDesError("");
                                                    }}
                                                />
                                                {featureDesError && <div className="text-danger fz_13">{featureDesError}</div>}
                                            </Col>
                                            <Col sm={6} md={2} lg={2} xl={2} className="mt-2 custom-col-icn d-flex p-0">
                                                {editMode ? (
                                                    <>
                                                        <TiTick className="c_pointer plus_icn m_r15" title="Update" onClick={() => addFeature()} />
                                                        <FaTimes
                                                            className="c_pointer close_icn" title="Cancel"
                                                            onClick={() => {
                                                                setEditMode(false);
                                                                setNewFeatureName("");
                                                                setNewFeatureDesc("");
                                                                setEditedFeatureId(null);
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    <BsPlusLg onClick={() => addFeature()} className="c_pointer" title="Add" />
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="m_b8">
                                    <Col sm={12} md={10} lg={10} xl={10}>
                                        <div className="h_218 overflow-y-auto">
                                            <Table
                                                className="align-items-center table-flush brd_solid"
                                            >
                                                <thead className="thead-clr">
                                                    <tr>
                                                        <th scope="col">Feature</th>
                                                        <th scope="col">Description</th>
                                                        <th scope="col">Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {featureList && featureList.length > 0 ?
                                                        featureList.map((feature, index) => (
                                                            <React.Fragment key={index}>
                                                                <tr>
                                                                    <td>{feature.featureName}</td>
                                                                    <td>{feature.description}</td>
                                                                    <td>
                                                                        <div className="d-flex">
                                                                            {/* Edit Icon */}
                                                                            <img
                                                                                src={editRole}
                                                                                alt="Edit"
                                                                                className="mr-2 c_pointer"
                                                                                onClick={() => {
                                                                                    setEditMode(true);
                                                                                    setNewFeatureName(feature.featureName);
                                                                                    setNewFeatureDesc(feature.description);
                                                                                    setEditedFeatureId(feature.id);
                                                                                    setFeatureNameError("");
                                                                                    setFeatureDesError("");
                                                                                }}
                                                                            />

                                                                            {/* Delete Icon */}
                                                                            <HiOutlineTrash
                                                                                onClick={() => showDeletePopup(feature.id)}
                                                                                title="Delete"
                                                                                className="ques_trash c_pointer"
                                                                            />
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            </React.Fragment>
                                                        )) : <tr>
                                                            <td colSpan="3" className="text-center">
                                                                No Features Were Found
                                                            </td>
                                                        </tr>}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
            {/* ----------- Delete Confirmation Popup ---------- */}
            < CustomModal
                show={deletePopupShow}
                handleClose={() => setDeletePopupShow(false)}
                performSubmit={() => deleteServiceFeature()}
                title="Delete Confirmation"
                btnText="Yes"
                body={
                    <>
                        <p>Are you sure you want to delete?</p>
                    </>
                }
            />
        </>
    );
};

export default PlanFeatureMapping;
