export default {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    width: '100%',
    whiteSpace: 'pre-wrap', // Ensures text wraps properly
    wordBreak: 'break-word', // Ensures long words break
    fontFamily: 'monospace', // Optional: Ensures accurate character width
    height:'30px',
    overflowY: 'hidden', // Hides the scrollbar
    scrollbarWidth: 'none', // Hides scrollbar in Firefox
    msOverflowStyle: 'none'
  },
  '&multiLine': {
    highlighter: {
        paddingTop:5,
      border: '1px solid transparent',
    },
    input: {
      border: '0px',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      },
    },
    '&singleLine': {
      display: 'inline-block',
      highlighter: {
        padding: 1,
        border: '2px inset transparent',
      }
    },
    suggestions: {
      list: {
        position:'absolute',
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 14,
        overflow: 'scroll',
        height: '40vh',
        width: '50vh',
        top:'-50vh'
      },
      item: {
        padding: '5px 15px',
        '&focused': {
          backgroundColor: '#cee4e5',
    },
  },
    },
  }