import React, { useState, useEffect } from "react";
import { useToast } from "../../Toast";
import HeaderMenu from "../headerMenu";
import SideBar from "../SideBar";
import {
    Button,
    Card,
    Container,
    Row,
    Col,
    Table,
    Modal,
    Form,
    Dropdown,
    Tabs,
    Tab,
} from "react-bootstrap";
import { connect } from "react-redux";
import { BiChevronDownCircle, BiChevronUpCircle } from "react-icons/bi";
import { format, parseISO, differenceInDays } from "date-fns";
import { FaCircle, FaEllipsisV } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { isBefore } from "date-fns";
import { useSubscription } from "../../SubscriptionContext";
import { useAuth } from "../../AuthContext";
import loader from "../../../assets/images/loader.gif";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { RiFileCopy2Line } from "react-icons/ri";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import { ImNotification } from "react-icons/im";
import Pagination from "react-bootstrap/Pagination";

import SockJS from "sockjs-client";
import { Client } from "@stomp/stompjs";

import { FaRegCreditCard } from "react-icons/fa6";

import visaCard from "../../../assets/images/Visa_Inc._logo.svg.png";
import mastercard from "../../../assets/images/Mastercard-logo.svg.png";
import americancard from "../../../assets/images/american express.png";
import discovercard from "../../../assets/images/Discover_Card_logo.svg.png";
import jcbcard from "../../../assets/images/JCB_logo.svg.png";
import dinerscard from "../../../assets/images/Diners_Club_Logo3.svg";

const SOCKET_URL = process.env.REACT_APP_BASEURL + "/ws";

const SubscriptionPlanDetails = (props) => {
    const [activeTab, setActiveTab] = useState("history");
    const [showDropdown, setShowDropdown] = useState(false);
    const [isBillingHistoryVisible, setIsBillingHistoryVisible] = useState(false);
    const [planDetails, setPlanDetails] = useState({});
    const [planList, setPlanList] = useState([]);
    const [activePlanId, setActivePlanId] = useState(null);
    const [validity, setValidity] = useState(null);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState([]);
    const [cancelReason, setCancelReason] = useState("");
    const [cancelReasons, setCancelReasons] = useState("");
    const [reasonMissing, setReasonMissing] = useState(false);
    const [trialStatus, setTrialStatus] = useState("");
    const [trialEndDate, setTrialEndDate] = useState("");
    const [comment, setComment] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [maxMn, setMaxMn] = useState("");
    const [maxYr, setMaxYr] = useState("");
    const navigate = useNavigate();
    const toast = useToast();
    const { refreshSubscriptions } = useSubscription();
    const [userPlanHistory, setUserPlanHistory] = useState([]);
    const [userBillingHistory, setUserBillingHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const { login } = useAuth();
    const [enableOption, setEnableOption] = useState(false);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [plan, setPlan] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState("5");
    const [totalPages, setTotalPages] = useState("");
    const [totalBillPages, setTotalBillPages] = useState("");
    const [paymentPopup, setPaymentPopup] = useState(false);
    const [errors, setErrors] = useState({});
    const [action, setAction] = useState();

    const [planDetials, setPlanDetials] = useState();
    const [paymentDetails, setPaymentDetails] = useState({
        creditCardNumber: "",
        expirationDate: "",
        cvvCode: "",
        amount: "",
        holdername: "",
        recurring: true,
    });

    const [isCardModalOpen, setIsCardModalOpen] = useState(false);

    const [show, setShow] = useState(false);
    const [cards, setCards] = useState([]);
    let stompClient = null;

    const [selectedPaymentProfileId, setSelectedPaymentProfileId] = useState("");

    // State to handle form inputs
    const [cardDetails, setCardDetails] = useState({
        cardNumber: "",
        cardHolder: "",
        expiryDate: "",
        cvv: "",
        autoRenew: false,
    });
    const [paymentProfileId, setPaymentProfileId] = useState("");
    // State for form validation errors
    const [formErrors, setFormErrors] = useState({});
    const handlePageChange = (page) => {
        if (page <= totalPages && page > 0) {
            setCurrentPage(page);
        }
    };

    const handleBillPageChange = (page) => {
        if (page <= totalBillPages && page > 0) {
            setCurrentPage(page);
        }
    };

    const handleLimitChange = (newLimit) => {
        setRowsPerPage(newLimit);
        setCurrentPage(1);
    };

    useEffect(() => {
        loadUserPlanHistory(currentPage, rowsPerPage);
        loadUserBillingHistory(currentPage, rowsPerPage);
    }, [currentPage, rowsPerPage, props.userId]);

    const onToggle = async () => {
        if (enableOption) {
            // Allow the user to toggle to false
            setEnableOption(false);
            setLoading(true);
            try {
                const response = await window.apiService.cancelAutorenew(props.userId);
                toast.success(
                    response.data?.message || "Auto-renew disabled successfully"
                );
            } catch (err) {
                console.error("Error canceling auto-renew", err);
                toast.error(
                    err.response?.data?.message || "Failed to disable auto-renew"
                );
                setEnableOption(true);
            } finally {
                setLoading(false);
            }
        } else {
            toast.info(
                "Your plan is active, you cannot enable auto-renew at this time."
            );
        }
    };

    useEffect(() => {
        fetchAutoRenewStatus(props.userId);
    }, [props.userId]);

    const fetchAutoRenewStatus = async (userId) => {
        try {
            console.log("userid", userId);
            const data = await window.apiService.getAutorenewStatus(userId);
            if (data && data.data && data.data.autoRenew !== undefined) {
                setEnableOption(data.data.autoRenew); // Assuming API returns { data: { autoRenew: "true"/"false" } }
                console.log("autoRenew", data.data.autoRenew);
            } else {
                console.error("autoRenew field is missing in the response");
            }
            setLoading(false); // Set loading to false when data is fetched
        } catch (err) {
            setLoading(false);
            console.error("Error fetching auto-renew status", err);
        }
    };

    useEffect(() => {
        getPlansById();
        loadUserPlanHistory();
        loadUserBillingHistory();
    }, [props]);
    useEffect(() => {
        loadPlans();
        loadCancelReason();
    }, []);

    const loadPlans = async () => {
        setLoading(true);
        try {
            const response = await window.apiService.loadPlans();
            setPlanList(response.data.recordInfo);
            setLoading(false);
            refreshSubscriptions();
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };
    const loadCancelReason = async () => {
        try {
            const response = await window.apiService.listCancelreason();
            setCancelReasons(response.data.recordInfo);
        } catch (error) {
            console.log(error);
        }
    };
    const toggleBillingHistory = () => {
        setIsBillingHistoryVisible(!isBillingHistoryVisible);
    };

    const getPlansById = async () => {
        if (props) {
            try {
                const response = await window.apiService.getPlanById(props.userId);
                if (response.data.status === "OK") {
                    setActivePlanId(response.data.recordInfo.planId);
                    setValidity(response.data.recordInfo.endDate);
                    setEnableOption(response.data.recordInfo.autoRenew);
                    setTrialStatus(response.data.recordInfo.trialStatus);
                    setTrialEndDate(response.data.recordInfo.trialEndDate);
                    setPaymentProfileId(response.data.recordInfo.paymentProfileId);
                    setAction(response.data.recordInfo.action);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };
    const filteredPlans = planList.filter((plan) => plan.id === activePlanId);
    useEffect(() => {
        if (planList.length > 0 && activePlanId) {
            const filteredPlans = planList.filter((plan) => plan.id === activePlanId);
            if (filteredPlans.length > 0) {
                const filteredPlan = filteredPlans[0];
                const maxPriceMn = Math.max(
                    ...planList
                        .filter((plan) => plan.billingCycle == "monthly")
                        .map((plan) => plan.price)
                );
                const maxPriceYr = Math.max(
                    ...planList
                        .filter((plan) => plan.billingCycle == "yearly")
                        .map((plan) => plan.price)
                );
                setMaxMn(maxPriceMn);
                setMaxYr(maxPriceYr);
            } else {
                console.log("No active plan found.");
            }
        }
    }, [planList, activePlanId]);
    const handleCancel = (plan) => {
        setSelectedPlan(plan);
        setShowCancelModal(true);
    };

    const showRenewPopup = (plan) => {
        setPlan(plan);
        setShowConfirmPopup(true);
    };

    const handleRenew = async (plan) => {
        const today = new Date();
        const currentEndDate = new Date(validity);

        const remainingDays = differenceInDays(currentEndDate, today);
        const startDate = today;
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + plan.planPeriod + remainingDays);
        const formattedStartDate = format(startDate, "yyyy-MM-dd");
        const formattedEndDate = format(endDate, "yyyy-MM-dd");

        const params = {
            userId: props.userId,
            planId: plan.id,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            status: "active",
            action: "renewed",
        };
        try {
            const response = await window.apiService.addUserPlan(params);
            if (response.status === "OK") {
                toast.success("Plan renewed successfully");
                refreshSubscriptions();
                getPlansById();
                loadPlans();
                loadUserPlanHistory();
                setShowConfirmPopup(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateAutoRenew = async (renew) => {
        const params = {
            userId: props.userId,
            autoRenew: renew,
        };
        try {
            const response = await window.apiService.updateAutoRenew(params);
            if (response.status === "OK") {
                if (renew) {
                    toast.success("Enabled successfully");
                } else {
                    toast.success("Disabled successfully");
                }
                refreshSubscriptions();
                getPlansById();
                loadPlans();
                loadUserPlanHistory();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const confirmCancellation = async () => {
        setIsLoading(true);
        if (!cancelReason) {
            setReasonMissing(true);
            setIsLoading(false);
            return;
        }
        const params = {
            userId: props.userId,
            planId: selectedPlan.id,
            cancellationReasonId: cancelReason,
            additionalComments: comment,
            action: "canceled",
        };
        try {
            const response = await window.apiService.addCancelPlan(params);
            if (response.status === "OK") {
                setIsLoading(false);
                toast.success(response.message);
                setShowCancelModal(false);
                getPlansById();
                loadPlans();
                setCancelReason("");
                setComment("");
                loadUserPlanHistory();
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    const loadAllPlanFeatures = async () => {
        try {
            const response = await window.apiService.loadPlanCancelReasons();
            const cancelReason = response.data.recordInfo;
            setCancelReasons(cancelReason);
        } catch (error) {
            console.log(error);
        }
    };

    const loadUserPlanHistory = async () => {
        if (props) {
            try {
                const response = await window.apiService.getUserPlanHistory(
                    props.userId,
                    currentPage,
                    rowsPerPage
                );
                if (response.data.status === "OK") {
                    setUserPlanHistory(response.data.recordInfo);
                    setTotalPages(response.data.totalPages);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const loadUserBillingHistory = async () => {
        if (props) {
            try {
                const response = await window.apiService.getUserBillingHistory(
                    props.userId,
                    currentPage,
                    rowsPerPage
                );
                if (response.data.status === "OK") {
                    setUserBillingHistory(response.data.recordInfo);
                    setTotalBillPages(response.data.totalPages);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const hasPlanExpired = () => {
        const validityDate = new Date(validity);
        const today = new Date();
        validityDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);

        return validityDate < today;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPaymentDetails({ ...paymentDetails, [name]: value });

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    const hnadlePaymentPopup = () => {
        setPaymentPopup(false); // Open the payment modal
        setPaymentDetails({
            creditCardNumber: "",
            expirationDate: "",
            cvvCode: "",
            amount: "",
        });
    };

    const handlePayment = (e, status) => {
        e.preventDefault();
        const newErrors = {};
        // Check for empty fields
        if (!paymentDetails.creditCardNumber) {
            newErrors.creditCardNumber = "Card Number required.";
        }
        if (!paymentDetails.expirationDate) {
            newErrors.expirationDate = "Expire Date required.";
        }
        if (!paymentDetails.cvvCode) {
            newErrors.cvvCode = "CVV required.";
        }

        setErrors(newErrors);

        const startDate = new Date();
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + planDetials.planPeriod);

        const formattedStartDate = format(startDate, "yyyy-MM-dd");
        const formattedEndDate = format(endDate, "yyyy-MM-dd");

        const currentPlan = planList.find((p) => p.id === activePlanId);
        const currentPrice = currentPlan ? currentPlan.price : 0;

        const action = planDetials.price > currentPrice ? "upgraded" : "downgraded";
        // const [expMonth, expYear] = paymentDetails.expirationDate.split("/");
        const [expMonth, expYear] = paymentDetails.expirationDate && paymentDetails.expirationDate.split("/") || [];

        if (!expMonth || !expYear) {
            toast.error("Please provide a valid expiration date in MM/YY format.");
            return;
        }

        const secureData = {
            authData: {
                clientKey: process.env.REACT_APP_AUTHORIZE_CLIENTKEY,
                apiLoginID: process.env.REACT_APP_AUTHORIZE_LOGINKEY,
            },
            cardData: {
                cardNumber: paymentDetails.creditCardNumber,
                month: expMonth.trim(),
                year: expYear.trim(),
                cardCode: paymentDetails.cvvCode,
            },
        };


        if (window.Accept) {
            // setLoading(true);
            window.Accept.dispatchData(secureData, async (response) => {
                if (response.messages.resultCode === "Error") {
                    console.error("Error:", response.messages.message);
                    const errorMessages = response.messages.message.map((msg) => msg.text).join(", ");
                    toast.error(errorMessages);
                    setLoading(false);
                } else {
                    const { dataDescriptor, dataValue } = response.opaqueData;
                    window.Accept.dispatchData(secureData, async (subscriptionResponse) => {
                        if (subscriptionResponse.messages.resultCode === "Error") {
                            console.error("Subscription Error:", subscriptionResponse.messages.message);
                            const subErrorMessages = subscriptionResponse.messages.message.map((msg) => msg.text).join(", ");
                            toast.error(subErrorMessages);
                            setLoading(false);
                        } else {
                            const { dataDescriptor: reniewdataDescriptor, dataValue: reniewdataValueSub } = subscriptionResponse.opaqueData;


                            console.log("Payment Tokenized Data:", response.opaqueData);
                            console.log("Subscription Tokenized Data:", subscriptionResponse.opaqueData);


                            const params = {
                                userId: props.userId,
                                planId: planDetials.id,
                                startDate: formattedStartDate,
                                endDate: status == "trial" ? null : formattedEndDate,
                                status: status == "trial" ? "none" : "active",
                                action: "renewed",
                                actionScheduledDate: (status == "trial" || (action == "downgraded")) && new Date(validity) >= new Date() ? formattedStartDate : undefined,
                                userPlanAction: (status == "trial" || (action == "downgraded")) && new Date(validity) >= new Date() ? (status == "trial" ? status : action) : undefined,
                                actionPlanId: (status == "trial" || (action == "downgraded")) && new Date(validity) >= new Date() ? planDetials.id : undefined,
                                autoRenew: paymentDetails.recurring,
                            };

                            const requestData = {
                                dataDescriptor,
                                dataValue,
                                amount: planDetials.price,
                                userId: props.userId,
                                planId: planDetials.id,
                                holdername: paymentDetails.holdername,
                                paymentGateWay: "authorize.net",
                                jsonData: JSON.stringify(params),
                                reniewdataDescriptor,
                                reniewdataValueSub,
                                prevPlanId: status == "trial" ? activePlanId : planDetials.id,
                                startDate: formattedStartDate,
                                endDate: status == "trial" ? null : formattedEndDate,
                                status: status == "trial" ? "none" : "active",
                                action: "renewed",
                            };
                            try {
                                const paymentProcess = await window.apiService.addFeaturesInplan(requestData);
                                setPaymentPopup(false)
                                const result = paymentProcess.data;
                                setPaymentDetails({
                                    creditCardNumber: "",
                                    expirationDate: "",
                                    cvvCode: "",
                                    amount: "",
                                });
                                console.log(result);

                                // addUserPlans(plan)
                                console.log("Payment Processed:", result);
                            } catch (error) {
                                setLoading(false);
                                console.error("Backend Error:", error);
                                if (error.response && error.response.data && error.response.data.message) {
                                    toast.error(`${error.response.data.message}`);
                                } else {
                                    toast.error("An unexpected error occurred. Please try again.");
                                }
                            } finally {
                                // setLoading(false);
                                setPaymentPopup(false); // Close modal after submission
                            }
                        }
                    });
                }
            });
        } else {
            console.error("Accept.js not loaded.");
        }
    };

    const connectToWebSocket = () => {
        const socket = new SockJS(SOCKET_URL);
        stompClient = new Client({
            webSocketFactory: () => socket,
            reconnectDelay: 5000,
            debug: (str) => { console.log(str); },
            onConnect: () => {
                if (stompClient.connected) {
                    stompClient.unsubscribe(`/topic/renewplan`); // Unsubscribe before subscribing
                }
                stompClient.subscribe(`/topic/renewplan`, (messageOutput) => {
                    const id = JSON.parse(messageOutput.body); // Assuming the payload is a plain JSON object
                    if (id == props.userId) {
                        toast.success("Payment Successful")
                        setTimeout(() => {
                            toast.success("Plan renewed successfully");
                            refreshSubscriptions();
                            getPlansById();
                            loadPlans();
                            loadUserPlanHistory();
                            setShowConfirmPopup(false)
                            console.log("Received ID:", id);
                        }, 2000);

                    }
                    // console.log("Received message:", messageOutput);
                });
            },
            onStompError: (error) => {
                console.error('STOMP error:', error);
            },
        });
        stompClient.activate();
    };



    const disconnectWebSocket = () => {
        if (stompClient !== null) {
            stompClient.deactivate();
        }
    };

    useEffect(() => {
        connectToWebSocket();
        return () => {
            disconnectWebSocket();
        };
    }, []);

    const openCardModal = () => {
        setIsCardModalOpen(true);
    };

    // Function to close modal
    const closeCardModal = () => {
        setIsCardModalOpen(false);
    };

    const handleCardInputChange = (e) => {
        const { name, value } = e.target;
        setCardDetails({ ...cardDetails, [name]: value });
    };

    const CreatePaymentProfile = async () => {
        const data = {
            email: props.emailId,
            userId: props.userId,
        };
        try {
            const result = await window.apiService.createPaymentProfile(data);
            const response = result.data;
            console.log("profile created", response);
        } catch (err) {
            console.log(err);
        }
    };

    const handleAddCard = async () => {
        let errors = {};
        if (!cardDetails.cardNumber) errors.cardNumber = "Card number is required";
        if (!cardDetails.cardHolder) errors.cardHolder = "Card holder is required";
        if (!cardDetails.expiryDate) errors.expiryDate = "Expiry date is required";
        if (!cardDetails.cvv) errors.cvv = "CVV is required";

        setFormErrors(errors);

        const [expMonth, expYear] =
            (cardDetails.expiryDate && cardDetails.expiryDate.split("/")) || [];

        if (!expMonth || !expYear) {
            toast.error("Please provide a valid expiration date in MM/YY format.");
            return;
        }

        const secureData = {
            authData: {
                clientKey: process.env.REACT_APP_AUTHORIZE_CLIENTKEY,
                apiLoginID: process.env.REACT_APP_AUTHORIZE_LOGINKEY,
            },
            cardData: {
                cardNumber: cardDetails.cardNumber,
                month: expMonth.trim(),
                year: expYear.trim(),
                cardCode: cardDetails.cvv,
            },
        };

        if (window.Accept) {
            window.Accept.dispatchData(secureData, async (response) => {
                if (response.messages.resultCode === "Error") {
                    console.error("Error:", response.messages.message);
                    const errorMessages = response.messages.message
                        .map((msg) => msg.text)
                        .join(", ");
                    toast.error(errorMessages);
                    setLoading(false);
                } else {
                    const { dataDescriptor, dataValue } = response.opaqueData;
                    const data = {
                        customerProfileId: paymentProfileId,
                        dataDescriptor: dataDescriptor,
                        dataValue: dataValue,
                        userId: props.userId,
                    };
                    try {
                        const result = await window.apiService.addPaymentCard(data);
                        const response = result.data;
                        console.log(response);
                        closeCardModal();
                    } catch (err) {
                        console.log(err);
                        closeCardModal();
                    }
                }
            });
        }
    };

    const handleShowCards = async () => {
        setLoading(true);
        try {
            const response = await window.apiService.getPaymentCards(
                paymentProfileId
            ); // Replace with your actual API URL
            setCards(response.data); // Store the card details
            setShow(true); // Show the modal
        } catch (error) {
            console.error("Error fetching card details:", error);
        }
        setLoading(false);
    };

    const handleClose = () => setShow(false);

    const handleCardSelect = (profilePaymentId) => {
        setSelectedPaymentProfileId(profilePaymentId);
        handleClose();
        updateAutorenew(profilePaymentId);
    };

    const updateAutorenew = async (profilePaymentId) => {
        const data = {
            userId: props.userId,
            profileId: paymentProfileId,
            paymentProfileId: profilePaymentId,
        };

        try {
            const response = await window.apiService.updateAutorenew(data);
            console.log("Payment Profile Sent:", response.data);
            // You can handle the response from the API here
        } catch (error) {
            console.error("Error sending payment profile:", error);
            // Handle error appropriately
        }
    };

    const getCardLogo = (cardType) => {
        if (!cardType) return null; // Prevent error when cardType is null or undefined

        switch (cardType.toLowerCase()) {
            case "visa":
                return visaCard;
            case "mastercard":
                return mastercard;
            case "amex":
            case "americanexpress":
                return americancard;
            case "discover":
                return discovercard;
            case "jcb":
                return jcbcard;
            case "diners club":
                return dinerscard;
            default:
                return null; // Return null if no match
        }
    };

    return (
        <>
            <HeaderMenu />
            <SideBar />
            <div className="main-content ml_200">
                <Card className="shade">
                    <Container fluid>
                        <div className="header-body pt-3">
                            <Row>
                                <Col lg="12" xl="12">
                                    <div className="d-flex mt-2 toggle_gap">
                                        <label className="font-weight-500 fz_14">
                                            My Plan and Billings
                                        </label>
                                    </div>
                                </Col>
                                <hr />
                                <Col lg="12" xl="12">
                                    <div className="d-flex mt-2 toggle_gap">
                                        <label className="font-weight-500 fz_14 a_clr">
                                            Plan Details
                                        </label>
                                    </div>
                                </Col>
                                {/* <Col lg="12" xl="12">
                                    <div className="d-flex mt-2 toggle_gap">
                                        <Card className="plan-card tog">
                                            <label className="font-weight-500 fz_14">
                                                Auto Renew
                                            </label>
                                            <label className="toggle-switch">
                                                <input
                                                    type="checkbox"
                                                    checked={enableOption}
                                                    onChange={() => onToggle()}
                                                />
                                                <span className="switch" />
                                            </label>
                                        </Card>
                                    </div>
                                </Col> */}
                                {/* {enableOption && <Col lg="12" xl="12">
                                    <div className="d-flex mt-2 toggle_gap">
                                        <Card className="plan-card tog">
                                            <label className="font-weight-500 fz_14 name_blue">
                                                <span className="pad_5"><ImNotification /></span>
                                                Auto-renewal is enabled. Your plan will renew automatically, and the payment will be deducted on the renewal date
                                            </label>
                                        </Card>
                                    </div>
                                </Col>} */}
                                {filteredPlans && filteredPlans.length > 0 ? (
                                    filteredPlans.map((plan, idx) => (
                                        <React.Fragment key={idx}>
                                            <Col
                                                xs="12"
                                                sm="6"
                                                md="6"
                                                lg="4"
                                                xl="3"
                                                className="mb-4 mt-4"
                                            >
                                                <Card className="plan-card">
                                                    <div className="ribbon">
                                                        {hasPlanExpired() &&
                                                            plan.isTrial == 0 &&
                                                            trialStatus != "active"
                                                            ? "Expired"
                                                            : "Active"}
                                                    </div>
                                                    <Card.Header
                                                        className="text-primary"
                                                        style={{ backgroundColor: "#ece9e2" }}
                                                    >
                                                        Current Plan
                                                        {plan.isTrial == 1 && (
                                                            <span className="freePln">Free</span>
                                                        )}
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <div className="row fts_15">
                                                            <div className="col-10">
                                                                <span>
                                                                    <FaCircle className="icn_blur text-basic" />
                                                                </span>{" "}
                                                                {plan.servicePlan}
                                                                {trialStatus == "active" && (
                                                                    <span className="freePln">
                                                                        Trial Activated
                                                                    </span>
                                                                )}
                                                                {trialStatus == "none" &&
                                                                    new Date(validity).toDateString() ===
                                                                    new Date().toDateString() && (
                                                                        <span className="freePln fs_12">
                                                                            Expires today
                                                                        </span>
                                                                    )}
                                                            </div>
                                                            {plan.isTrial == 0 && trialStatus != "active" && (
                                                                <div className="d-flex col-2 justify-content-end">
                                                                    <Dropdown
                                                                        show={showDropdown}
                                                                        onToggle={(isOpen) =>
                                                                            setShowDropdown(isOpen)
                                                                        }
                                                                    >
                                                                        <Dropdown.Toggle
                                                                            as="span"
                                                                            id="dropdown-custom-components"
                                                                            className="p-0 m-0 three_drp"
                                                                        >
                                                                            <PiDotsThreeVerticalBold className="three_dots_icn text-basic" />
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item
                                                                                onClick={() => {
                                                                                    if (action === "canceled") {
                                                                                        toast.warning(
                                                                                            "Your plan is already canceled."
                                                                                        ); // Show warning message
                                                                                    } else {
                                                                                        handleCancel(plan); // Proceed with cancelation if not already canceled
                                                                                    }
                                                                                }}
                                                                            >
                                                                                Cancel Plan
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div>{plan.description}</div>
                                                        {plan.isTrial == 1 && (
                                                            <ul className="list-unstyled mt-3 mb-4">
                                                                {plan.features.map((feature, featureIdx) => (
                                                                    <li key={featureIdx}>
                                                                        <TiTick className="tck" />{" "}
                                                                        {feature.featureName}
                                                                        {/* <span className="text-muted"> ({feature.description})</span> */}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                        {plan.isTrial === 0 && (
                                                            <>
                                                                <h5 className="mt-2">
                                                                    ${plan.price}
                                                                    <small>
                                                                        {" "}
                                                                        /{" "}
                                                                        {plan.billingCycle === "monthly"
                                                                            ? "month"
                                                                            : "year"}
                                                                    </small>
                                                                </h5>
                                                                <div className="mb-2">
                                                                    {trialStatus === "active" ? (
                                                                        new Date(trialEndDate).toDateString() ===
                                                                            new Date().toDateString() ? (
                                                                            "Trial expires today"
                                                                        ) : (
                                                                            `Trial expires on ${format(
                                                                                new Date(trialEndDate),
                                                                                "dd MMM yyyy"
                                                                            )}`
                                                                        )
                                                                    ) : (
                                                                        <span>
                                                                            Renew at{" "}
                                                                            {format(
                                                                                new Date(validity),
                                                                                "dd MMM yyyy"
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                        {hasPlanExpired() &&
                                                            plan.isTrial == 0 &&
                                                            trialStatus != "active" ? (
                                                            <Button
                                                                className="w_100"
                                                                variant="primary"
                                                                onClick={() => {
                                                                    setPaymentPopup(true);
                                                                    setPlanDetials(plan);
                                                                    setPaymentDetails({ recurring: true });
                                                                }}
                                                            >
                                                                Renew Plan
                                                            </Button>
                                                        ) : (
                                                            plan.isTrial == 0 &&
                                                            trialStatus != "active" && (
                                                                <>
                                                                    <div className="up_btn align-items-center d-flex justify-content-end">
                                                                        <Button
                                                                            className="w_70 "
                                                                            variant="outline-primary"
                                                                            style={{ margin: "11px 0" }}
                                                                            onClick={() => {
                                                                                // showRenewPopup(plan)
                                                                                setPaymentPopup(true);
                                                                                setPlanDetials(plan);
                                                                                setPaymentDetails({ recurring: true });
                                                                            }}
                                                                        >
                                                                            Renew Plan
                                                                        </Button>
                                                                        <Button
                                                                            className="w_70"
                                                                            variant="outline-primary"
                                                                            onClick={() =>
                                                                                navigate("/dashboard/profile", {
                                                                                    state: true,
                                                                                })
                                                                            }
                                                                        >
                                                                            {/* <span>{(plan.billingCycle === "monthly" && (plan.price >= maxMn)) || (plan.billingCycle === "yearly" && (plan.price >= maxYr)) ? 'Downgrade Plan' : 'Upgrade Plan'}</span> */}
                                                                            <span>Change Plan</span>
                                                                        </Button>
                                                                    </div>
                                                                </>
                                                            )
                                                        )}

                                                        {plan.isTrial == 1 && (
                                                            <Button
                                                                className="w_100"
                                                                variant="outline-primary"
                                                                onClick={() =>
                                                                    navigate("/dashboard/profile", {
                                                                        state: true,
                                                                    })
                                                                }
                                                            >
                                                                Upgrade Plan
                                                            </Button>
                                                        )}
                                                        {trialStatus == "active" && (
                                                            <Button
                                                                className="w_100"
                                                                variant="outline-primary"
                                                                onClick={() =>
                                                                    navigate("/dashboard/profile", {
                                                                        state: true,
                                                                    })
                                                                }
                                                            >
                                                                {/* <span>{(plan.billingCycle === "monthly" && (plan.price >= maxMn)) || (plan.billingCycle === "yearly" && (plan.price >= maxYr)) ? 'Downgrade Plan' : 'Upgrade Plan'}</span> */}
                                                                <span>Change Plan</span>
                                                            </Button>
                                                        )}
                                                    </Card.Body>
                                                </Card>
                                            </Col>

                                            {plan.isTrial == 0 && (
                                                <Col
                                                    xs="12"
                                                    sm="6"
                                                    md="6"
                                                    lg="4"
                                                    xl="3"
                                                    className="mb-4 mt-4"
                                                >
                                                    <Card className="plan-card">
                                                        <Card.Header
                                                            className="text-primary"
                                                            style={{ backgroundColor: "#ece9e2" }}
                                                        >
                                                            Current Plan Features
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <ul className="list-unstyled  feat fts_15">
                                                                {plan.features.map((feature, featureIdx) => (
                                                                    <li key={featureIdx}>
                                                                        <TiTick className="tck" />{" "}
                                                                        {feature.featureName}
                                                                        {/* <span className="text-muted"> ({feature.description})</span> */}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )}
                                        </React.Fragment>
                                    ))
                                ) : loading ? (
                                    <div className="loader-center">
                                        <img
                                            className="loader-image"
                                            src={loader}
                                            alt="Loading..."
                                        />
                                    </div>
                                ) : (
                                    // <div className="row d-align mx-0 pb-4">
                                    //     <div className="no-data font-weight-600 w-100 text-center fz_14">
                                    //         Sorry, there is no data to be displayed
                                    //     </div>
                                    // </div>
                                    <div className="loader-center">
                                        <img
                                            className="loader-image"
                                            src={loader}
                                            alt="Loading..."
                                        />
                                    </div>
                                )}

                                <Col lg="12" xl="12">
                                    <div className="d-flex justify-content-end mb-3">
                                        <button
                                            className="btn btn-outline-primary btn-sm me-2"
                                            onClick={() => {
                                                openCardModal();
                                                if (paymentProfileId == null) {
                                                    CreatePaymentProfile();
                                                }
                                            }}
                                        >
                                            Add Payment Card
                                        </button>
                                        <button
                                            className="btn btn-primary btn-sm"
                                            onClick={handleShowCards}
                                            disabled={loading}
                                        >
                                            {loading
                                                ? "Loading..."
                                                : "Choose Your Card for Auto-Renew"}
                                        </button>
                                    </div>
                                    <div className="pro_tab_div pln_blg">
                                        <Tabs
                                            id="plan-history-tabs"
                                            activeKey={activeTab}
                                            onSelect={(k) => {
                                                setActiveTab(k);
                                                setCurrentPage(1);
                                                setRowsPerPage("5");
                                            }}
                                            className="mb-3"
                                            style={{ border: "1px solid #e9e6df" }}
                                        >
                                            {/* Plan History Tab */}
                                            <Tab
                                                eventKey="history"
                                                title={
                                                    <span className="pro_tab_span">
                                                        <RiFileCopy2Line
                                                            className={`dashboardTabIcon ${activeTab == "history" ? "clr_darkblue" : ""
                                                                }`}
                                                        />
                                                        Plan History
                                                    </span>
                                                }
                                            >
                                                <Table
                                                    className="align-items-center text-center table-flush brd_solid"
                                                    responsive
                                                >
                                                    <thead className="thead-clr">
                                                        <tr>
                                                            <th>Sl. No</th>
                                                            <th>Plan Name</th>

                                                            {/* <th>Amount</th> */}
                                                            <th>Status</th>
                                                            <th>Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {userPlanHistory.length > 0 ? (
                                                            userPlanHistory.map((history, index) => (
                                                                <tr key={history.id}>
                                                                    <td>
                                                                        {(currentPage - 1) * rowsPerPage +
                                                                            index +
                                                                            1}
                                                                    </td>
                                                                    <td>
                                                                        {planList.find(
                                                                            (plan) => plan.id === history.planId
                                                                        )?.servicePlan || ""}
                                                                    </td>

                                                                    <td>
                                                                        {history.action.charAt(0).toUpperCase() +
                                                                            history.action.slice(1)}
                                                                    </td>
                                                                    <td>
                                                                        {format(
                                                                            new Date(history.startDate),
                                                                            "dd MMM yyyy"
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="6" className="text-center">
                                                                    No History Found
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                                {userPlanHistory.length != 0 ? (
                                                    <div className="d-flex justify-content-between align-items-center border_att pag_pad bck_pag">
                                                        <div className="select-container">
                                                            <span className="select-label">Rows </span>
                                                            <select
                                                                className="select-element"
                                                                value={rowsPerPage}
                                                                onChange={(e) =>
                                                                    handleLimitChange(e.target.value)
                                                                }
                                                            >
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                        </div>

                                                        <Pagination className="mb-0">
                                                            <Pagination.Prev
                                                                onClick={() =>
                                                                    currentPage > 1 &&
                                                                    handlePageChange(currentPage - 1)
                                                                }
                                                                disabled={currentPage === 1}
                                                            />

                                                            <Pagination.Item
                                                                active={currentPage === 1}
                                                                onClick={() => handlePageChange(1)}
                                                            >
                                                                1
                                                            </Pagination.Item>

                                                            {currentPage > 4 && (
                                                                <Pagination.Ellipsis disabled />
                                                            )}

                                                            {Array.from(
                                                                { length: 5 },
                                                                (_, i) => currentPage - 2 + i
                                                            )
                                                                .filter((page) => page > 1 && page < totalPages)
                                                                .map((page) => (
                                                                    <Pagination.Item
                                                                        key={page}
                                                                        active={page === currentPage}
                                                                        onClick={() => handlePageChange(page)}
                                                                    >
                                                                        {page}
                                                                    </Pagination.Item>
                                                                ))}

                                                            {currentPage < totalPages - 3 && (
                                                                <Pagination.Ellipsis disabled />
                                                            )}

                                                            {totalPages > 1 && (
                                                                <Pagination.Item
                                                                    active={currentPage === totalPages}
                                                                    onClick={() => handlePageChange(totalPages)}
                                                                >
                                                                    {totalPages}
                                                                </Pagination.Item>
                                                            )}

                                                            <Pagination.Next
                                                                onClick={() =>
                                                                    currentPage < totalPages &&
                                                                    handlePageChange(currentPage + 1)
                                                                }
                                                                disabled={currentPage === totalPages}
                                                            />
                                                        </Pagination>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {/* )} */}
                                            </Tab>

                                            <Tab
                                                eventKey="billing"
                                                title={
                                                    <span className="pro_tab_span">
                                                        <FaMoneyCheckDollar
                                                            className={`dashboardTabIcon ${activeTab == "billing" ? "clr_darkblue" : ""
                                                                }`}
                                                        />
                                                        Billing Details
                                                    </span>
                                                }
                                            >
                                                <Table
                                                    className="align-items-center text-center table-flush brd_solid"
                                                    responsive
                                                >
                                                    <thead className="thead-clr">
                                                        <tr>
                                                            <th>Sl.No</th>
                                                            <th>Transaction Id</th>
                                                            <th>Plan Name</th>
                                                            <th>Amount Paid</th>
                                                            <th>Date</th>
                                                            <th>status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {userBillingHistory.length > 0 ? (
                                                            userBillingHistory.map((history, index) => (
                                                                <tr key={history.id}>
                                                                    <td>
                                                                        {(currentPage - 1) * rowsPerPage +
                                                                            index +
                                                                            1}
                                                                    </td>
                                                                    <td>{history.transactionId}</td>
                                                                    <td>
                                                                        {planList.find(
                                                                            (plan) => plan.id === history.planId
                                                                        )?.servicePlan || ""}
                                                                    </td>

                                                                    <td>{history.paymentAmount}</td>
                                                                    <td>
                                                                        {format(
                                                                            new Date(history.paymentDate),
                                                                            "dd MMM yyyy"
                                                                        )}
                                                                    </td>
                                                                    <td>{history.paymentStatus}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="6" className="text-center">
                                                                    No Details Found
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                                {userBillingHistory.length != 0 ? (
                                                    <div className="d-flex justify-content-between align-items-center border_att pag_pad bck_pag">
                                                        <div className="select-container">
                                                            <span className="select-label">Rows </span>
                                                            <select
                                                                className="select-element"
                                                                value={rowsPerPage}
                                                                onChange={(e) =>
                                                                    handleLimitChange(e.target.value)
                                                                }
                                                            >
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                        </div>

                                                        <Pagination className="mb-0">
                                                            <Pagination.Prev
                                                                onClick={() =>
                                                                    currentPage > 1 &&
                                                                    handleBillPageChange(currentPage - 1)
                                                                }
                                                                disabled={currentPage === 1}
                                                            />

                                                            <Pagination.Item
                                                                active={currentPage === 1}
                                                                onClick={() => handleBillPageChange(1)}
                                                            >
                                                                1
                                                            </Pagination.Item>

                                                            {currentPage > 4 && (
                                                                <Pagination.Ellipsis disabled />
                                                            )}

                                                            {Array.from(
                                                                { length: 5 },
                                                                (_, i) => currentPage - 2 + i
                                                            )
                                                                .filter(
                                                                    (page) => page > 1 && page < totalBillPages
                                                                )
                                                                .map((page) => (
                                                                    <Pagination.Item
                                                                        key={page}
                                                                        active={page === currentPage}
                                                                        onClick={() => handleBillPageChange(page)}
                                                                    >
                                                                        {page}
                                                                    </Pagination.Item>
                                                                ))}

                                                            {currentPage < totalBillPages - 3 && (
                                                                <Pagination.Ellipsis disabled />
                                                            )}

                                                            {totalBillPages > 1 && (
                                                                <Pagination.Item
                                                                    active={currentPage === totalBillPages}
                                                                    onClick={() =>
                                                                        handleBillPageChange(totalBillPages)
                                                                    }
                                                                >
                                                                    {totalBillPages}
                                                                </Pagination.Item>
                                                            )}

                                                            <Pagination.Next
                                                                onClick={() =>
                                                                    currentPage < totalBillPages &&
                                                                    handleBillPageChange(currentPage + 1)
                                                                }
                                                                disabled={currentPage === totalBillPages}
                                                            />
                                                        </Pagination>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Card>

                {/* Cancel Plan Modal */}
                <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Cancel Plan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="cancelReasonSelect">
                            <Form.Label>
                                Reason for cancellation <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                                value={cancelReason}
                                onChange={(e) => {
                                    setCancelReason(e.target.value);
                                    setReasonMissing(false);
                                }}
                                aria-label="Select cancellation reason"
                            >
                                <option value="">Select a reason</option>
                                {cancelReasons &&
                                    cancelReasons.map((reasonObj) => (
                                        <option key={reasonObj.id} value={reasonObj.id}>
                                            {reasonObj.reason}
                                        </option>
                                    ))}
                            </Form.Select>
                            {reasonMissing && (
                                <div className="text-danger mt-2">
                                    Please select a reason for cancellation.
                                </div>
                            )}
                        </Form.Group>
                        <Form.Group controlId="cancelReason" className="mt-3">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                placeholder="Enter additional comments"
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="outline-danger"
                            onClick={() => setShowCancelModal(false)}
                        >
                            Close
                        </Button>
                        <Button
                            className="u_review_btn"
                            variant="primary"
                            onClick={confirmCancellation}
                        >
                            Confirm Cancellation
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* ----------- Renewal Confirmation Popup ---------- */}
                <Modal
                    size="sm"
                    show={showConfirmPopup}
                    onHide={() => setShowConfirmPopup(false)}
                >
                    <Modal.Header>
                        <Modal.Title
                            id="example-custom-modal-styling-title"
                            style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                            {enableOption ? "Plan Renewal Info" : "Plan Renewal Confirmation"}
                        </Modal.Title>
                        <span onClick={() => setShowConfirmPopup(false)} title="Close">
                            <AiOutlineClose className="c_pointer" />
                        </span>
                    </Modal.Header>
                    <Modal.Body className="bordr">
                        {!enableOption ? (
                            <span>Are you sure you want to renew the plan?</span>
                        ) : (
                            <span>
                                Your plan is already set to auto-renew. Renewing manually will
                                override the current auto-renewal.
                            </span>
                        )}
                    </Modal.Body>
                    <Modal.Footer className="bordr pad_13">
                        {!enableOption && (
                            <Button
                                variant="primary"
                                className="del_bttn"
                                onClick={() => handleRenew(plan)}
                            >
                                Yes
                            </Button>
                        )}
                        <Button
                            variant="danger"
                            className="del_bttn c_pointer"
                            onClick={() => setShowConfirmPopup(false)}
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal size="md" show={paymentPopup} onHide={hnadlePaymentPopup}>
                    <Modal.Header className="d-flex  align-items-center position-relative">
                        <Modal.Title>
                            <div
                                style={{ fontSize: "20px", fontWeight: 600, color: "#4A4A4A" }}
                            >
                                Payment Details
                            </div>

                            <div
                                style={{ fontSize: "13px", fontWeight: 400, color: "#8D8A8A" }}
                                className="mt-3"
                            >
                                All transactions are secure and encrypted
                            </div>
                        </Modal.Title>
                        <span
                            onClick={hnadlePaymentPopup}
                            title="Close"
                            className="c_pointer position-absolute"
                            style={{ right: "50px" }}
                        >
                            <AiOutlineClose />
                        </span>
                    </Modal.Header>

                    <Modal.Body
                        style={{
                            background: "#F0F0F0",
                            margin: "0px 20px 0px 20px",
                            borderRadius: "30px",
                        }}
                    >
                        <div className="card-logos d-flex justify-content-between align-items-center flex-wrap mb-1">
                            <div style={{ fontSize: "18px", fontWeight: "600" }}>
                                Card Details
                            </div>
                            <img
                                src={visaCard}
                                alt="Visa"
                                title="Visa"
                                className="card-logo img-fluid"
                                style={{ height: "10px", width: "auto" }}
                            />
                            <img
                                src={mastercard}
                                alt="MasterCard"
                                title="MasterCard"
                                className="card-logo img-fluid"
                                style={{ height: "15px", width: "auto" }}
                            />
                            <img
                                src={americancard}
                                alt="American Express"
                                title="American Express"
                                className="card-logo img-fluid"
                                style={{ height: "18px", width: "auto" }}
                            />
                            <img
                                src={discovercard}
                                alt="Discover"
                                title="Discover"
                                className="card-logo img-fluid"
                                style={{ height: "10px", width: "auto" }}
                            />
                            <img
                                src={dinerscard}
                                alt="Diners Club"
                                title="Diners Club"
                                className="card-logo img-fluid"
                                style={{ height: "20px", width: "auto" }}
                            />
                            <img
                                src={jcbcard}
                                alt="JCB"
                                title="JCB"
                                className="card-logo img-fluid"
                                style={{ height: "18px", width: "auto" }}
                            />
                        </div>

                        <div
                            className="mt-3"
                            style={{ fontSize: "14px", fontWeight: "bold", color: "#28a745" }}
                        >
                            Amount will be charged:{" "}
                            <span style={{ fontSize: "18px", color: "#dc3545" }}>
                                ${planDetials?.price || ""}
                            </span>
                        </div>

                        <Form>
                            <div className="row ">
                                {/* Card Holder Name */}
                                <div className="col-md-6">
                                    <Form.Group controlId="creditCardNumber">
                                        <Form.Label className="mt-3">Card Number</Form.Label>
                                        <div className="input-group">
                                            <Form.Control
                                                type="text"
                                                name="creditCardNumber"
                                                value={paymentDetails.creditCardNumber}
                                                onChange={handleInputChange}
                                                placeholder="4111 1111 1111 1111"
                                            />
                                            <span className="input-group-text">
                                                <FaRegCreditCard />
                                            </span>
                                        </div>
                                        {errors.creditCardNumber && (
                                            <div className="text-danger mt-1">
                                                {errors.creditCardNumber}
                                            </div>
                                        )}
                                    </Form.Group>
                                </div>

                                <div className="col-md-6">
                                    <Form.Group controlId="holdername">
                                        <Form.Label className="mt-3">Card Holder Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="holdername"
                                            value={paymentDetails.holdername}
                                            onChange={handleInputChange}
                                            placeholder="Holder Name"
                                        />
                                        {errors.holdername && (
                                            <div className="text-danger mt-1">
                                                {errors.holdername}
                                            </div>
                                        )}
                                    </Form.Group>
                                </div>
                            </div>
                            {/* Grid layout for holder name, expiration date, and CVV */}
                            <div className="row mt-3">
                                {/* Card Holder Name */}

                                {/* Expiration Date */}
                                <div className="col-md-6">
                                    <Form.Group controlId="expirationDate">
                                        <Form.Label>Expire date</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="expirationDate"
                                            value={paymentDetails.expirationDate}
                                            onChange={handleInputChange}
                                            placeholder="MM/YY"
                                        />
                                        {errors.expirationDate && (
                                            <div className="text-danger mt-1">
                                                {errors.expirationDate}
                                            </div>
                                        )}
                                    </Form.Group>
                                </div>

                                {/* CVV Code */}
                                <div className="col-md-6">
                                    <Form.Group controlId="cvvCode">
                                        <Form.Label>CVV Code</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="cvvCode"
                                            value={paymentDetails.cvvCode}
                                            onChange={handleInputChange}
                                            placeholder="123"
                                        />
                                        {errors.cvvCode && (
                                            <div className="text-danger mt-1">{errors.cvvCode}</div>
                                        )}
                                    </Form.Group>
                                </div>
                            </div>
                        </Form>

                        <div className="w-100 px-3">
                            <Form.Group className="d-flex align-items-center ">
                                <Form.Label
                                    className="mb-0 mt-4 mr-4 "
                                    style={{ fontSize: "14px", fontWeight: "600" }}
                                >
                                    {" "}
                                    Auto Renewal
                                </Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="recurringPaymentToggle"
                                    checked={paymentDetails.recurring}
                                    onChange={() => {
                                        setPaymentDetails({
                                            ...paymentDetails,
                                            recurring: !paymentDetails.recurring,
                                        });
                                    }}
                                />
                            </Form.Group>

                            {/* Show message when recurring payment is active */}
                            {paymentDetails.recurring && (
                                <div>
                                    <div
                                        className="text-success mt-2"
                                        style={{ fontWeight: "600", fontSize: "12px" }}
                                    >
                                        Your Auto Renewal is active.
                                    </div>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div
                            className="d-flex justify-content-center w-100"
                            style={{ padding: "0 30px 10px 30px" }}
                        >
                            <Button
                                variant="primary"
                                onClick={handlePayment}
                                className="me-2 px-4 w-50"
                            >
                                Pay Now
                            </Button>
                            <Button
                                variant="danger"
                                onClick={hnadlePaymentPopup}
                                className="px-4 w-50"
                            >
                                Cancel
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                <Modal size="md" show={isCardModalOpen} onHide={closeCardModal}>
                    <Modal.Header className="d-flex align-items-center position-relative">
                        <Modal.Title>
                            <div
                                style={{ fontSize: "20px", fontWeight: 600, color: "#4A4A4A" }}
                            >
                                Add Payment Card
                            </div>
                            <div
                                style={{ fontSize: "13px", fontWeight: 400, color: "#8D8A8A" }}
                                className="mt-3"
                            >
                                All transactions are secure and encrypted
                            </div>
                        </Modal.Title>
                        <span
                            onClick={closeCardModal}
                            title="Close"
                            className="c_pointer position-absolute"
                            style={{ right: "50px" }}
                        >
                            <AiOutlineClose />
                        </span>
                    </Modal.Header>

                    <Modal.Body
                        style={{
                            background: "#F0F0F0",
                            margin: "0px 20px 0px 20px",
                            borderRadius: "30px",
                        }}
                    >
                        <div className="card-logos d-flex justify-content-between align-items-center flex-wrap mb-1">
                            <div style={{ fontSize: "18px", fontWeight: "600" }}>
                                Card Details
                            </div>
                            <img
                                src={visaCard}
                                alt="Visa"
                                title="Visa"
                                className="card-logo img-fluid"
                                style={{ height: "10px", width: "auto" }}
                            />
                            <img
                                src={mastercard}
                                alt="MasterCard"
                                title="MasterCard"
                                className="card-logo img-fluid"
                                style={{ height: "15px", width: "auto" }}
                            />
                            <img
                                src={americancard}
                                alt="American Express"
                                title="American Express"
                                className="card-logo img-fluid"
                                style={{ height: "18px", width: "auto" }}
                            />
                            <img
                                src={discovercard}
                                alt="Discover"
                                title="Discover"
                                className="card-logo img-fluid"
                                style={{ height: "10px", width: "auto" }}
                            />
                            <img
                                src={dinerscard}
                                alt="Diners Club"
                                title="Diners Club"
                                className="card-logo img-fluid"
                                style={{ height: "20px", width: "auto" }}
                            />
                            <img
                                src={jcbcard}
                                alt="JCB"
                                title="JCB"
                                className="card-logo img-fluid"
                                style={{ height: "18px", width: "auto" }}
                            />
                        </div>

                        {/* Card Input Form */}
                        <Form>
                            <div className="row">
                                {/* Card Number */}
                                <div className="col-md-6">
                                    <Form.Group>
                                        <Form.Label className="mt-3">Card Number</Form.Label>
                                        <div className="input-group">
                                            <Form.Control
                                                type="text"
                                                name="cardNumber"
                                                value={cardDetails.cardNumber}
                                                onChange={handleCardInputChange}
                                                placeholder="4111 1111 1111 1111"
                                            />
                                            <span className="input-group-text">
                                                <FaRegCreditCard />
                                            </span>
                                        </div>
                                        {formErrors.cardNumber && (
                                            <div className="text-danger mt-1">
                                                {formErrors.cardNumber}
                                            </div>
                                        )}
                                    </Form.Group>
                                </div>

                                {/* Card Holder Name */}
                                <div className="col-md-6">
                                    <Form.Group>
                                        <Form.Label className="mt-3">Card Holder Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="cardHolder"
                                            value={cardDetails.cardHolder}
                                            onChange={handleCardInputChange}
                                            placeholder="Holder Name"
                                        />
                                        {formErrors.cardHolder && (
                                            <div className="text-danger mt-1">
                                                {formErrors.cardHolder}
                                            </div>
                                        )}
                                    </Form.Group>
                                </div>
                            </div>

                            {/* Expiry Date & CVV */}
                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <Form.Group>
                                        <Form.Label>Expire Date</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="expiryDate"
                                            value={cardDetails.expiryDate}
                                            onChange={handleCardInputChange}
                                            placeholder="MM/YY"
                                        />
                                        {formErrors.expiryDate && (
                                            <div className="text-danger mt-1">
                                                {formErrors.expiryDate}
                                            </div>
                                        )}
                                    </Form.Group>
                                </div>

                                <div className="col-md-6">
                                    <Form.Group>
                                        <Form.Label>CVV Code</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="cvv"
                                            value={cardDetails.cvv}
                                            onChange={handleCardInputChange}
                                            placeholder="123"
                                        />
                                        {formErrors.cvv && (
                                            <div className="text-danger mt-1">{formErrors.cvv}</div>
                                        )}
                                    </Form.Group>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        <div
                            className="d-flex justify-content-center w-100"
                            style={{ padding: "0 30px 10px 30px" }}
                        >
                            <Button
                                variant="primary"
                                onClick={() => {
                                    if (paymentProfileId == null) {
                                        CreatePaymentProfile();
                                    } else {
                                        handleAddCard();
                                    }
                                }}
                                className="w-50"
                            >
                                Add Card
                            </Button>
                            <Button
                                variant="danger"
                                onClick={closeCardModal}
                                className="w-50"
                            >
                                Cancel
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Select a Card</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
                        {cards.length > 0 ? (
                            <div>
                                {cards.map((card, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            background: "#f1f3f5", // Soft, lighter background for each card
                                            padding: "20px", // Increased padding for more space inside
                                            borderRadius: "12px", // More rounded corners
                                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Softer and larger shadow for depth
                                            marginBottom: "15px", // More space between cards
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "15px", // Increased gap between card items
                                            cursor: "pointer", // Pointer cursor for interaction
                                            transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth hover effects
                                            paddingBottom: "20px", // Additional padding for bottom space
                                            border: "1px solid #ddd", // Added border to the card
                                        }}
                                        onClick={() =>
                                            handleCardSelect(card.customerPaymentProfileId)
                                        }
                                        onMouseOver={(e) => {
                                            e.currentTarget.style.transform = "scale(1.03)";
                                            e.currentTarget.style.boxShadow =
                                                "0px 6px 12px rgba(0, 0, 0, 0.15)";
                                        }}
                                        onMouseOut={(e) => {
                                            e.currentTarget.style.transform = "scale(1)";
                                            e.currentTarget.style.boxShadow =
                                                "0px 4px 10px rgba(0, 0, 0, 0.1)";
                                        }}
                                    >
                                        {/* Card Logo */}
                                        {getCardLogo(card.payment.creditCard.cardType) && (
                                            <img
                                                src={getCardLogo(card.payment.creditCard.cardType)}
                                                alt={card.payment.creditCard.cardType}
                                                style={{
                                                    width: "50px", // Larger logo
                                                    height: "auto",
                                                    objectFit: "contain", // Ensures the logo maintains aspect ratio
                                                }}
                                            />
                                        )}

                                        <div style={{ fontSize: "15px", color: "#495057" }}>
                                            {" "}
                                            {/* Different text color */}
                                            <div style={{ marginBottom: "10px" }}>
                                                {" "}
                                                {/* Space between card type and number */}
                                                <strong>Card Type:</strong>{" "}
                                                {card.payment.creditCard.cardType || "Unknown"}
                                            </div>
                                            <div>
                                                <strong>Card Number:</strong>{" "}
                                                {card.payment.creditCard.cardNumber}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p style={{ color: "#6c757d" }}>No card details found.</p> // More subtle color for the "No card" message
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        userId: state.user.userId,
        userType: state.user.userType,
        profileId: state.user.profileId,
        emailId: state.user.email,
    };
};

export default connect(mapStateToProps)(SubscriptionPlanDetails);
